import { Box, Button, styled } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { getImageSrc } from "helpers/getImageSrc";
import useParamOutlet from "hooks/useParamOutlet";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectEmployeesByServiceIdAndOutletId,
  selectOrganization,
  selectServiceVariantById,
} from "store/selectors";
import { trackEvent } from "tracking";

import { useBookingContext } from "../bookingContext";
import AnyEmployeeRadio from "./BookingAnyEmployeeRadio";
import EmployeeRadio from "./BookingEmployeeRadio";

type BookingEmployeeListModalProps = {
  index: Nullable<number>;

  open: boolean;
  onClose: () => void;
};

export default function BookingEmployeeListModal(props: BookingEmployeeListModalProps) {
  const { t } = useTranslation("bookingPage");

  const { index, open, onClose } = props;

  const organization = useSelector(selectOrganization);

  const { id: outletId } = useParamOutlet();

  const {
    state: { selectedSlot, serviceEmployees },
    dispatch,
  } = useBookingContext();

  const [serviceVariantId, employeeId] = index !== null ? serviceEmployees[index] : [-1, null];

  const [checkedEmployeeId, setCheckedEmployeeId] = useState(employeeId);

  useEffect(() => setCheckedEmployeeId(employeeId), [employeeId]);

  useEffect(() => {
    if (organization?.id && organization?.name && open) {
      trackEvent(
        "Resource Selection Explored",
        { id: organization.id, name: organization.name },
        {}
      );
    }
  }, [organization, open]);

  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId));

  const allEmployees = useSelector(
    selectEmployeesByServiceIdAndOutletId(serviceVariant?.service || -1, outletId)
  );

  const getIsEmployeeAvailable = (employeeId: number) => {
    if (index === null) return false;
    return Boolean(selectedSlot?.employees[index].includes(employeeId));
  };

  const setEmployeeAndHideModal = () => {
    if (index === null) return;

    dispatch({
      type: "setEmployee",
      payload: { index, employeeId: checkedEmployeeId },
    });
    onClose();
  };

  // TODO: Fix title to match design (include start and end time)
  return (
    <DrawerModal
      title={t("selectAvailableStaff")}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "80vh", padding: "16px" } }}
    >
      <Box sx={{ marginTop: "25px" }}>
        <AnyEmployeeRadio
          checkedEmployeeId={checkedEmployeeId}
          setCheckedEmployeeId={setCheckedEmployeeId}
        />

        {allEmployees.map(({ id, avatar, t: { fullName } }) => (
          <EmployeeRadio
            key={id}
            name={t(fullName)}
            checked={checkedEmployeeId === id}
            available={getIsEmployeeAvailable(id)}
            onClick={() => setCheckedEmployeeId(id)}
            avatarSrc={getImageSrc(avatar)}
          />
        ))}

        <ModalAction>
          <Button fullWidth onClick={setEmployeeAndHideModal} size="medium">
            {t("onSelectEmployee")}
          </Button>
        </ModalAction>
      </Box>
    </DrawerModal>
  );
}

const ModalAction = styled(Box)(() => ({
  position: "absolute",
  width: "calc(100% - 32px)",
  bottom: "16px",
}));
