import {
  Box,
  Grid,
  Stack,
  styled,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import PeepLogo from "assets/images/PeepLogo";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { Languages, TranslationNamespaces } from "configs/i18n/TranslationTypes";
import { DateTime, Settings } from "luxon";
import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Outlet as RouterOutlet } from "react-router-dom";

const USER_LANGUAGE = "language";
const { year } = DateTime.now();

export default function FooterLayout() {
  const { t, i18n } = useTranslation("misc");

  const setLanguage = useCallback(
    (language: Languages) => {
      i18n.changeLanguage(language);

      if (language === Languages.arabic) {
        Settings.defaultLocale = language + "-KW-u-nu-latn";
      } else {
        Settings.defaultLocale = language;
      }

      // This would set the direction value on the top level HTML element (CSS)
      document.dir = i18n.dir();
    },
    [i18n]
  );

  useEffect(() => {
    const storedLanguage = localStorage.getItem(USER_LANGUAGE);

    if (storedLanguage === Languages.arabic || storedLanguage === Languages.english) {
      setLanguage(storedLanguage);
    }
  }, [setLanguage]);

  function selectLanguage(_: any, selectedLanguage: Languages) {
    setLanguage(selectedLanguage);
    localStorage.setItem(USER_LANGUAGE, selectedLanguage);
  }

  const areNamespacesLoaded = Object.values(TranslationNamespaces).every((ns) =>
    i18n.hasLoadedNamespace(ns)
  );
  if (!areNamespacesLoaded) return <CentredSpinnerBox />;

  return (
    <>
      <Box display="flex" flexDirection="column" height="100%">
        <Box flexGrow={1}>
          <RouterOutlet />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column" width="100%" position="static" bottom={0}>
        <StackStyle>
          <Grid container spacing={0.5} alignItems="center">
            <Grid item xs={2}>
              <ToggleButtonGroupStyle value={i18n.language} exclusive onChange={selectLanguage}>
                <ToggleButton value={Languages.english}>En</ToggleButton>
                <ToggleButton value={Languages.arabic}>عربي</ToggleButton>
              </ToggleButtonGroupStyle>
            </Grid>

            <Grid item xs={8} display="flex" flexDirection="row" justifyContent="center">
              <CopyRightText>{t("allRightsReserved", { year })}</CopyRightText>
            </Grid>

            <Grid item xs={2} display="flex" flexDirection="row" justifyContent="flex-end">
              <LogoLink href="https://www.bookpeep.com">
                <PeepLogo />
              </LogoLink>
            </Grid>
          </Grid>
        </StackStyle>
      </Box>
    </>
  );
}

const LogoLink = styled("a")({
  height: "1.25rem",
  width: "3.5rem",
  display: "block",
});

const StackStyle = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  padding: theme.spacing(2.5, 2),
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  flexDirection: "row",
}));

const CopyRightText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontSize: "11px",
  fontWeight: 600,
}));

const ToggleButtonGroupStyle = styled(ToggleButtonGroup)(({ theme }) => ({
  maxHeight: "26px",
  ".MuiButtonBase-root": {
    maxWidth: "32px",
    borderRadius: "8px !important",
    border: `1px solid ${theme.palette.primary.main} !important`,
    marginRight: theme.spacing(0.5),
    fontSize: "11px",
    lineHeight: "16px",
    ":last-child": {
      marginRight: "0",
    },
  },
}));
