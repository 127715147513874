import * as Sentry from "@sentry/react";
import { useEffect } from "react";
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";

if (process.env.NODE_ENV === "production")
Sentry.init({
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  dsn: process.env.REACT_APP_SENTRY_DSN,

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.breadcrumbsIntegration(),

    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),

    // Enable offline support
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,

  // By default Sentry SDKs normalize any context to a depth of 3, which in the case of sending Redux state you probably will want to increase that
  normalizeDepth: 10,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

// Sentry Redux Integration
// WARNING: if there are some data that we store in redux that we should not send to Sentry, we need to add some options to disable sending them to sentry
export const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  // Optionally pass options
});
