import { LoadingButton } from "@mui/lab";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { selectAppointmentById } from "store/slices/appointments/appointmentsSelectors";
import { cancelAppointment } from "store/slices/appointments/appointmentsSlice";
import { trackEvent } from "tracking";
import { notify } from "utils/SnackbarProvider";

const CancelAppointmentModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation(["reviewAppointmentPage", "errors"]);

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const { appointmentId } = useParams();

  const organization = useSelector(selectOrganization);
  const appointment = useSelector(selectAppointmentById(appointmentId || ""));

  const currentTime = DateTime.local();

  const appointmentStartTime = appointment
    ? DateTime.fromISO(appointment.appointmentTimeRange.startTime, {
        zone: "utc",
      })
    : null;

  const diffInMinutes = appointmentStartTime
    ? appointmentStartTime.diff(currentTime, ["minutes"])
    : null;

  const dispatch = useDispatch();

  const handleAppointmentCancellation = () => {
    if (appointmentId) {
      setIsRequestLoading(true);
      dispatch(cancelAppointment({ id: appointmentId, status: "CANCELLED" }))
        .unwrap()
        .then(() => {
          if (organization?.id && organization?.name && appointment && diffInMinutes) {
            trackEvent(
              "Appointment Cancelled",
              { id: organization.id, name: organization.name },
              { AppointmentId: appointmentId, MinutesBeforeStart: diffInMinutes.minutes }
            );
          }
          setOpen(false);
        })
        .catch((error: any) => {
          setIsRequestLoading(false);
          setOpen(false);
          notify(
            `${t("errors:cancelAppointment.couldNotCancelAppointment")} ${
              error?.response?.data?.detail ?? ""
            }`,
            "error",
            {
              shouldPersist: true,
            }
          );
        });
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box p={3}>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3} bgcolor="bg.blank">
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                {t("promptBookingCancellation")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider variant="fullWidth" />

        <Box pt={2}>
          <DialogActions>
            <Grid container justifyContent="space-between" spacing={3} bgcolor="bg.paper">
              <Grid item xs={12}>
                <LoadingButton
                  size="small"
                  color="error"
                  loading={isRequestLoading}
                  fullWidth
                  onClick={handleAppointmentCancellation}
                >
                  {t("common:cancel")}
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CancelAppointmentModal;
