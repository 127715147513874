import type { AnyAction } from "redux";

/**
 * Use this function inside RTK's addMatcher to match all actions dispatched through a specific slice.
 *
 * @param sliceName The slice name to match actions from
 */
export default function matchSlice(sliceName: string) {
  return ({ type }: AnyAction) => typeof type === "string" && type.startsWith(sliceName);
}
