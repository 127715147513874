/**
 * returns the phone number without the country code assuming "you sent valid values"
 * @param phoneNumber
 * @param countryCode
 * @returns
 */

const getPhoneNumberWithoutCountryCode = (
  phoneNumberWithCountryCode: string,
  countryCode: string
): string => {
  if (phoneNumberWithCountryCode.length < countryCode.length) {
    return phoneNumberWithCountryCode;
  }

  const phoneNumberWithoutCountryCode = phoneNumberWithCountryCode.substring(countryCode.length);

  return phoneNumberWithoutCountryCode;
};

export default getPhoneNumberWithoutCountryCode;
