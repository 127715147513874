type BriefOrganization = {
  id: number;
  name: string;
};

export const trackEvent = (eventName: string, organization: BriefOrganization, properties: any) => {
  return;
  // mixpanel.get_group("Partner", `${organization.id}`).set({ $name: organization.name });
  // mixpanel.track(eventName, {
  //   ...properties,
  //   "Partner Id": `${organization.id}`,
  //   "Partner Name": organization.name,
  // });
};

export const setUserForTracking = (
  user: ProfileUser | null,
  organization: BriefOrganization | null
) => {
  return;
  // if (user && organization) {
  //   mixpanel.identify(user ? `${user.id}` : "");
  //   mixpanel.set_group("Partner", organization.id);
  //   mixpanel.people.set({
  //     $name: `${user.firstName} ${user.lastName}`,
  //     $email: user.email,
  //     "Partner Id": organization.id,
  //     "Partner Name": organization.name,
  //   });
  // }
};
