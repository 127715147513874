import { InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { forwardRef, useImperativeHandle, useRef } from "react";

import CountryCodeAutocomplete, {
  CountryCodeAutocompleteProps as CountryCodeAutocompletePropsType,
} from "./CountryCodeAutocomplete";

type PhoneFieldProps = TextFieldProps & {
  CountryCodeAutocompleteProps: Omit<CountryCodeAutocompletePropsType, "focusPhoneField">;
};

const PhoneField = forwardRef<HTMLInputElement, PhoneFieldProps>(
  ({ CountryCodeAutocompleteProps, InputProps, inputProps, ...phoneFieldProps }, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => {
      if (!inputRef.current) throw new Error("TODO: learn how to do this with proper TS solution"); // TODO: read throw
      return inputRef.current;
    });

    const focusPhoneField = () => {
      if (inputRef.current && inputRef.current instanceof HTMLElement) inputRef.current.focus();
    };

    return (
      <TextField
        inputProps={{
          ...inputProps,
          inputMode: "tel",
        }}
        InputProps={{
          ...InputProps,
          startAdornment: (
            <InputAdornment position="start">
              <CountryCodeAutocomplete
                focusPhoneField={focusPhoneField}
                {...CountryCodeAutocompleteProps}
              />
            </InputAdornment>
          ),
        }}
        inputRef={inputRef}
        {...phoneFieldProps}
      />
    );
  }
);

export default PhoneField;
