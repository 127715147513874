import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateEmployee } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const employeeAdapter = createEntityAdapter<Employee>({
  selectId: (employee) => employee.id,
});

const initialState = employeeAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const employeeSlice = createSlice({
  name: "employees",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const employeesWithTranslationKeys = payload.employees.map((employee) => {
          return { ...employee, t: translateEmployee(employee.id) };
        });

        employeeAdapter.setAll(state, employeesWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default employeeSlice.reducer;
