import { Remove } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import truncate from "helpers/truncate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectCartArticleSummary, selectOrganization } from "store/selectors";
import { removeArticleFromCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

export default function PurchasableItemsSummary() {
  const { t } = useTranslation(["bookingPage", "reviewAppointmentPage"]);

  const dispatch = useDispatch();

  const organization = useSelector(selectOrganization);
  const articles = useSelector(selectCartArticleSummary);

  return (
    <Box display="flex" flexDirection="column" width="100%" py={1.5}>
      <Box
        display="flex"
        width="100%"
        flexDirection="column"
        rowGap={1}
        justifyContent="center"
        py={1}
      >
        <Typography variant="primary" align="center" fontWeight="bold">
          {t("reviewAppointmentPage:yourItems")}
        </Typography>
      </Box>

      {articles.map((article, index) => {
        const removeArticle = () => {
          if (organization?.id && organization?.name) {
            trackEvent(
              "Item removed from cart",
              { id: organization.id, name: organization.name },
              { from: "Booking" }
            );
          }
          dispatch(removeArticleFromCart(index));
        };

        const articleTitle = `${t(article.title[0])}`;

        const truncatedString = truncate({ string: articleTitle, maxCharacters: 20 });

        return (
          <Box
            key={article.startTime}
            display="flex"
            justifyContent="space-between"
            borderRadius={1}
            p={[2, 2.5]}
            bgcolor="bg.secondary"
          >
            <Box
              display="flex"
              justifyContent="space-between"
              gap={1}
              alignItems="center"
              width="100%"
            >
              <Box display="flex" alignItems="center" columnGap={1}>
                <Typography variant="secondary" fontWeight="bold">
                  {truncatedString.string}
                </Typography>
              </Box>

              <Box display="flex" alignItems="center" justifyContent="flex-end" columnGap={1}>
                <Typography variant="secondary">
                  {article.startTime} - {article.endTime}
                </Typography>

                <IconButton
                  onClick={removeArticle}
                  sx={{ width: "0.875rem", height: "0.875rem", padding: 1.1 }}
                  color="error"
                >
                  <Remove sx={{ fontSize: "0.875rem" }} />
                </IconButton>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
