import { DateTime } from "luxon";

/**
 * takes an ISO date time string and returns a formatted date and time string
 *
 * @param dateTime ISO string
 * @returns formatted date and time in this format "Saturday, August 27, 10:00 PM"
 */

const getFormattedDateAndTime = (dateTime: string): string =>
  DateTime.fromISO(dateTime).toLocaleString({
    day: "numeric",
    month: "short",
    hour: "numeric",
    minute: "2-digit",
  });

export default getFormattedDateAndTime;
