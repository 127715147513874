import { styled } from "@mui/material";

/**
 * Function to get palette color from theme object from a string object notation
 *
 * @param {*} theme MuiTheme object
 * @param {string} colorKey the color object path as string (e.g. "primary.main")
 * @returns {string} colorValue the value of the color in the palette of provided theme
 */
const getThemePaletteColor = (theme, colorKey) =>
  colorKey.split(".").reduce((obj, index) => obj[index], theme.palette);

const StyledSvg = styled("svg")(({ color, theme }) => ({
  fill: getThemePaletteColor(theme, color) || color,
}));

export default StyledSvg;
