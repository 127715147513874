import { Grid } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { useSelector } from "store";
import { selectOutletIdBySlug, selectPackagesByOutletId } from "store/selectors";

import PurchasableCard from "./PurchasableCard";

const PackageList = () => {
  const paramOutlet = useParamOutlet(true);

  const outletSlug = paramOutlet?.slug || "";

  const outlet = useSelector(selectOutletIdBySlug(outletSlug));

  const allPackages = useSelector(selectPackagesByOutletId(outlet || 0));

  return (
    <>
      {allPackages.map((pkg) => (
        <Grid key={pkg?.id ?? 0} item xs={12}>
          <PurchasableCard packageId={pkg.id} />
        </Grid>
      ))}
    </>
  );
};

export default PackageList;
