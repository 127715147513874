import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { onlinePaymentAdapter } from "./onlinePaymentsSlice";

export const selectOnlinePaymentsState = (state: RootState) => state.onlinePayments;

const { selectById, selectAll } = onlinePaymentAdapter.getSelectors(selectOnlinePaymentsState);

export const selectAllOnlinePayments = selectAll;

export const selectOnlinePaymentIsLoading = (state: RootState) =>
  state.onlinePayments.status === SliceStatus.LOADING;

export const selectOnlinePaymentById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectOnlinePaymentsByAppointmentId = (id: number | string) => (state: RootState) =>
  selectAll(state).filter((onlinePayment) => id && +onlinePayment.appointment === +id);
