/**
 * This function should filter subscriptions by item id and item type
 * @param activeSubscriptions
 * @param itemId
 * @param itemType
 * @returns filteredSubscriptionsByItem
 */

import { callSortAscendingByKey } from "helpers/sort";
import {
  ProcessedSubscriptionPurchase,
  RedeemableSubscriptionItemOption,
} from "types/Subscription";

export const getSubscriptionsByItem = (
  activeSubscriptions: ProcessedSubscriptionPurchase[],
  itemId: number,
  itemType: RedeemableSubscriptionItemOption
) => {
  let filteredSubscriptionsByItem;

  if (itemType === RedeemableSubscriptionItemOption.PackageVariant) {
    filteredSubscriptionsByItem = activeSubscriptions.filter(
      (subscription) => subscription[itemType] === itemId
    );
  } else {
    filteredSubscriptionsByItem = activeSubscriptions.filter((subscription) => {
      return subscription[itemType].includes(itemId);
    });
  }

  return filteredSubscriptionsByItem;
};

/**
 * This function should sort subscriptions by earliest expiration date (ascending order)
 * @param subscriptionsDataWithExpirationDate
 * @returns
 */

export const getSortedSubscriptionsByEarliestExpiration = (
  subscriptionsDataWithExpirationDate: ProcessedSubscriptionPurchase[]
) => {
  const sortedSubscriptionsByEarliestExpiration = subscriptionsDataWithExpirationDate.sort(
    callSortAscendingByKey("expirationDate")
  );

  return sortedSubscriptionsByEarliestExpiration;
};
