import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  DialogTitle as MuiDialogTitle,
  Slide,
  SlideProps,
  Typography,
  styled,
} from "@mui/material";
import ArrowLeft from "poc/rtl-icons/ArrowLeft";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return (
    <Slide direction="up" ref={ref} {...props}>
      {props?.children}
    </Slide>
  );
});

type FullWidthModalProps = {
  open: boolean;
  onClose: () => void;

  title?: string;
  children: JSX.Element;
  navActions?: JSX.Element;
  footer?: JSX.Element;
};

export default function FullWidthModal({
  open,
  onClose,
  title,
  children,
  navActions,
  footer,
}: FullWidthModalProps) {
  const { t } = useTranslation();

  return (
    <Dialog
      fullScreen
      fullWidth
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      scroll="paper"
    >
      <DialogTitle>
        <Grid container spacing={2} justifyContent="space-between" alignItems="center">
          <Grid item xs={4}>
            <Button variant="text" size="small" startIcon={<ArrowLeft />} onClick={onClose}>
              {t("back")}
            </Button>
          </Grid>

          <Grid item xs={4}>
            {title && (
              <Typography variant="h3" color="primary" align="center">
                {title}
              </Typography>
            )}
          </Grid>

          <Grid item xs={4} display="flex" justifyContent="flex-end">
            {navActions && navActions}
          </Grid>
        </Grid>
      </DialogTitle>

      <DialogContent>{children}</DialogContent>

      <DialogActions sx={{ padding: 0 }}>{footer && footer}</DialogActions>
    </Dialog>
  );
}

const DialogTitle = styled(MuiDialogTitle)(({ theme }) => ({
  display: "flex",
  alignItems: "center",

  backgroundColor: theme.palette.bg.secondary,
  boxShadow: theme.shadows.divider,

  padding: theme.spacing(1, 2),
  width: "100%",
  maxHeight: "56px",
  minHeight: "56px",
}));
