import { DateTime } from "luxon";

export default function getEarliestCartArticleSlot(articles: CartArticle[]) {
  let earliestTime: DateTime | null = null;

  articles.forEach((article) => {
    article.slots.forEach((slot) => {
      const slotTime = DateTime.fromISO(slot.start);

      if (!earliestTime || slotTime < earliestTime) earliestTime = slotTime;
    });
  });

  return earliestTime as DateTime | null;
}
