/**
 * 🎁 Note: Dear future developers, please remember to update the return of both parallel and sequential packages when adding new fields to the cart article. 🎁 - Anfal Your Life Saver
 *
 * @param startTime
 * @param serviceEmployees
 * @param subscriptionPurchaseId
 * @param serviceVariant
 * @param packageVariant
 * @returns
 */

import { DateTime } from "luxon";
import { PackageVariantWithServiceVariants } from "store/selectors";
import { calculateDeposit } from "utils/deposit";

type ServiceIndex = number;
type StaffId = Nullable<number>;
type PackageEmployee = [ServiceIndex, StaffId];

type ServiceEmployee = number;

function generateCartArticle(
  startTime: DateTime,
  serviceEmployees: PackageEmployee[] | ServiceEmployee,
  subscriptionPurchaseId: Nullable<number>,
  depositPercentage: Nullable<number>,
  serviceVariant?: ServiceVariant,
  packageVariant?: PackageVariantWithServiceVariants
) {
  if (packageVariant) {
    if (packageVariant.parallel) {
      const slots = packageVariant.serviceVariants.map((serviceVariant, index) => {
        const endTime = startTime.plus({ minutes: serviceVariant.duration });
        let end = "";

        if (endTime.toISODate() !== startTime.toISODate()) {
          end = `${startTime.toISODate()}T23:59:00.000+03:00`;
        } else {
          end = endTime.toISO();
        }

        return {
          serviceVariant: serviceVariant.id,
          staff: typeof serviceEmployees === "number" ? null : serviceEmployees[index][1],
          start: startTime.toISO(),
          end,
        };
      });

      return {
        serviceVariant: null,
        packageVariant: packageVariant.id,
        totalPrice: Number(packageVariant.price),
        subscriptionPurchaseId,
        slots,
        amountToBePaid: calculateDeposit(depositPercentage, Number(packageVariant.price)),
      };
    } else {
      let nextStartTime = startTime;

      const slots = packageVariant.serviceVariants.map((serviceVariant, index) => {
        const start = nextStartTime.toISO();

        const endTime = nextStartTime.plus({ minutes: serviceVariant.duration });
        let end = "";

        if (endTime.toISODate() !== startTime.toISODate()) {
          end = `${startTime.toISODate()}T23:59:00.000+03:00`;
        } else {
          end = endTime.toISO();
        }

        nextStartTime = nextStartTime.plus({ minute: serviceVariant.duration });

        return {
          serviceVariant: serviceVariant.id,
          staff: typeof serviceEmployees === "number" ? null : serviceEmployees[index][1],
          start,
          end,
        };
      });
      return {
        serviceVariant: null,
        packageVariant: packageVariant.id,
        totalPrice: Number(packageVariant.price),
        subscriptionPurchaseId,
        slots,
        amountToBePaid: calculateDeposit(depositPercentage, Number(packageVariant.price)),
      };
    }
  } else if (serviceVariant) {
    const { duration, id, price } = serviceVariant;
    const start = startTime.toISO();
    const endTime = startTime.plus({ minutes: duration });
    let end = "";

    if (endTime.toISODate() !== startTime.toISODate()) {
      end = `${startTime.toISODate()}T23:59:00.000+03:00`;
    } else {
      end = endTime.toISO();
    }

    return {
      serviceVariant: id,
      packageVariant: null,

      totalPrice: Number(price),

      slots: [
        {
          serviceVariant: id,
          staff: typeof serviceEmployees === "number" ? serviceEmployees : null,
          start,
          end,
        },
      ],
      amountToBePaid: calculateDeposit(depositPercentage, Number(serviceVariant.price)),
      subscriptionPurchaseId,
    };
  }
}

export default generateCartArticle;
