import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DateTime } from "luxon";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useSelector } from "store";
import {
  selectActiveSubscriptionByOutletIdAndItemId,
  selectCart,
  selectPackageVariantById,
  selectPackageVariantsLoading,
} from "store/selectors";
import { RedeemableSubscriptionItemOption } from "types/Subscription";

import ArticleItemCard from "../BookingArticleItemCard";
import ArticleSummary from "../BookingArticleSummary";
import BookingCartSummary from "../BookingCartSummary";
import { useBookingContext } from "../bookingContext";
import EmployeeSelect from "../BookingEmployeeSelect";
import SlotPicker from "../BookingSlotPicker";
import GoToCartButton from "../GoToCartButton";
import PackageBookingAddToCartButton from "./PackageBookingAddToCartButton";

export default function PackageBookingPage() {
  const { t } = useTranslation("bookingPage");

  const { state } = useBookingContext();

  const packageVariantId = state.packageVariantId;

  const cart = useSelector(selectCart);

  const [cartDate, setCartDate] = useState<DateTime | null>(
    cart.date ? DateTime.fromISO(cart.date) : DateTime.now()
  );

  const {
    state: { subscriptionPurchaseId },
    dispatch: contextDispatch,
  } = useBookingContext();

  const subscriptions = useSelector(
    selectActiveSubscriptionByOutletIdAndItemId(
      cart.outlet || 0,
      packageVariantId || 0,
      RedeemableSubscriptionItemOption.PackageVariant
    )
  );

  const isLoading = useSelector(selectPackageVariantsLoading);
  const packageVariant = useSelector(selectPackageVariantById(packageVariantId));

  if (isLoading) return null;
  if (!packageVariant) return <Navigate to="/" />;

  const { totalDuration, price } = packageVariant;

  const cartHasArticles = !!cart.articles.length;

  const handleToggleSubscriptionActivation = (subscriptionId: Nullable<number>) => {
    contextDispatch({
      type: "setSubscriptionPurchase",
      payload: subscriptionId === subscriptionPurchaseId ? null : subscriptionId,
    });
  };

  return (
    <>
      <PageWrapper container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t("pickDateAndTime")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <ArticleItemCard
            title={t(packageVariant.t.name)}
            duration={totalDuration}
            price={Number(price)}
            subscriptions={subscriptions}
            handleToggleSubscriptionActivation={handleToggleSubscriptionActivation}
            appliedSubscription={subscriptionPurchaseId}
            selectedDate={cartDate}
          />
        </Grid>

        <Grid item xs={12}>
          <SlotPicker setCartDate={setCartDate} />
        </Grid>

        <Grid item xs={12} mt={2}>
          <EmployeeSelect />
        </Grid>
      </PageWrapper>

      <Grid container spacing={3} p={3}>
        <Grid item xs={12}>
          <ArticleSummary />
        </Grid>

        <Grid item xs={cartHasArticles ? 9 : 12}>
          <PackageBookingAddToCartButton />
        </Grid>

        {cartHasArticles && (
          <Grid item xs={3}>
            <GoToCartButton />
          </Grid>
        )}

        <Grid item xs={12}>
          <BookingCartSummary />
        </Grid>
      </Grid>
    </>
  );
}

const PageWrapper = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.bg.secondary,
  overflowX: "hidden",
  boxShadow: "divider",
}));
