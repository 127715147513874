import { Box, Button, Typography } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import OutletBookingContext from "pages/OutletPage/outletContext";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { addArticleToCart, clearCartArticles } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

type StartNewBookingModalProps = {
  cartArticle: Nullable<CartArticle>;
  open: boolean;
  onClose: () => void;
};

const StartNewBookingModal = ({ cartArticle, open, onClose }: StartNewBookingModalProps) => {
  const { t } = useTranslation("outletPage");

  const organization = useSelector(selectOrganization);

  const { setSelectedPurchasable } = useContext(OutletBookingContext);

  const dispatch = useDispatch();

  const handleProceed = () => {
    if (cartArticle) {
      dispatch(clearCartArticles());

      dispatch(addArticleToCart(cartArticle));

      setSelectedPurchasable(null);

      if (organization?.id && organization?.name) {
        trackEvent("Lost Cart", { id: organization.id, name: organization.name }, {});

        trackEvent(
          "Cart Initiated",
          { id: organization.id, name: organization.name },
          { isResourceSelected: false }
        );
      }

      onClose();
    }
  };

  return (
    <DrawerModal
      title={t("startNewBooking")}
      showClose
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "20vh", padding: "16px" } }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyItems="space-between"
        height="100%"
        rowGap={2}
      >
        <Typography variant="primary" color="#8E98A9">
          {t("newBookingPrompt")}
        </Typography>

        <Button fullWidth variant="contained" color="primary" size="large" onClick={handleProceed}>
          {t("start")}
        </Button>

        <Button fullWidth variant="text" color="primary" size="large" onClick={onClose}>
          {t("cancel")}
        </Button>
      </Box>
    </DrawerModal>
  );
};
export default StartNewBookingModal;
