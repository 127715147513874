import { Alert, AlertProps as MuiAlertProps } from "@mui/material";
import { useState } from "react";

export type AlertOptions = {
  message: string;
  severity: MuiAlertProps["severity"];
};

/**
 * a hook to display an alert box with a message based on the severity.
 *
 * @return AlertBox component, a function to set the alert message and severity,  a function to clear the alert box
 */

export default function useAlertBox() {
  const [alert, setAlert] = useState<AlertOptions | null>(null);

  const clearAlert = () => setAlert(null);

  const AlertBox = () =>
    alert?.message ? (
      <Alert severity={alert?.severity} variant="filled">
        {alert?.message}
      </Alert>
    ) : null;

  return { AlertBox, setAlert, clearAlert } as const;
}
