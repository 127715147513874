import { Box } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { DateTime } from "luxon";
import { slotsApi } from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCart, selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";

import StaffModalItem from "./StaffModalItem";

type AppointmentArticleSlotStaffModalProps = {
  isService?: boolean;
  open: boolean;
  onClose: () => void;
  slot: CartArticleSlot;

  blockedEmployeeIds: number[];
  handleArticleUpdate: (slot: CartArticleSlot) => void;
};

const AppointmentArticleSlotStaffModal = ({
  isService,
  open,
  onClose,
  slot,
  blockedEmployeeIds,
  handleArticleUpdate,
}: AppointmentArticleSlotStaffModalProps) => {
  const { t } = useTranslation("bookingPage");
  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);

  const [availableEmployees, setAvailableEmployees] = useState<number[]>([]);

  useEffect(() => {
    if (cart.date && cart.outlet && open) {
      slotsApi
        .getServiceSlots({
          date: cart.date,
          serviceVariantId: slot.serviceVariant,
          outletId: cart.outlet,
        })
        .then((response) => {
          let availableSlots = [];
          if (isService) {
            availableSlots = response.data.filter(
              (apiSlot) =>
                DateTime.fromISO(apiSlot.time).toISOTime() ===
                DateTime.fromISO(slot.start).toISOTime()
            );
          } else {
            availableSlots = response.data.filter((apiSlot, index) => {
              const slotDateTime = DateTime.fromISO(slot.start).toISOTime();
              const apiSlotDateTime = DateTime.fromISO(apiSlot.time).toISOTime();
              const nextApiSlotDateTime = DateTime.fromISO(
                response.data[index + 1]?.time
              ).toISOTime();

              if (index !== response.data.length - 1) {
                return slotDateTime >= apiSlotDateTime && slotDateTime <= nextApiSlotDateTime;
              }
              return apiSlotDateTime;
            });
          }

          if (availableSlots.length) {
            const allowedEmployees = availableSlots[0].employees.filter(
              (employeeId) => !blockedEmployeeIds.includes(employeeId)
            );

            setAvailableEmployees(allowedEmployees);
          }
        });
    }
  }, [
    blockedEmployeeIds,
    cart.date,
    cart.outlet,
    slot.serviceVariant,
    slot.start,
    open,
    isService,
  ]);

  return (
    <DrawerModal
      title={t("selectAvailableStaff") || ""}
      showClose={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "30vh", padding: "16px" } }}
    >
      <Box width="100%" display="flex" flexDirection="column" py={2}>
        <StaffModalItem
          checked={slot.staff === null}
          onClick={() => {
            if (organization?.id && organization?.name) {
              trackEvent("Staff Selected", { id: organization.id, name: organization.name }, {});
            }
            handleArticleUpdate({ ...slot, staff: null });
            onClose();
          }}
          employeeId={null}
          available={true}
        />

        {availableEmployees.map((employee) => (
          <StaffModalItem
            key={employee}
            checked={slot.staff === employee}
            onClick={() => {
              handleArticleUpdate({ ...slot, staff: employee });
              onClose();
            }}
            employeeId={employee}
            available={true}
          />
        ))}
      </Box>
    </DrawerModal>
  );
};

export default AppointmentArticleSlotStaffModal;
