import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Grid, TextField } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import PasswordField from "components/PasswordField";
import convertEmailToUsername from "helpers/convertEmailToUsername";
import getErrorDetailTranslationKey from "helpers/errors/getErrorDetailTranslationKey";
import useAlertBox from "hooks/useAlertBox";
import useFormValidation from "hooks/useFormValidation";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { login } from "store/slices/auth/authSlice";
import { trackEvent } from "tracking";
import { isAxiosErrorOfResponseType } from "types/helpers";
import * as yup from "yup";

const loginFormSchema = yup.object({
  emailAddress: yup.string().required().email(),
  password: yup.string().required(),
});

type LoginFormInputs = yup.InferType<typeof loginFormSchema>;

export default function LoginForm() {
  const { t } = useTranslation(["auth", "errors"]);
  const dispatch = useDispatch();

  const organization = useSelector(selectOrganization);

  const [isRequestPending, setIsRequestPending] = useState(false);

  const { AlertBox, setAlert, clearAlert } = useAlertBox();

  const { handleSubmit, formState, register } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginFormSchema),

    mode: "onBlur",
    reValidateMode: "onBlur",
  });

  const registerValidation = useFormValidation(formState, { ns: "auth" });

  const attemptLogin = handleSubmit(({ emailAddress, password }) => {
    setIsRequestPending(true);
    clearAlert();

    const username = convertEmailToUsername(emailAddress);

    dispatch(login({ username, password, shouldRememberUser: true })).then((action) => {
      if (isFulfilled(action)) {
        if (organization?.id && organization?.name)
          trackEvent(
            "Login",
            { id: organization.id, name: organization.name },
            {
              Email: emailAddress,
            }
          );
      }
      if (isRejected(action)) {
        setIsRequestPending(false);

        if (isAxiosErrorOfResponseType(action.payload) && action.payload.response) {
          const responseData = action.payload.response.data;

          if (responseData.detail) {
            const errorMessageTranslationKey = getErrorDetailTranslationKey(responseData.detail);

            setAlert({
              message: t(errorMessageTranslationKey),
              severity: "error",
            });
          }
        }
      }
    });
  });

  return (
    <>
      <Grid item xs={12}>
        <AlertBox />
      </Grid>

      <Grid item xs={12} container spacing={3} component="form" onSubmit={attemptLogin}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("emailAddress")}
            placeholder={t("emailPlaceholder")}
            {...register("emailAddress")}
            {...registerValidation("emailAddress")}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordField
            fullWidth
            label={t("password")}
            placeholder={t("passwordPlaceholder")}
            {...register("password")}
            {...registerValidation("password")}
          />
        </Grid>

        <Grid item xs={12} textAlign="right">
          <Link to="/forgot-password">{t("forgotPassword")}</Link>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" loading={isRequestPending} size="medium">
            {t("login")}
          </LoadingButton>
        </Grid>
      </Grid>
    </>
  );
}
