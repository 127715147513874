import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectServiceById,
  selectServiceVariantById,
  selectServiceVariantLongName,
} from "store/selectors";

type PackageServiceProps = {
  variantId: number;
  displayDivider: boolean;
  color?: string;
  secondaryColor?: string;
};

const PackageService = ({
  variantId,
  displayDivider,
  color,
  secondaryColor,
}: PackageServiceProps) => {
  const { t } = useTranslation(["outletPage", "common"]);
  const variant = useSelector(selectServiceVariantById(variantId));
  const service = useSelector(selectServiceById(String(variant?.service ?? 0)));
  const serviceName = useSelector(selectServiceVariantLongName(variant?.id || -1));

  if (!service || !variant) return null;

  return (
    <>
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" rowGap={1} width="100%">
          <Typography variant="primary" fontWeight="bold" sx={{ color: color }}>
            {t(serviceName[0])}
            {serviceName[1] ? ` - ${t(serviceName[1])}` : ""}
          </Typography>

          <Typography variant="secondary" sx={{ color: secondaryColor }}>
            {t("common:duration")}: {getFormattedDuration(variant.duration)}
          </Typography>
        </Box>
      </Box>

      {displayDivider && (
        <Box display="flex" justifyContent="center">
          <Divider sx={{ color: `${color}`, opacity: "50%", width: "95%" }} variant="fullWidth" />
        </Box>
      )}
    </>
  );
};

export default PackageService;
