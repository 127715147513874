import { ChevronDown } from "@bookpeep/ui";
import { Box, Button, Typography } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import useParamOutlet from "hooks/useParamOutlet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectCart,
  selectOrganization,
  selectOutletById,
  selectSubscriptionByOutletId,
} from "store/selectors";
import { trackEvent } from "tracking";

export enum OutletTabs {
  services = "services",
  staff = "staff",
  subscriptions = "subscriptions",
}

type ChangeViewControllerProps = {
  currentView: string;
  handleChangeView: (newTab: OutletTabs) => void;
};

const ChangeViewController = ({ currentView, handleChangeView }: ChangeViewControllerProps) => {
  const { t } = useTranslation(["bookingPage", "glossary", "common"]);
  const { id: outletId } = useParamOutlet();

  const [isViewModalOpen, setIsViewModalOpen] = useState(false);

  const cart = useSelector(selectCart);
  const org = useSelector(selectOrganization);
  const outletSubscriptions = useSelector(selectSubscriptionByOutletId(outletId));
  const outlet = useSelector(selectOutletById(outletId));
  const { hasIban } = org;

  const onClose = () => {
    setIsViewModalOpen(false);
  };

  const getCurrentViewLabel = () => {
    if (currentView === OutletTabs.services) return t("bookAService");
    else if (currentView === OutletTabs.staff) return t("bookAStaff");
    else if (currentView === OutletTabs.subscriptions) return t("glossary:subscriptions");
    else return "";
  };

  const showSubscriptionsButton =
    (hasIban || outlet?.hasIban) && cart.articles.length === 0 && outletSubscriptions?.length;

  return (
    <>
      <Box p={2} bgcolor="white">
        <Button
          sx={{ borderRadius: "8px" }}
          fullWidth
          variant="outlined"
          endIcon={<ChevronDown />}
          onClick={() => {
            setIsViewModalOpen(true);
            if (org?.id && org?.name) {
              trackEvent("Flows Explored", { id: org.id, name: org.name }, {});
            }
          }}
        >
          <Typography fontSize="0.875rem" fontWeight={700}>
            {getCurrentViewLabel()}
          </Typography>
        </Button>
      </Box>

      {isViewModalOpen && (
        <DrawerModal
          title=""
          showClose={false}
          open={isViewModalOpen}
          onClose={onClose}
          PaperProps={{ sx: { paddingTop: "16px" } }}
        >
          <Box display="flex" flexDirection="column" width="100%" pb={2}>
            <Button
              fullWidth
              size="large"
              variant="text"
              onClick={() => {
                handleChangeView(OutletTabs.services);
                setIsViewModalOpen(false);
              }}
            >
              <Typography color="black" fontSize="0.875rem" fontWeight={500}>
                {t("bookAService")}
              </Typography>
            </Button>

            <Button
              fullWidth
              size="large"
              variant="text"
              onClick={() => {
                handleChangeView(OutletTabs.staff);
                setIsViewModalOpen(false);
              }}
            >
              <Typography color="black" fontSize="0.875rem" fontWeight={500}>
                {t("bookAStaff")}
              </Typography>
            </Button>

            {!!showSubscriptionsButton && (
              <Button
                fullWidth
                size="large"
                variant="text"
                onClick={() => {
                  handleChangeView(OutletTabs.subscriptions);
                  setIsViewModalOpen(false);
                }}
              >
                <Typography color="black" fontSize="0.875rem" fontWeight={500}>
                  {t("glossary:subscriptions")}
                </Typography>
              </Button>
            )}

            <Button
              fullWidth
              size="large"
              variant="text"
              color="error"
              onClick={() => {
                setIsViewModalOpen(false);
              }}
            >
              <Typography fontSize="0.875rem" fontWeight={500}>
                {t("common:cancel")}
              </Typography>
            </Button>
          </Box>
        </DrawerModal>
      )}
    </>
  );
};

export default ChangeViewController;
