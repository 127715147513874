import type { DateTime } from "luxon";

import { TIME_FORMAT } from "./datetimeConstants";

/**
 * Receives two DateTime objects and returns the a string that separates them with a dash.
 *
 * @param startTime
 * @param endTime
 * @param format
 * @returns string that separates startTime and endTime with a dash
 *
 * @example fromTofyTimes(slot.start, slot.end, "HH:mm") // "09:00 - 10:00"
 */

export default function fromTofyTimes(
  startTime: DateTime,
  endTime: DateTime,
  format = TIME_FORMAT
) {
  return `${startTime.toFormat(format)} - ${endTime.toFormat(format)}`;
}
