import CentredSpinnerBox from "components/CentredSpinnerBox";
import useParamOutlet from "hooks/useParamOutlet";
import { useEffect } from "react";
import { Navigate, Outlet as RouterOutlet } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectIsOutletsLoading } from "store/selectors";
import { selectCartOutletId } from "store/slices/cart/cartSelectors";
import { switchCartOutlet } from "store/slices/cart/cartSlice";

// There is some jo7a code here as I am getting the outlet from the param many times
// We could possibly create a store that keeps track of the "active outlet" and then use that with more guaranty

export default function OutletRedirector() {
  const dispatch = useDispatch();

  const outlet = useParamOutlet(true);

  const cartOutletId = useSelector(selectCartOutletId);

  // Switch active cart
  // TODO: Read comment above and fix
  useEffect(() => {
    if (outlet?.id !== cartOutletId) {
      dispatch(switchCartOutlet(outlet?.id || null));
    }
  }, [cartOutletId, dispatch, outlet?.id]);

  // Show loading if data is still pending
  const areOutletsLoading = useSelector(selectIsOutletsLoading);
  if (areOutletsLoading) return <CentredSpinnerBox />;

  // Redirect to home if outlet doesn't exist
  if (!outlet) return <Navigate to="/" />;

  return <RouterOutlet />;
}
