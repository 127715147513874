import mapObject from "helpers/object/mapObject";
import i18next, { changeLanguage } from "i18next";
import http from "utils/http";

import { Languages, TranslationNamespaces, TranslationsResponse } from "./TranslationTypes";

const TRANSLATIONS_CACHE = "peep_trans";

function isValidLanguage(language: string): language is Languages {
  return Object.values(Languages).includes(language as any);
}

function isValidBackendFileName(fileName: string): fileName is keyof typeof TranslationNamespaces {
  return Object.keys(TranslationNamespaces).includes(fileName);
}

function addBundle(language: string, namespace: string, translationJson: any) {
  i18next.addResourceBundle(language, namespace, translationJson, true);
  localStorage.setItem(
    `${TRANSLATIONS_CACHE}_${language}_${namespace}`,
    JSON.stringify(translationJson)
  );
}

const processNamespaces =
  (language: Languages, data: TranslationsResponse) =>
  ([backendFileName, namespace]: [string, string]) => {
    if (isValidBackendFileName(backendFileName)) {
      try {
        const namespaceJsonByLanguage = data[language][backendFileName];
        //Check if the backend file name is not employees because employees need processing before adding their json
        if (backendFileName !== "employees") {
          addBundle(language, namespace, namespaceJsonByLanguage);
        } else {
          //Processing the employees json to add the fullName key and it's value
          const employeesJson = mapObject(
            namespaceJsonByLanguage,
            ([key, value]: [string, any]) => [
              key,
              {
                ...value,
                // TODO: use i18next nesting: https://www.i18next.com/translation-function/nesting
                ...(value.firstName
                  ? {
                      fullName: `${value.firstName} ${value.lastName ? value.lastName : ""}`.trim(),
                    }
                  : {}),
              },
            ]
          );

          addBundle(language, namespace, employeesJson);
        }
      } catch (error) {
        throw new Error(`Could not load ${backendFileName}`);
      }
    }
  };

function getLocales() {
  //TODO: This caching is garbage, fix one day
  Object.values(TranslationNamespaces).forEach((namespace) => {
    const englishJson = localStorage.getItem(
      `${TRANSLATIONS_CACHE}_${Languages.english}_${namespace}`
    );
    if (englishJson) addBundle(Languages.english, namespace, JSON.parse(englishJson));

    const arabicJson = localStorage.getItem(
      `${TRANSLATIONS_CACHE}_${Languages.arabic}_${namespace}`
    );
    if (arabicJson) addBundle(Languages.arabic, namespace, JSON.parse(arabicJson));
  });

  http.get("/api/translations/jsons/").then(({ data }: { data: TranslationsResponse }) => {
    //Loop over the languages from the json response
    Object.keys(data).forEach((language) => {
      if (isValidLanguage(language)) {
        //Loop over the namespaces defined above
        Object.entries(TranslationNamespaces).forEach(processNamespaces(language, data));
      }
    });

    changeLanguage(i18next.language);
  });
}

getLocales();
