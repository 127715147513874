import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getNormalizedAppointments } from "store/globalThunks";
import matchThunks from "store/utils/matchThunks";

import createThunkFromApi from "../../utils/createThunkFromApi";
import { logout } from "../auth/authSlice";
import { SliceStatus } from "../utils";
import api from "./appointmentsApi";

export const appointmentAdapter = createEntityAdapter<Appointment>({
  selectId: (appointment) => appointment.id,
});

const initialState = appointmentAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const createAppointment = createThunkFromApi(
  "appointments/createAppointment",
  api.createAppointment,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const cancelAppointment = createThunkFromApi(
  "appointments/cancelAppointment",
  api.cancelAppointment,
  {
    handleResponse: ({ response }) => {
      return response.data;
    },
  }
);

export const appointmentsSlice = createSlice({
  name: "appointments",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(createAppointment.fulfilled, (state, { payload: { appointment } }) => {
        appointmentAdapter.upsertOne(state, appointment);
        state.status = SliceStatus.IDLE;
      })
      .addCase(cancelAppointment.fulfilled, (state, { payload: { appointment } }) => {
        appointmentAdapter.updateOne(state, { id: appointment.id, changes: appointment });
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedAppointments.fulfilled, (state, { payload: { appointments } }) => {
        appointmentAdapter.upsertMany(state, appointments);
        state.status = SliceStatus.IDLE;
      })
      .addMatcher(matchThunks({ slice: "appointments", status: "pending" }), (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addMatcher(matchThunks({ slice: "appointments", status: "fulfilled" }), (state) => {
        state.status = SliceStatus.IDLE;
      })
      .addMatcher(matchThunks({ slice: "appointments", status: "rejected" }), (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addMatcher(logout.match, () => initialState);
  },
});

export default appointmentsSlice.reducer;
