import { Ticket } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type SubscriptionRedemptionTagProps = {
  title: string;
};

const SubscriptionRedemptionTag = ({ title }: SubscriptionRedemptionTagProps) => {
  const { t } = useTranslation();

  return (
    <Box
      bgcolor="bg.secondary"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
      borderRadius={1}
      gap={2}
      flexWrap="wrap"
      sx={{ borderWidth: 1, borderStyle: "solid", borderColor: "#D4D6E4", borderRadius: "8px" }}
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" alignItems="center">
          <Ticket
            sx={{
              fontSize: "0.9rem",
              color: "primary.dark",
            }}
          />

          <Typography pl={0.75} variant="primary" fontWeight={700} noWrap>
            {title}
          </Typography>
        </Box>
      </Box>

      <Typography pl={0.75} variant="primary" color="text.placeholder" fontWeight={500} noWrap>
        {t("subscriptionApplied")}
      </Typography>
    </Box>
  );
};

export default SubscriptionRedemptionTag;
