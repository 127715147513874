/**
 * A function that compounds words together in a grammatically correct way.
 *
 * Example: ["apple", "orange", "banana"] => "apple, orange, and banana"
 *
 * @param array array of strings you want to compound
 * @param and a divider to add to the last item
 * @returns The given array items combined into a single string divided by commas by grammar
 */
export default function andifyArray(array: string[], and: string) {
  // Remove empty items
  const filteredArray = array.filter((item) => item);

  if (filteredArray.length === 1) return filteredArray[0];

  if (filteredArray.length === 2) return `${filteredArray[0]} ${and} ${filteredArray[1]}`;

  const indexOfLastItem = filteredArray.length - 1;

  return filteredArray.reduce((sentence, item, index) => {
    if (indexOfLastItem === index) return `${sentence}${and} ${item}`;
    else return `${sentence}${item}, `;
  }, "");
}
