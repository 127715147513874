import { Grid } from "@mui/material";
import NothingHere from "components/NothingHere";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectCart, selectOrganization } from "store/selectors";
import {
  removeArticleFromCart,
  updateArticleInCart,
  updateArticleSubscriptionPurchase,
} from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

import ReviewPackageArticle from "./ReviewPackageArticle";
import ReviewServiceArticle from "./ReviewServiceArticle";

const CartArticles = () => {
  const { t } = useTranslation("reviewAppointmentPage");

  const dispatch = useDispatch();

  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);

  const handleArticleDelete = (index: number) => {
    if (organization?.id && organization?.name) {
      trackEvent(
        "Item removed from cart",
        { id: organization.id, name: organization.name },
        { from: "Review" }
      );
    }
    dispatch(removeArticleFromCart(index));
  };

  const handleArticleUpdate = (index: number, article: CartArticle) => {
    if (organization?.id && organization?.name) {
      trackEvent(
        "Staff Selected",
        { id: organization.id, name: organization.name },
        { from: "Review" }
      );
    }
    dispatch(updateArticleInCart({ index, article }));
  };

  const handleUpdateSubscriptionPurchase =
    (index: number) => (subscriptionPurchaseId: Nullable<number>) => {
      dispatch(updateArticleSubscriptionPurchase({ id: index, subscriptionPurchaseId }));
    };

  const cartDate = cart.date ? DateTime.fromISO(cart.date) : DateTime.now();

  return cart.articles.length > 0 ? (
    <>
      {cart.articles.map((article, index) => {
        if (isServiceCartArticle(article))
          return (
            <Grid item xs={12} key={index}>
              <ReviewServiceArticle
                selectedDate={cartDate}
                handleSubscriptionPurchaseSelect={(subscriptionPurchaseId: Nullable<number>) =>
                  handleUpdateSubscriptionPurchase(index)(subscriptionPurchaseId)
                }
                outletId={cart.outlet || 0}
                article={article}
                handleArticleUpdate={(slot: CartArticleSlot) =>
                  handleArticleUpdate(index, { ...article, slots: [slot] })
                }
                handleArticleDelete={() => handleArticleDelete(index)}
              />
            </Grid>
          );
        else if (isPackageCartArticle(article))
          return (
            <Grid item xs={12} key={index}>
              <ReviewPackageArticle
                selectedDate={cartDate}
                handleSubscriptionPurchaseSelect={(subscriptionPurchaseId: Nullable<number>) =>
                  handleUpdateSubscriptionPurchase(index)(subscriptionPurchaseId)
                }
                outletId={cart.outlet || 0}
                article={article}
                handleArticleUpdate={(slots: CartArticleSlot[]) =>
                  handleArticleUpdate(index, { ...article, slots: slots })
                }
                handleArticleDelete={() => handleArticleDelete(index)}
              />
            </Grid>
          );
        else return null;
      })}
    </>
  ) : (
    <NothingHere message={t("noServices")} />
  );
};

export default CartArticles;

function isServiceCartArticle(article: CartArticle): article is ServiceCartArticle {
  return Boolean(article.serviceVariant);
}

function isPackageCartArticle(article: CartArticle): article is PackageCartArticle {
  return Boolean(article.packageVariant);
}
