import { createSvgIcon } from "@mui/material";

export const InfoAlertIcon = createSvgIcon(
  <svg width="20" height="20" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 8C1.5 4.41 4.41 1.5 8 1.5C11.59 1.5 14.5 4.41 14.5 8C14.5 11.59 11.59 14.5 8 14.5C4.41 14.5 1.5 11.59 1.5 8ZM8 5.5C8.13261 5.5 8.25979 5.55268 8.35355 5.64645C8.44732 5.74022 8.5 5.86739 8.5 6V8.5C8.5 8.63261 8.44732 8.75979 8.35355 8.85355C8.25979 8.94732 8.13261 9 8 9C7.86739 9 7.74022 8.94732 7.64645 8.85355C7.55268 8.75979 7.5 8.63261 7.5 8.5V6C7.5 5.86739 7.55268 5.74022 7.64645 5.64645C7.74022 5.55268 7.86739 5.5 8 5.5ZM8 11C8.13261 11 8.25979 10.9473 8.35355 10.8536C8.44732 10.7598 8.5 10.6326 8.5 10.5C8.5 10.3674 8.44732 10.2402 8.35355 10.1464C8.25979 10.0527 8.13261 10 8 10C7.86739 10 7.74022 10.0527 7.64645 10.1464C7.55268 10.2402 7.5 10.3674 7.5 10.5C7.5 10.6326 7.55268 10.7598 7.64645 10.8536C7.74022 10.9473 7.86739 11 8 11Z"
      fill="#FF9800"
    />
  </svg>,
  "InfoAlert"
);
