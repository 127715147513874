import { memo } from "react";
import SvgImage from "./SvgImage";

const PeepLogo = ({ color = "primary.main", ...props }) => (
  <SvgImage viewBox="0 0 56 20" color={color} {...props}>
    <path d="M51.6891 0H47.6654C45.2301 0 43.2488 1.98134 43.2488 4.41667V20L46.5622 18.9341V14.8794L53.0411 12.7973C54.8744 12.2077 56.107 10.5174 56.107 8.59204V4.41667C56.1057 1.98134 54.1244 0 51.6891 0ZM52.7923 8.59328C52.7923 9.07463 52.4851 9.49627 52.0261 9.64303L46.561 11.3993V4.41667C46.561 3.80846 47.056 3.31343 47.6642 3.31343H51.6878C52.296 3.31343 52.7911 3.80846 52.7911 4.41667V8.59328H52.7923Z" />
    <path d="M39.3568 11.9303H32.5769C32.3941 11.9303 32.2461 11.7811 32.2461 11.5995V10.7052L38.7249 8.62313C40.5583 8.03358 41.7908 6.34328 41.7908 4.41791C41.7896 1.98134 39.8083 0 37.3717 0H33.3481C30.9127 0 28.9314 1.98134 28.9314 4.41667V11.5983C28.9314 13.6082 30.567 15.2438 32.5769 15.2438H37.6553L39.3568 11.9303ZM32.2448 4.41667C32.2448 3.80846 32.7399 3.31343 33.3481 3.31343H37.3717C37.9799 3.31343 38.4749 3.80846 38.4749 4.41667C38.4749 4.89801 38.1677 5.31965 37.7088 5.46642L32.2436 7.22263V4.41667H32.2448Z" />
    <path d="M25.0396 11.9303H18.2598C18.0769 11.9303 17.9289 11.7811 17.9289 11.5995V10.7052L24.4078 8.62313C26.2411 8.03358 27.4737 6.34328 27.4737 4.41791C27.4724 1.98134 25.4911 0 23.0558 0H19.0321C16.5968 0 14.6155 1.98134 14.6155 4.41667V11.5983C14.6155 13.6082 16.2511 15.2438 18.261 15.2438H23.3394L25.0396 11.9303ZM17.9289 4.41667C17.9289 3.80846 18.4239 3.31343 19.0321 3.31343H23.0558C23.664 3.31343 24.159 3.80846 24.159 4.41667C24.159 4.89801 23.8518 5.31965 23.3928 5.46642L17.9277 7.22263V4.41667H17.9289Z" />
    <path d="M8.44154 0H4.41667C1.98134 0 0 1.98134 0 4.41667V20L3.31343 18.9341V14.8794L9.79229 12.7973C11.6256 12.2077 12.8582 10.5174 12.8582 8.59204V4.41667C12.8582 1.98134 10.8769 0 8.44154 0ZM9.54478 8.59328C9.54478 9.07463 9.23756 9.49627 8.77861 9.64303L3.31343 11.3993V4.41667C3.31343 3.80846 3.80846 3.31343 4.41667 3.31343H8.4403C9.04851 3.31343 9.54353 3.80846 9.54353 4.41667V8.59328H9.54478Z" />
  </SvgImage>
);

export default memo(PeepLogo);
