import { Avatar, Box, Button, styled, Typography } from "@mui/material";
import { getImageSrc } from "helpers/getImageSrc";
import useParamOutlet from "hooks/useParamOutlet";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";

const EmployeeCard = ({ employee }: { employee: Employee }) => {
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";

  const organization = useSelector(selectOrganization);

  const handleBookClick = () => {
    if (organization?.id && organization?.name) {
      trackEvent("Staff Profile Viewed", { id: organization.id, name: organization.name }, {});
    }
    navigate(`/${outletSlug}/staff/${employee.id}/`);
  };

  return (
    <StyledBox>
      <Box display="flex" justifyContent="space-between" alignItems="center" width="100%" gap={1}>
        <Avatar
          sx={{ width: "46px", height: "46px" }}
          src={getImageSrc(employee.avatar)}
          alt={t(employee.t.fullName)}
        />

        <Box display="flex" flexDirection="column" sx={{ flexGrow: 1 }} gap={2}>
          <Typography variant="h4">{t(employee.t.fullName)}</Typography>
        </Box>

        <Button size="small" variant="outlined" onClick={handleBookClick}>
          {t("common:book")}
        </Button>

        {/* <IconButton>
          <Heart color="primary" />
        </IconButton> */}
      </Box>
    </StyledBox>
  );
};

export default EmployeeCard;

const StyledBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: 8,
  border: "1px solid #D4D6E4",
  borderStyle: "solid",
  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
  rowGap: theme.spacing(2),
}));
