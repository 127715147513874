const GREEN = "#A1DD37";
const PINK = "#ECA4D7";
const APRICOT = "#F4C177";
const PURPLE = "#A87DE9";
const BLUE = "#77AAF4";
const CART = "#53BFB0";

const COLOR_MAP = { 0: GREEN, 1: PINK, 2: APRICOT, 3: PURPLE, 4: BLUE, 5: CART };

export default function getIndexColor(index: number) {
  const colorIndex = uTurnIndex(index);
  return COLOR_MAP[colorIndex];
}

type ColorIndex = keyof typeof COLOR_MAP;

function uTurnIndex(index: number) {
  const maxValue = Object.entries(COLOR_MAP).length;
  return (index % maxValue) as ColorIndex;
}
