import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { articleSlotAdapter } from "./articleSlotsSlice";

export const selectArticleSlotsState = (state: RootState) => state.articleSlots;

const { selectById, selectAll } = articleSlotAdapter.getSelectors(selectArticleSlotsState);

export const selectAllArticleSlots = selectAll;

export const selectArticleSlotIsLoading = (state: RootState) =>
  state.articleSlots.status === SliceStatus.LOADING;

export const selectArticleSlotById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectArticleSlotsByArticleIds = (ids: number[]) => (state: RootState) =>
  selectAll(state).filter((slot) => ids.includes(slot.article));
