import { Box, Typography } from "@mui/material";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";

import { CallToActionLink } from "./CallToActionLink";
import { selectOrganization } from "store/selectors";
import { useSelector } from "store";

export default function SmallMPBanner() {
  const { t } = useTranslation(["appointmentPage", "common"]);
  const organization = useSelector(selectOrganization);
  const { isAndroidDevice } = useDeviceDetection();
  return (
    <Box
      position="relative"
      display="flex"
      alignItems="center"
      paddingX="3%"
      height="75px"
      color="white"
      gap={2}
      zIndex={2}
      overflow="hidden"
      sx={{
        background: "radial-gradient(circle at  bottom right, #A7BAFB 30%, #87A2FE 20%)",
      }}
    >
      <img alt="download-app" src="/images/DownloadAppIcon.svg" />

      <Typography variant="h4">{t("downloadAppForFasterRebooking")}</Typography>
      <Box flex={1} display="flex" justifyContent="end">
        <CallToActionLink
          href={organization.appLink || getUseAppLink(isAndroidDevice)}
          onClick={() => {
            ReactGA.event({
              category: "Small",
              action: "Confirmation Small App Download",
              label: "Appointment",
            });
          }}
          paddingX={1}
          paddingY={0.5}
        >
          {t("common:download")}
        </CallToActionLink>
      </Box>
    </Box>
  );
}
