import { LoadingButton } from "@mui/lab";
import { Grid } from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { getCountryPhonePrefixFromCountryCode } from "assets/constants/countries";
import convertEmailToUsername from "helpers/convertEmailToUsername";
import getErrorDetailTranslationKey from "helpers/errors/getErrorDetailTranslationKey";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import type { UpdateProfileArguments } from "store/slices/auth/authApi";
import { updateProfile } from "store/slices/auth/authSlice";
import { isAxiosErrorOfResponseType } from "types/helpers";
import { notify } from "utils/SnackbarProvider";

import type { ProfileDetailsFormInputs } from "./EditProfileDetailsForm";

const UpdateProfileButton = () => {
  const { t } = useTranslation(["editUserProfilePage", "notifications"]);
  const dispatch = useDispatch();

  const [isRequestPending, setIsRequestPending] = useState(false);

  const { handleSubmit, setError } = useFormContext<ProfileDetailsFormInputs>();

  const handleUpdateProfile = handleSubmit((data) => {
    setIsRequestPending(true);

    const requestArgument = getRequestArgumentFromData(data);

    dispatch(updateProfile(requestArgument)).then((action) => {
      setIsRequestPending(false);

      if (isRejected(action)) {
        if (isAxiosErrorOfResponseType(action.payload) && action.payload.response) {
          const responseData = action.payload.response.data;
          // TODO: fix this typescript shit - ANFAL
          // Loop over response data object excluding detail and setError for each
          if (!responseData.detail)
            Object.entries(responseData).forEach(([field, errors]) => {
              const fieldKey = field as keyof ProfileDetailsFormInputs;
              const isErrorAnArray = typeof errors !== "string";

              if (isErrorAnArray) {
                // error is an array of strings
                const errorMessages = errors.join("\n");

                setError(fieldKey, {
                  type: "custom",
                  message: errorMessages,
                });
              }
            });

          // check for specific detail errors and handle them manually
          const errorMessageTranslationKey = getErrorDetailTranslationKey(responseData.detail);

          if (responseData.detail === "Email already exists") {
            setError("emailAddress", {
              type: "custom",
              message: t(errorMessageTranslationKey),
            });
          }
        }
      }

      if (isFulfilled(action)) {
        const message = t("notifications:entitySuccessfullyUpdated", {
          entity: t("editUserProfilePage:profileDetails"),
        });

        notify(message, "success");
      }
    });
  });

  return (
    <Grid item xs={12}>
      <LoadingButton
        type="submit"
        fullWidth
        onClick={handleUpdateProfile}
        loading={isRequestPending}
        size="medium"
      >
        {t("saveUpdates")}
      </LoadingButton>
    </Grid>
  );
};

export default UpdateProfileButton;

function getRequestArgumentFromData(formData: ProfileDetailsFormInputs): UpdateProfileArguments {
  const {
    firstName,
    lastName = "",
    emailAddress,
    countryCode,
    phoneNumber,
    dateOfBirth,
    gender,
  } = formData;

  const phoneCountryCode = `+${getCountryPhonePrefixFromCountryCode(countryCode)}`;

  const user = {
    firstName,
    lastName,
    username: convertEmailToUsername(emailAddress),
    email: emailAddress,

    phoneCountryCode,
    phoneNumber: `+${phoneCountryCode}${phoneNumber}`,
  };

  return {
    user,
    ...(dateOfBirth && { dateOfBirth }),
    ...(gender && { gender }),
  };
}
