import { Typography } from "@mui/material";
import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import LoginForm from "./LoginForm";

export default function LoginPage() {
  const { t } = useTranslation("loginPage");
  const navigate = useNavigate();

  return (
    <AuthenticationPageLayout
      title={t("title")}
      subtitle={t("subtitle")}
      footer={
        <Typography>
          {t("noAccount")}{" "}
          <Typography
            variant="h4"
            color="primary"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={() => navigate("/register")}
          >
            {t("createAccount")}
          </Typography>
        </Typography>
      }
    >
      <LoginForm />
    </AuthenticationPageLayout>
  );
}
