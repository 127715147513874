import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateService } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const serviceAdapter = createEntityAdapter<Service>();

const initialState = serviceAdapter.getInitialState({ status: SliceStatus.IDLE });

const serviceSlice = createSlice({
  name: "services",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const servicesWithTranslationKeys = payload.services.map((service) => ({
          ...service,
          t: translateService(service.id),
        }));
        serviceAdapter.setAll(state, servicesWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default serviceSlice.reducer;
