import { RootState } from "store";

import { serviceCategoriesAdapter } from "./serviceCategoriesSlice";

const selectServiceCategoriesState = (state: RootState) => state.serviceCategories;

const { selectById, selectAll } = serviceCategoriesAdapter.getSelectors(
  selectServiceCategoriesState
);

export const selectServiceCategoryById = (id: string) => (state: RootState) =>
  selectById(state, id);

export const selectAllServiceCategories = (state: RootState) =>
  selectAll(state).sort((a, b) => (a.position || 0) - (b.position || 0));
