import { Grid, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";

type TermsAndConditionsSectionProps = {
  title: string;
  description: string;
};

function TermsAndConditionsSection({ title, description }: TermsAndConditionsSectionProps) {
  const { t } = useTranslation("terms");

  return (
    <Grid item xs={12}>
      <HeadingText variant="h4">{t(title)}</HeadingText>

      <BodyText variant="primary">{t(description)}</BodyText>
    </Grid>
  );
}

const HeadingText = styled(Typography)({
  lineHeight: "22px",
});

const BodyText = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  lineHeight: "17.5px",
  color: theme.palette.text.label,
}));

export default TermsAndConditionsSection;
