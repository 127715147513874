import { Box } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import PurchasableItemsSummary from "pages/OutletPage/PurchasableItemsSummary";

type PurchasableItemsModalProps = {
  open: boolean;
  onClose: () => void;
};

const PurchasableItemsModal = ({ open, onClose }: PurchasableItemsModalProps) => {
  // const { t } = useTranslation("outletPage");

  return (
    <DrawerModal
      title=""
      showClose={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { paddingTop: "16px" } }}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <PurchasableItemsSummary />
      </Box>
    </DrawerModal>
  );
};
export default PurchasableItemsModal;
