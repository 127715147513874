import { Calendar } from "@bookpeep/ui";
import { Tab, TextFieldProps } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DateTime } from "luxon";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";

type DatePickerTabProps = {
  selectedCalendarDate: DateTime | null;
  handleDateChange: (newDate: Nullable<DateTime>) => void;
  disabled: boolean;
};

const DatePickerTab = ({
  selectedCalendarDate,
  handleDateChange,
  disabled = false,
}: DatePickerTabProps) => {
  const { t } = useTranslation("bookingPage");

  return (
    <DesktopDatePicker
      disabled={disabled}
      //Send disable past as a prop if this becomes a common component
      disablePast
      dayOfWeekFormatter={(day) => day}
      value={selectedCalendarDate}
      onChange={handleDateChange}
      disableMaskedInput
      renderInput={(params) => (
        <Tab
          ref={params.inputRef}
          disabled={disabled}
          onClick={extractPickerOnClick(params)}
          icon={<Calendar />}
          iconPosition="start"
          label={t("openCalendar")}
          sx={{
            opacity: 100,
          }}
        />
      )}
    />
  );
};

export default DatePickerTab;

function extractPickerOnClick(params: TextFieldProps) {
  //MATERIAL UI IS SUPER BAD
  const endAdornment = params?.InputProps?.endAdornment as ReactElement;
  return endAdornment?.props?.children?.props?.onClick;
}
