import type { RootState } from "store";

import { selectArticleSlotById } from "../articleSlots/articleSlotsSelectors";
import { SliceStatus } from "../utils";
import { articleAdapter } from "./articlesSlice";

export const selectArticlesState = (state: RootState) => state.articles;

const { selectById, selectAll } = articleAdapter.getSelectors(selectArticlesState);

export const selectAllArticles = selectAll;

export const selectArticleIsLoading = (state: RootState) =>
  state.articles.status === SliceStatus.LOADING;

export const selectArticleById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectArticlesByAppointmentId = (id: number | string) => (state: RootState) =>
  selectAll(state).filter((article) => +article.appointment === +id);

export const selectArticlesByAppointmentIdWithSlotsDetails =
  (id: number | string) => (state: RootState) => {
    const articles = selectAll(state).filter((article) => +article.appointment === +id);

    return articles.map((article) => ({
      ...article,
      slots: article.slots.map((slotId: number) => selectArticleSlotById(slotId)(state)),
    }));
  };
