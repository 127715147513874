/**
 * a function that returns a unique id (kinda unique)
 *
 * @returns {string}
 */

const getUniqueId = () => {
  const uniqueId = `${performance.now() + Math.random()}`;

  return uniqueId;
};

export default getUniqueId;
