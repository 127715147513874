import { Box, Typography } from "@mui/material";
import andifyArray from "helpers/andifyArray";
import humanizeDayDuration from "helpers/humanizeDayDuration";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectOutletNamesByIds,
  selectServiceVariantNamesByPackageVariantId,
  selectSubscriptionById,
} from "store/selectors";

type SubscriptionItemDetailsProps = {
  subscriptionId: number;
};

const SubscriptionItemDetails = ({ subscriptionId }: SubscriptionItemDetailsProps) => {
  const { t } = useTranslation(["common", "glossary", "subscriptions"]);

  const subscription = useSelector(selectSubscriptionById(subscriptionId));

  const serviceVariantNames = useSelector(
    selectServiceVariantNamesByPackageVariantId(subscription?.packageVariant || 0)
  );

  const outletNames = useSelector(selectOutletNamesByIds(subscription?.redeemingOutlets || []));

  if (!subscription) return null;

  return (
    <Box
      bgcolor="#F2F2F7"
      p={2}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      gap={0.5}
    >
      <Typography fontWeight={500} fontSize="small" sx={{ color: "#4E5D78" }}>
        {t("subscriptions:validFor")}{" "}
        <Box component="span" color="primary.main">
          {humanizeDayDuration(subscription.validityInDays)}
        </Box>
      </Typography>

      {!!serviceVariantNames.length && subscription.packageVariant && (
        <Typography fontWeight={500} fontSize="small" sx={{ color: "#4E5D78" }}>
          {subscription.itemName} {t("subscriptions:includes")}{" "}
          <Box component="span" color="primary.main">
            {andifyArray(serviceVariantNames, t("and"))}
          </Box>{" "}
          {t("glossary:services")}
        </Typography>
      )}

      <Typography fontWeight={500} fontSize="small" sx={{ color: "#4E5D78" }}>
        {t("subscriptions:redeemableAt")}{" "}
        <Box component="span" color="primary.main">
          {andifyArray(outletNames, t("and"))}
        </Box>
      </Typography>
    </Box>
  );
};
export default SubscriptionItemDetails;
