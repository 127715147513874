import { Confirm } from "@bookpeep/ui";
import { Avatar, Badge, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { getImageSrc } from "helpers/getImageSrc";
import getInitials from "helpers/getInitials";
import { useTranslation } from "react-i18next";

const SelectedEmployee = ({ employee }: { employee: Employee }) => {
  const { t } = useTranslation();

  const employeeInitials = getInitials(t(employee.t.firstName), t(employee.t.lastName));

  return (
    <Box display="flex" gap={1}>
      <Box
        sx={{ borderWidth: 1, borderStyle: "solid", borderColor: "#D4D6E4", borderRadius: "8px" }}
        width="100%"
        display="flex"
        bgcolor="#F7F8FD"
        p={2}
        columnGap={1}
        alignItems="center"
      >
        <Badge
          color="primary"
          componentsProps={{ badge: {} }}
          badgeContent={<Confirm sx={{ width: "9px", height: "9px" }} />}
          overlap="circular"
        >
          <Avatar src={getImageSrc(employee.avatar)} alt={t(employee.t.fullName)}>
            {employeeInitials}
          </Avatar>
        </Badge>

        <Typography variant="h4">{t(employee.t.fullName)}</Typography>
      </Box>
    </Box>
  );
};

export default SelectedEmployee;
