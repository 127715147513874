import { Box, Button, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

type CancelBookingCardProps = {
  handleClick: () => void;
};

const CancelBookingCard = ({ handleClick }: CancelBookingCardProps) => {
  const { t } = useTranslation("appointmentPage");

  return (
    <Box>
      <Box display="flex" flexDirection="row" gap={1}>
        <Typography variant="primary" fontWeight={500}>
          {t("cancelBookingPrompt")}
        </Typography>
      </Box>

      <Box display="flex" flexDirection="row" gap={1} pt={2} height="100%">
        <Button variant="outlined" fullWidth onClick={handleClick}>
          {t("cancelBooking")}
        </Button>
      </Box>
    </Box>
  );
};

export default CancelBookingCard;
