import { memo } from "react";

import BookingEmployeeRadio from "./BookingEmployeeRadio";

type BookingAnyEmployeeRadioProps = {
  checkedEmployeeId: Nullable<number>;
  setCheckedEmployeeId: (value: Nullable<number>) => void;
};

function BookingAnyEmployeeRadio({
  checkedEmployeeId,
  setCheckedEmployeeId,
}: BookingAnyEmployeeRadioProps) {
  return (
    <BookingEmployeeRadio
      name={null}
      checked={checkedEmployeeId === null}
      available
      onClick={() => setCheckedEmployeeId(null)}
      avatarSrc=""
    />
  );
}

export default memo(BookingAnyEmployeeRadio);
