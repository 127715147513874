import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";

const cacheRtl = createCache({
  key: "rtl",
  stylisPlugins: [prefixer, rtlPlugin],
});

type DirectionProviderChildren = { children: ReactNode };

export default function DirectionProvider({ children }: DirectionProviderChildren) {
  const { i18n } = useTranslation();

  if (i18n.dir() === "rtl") return <CacheProvider value={cacheRtl}>{children}</CacheProvider>;

  return <>{children}</>;
}

/**
 * I am not sure how any of this shit works, I just copy like monkey from the following resources:
 *
 * 1. https://www.npmjs.com/package/stylis-plugin-rtl
 * 2. https://mui.com/material-ui/guides/right-to-left/
 * 3. https://stackoverflow.com/questions/69371106/how-to-configure-rtl-right-to-left-support-in-material-ui-version-5
 */
