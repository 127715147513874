import type {} from "@mui/lab/themeAugmentation";

import { peepTheme } from "@bookpeep/ui";
import { ThemeOptions, ThemeProvider } from "@mui/material";
import { deepmerge } from "@mui/utils";
import useLanguageDirection from "hooks/useLanguageDirection";
import { ReactNode } from "react";

const themeOverrides: ThemeOptions = {
  components: {
    MuiButton: {
      defaultProps: { size: "small" },

      styleOverrides: {
        root: {
          borderRadius: "4px",
        },

        sizeSmall: {
          minWidth: 58,
          minHeight: 38,
          maxHeight: 38,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: peepTheme.palette.common.white,
          borderRadius: peepTheme.spacing(1),
        },
      },
    },

    MuiCssBaseline: {
      styleOverrides: {
        a: {
          textDecoration: "none",
        },
      },
    },
  },
};

type ThemeProviderProps = { children: ReactNode };

const theme = deepmerge(peepTheme, themeOverrides);

export default function PeepThemeProvider({ children }: ThemeProviderProps) {
  const direction = useLanguageDirection();

  return <ThemeProvider theme={{ ...theme, direction }}>{children}</ThemeProvider>;
}
