import { DateTime } from "luxon";
import { KeysMatching } from "types/helpers";

// Sorts an array of objects by a given key in a descending order by date (newest first)
function sortByDateDescending(array: any[], key: string) {
  return [...array].sort((a, b) => {
    if (DateTime.fromISO(b[key]) >= DateTime.fromISO(a[key])) return 1;
    if (DateTime.fromISO(b[key]) <= DateTime.fromISO(a[key])) return -1;
    return 0;
  });
}

// Sorts an array of objects by a given key in a descending order by date (newest first)
function sortByDateAscending(array: any[], key: string) {
  return [...array].sort((a, b) => {
    if (DateTime.fromISO(b[key]) >= DateTime.fromISO(a[key])) return -1;
    if (DateTime.fromISO(b[key]) <= DateTime.fromISO(a[key])) return 1;
    return 0;
  });
}

// keyToSortBy should have a value of type iso DateTime string, or string
type SortByKey = <E extends Record<string, any>>(
  keyToSortBy: KeysMatching<E, string>
) => (a: E, b: E) => number;

/**
 * This is a function can be used within array.sort function, to sort an array of a key that has a string value.
 * It sorts in an ascending order (for date: older dates appears first, most recent dates appears last)
 * @param keyToSortBy
 * @returns A function that can be used to sort an array of objects by the given key.
 */

const callSortAscendingByKey: SortByKey = (keyToSortBy) => (a, b) => {
  if (a[keyToSortBy] >= b[keyToSortBy]) return 1;
  else if (a[keyToSortBy] <= b[keyToSortBy]) return -1;
  return 0;
};

export { sortByDateDescending, sortByDateAscending, callSortAscendingByKey };
