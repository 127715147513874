import { Box, BoxProps, Typography, styled } from "@mui/material";
import StartNewBookingModal from "components/StartNewBookingModal";
import generateCartArticle from "helpers/article/generateCartArticle";
import { TIME_FORMAT } from "helpers/datetime/datetimeConstants";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import getHighestDuration from "helpers/service/getHighestDuration";
import dontForwardProps from "helpers/styles/dontForwardProps";
import useParamOutlet from "hooks/useParamOutlet";
import useTranslatedValue from "hooks/useTranslatedValue";
import { getSlotDateTime } from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import { BookingSlot } from "pages/BookingPage/bookingPageTypes";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import {
  selectCart,
  selectOrganization,
  selectPackageById,
  selectPackageVariantWithServicesById,
  selectPackageVariantsByPackageId,
  selectPurchasableEarliestArticleFromCart,
  selectServiceById,
  selectServiceVariantsByServiceId,
} from "store/selectors";
import { addArticleToCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

import ServiceVariantItem from "../ServiceVariantItem";
import { TYPES } from "../constants";
import OutletBookingContext from "../outletContext";
import CustomBookButton from "./CustomBookButton";
import PackageListItemPrice from "./PackageListItemPrice";
import PurchasableCardPackageDetails from "./PurchasableCardPackageDetails";
import PurchasableSelectedSlotButton from "./PurchasableSelectedSlotButton";
import PurchasableSlots from "./PurchasableSlots";
import ServiceListItemPrice from "./ServiceListItemPrice";

type PurchasableCardProps = {
  serviceId?: number;
  packageId?: number;
};

type BookingServiceEmployee = [serviceVariantId: number, employeeId: Nullable<number>];

const packageColor = "#B447E6";

const PurchasableCard = ({ serviceId, packageId }: PurchasableCardProps) => {
  const { t } = useTranslation(["outletPage", "common"]);
  const { getTranslatedValue } = useTranslatedValue();

  const dispatch = useDispatch();
  const outlet = useParamOutlet();

  const { selectedStaff, selectedPurchasable, selectedDate, setSelectedPurchasable } =
    useContext(OutletBookingContext);

  const [cartArticleDraft, setCartArticleDraft] = useState<Nullable<CartArticle>>(null);

  useEffect(() => {
    setSelectedPurchasable(null);
  }, [outlet, setSelectedPurchasable]);

  // const organization = useSelector(selectOrganization);
  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);
  const pkg = useSelector(selectPackageById(packageId || 0));
  const service = useSelector(selectServiceById(serviceId || 0));
  const serviceVariants = useSelector(selectServiceVariantsByServiceId(serviceId || 0));
  const packageVariants = useSelector(selectPackageVariantsByPackageId(packageId || 0));
  const packageVariant = useSelector(
    selectPackageVariantWithServicesById(packageVariants.length ? packageVariants[0].id : 0)
  );
  const isService = !!service && !!serviceVariants.length;

  const isPackage = !!pkg && !!packageVariants.length;

  const serviceHasOneVariant = isService && serviceVariants.length === 1;

  const packageArticlesSelector = selectPurchasableEarliestArticleFromCart(
    isPackage ? packageVariants[0].id : 0,
    TYPES.PACKAGES
  );

  const serviceVariantArticlesSelector = selectPurchasableEarliestArticleFromCart(
    serviceHasOneVariant ? serviceVariants[0].id : 0,
    TYPES.SERVICES
  );

  const earliestPurchasableArticleTimeInCart = useSelector(
    isPackage ? packageArticlesSelector : serviceVariantArticlesSelector
  );

  if (!isPackage && !isService) return null;

  const serviceAndVariantNamesAreIdentical =
    serviceHasOneVariant && serviceVariants[0].name === service.name;

  const shouldShowBookButton = isPackage || serviceHasOneVariant;

  let title = "";
  let subtitle = "";

  if (isService) {
    title =
      serviceAndVariantNamesAreIdentical || !serviceHasOneVariant
        ? getTranslatedValue(service.t.name)
        : `${getTranslatedValue(service.t.name)} - ${getTranslatedValue(
            serviceVariants[0].t.name
          )}`;

    subtitle = serviceHasOneVariant
      ? getFormattedDuration(serviceVariants[0].duration)
      : `${t("common:upTo")} ${getFormattedDuration(getHighestDuration(serviceVariants))}`;
  } else if (isPackage) {
    title = getTranslatedValue(pkg.t.name);
    subtitle = getFormattedDuration(packageVariants[0].totalDuration);
  }

  const handleBookClick = (id: number) => {
    if (selectedPurchasable === id) setSelectedPurchasable(null);
    else setSelectedPurchasable(id);
  };

  const handleSlotSelection = (selectedSlot: Nullable<BookingSlot>) => {
    if (selectedSlot) {
      //Change this later to display prompt of clearing cart

      if (packageVariant && selectedSlot) {
        const startTime = getSlotDateTime(selectedSlot);
        const serviceArticleSlotData: BookingServiceEmployee[] = packageVariant.serviceVariants.map(
          (serviceVariant) => [serviceVariant.id, null]
        );

        const cartArticle = generateCartArticle(
          startTime,
          serviceArticleSlotData,
          null,
          packageVariant.depositPercentage,
          undefined,
          packageVariant
        );

        if (cartArticle) {
          if (cart.articles.length && selectedSlot.date !== cart.date) {
            setCartArticleDraft(cartArticle);
          } else {
            dispatch(addArticleToCart(cartArticle));
            setSelectedPurchasable(null);
            if (organization?.id && organization?.name) {
              trackEvent(
                !!cart.articles.length ? "Item added to cart" : "Cart Initiated",
                { id: organization.id, name: organization.name },
                { isResourceSelected: false }
              );
            }
          }
        }
      } else if (serviceHasOneVariant && selectedSlot) {
        const serviceVariant = serviceVariants[0];

        const startTime = getSlotDateTime(selectedSlot);
        const cartArticle = generateCartArticle(
          startTime,
          selectedStaff || [],
          null,
          service?.depositPercentage,
          serviceVariant,
          undefined
        );
        if (cartArticle) {
          if (cart.articles.length && selectedSlot.date !== cart.date)
            setCartArticleDraft(cartArticle);
          else {
            dispatch(addArticleToCart(cartArticle));
            setSelectedPurchasable(null);
            if (organization?.id && organization?.name) {
              trackEvent(
                !!cart.articles.length ? "Item added to cart" : "Cart Initiated",
                { id: organization.id, name: organization.name },
                { isResourceSelected: selectedStaff ? !!selectedStaff : false }
              );
            }
          }
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const isBookButtonExpanded = isPackage
    ? selectedPurchasable === packageVariants[0].id
    : serviceVariants.map((serviceVariant) => serviceVariant.id).includes(selectedPurchasable || 0);

  const shouldShowTimeButton =
    earliestPurchasableArticleTimeInCart && selectedDate.toISODate() === cart.date;

  return (
    <>
      <StartNewBookingModal
        open={!!cartArticleDraft}
        onClose={() => setCartArticleDraft(null)}
        cartArticle={cartArticleDraft}
      />

      <StyledBox isPackage={isPackage} isSelected={isBookButtonExpanded}>
        <Box
          px={2}
          pt={2}
          pb={isBookButtonExpanded ? 0 : 2}
          display="flex"
          flexDirection="column"
          rowGap={1}
        >
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" flexDirection="column" rowGap={1} width="45%">
              <Typography
                variant="primary"
                color={isPackage ? packageColor : "black"}
                fontWeight="bold"
              >
                {title}
              </Typography>

              <Typography variant="secondary" fontWeight={500}>
                {t("glossary:duration", { durationText: subtitle })}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" justifyContent="flex-end">
              {isService ? (
                <ServiceListItemPrice variants={serviceVariants} />
              ) : (
                <PackageListItemPrice variants={packageVariants} />
              )}

              {shouldShowBookButton &&
                (shouldShowTimeButton ? (
                  <PurchasableSelectedSlotButton
                    label={earliestPurchasableArticleTimeInCart.earliestTime.toFormat(TIME_FORMAT)}
                    colorIndex={earliestPurchasableArticleTimeInCart.articleIndex}
                    onClick={() =>
                      handleBookClick(isPackage ? packageVariants[0].id : serviceVariants[0].id)
                    }
                  />
                ) : (
                  <CustomBookButton
                    isSelected={isBookButtonExpanded}
                    variant="outlined"
                    isPackage={isPackage}
                    onClick={() => {
                      if (organization?.id && organization?.name) {
                        trackEvent(
                          isService ? "Service Selected" : "Package Selected",
                          { id: organization.id, name: organization.name },
                          { isPopular: isService ? service.popular : pkg?.popular }
                        );
                      }

                      handleBookClick(isPackage ? packageVariants[0].id : serviceVariants[0].id);
                    }}
                  >
                    <Typography
                      variant="primary"
                      color={isPackage ? packageColor : "primary"}
                      fontWeight="bold"
                    >
                      {t("glossary:book")}
                    </Typography>
                  </CustomBookButton>
                ))}
            </Box>
          </Box>

          {isBookButtonExpanded && (serviceHasOneVariant || isPackage) && (
            <Box pb={2}>
              <PurchasableSlots
                duration={
                  serviceHasOneVariant
                    ? serviceVariants[0].duration
                    : isPackage
                    ? packageVariants[0].totalDuration
                    : 1
                }
                serviceVariantId={serviceHasOneVariant ? serviceVariants[0]?.id : undefined}
                packageVariantId={isPackage ? packageVariants[0]?.id : undefined}
                handleSlotSelection={handleSlotSelection}
              />
            </Box>
          )}
        </Box>

        {isService && !serviceHasOneVariant && (
          <Box width="100%" pt={1}>
            {serviceVariants.map((variant, index) => (
              <ServiceVariantItem
                shouldShowCart={setSelectedPurchasable}
                variant={variant}
                key={variant.id}
                displayDivider={index !== serviceVariants.length - 1}
              />
            ))}
          </Box>
        )}

        <PurchasableCardPackageDetails
          packageId={packageId || 0}
          serviceId={serviceId || 0}
          packageDescription={getTranslatedValue(pkg?.t.description)}
          serviceDescription={getTranslatedValue(service?.t.description)}
        />
      </StyledBox>
    </>
  );
};

export default PurchasableCard;

type CustomBoxProps = BoxProps & {
  isPackage: boolean;
  isSelected: boolean;
};

const StyledBox = styled(
  Box,
  dontForwardProps("isPackage", "isSelected")
)<CustomBoxProps>(({ theme, isPackage, isSelected }) => ({
  borderRadius: theme.spacing(1),
  border: isPackage ? "1px solid #D4A4EB" : "1px solid #D4D6E4",
  borderStyle: "solid",
  overflowX: "clip",
  boxShadow: isSelected ? "0px 2px 24px rgba(0, 0, 0, 0.18)" : "none",

  display: "flex",
  flexDirection: "column",
  maxWidth: "100%",
}));
