import axios, { AxiosError } from "axios";

/**
 * This function checks if payload of type AxiosError
 * by extracting other types other than AxiosError type from the payload type so that the
 * response type will be of type AxiosError only, without the other types we don't want such as `undefined`,
 * To stop Typescript from complaining about accessing properties of undefined bla bla bla bullshit
 *
 * @param payload
 * @returns
 */

function isAxiosErrorOfResponseType<ResponseType>(
  payload: ResponseType
): payload is Extract<ResponseType, AxiosError> {
  return axios.isAxiosError(payload);
}

export default isAxiosErrorOfResponseType;
