import { Grid } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectPopularPackagesByOutletId,
  selectPopularServicesByOutletIdAndEmployeeId,
} from "store/selectors";

import PurchasableCard from "./PurchasableCard";

type PopularListProps = {
  isEmployeeView?: boolean;
};

const PopularList = ({ isEmployeeView = false }: PopularListProps) => {
  const paramOutlet = useParamOutlet(true);

  const { employeeId } = useParams();

  const popularServices = useSelector(
    selectPopularServicesByOutletIdAndEmployeeId(
      paramOutlet?.id || 0,
      Number(employeeId) || undefined
    )
  );

  const popularPackages = useSelector(selectPopularPackagesByOutletId(paramOutlet?.id || 0));

  return (
    <>
      {popularServices.map((service) => (
        <Grid key={service?.id ?? 0} item xs={12}>
          <PurchasableCard serviceId={service.id} />
        </Grid>
      ))}

      {!isEmployeeView &&
        popularPackages.map((pkg) => (
          <Grid key={pkg?.id ?? 0} item xs={12}>
            <PurchasableCard packageId={pkg.id} />
          </Grid>
        ))}
    </>
  );
};

export default PopularList;
