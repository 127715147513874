import {
  FormControlLabel,
  FormControlLabelProps,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  styled,
} from "@mui/material";

type SwitchProps = MuiSwitchProps & {
  formControlLabelProps?: Omit<FormControlLabelProps, "control">;
};

/**
 * This component can be used with or without labels
 * 
 * 
 * To have a `label` next to the switch you should pass label prop to formControlLabelProps:
 * @example
 *  <Switch
          name="isSubscriptionApplied"
          checked={isSubscriptionApplied}
          onChange={handleSwitchToggle}
          formControlLabelProps={{
            label: "text",
          }}
        />
 */

const Switch = ({ formControlLabelProps, ...switchProps }: SwitchProps) => {
  return formControlLabelProps ? (
    <FormControlLabel
      {...formControlLabelProps}
      control={<CustomSwitch disableRipple {...switchProps} sx={{ mx: 1 }} />}
    />
  ) : (
    <CustomSwitch disableRipple {...switchProps} />
  );
};

export default Switch;

const CustomSwitch = styled((props: MuiSwitchProps) => (
  <MuiSwitch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,

  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",

    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
      },
    },

    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },

    "&.Mui-disabled .MuiSwitch-thumb": {
      color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      backgroundColor: theme.palette.bg.blank,
      border: "0.5px solid #0000001A",
    },

    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      background:
        "repeating-linear-gradient( -45deg, #bfbfbf8f, #bfbfbf8f 1px, #c4ddfdad 1px, #c4ddfda1 7px )",
    },
  },

  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },

  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));
