import { Autocomplete, FilterOptionsState, Paper, TextField, styled } from "@mui/material";
import COUNTRIES, { Country, getCountryFromCountryCode } from "assets/constants/countries";

export type CountryCodeAutocompleteProps = {
  value: string;
  onChange: (code: string) => void;

  focusPhoneField: () => void;
};

const CountryCodeAutocomplete = ({
  value,
  onChange,
  focusPhoneField,
}: CountryCodeAutocompleteProps) => (
  <InputAdornmentAutocomplete
    options={COUNTRIES}
    onChange={(_, country) => {
      onChange(country.code);
      focusPhoneField();
    }}
    filterOptions={filterCountriesByInput}
    disableClearable
    value={getCountryFromCountryCode(value)}
    getOptionLabel={(country) => `${country.flag} +${country.prefix}`}
    PaperComponent={FatPaper}
    renderInput={({ InputProps, ...params }) => (
      <TextField
        variant="standard"
        InputProps={{ disableUnderline: true, ...InputProps }}
        {...params}
      />
    )}
  />
);

const filterCountriesByInput = (
  countries: Country[],
  { inputValue }: FilterOptionsState<Country>
) =>
  countries.filter(
    ({ label, code, prefix }) =>
      label.toLowerCase().includes(inputValue.toLowerCase()) ||
      code.includes(inputValue.toUpperCase()) ||
      prefix.includes(inputValue.replace("+", ""))
  );

export default CountryCodeAutocomplete;

const InputAdornmentAutocomplete = styled(Autocomplete)({
  width: "85px",

  "& *": { paddingRight: "0 !important" }, // TODO: Find correct class instead of targeting *
}) as typeof Autocomplete; // This is a bug in MUI https://github.com/mui/material-ui/issues/30294

const FatPaper = styled(Paper)({ width: "120px" });
