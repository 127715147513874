/**
 * Truncates a string based on the maximum number characters it can have,
 * and takes into account truncating by words if useWordBoundary was set to true.
 * @param {string} string - The string that will be truncated
 * @param {number} maxCharacters - The maximum number of characters the truncated string can have.
 * @param {boolean} useWordBoundary - Used to check whether the words can be truncated or not.
 * @return {string:string, wasStringTruncated:boolean} returns an object of the truncated string with "..." if it was greater than maxCharacters wasStringTruncated = true boolean field or the original string if its length does not exceeds the maxCharacters
 */

const truncate = ({
  string,
  maxCharacters = 100,
  useWordBoundary = false,
}: {
  string: string;
  maxCharacters?: number;
  useWordBoundary?: boolean;
}) => {
  if (string.length <= maxCharacters) {
    return {
      string,
    };
  }
  const subString = string.substring(0, maxCharacters);

  const truncatedString =
    (useWordBoundary ? subString.substring(0, subString.lastIndexOf(" ")) : subString) + "...";

  return {
    string: truncatedString,
    wasStringTruncated: true,
  };
};

export default truncate;
