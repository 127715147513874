import type { RootState } from "store";
import { RedeemableSubscriptionItemOption } from "types/Subscription";

import { selectSubscriptionById } from "../subscriptions/subscriptionsSelectors";
import { SliceStatus } from "../utils";
import { subscriptionPurchasesAdapter } from "./subscriptionPurchasesSlice";
import { getSortedSubscriptionsByEarliestExpiration, getSubscriptionsByItem } from "./utils";

export const selectSubscriptionPurchasesState = (state: RootState) => state.subscriptionPurchases;

const { selectAll, selectById } = subscriptionPurchasesAdapter.getSelectors(
  selectSubscriptionPurchasesState
);

export const selectAllSubscriptionPurchases = selectAll;

export const selectSubscriptionPurchasesIsLoading = (state: RootState) =>
  state.subscriptionPurchases.status === SliceStatus.LOADING;

export const selectSubscriptionPurchaseById =
  (subscriptionPurchaseId: number | string) => (state: RootState) =>
    selectById(state, subscriptionPurchaseId);

export const selectSubscriptionPurchaseByIdWithSubscriptionDetails =
  (subscriptionPurchaseId: number | string) => (state: RootState) => {
    const subscriptionPurchase = selectSubscriptionPurchaseById(subscriptionPurchaseId)(state);

    return {
      ...subscriptionPurchase,
      subscription: selectSubscriptionById(subscriptionPurchase?.subscription || "")(state) || null,
    };
  };

export const selectActiveSubscriptionByOutletId = (outletId: number) => (state: RootState) => {
  const subscriptions = selectAllSubscriptionPurchases(state);

  return subscriptions.filter((subscription) => subscription.outlets.includes(outletId)) || [];
};

export const selectActiveSubscriptionByOutletIdAndItemId =
  (outletId: number, itemId: number, itemType: RedeemableSubscriptionItemOption) =>
  (state: RootState) => {
    const clientActiveSubscriptions = selectActiveSubscriptionByOutletId(outletId)(state);

    const itemSubscriptions = getSubscriptionsByItem(clientActiveSubscriptions, itemId, itemType);

    // filtering is ruining the order of subscriptions by expiration date, we need to sort them again
    return getSortedSubscriptionsByEarliestExpiration(itemSubscriptions);
  };
