import { Eye } from "@bookpeep/ui";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import { forwardRef, useState } from "react";

const PasswordField = forwardRef<HTMLDivElement, TextFieldProps>((textFieldProps, ref) => {
  const [shouldHideValue, setShouldHideValue] = useState(true);

  const toggleHideValue = () => setShouldHideValue((previousValue) => !previousValue);

  const { InputProps, ...restOfProps } = textFieldProps;

  return (
    <TextField
      // Add the customised default props
      type={shouldHideValue ? "password" : "text"}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton onClick={toggleHideValue} edge="end">
              {shouldHideValue ? <Eye color="disabled" /> : <Eye color="primary" />}
            </IconButton>
          </InputAdornment>
        ),
        // Prevent overwriting InputProps
        ...InputProps,
      }}
      // Pass on props as normal to follow MUI API
      {...restOfProps}
      ref={ref}
    />
  );
});

export default PasswordField;
