import { styled, Box, Grid, Typography } from "@mui/material";
import { CallToActionLink } from "components/CallToActionLink";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import { ReactNode, memo } from "react";
import { useTranslation } from "react-i18next";
import dontForwardProps from "helpers/styles/dontForwardProps";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { APP_STORE_LINK, PLAY_STORE_LINK } from "constants/marketplace";

type Props = {
  title: string;
  subtitle?: ReactNode;

  shouldShowPrivacyDisclaimer?: boolean;

  children: ReactNode;

  footer?: ReactNode;
};

function AuthenticationPageLayout({
  title,
  subtitle,
  shouldShowPrivacyDisclaimer = true,
  children,
  footer,
}: Props) {
  const { t } = useTranslation("misc");
  const { isAndroidDevice } = useDeviceDetection();
  const organization = useSelector(selectOrganization);

  return (
    <>
      <Grid container bgcolor="bg.secondary" p={3} spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h2" mb={1}>
            {title}
          </Typography>

          {subtitle && (
            <Typography variant="primary" color="text.label">
              {subtitle}
            </Typography>
          )}
        </Grid>

        {children}
      </Grid>

      <Grid container p={3} spacing={3}>
        {footer && (
          <Grid item xs={12} textAlign="center">
            {footer}
          </Grid>
        )}
      </Grid>
      {organization.isMpVisible && (
        <Banner
          bgcolor="#C6C8F3"
          color="white"
          imgUrl="/images/LoginDownloadAppBanner.svg"
          height="150px"
        >
          <Typography variant="h2" color="text.primary">
            {t("loginOnlyOnce")}
          </Typography>
          <Box
            mt={1}
            width="fit-content"
            alignItems="center"
            display="flex"
            flexDirection="column"
            position="relative"
          >
            <CallToActionLink
              href={organization.appLink || getUseAppLink(isAndroidDevice)}
              paddingY={0.5}
              paddingX={1.5}
            >
              {t("downloadApp")}
            </CallToActionLink>
            <Box display="flex" flexDirection="row" gap={0.5} mt="14px">
              <a href={APP_STORE_LINK}>
                <img
                  alt="download-on-app-store"
                  src="/images/DownloadAppOnAppStore.svg"
                  height="12px"
                />
              </a>
              <a href={PLAY_STORE_LINK}>
                <img
                  alt="download-on-google-play"
                  src="/images/DownloadAppOnGooglePlay.svg"
                  height="12px"
                />
              </a>
            </Box>
            <Box position="absolute" right="-60px">
              <img alt="" src="/images/download-arrow.svg" height="60px" />
            </Box>
          </Box>
        </Banner>
      )}
    </>
  );
}

export default memo(AuthenticationPageLayout);

const Banner = styled(
  Box,
  dontForwardProps("imgUrl")
)<{ imgUrl: string }>(({ imgUrl, theme }) => ({
  backgroundImage: `url(${imgUrl})`,
  backgroundPosition: "bottom right",
  backgroundSize: "auto 130%",
  backgroundRepeat: "no-repeat",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  gap: 5,
  paddingLeft: "5%",
  paddingRight: "5%",
}));
