import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import createThunkFromApi from "store/utils/createThunkFromApi";
import matchThunks from "store/utils/matchThunks";
import { SubscriptionPurchase } from "types/Subscription";

import { SliceStatus } from "../utils";
import api from "./subscriptionPurchasesApi";

export const subscriptionPurchasesAdapter = createEntityAdapter<SubscriptionPurchase>({
  selectId: (subscription) => subscription.id,
});

const initialState = subscriptionPurchasesAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const getSubscriptionPurchases = createThunkFromApi(
  "subscriptions/getSubscriptionPurchases",
  api.getSubscriptionPurchases
);

export const getSubscriptionPurchase = createThunkFromApi(
  "subscriptions/getSubscriptionPurchase",
  api.getSubscriptionPurchase
);

export const createSubscriptionPurchase = createThunkFromApi(
  "subscriptions/createSubscriptionPurchase",
  api.createSubscriptionPurchase
);

export const subscriptionPurchasesSlice = createSlice({
  name: "subscriptionPurchases",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(createSubscriptionPurchase.fulfilled, (state) => {
        state.status = SliceStatus.IDLE;
      })

      .addCase(getSubscriptionPurchase.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(getSubscriptionPurchase.fulfilled, (state, { payload }) => {
        subscriptionPurchasesAdapter.upsertOne(state, payload);
        state.status = SliceStatus.IDLE;
      })

      .addCase(getSubscriptionPurchase.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(getSubscriptionPurchases.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(getSubscriptionPurchases.fulfilled, (state, { payload }) => {
        subscriptionPurchasesAdapter.upsertMany(state, payload.subscriptions);
        state.status = SliceStatus.IDLE;
      })

      .addCase(getSubscriptionPurchases.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "pending" }), (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "fulfilled" }), (state) => {
        state.status = SliceStatus.IDLE;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "rejected" }), (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default subscriptionPurchasesSlice.reducer;
