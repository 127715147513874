import { createSlice } from "@reduxjs/toolkit";
import matchThunks from "store/utils/matchThunks";

import { getProfile, logout } from "../auth/authSlice";
import { SliceStatus } from "../utils";

type WalletSliceState = Partial<Wallet> & { status: SliceStatus; requestStatus: SliceStatus };

const initialState: WalletSliceState = {
  status: SliceStatus.IDLE,
  requestStatus: SliceStatus.IDLE,
};

export const walletSlice = createSlice({
  name: "wallet",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getProfile.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        const { wallet } = payload;
        return {
          ...state,
          ...wallet,
          status: SliceStatus.IDLE,
        };
      })
      .addCase(getProfile.rejected, (state, { payload }) => {
        state.status = SliceStatus.FAILED;
      })

      .addMatcher(matchThunks({ slice: "auth", status: "pending" }), (state) => {
        state.requestStatus = SliceStatus.LOADING;
      })
      .addMatcher(matchThunks({ slice: "auth", status: "fulfilled" }), (state) => {
        state.requestStatus = SliceStatus.IDLE;
      })
      .addMatcher(matchThunks({ slice: "auth", status: "rejected" }), (state) => {
        state.requestStatus = SliceStatus.FAILED;
      })
      .addMatcher(logout.match, () => initialState);
  },
});

export default walletSlice.reducer;
