import { createSvgIcon } from "@mui/material";

export const UserIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.46447 15.4645C6.40215 14.5268 7.67392 14 9 14H15C16.3261 14 17.5979 14.5268 18.5355 15.4645C19.4732 16.4021 20 17.6739 20 19V21C20 21.5523 19.5523 22 19 22C18.4477 22 18 21.5523 18 21V19C18 18.2044 17.6839 17.4413 17.1213 16.8787C16.5587 16.3161 15.7956 16 15 16H9C8.20435 16 7.44129 16.3161 6.87868 16.8787C6.31607 17.4413 6 18.2044 6 19V21C6 21.5523 5.55228 22 5 22C4.44772 22 4 21.5523 4 21V19C4 17.6739 4.52678 16.4021 5.46447 15.4645Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10C13.6569 10 15 8.65685 15 7C15 5.34315 13.6569 4 12 4ZM7 7C7 4.23858 9.23858 2 12 2C14.7614 2 17 4.23858 17 7C17 9.76142 14.7614 12 12 12C9.23858 12 7 9.76142 7 7Z"
    />
  </>,
  "User"
);
