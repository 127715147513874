// Use this with care, there are cases where the typing wouldn't be very precise
// Check the following links:
// https://github.com/microsoft/TypeScript/issues/35101
// https://stackoverflow.com/questions/55012174/why-doesnt-object-keys-return-a-keyof-type-in-typescript

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

function entries<T>(obj: T): Entries<T> {
  return Object.entries(obj) as any;
}

/**
 * A function to map through object's entries to modify them.
 *
 * @param obj Object you want to map through
 * @param func Function you want to apply on each entry
 * @returns The modified object
 */
export default function mapObject<ObjectType extends object>(
  obj: ObjectType,
  func: (entry: Entries<ObjectType>[number], index: number, array: Entries<ObjectType>) => any
) {
  return Object.fromEntries(entries(obj).map(func));
}
