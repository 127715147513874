import { Link, styled } from "@mui/material";

export const CallToActionLink = styled(Link)(({ theme }) => ({
  backgroundColor: "white",
  fontWeight: 700,
  fontSize: "small",
  borderRadius: 25,
  textDecoration: "none",
  rowGap: theme.spacing(1),
}));
