import { MenuItem, MenuItemProps } from "@mui/material";

type UserMenuItemProps = MenuItemProps & {
  onClose?: () => void;
};

export default function UserMenuItem({ onClose, onClick, ...menuItemProps }: UserMenuItemProps) {
  return (
    <MenuItem
      onClick={(args) => {
        if (onClick) onClick(args);
        if (onClose) onClose();
      }}
      {...menuItemProps}
    />
  );
}
