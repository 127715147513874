import { PhoneNumberUtil } from "google-libphonenumber";
import { addMethod, string } from "yup";

const phoneUtil = PhoneNumberUtil.getInstance();

declare module "yup" {
  interface StringSchema {
    phoneNumber(countryCodeKey: string, message?: string): StringSchema;
  }
}

addMethod(string, "phoneNumber", function phoneNumber(countryCodeKey: string, message?: string) {
  return this.test("phoneNumber", message || "invalidPhoneNumber", function (fieldValue) {
    // If no fieldValue is provided true is returned in similar fashion as string().email()
    if (!fieldValue) return true;
    // This leaves it up to "required()" to catch an empty field

    const { path, createError, parent } = this;

    // Get the country code and report an error if it doesn't exist
    const countryCode = parent[countryCodeKey];
    if (!countryCode) return createError({ message: "invalidCountryCode", path });

    // if the fieldValue is not a valid number the parsing will explode and ruin the validation of all the fields -- Anfal
    try {
      const phoneNumber = phoneUtil.parse(fieldValue, countryCode);
      const phoneNumberRawValue = phoneNumber.getNationalNumber();

      const isPossibleMobileNumber = phoneUtil.isPossibleNumber(phoneNumber);

      const isValidMobileNumber = phoneUtil.isValidNumber(phoneNumber);

      // To ensure that the fieldValue does not include countryCode, because phoneUtil.parse will remove the additional country code from the fieldValue when parsing, which will make validating phoneNumber always successful, which we don't want -- Anfal
      const doesParsedPhoneMatchesFieldValue = phoneNumberRawValue === Number(fieldValue);

      if (isPossibleMobileNumber && isValidMobileNumber && doesParsedPhoneMatchesFieldValue)
        return true;

      throw new Error(); // Go to catch phrase
    } catch {
      return createError({ message: "invalidPhoneNumber", path });
    }
  });
});
