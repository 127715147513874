import { Box, BoxProps, styled } from "@mui/material";

type SectionWrapperProps = BoxProps & {
  children: any;
};

function SectionWrapper({ children, ...props }: SectionWrapperProps) {
  return <Wrapper {...props}>{children}</Wrapper>;
}

const Wrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  width: "100%",
  borderRadius: "16px",
}));

export default SectionWrapper;
