import { Box, Grid, Typography } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectOutletBySlug,
  selectPackagesByCategoryIdAndOutletId,
  selectServicesByCategoryIdAndOutletIdAndEmployeeId,
} from "store/selectors";

import { TYPES } from "./constants";
import PurchasableCard from "./PurchasableCard";

type CategoryServicesProps = {
  category: ServiceCategory | PackageCategory;
  type: string;
};

export default function CategoryServices({ category, type }: CategoryServicesProps) {
  const { t } = useTranslation("outletPage");

  const paramOutlet = useParamOutlet(true);
  const { employeeId: rowEmployeeId } = useParams();

  const outletSlug = paramOutlet?.slug || "";

  const employeeId = rowEmployeeId ? Number(rowEmployeeId) : undefined;

  const outlet = useSelector(selectOutletBySlug(outletSlug));
  const services = useSelector(
    selectServicesByCategoryIdAndOutletIdAndEmployeeId(category.id, outlet?.id ?? 0, employeeId)
  );

  const packages = useSelector(selectPackagesByCategoryIdAndOutletId(category.id, outlet?.id ?? 0));

  const servicesList = () => {
    if (employeeId && !services.length)
      return (
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" p={3}>
            <Typography variant="primary" color="#848FA1" align="center">
              {t("staffHasNoServicesInCategory")}
            </Typography>
          </Box>
        </Grid>
      );

    return services.map((service) => (
      <Grid key={service?.id ?? 0} item xs={12}>
        <PurchasableCard serviceId={service.id} />
      </Grid>
    ));
  };

  const packagesList = packages.map((pkg) => (
    <Grid key={pkg?.id ?? 0} item xs={12}>
      <PurchasableCard packageId={pkg.id} />
    </Grid>
  ));

  return (
    <Grid container spacing={1}>
      {type === TYPES.SERVICES ? servicesList() : packagesList}
    </Grid>
  );
}
