import { Grid } from "@mui/material";

import NoResults from "./NoResults";
import PurchasableCard from "./PurchasableCard";

const PackageSearchResult = ({
  filteredPackageVariants,
}: {
  filteredPackageVariants: PackageVariant[];
}) => {
  const isListEmpty = !filteredPackageVariants.length;

  return isListEmpty ? (
    <NoResults />
  ) : (
    <>
      {filteredPackageVariants.map((packageVariant) => (
        <Grid key={packageVariant?.package ?? 0} item xs={12}>
          <PurchasableCard packageId={packageVariant?.package ?? 0} />
        </Grid>
      ))}
    </>
  );
};
export default PackageSearchResult;
