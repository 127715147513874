import { Typography } from "@mui/material";
import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ResetPasswordForm from "./ResetPasswordForm";

export default function ResetPassword() {
  const { t } = useTranslation("forgetPassword");

  return (
    <AuthenticationPageLayout
      title={t("setYourNewPassword")}
      subtitle={t("setYourNewPassword")}
      footer={
        <Typography variant="h4">
          {t("alreadyHaveAccount")} <Link to="/login">{t("login")}</Link>
        </Typography>
      }
    >
      <ResetPasswordForm />
    </AuthenticationPageLayout>
  );
}
