import { RootState } from "store";

import { selectServiceById } from "../services/servicesSelectors";
import { SliceStatus } from "../utils";
import { serviceVariantAdapter } from "./serviceVariantsSlice";

const selectServiceVariantsState = (state: RootState) => state.serviceVariants;

const { selectById, selectAll } = serviceVariantAdapter.getSelectors(selectServiceVariantsState);

export const selectServiceVariantById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectServiceVariantsByIds = (ids: number[]) => (state: RootState) => {
  const selectedServiceVariants = ids.map((id) => {
    return selectById(state, id);
  });

  return selectedServiceVariants;
};

export const selectServiceVariantPriceById =
  (id: Nullable<number | string>) => (state: RootState) =>
    Number(selectById(state, id || "")?.price || 0);

export const selectServiceByServiceVariantId = (id: string | number) => (state: RootState) => {
  const serviceVariant = selectById(state, id);
  return selectServiceById(serviceVariant?.service || 0)(state);
};

export const selectServicesByServiceVariantIds = (ids: number[]) => (state: RootState) => {
  const selectedServices = ids.map((id) => {
    const serviceVariant = selectById(state, id);
    return selectServiceById(serviceVariant?.service || 0)(state);
  });

  return selectedServices;
};
export const selectServiceVariantsByServiceId =
  (id: number) =>
  (state: RootState): ServiceVariant[] =>
    selectAll(state).filter((variant) => variant && variant.service === id);

export const selectAllServiceVariants = selectAll;

export const selectServiceVariantsLoading = (state: RootState) =>
  state.serviceVariants.status === SliceStatus.LOADING;

export const selectServiceVariantLongName = (id: number) => (state: RootState) => {
  const serviceVariant = selectById(state, id);
  const service = selectServiceById(String(serviceVariant?.service))(state);

  // TODO: Find a proper way to handle these cases
  if (!serviceVariant || !service) return [];

  if (serviceVariant.name === service.name) return [service.t.name];

  return [service.t.name, serviceVariant.t.name];
};
