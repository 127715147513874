import { DateTime } from "luxon";

export type EarliestArticleData = {
  earliestTime: DateTime;
  articleIndex: number;
};

type ModifiedCartArticle = CartArticle & { index: number };

export function getEarliestCartArticleSlotWithArticleIndex(articles: ModifiedCartArticle[]) {
  let earliestTime: Nullable<DateTime> = null;
  let articleIndex: Nullable<number> = null;

  articles.forEach((article) => {
    article.slots.forEach((slot) => {
      const slotTime = DateTime.fromISO(slot.start);

      if (!earliestTime || slotTime < earliestTime) {
        earliestTime = slotTime;
        articleIndex = article.index;
      }
    });
  });

  return earliestTime && articleIndex !== null
    ? ({ earliestTime, articleIndex } as EarliestArticleData)
    : null;
}
