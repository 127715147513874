export type Subscription = {
  id: number;

  name: string;
  itemName: string;

  serviceVariant: Nullable<number>;
  packageVariant: Nullable<number>;

  serviceVariants: number[];

  price: string;

  count: number;
  validityInDays: number;

  sellingOutlets: number[];
  redeemingOutlets: number[];

  active: boolean;
};

export type SubscriptionPurchase = {
  id: number;

  subscription: number | string;

  name: string;
  itemName: string;

  serviceVariant: Nullable<number>;
  packageVariant: Nullable<number>;

  serviceVariants: number[];

  outlets: number[];

  remainingRedemptionsCount: number;
  totalCreditsCount: number;
  validityInDays: number;

  createdAt: string;
  expirationDate: string;

  isRelatedToOnlineProfile: boolean;
};

export type ProcessedSubscriptionPurchase = SubscriptionPurchase & {
  expirationDate: string;
};

export enum RedeemableSubscriptionItemOption {
  ServiceVariants = "serviceVariants",
  PackageVariant = "packageVariant",
}

export enum SubscriptionStatus {
  activeSubscriptions = "Active",
  expiredSubscriptions = "Inactive",
}

export type SubscriptionRedemption = {
  id: number;
  name: string;
};
