import { DateTime } from "luxon";
import { RootState } from "store";

import { SliceStatus } from "../utils";

export const selectOrganization = (state: RootState) => state.organization;

export const selectDate = (state: RootState) => DateTime.fromISO(state.organization.date);

export const selectIsOrganizationLoading = (state: RootState) =>
  state.organization.status === SliceStatus.LOADING;
