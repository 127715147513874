import { Avatar, Box, Grid, Radio, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PForPeep from "assets/icons/PForPeep";
import { getImageSrc } from "helpers/getImageSrc";
import { useSelector } from "store";
import { selectOutletById } from "store/selectors";

type OutletRadioProps = {
  outletId: number;
  checkedOutletSlug: Nullable<string>;
  onClick: (outletSlug: string) => void;
  disabled?: boolean;
  disabledText?: string;
};

const OutletRadio = ({
  outletId,
  checkedOutletSlug,
  onClick,
  disabled = false,
  disabledText = "",
}: OutletRadioProps) => {
  const outlet = useSelector(selectOutletById(outletId));

  const outletImage = getImageSrc(outlet?.primaryImage) || "";

  return (
    <OutletRadioWrapper
      disabled={disabled}
      sx={{ display: outlet ? "block" : "none" }}
      onClick={() => (disabled ? {} : onClick(outlet?.slug || ""))}
    >
      <Grid container>
        <Grid item xs={8} display="flex" alignItems="center">
          <OutletAvatarWrapper>
            {outlet?.name && (
              <Avatar alt={outlet?.name} src={outletImage} sx={{ width: 40, height: 40 }}>
                <PForPeep fontSize="large" />
              </Avatar>
            )}
          </OutletAvatarWrapper>

          <Box>
            <Typography variant="primary" fontWeight="700" color="text.label" component="h4">
              {outlet?.name}
            </Typography>

            {disabled && disabledText && (
              <Typography variant="secondary" fontWeight="500" color="text.primary" component="p">
                {disabledText}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={4} textAlign="right">
          <Radio disabled={disabled} checked={checkedOutletSlug === outlet?.slug} />
        </Grid>
      </Grid>
    </OutletRadioWrapper>
  );
};

export default OutletRadio;

type OutletRadioWrapperProps = {
  disabled: boolean;
};

const OutletRadioWrapper = styled(Box)<OutletRadioWrapperProps>(({ theme, disabled }) => ({
  background: disabled ? theme.palette.bg.disabled : null,
  border: `1px solid ${theme.palette.common.gray}24`,
  borderRadius: 4,
  minHeight: "64px",
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  cursor: "pointer",
}));

const OutletAvatarWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  ".MuiSvgIcon-root": {
    width: 31,
    height: 31,
  },
}));
