import { Box, Grid } from "@mui/material";
import useEmblaCarousel from "embla-carousel-react";
import useLanguageDirection from "hooks/useLanguageDirection";
import { DateTime } from "luxon";
import OutletBookingContext from "pages/OutletPage/outletContext";
import { useContext, useMemo } from "react";

import { BookingSlot } from "../../bookingPageTypes";
import SlotPickerEmptyDate from "../SlotPickerEmptyDate";
import { pushSlotToPeriod } from "../slotPickerHelpers";
import SlotPage from "./SlotPage";

type SlotPeriodBinder = {
  morning: BookingSlot[][];
  afternoon: BookingSlot[][];
  evening: BookingSlot[][];
};

type SlotPickerBinderProps = {
  duration: number;
  options: BookingSlot[];
  value: Nullable<BookingSlot>;
  onChange: (slot: Nullable<BookingSlot>) => void;

  employeeIdFilter: Nullable<number>;
};

// TODO:CLEANUP
function SlotPickerBinder({
  duration,
  value,
  onChange,
  options,
  employeeIdFilter,
}: SlotPickerBinderProps) {
  const { selectedDate, selectedStaff } = useContext(OutletBookingContext);
  const languageDirection = useLanguageDirection();

  const [emblaRef] = useEmblaCarousel({ direction: languageDirection });

  const sortedSlots = options.sort((a, b) => {
    if (DateTime.fromISO(b.time) >= DateTime.fromISO(a.time)) return -1;
    if (DateTime.fromISO(b.time) <= DateTime.fromISO(a.time)) return 1;
    return 0;
  });

  const slotBinder = useMemo(() => {
    if (employeeIdFilter) {
      return createSlotBinder(
        sortedSlots.filter((option) => option.employees[0].includes(employeeIdFilter))
      );
    }

    return createSlotBinder(sortedSlots);
  }, [employeeIdFilter, sortedSlots]);

  const spreadBinderPages = useMemo(
    () => [...slotBinder.morning, ...slotBinder.afternoon, ...slotBinder.evening],
    [slotBinder]
  );

  const renderSlotPage = (slots: BookingSlot[], index: number) =>
    slots.length ? (
      <SlotPage
        duration={duration}
        key={`page[${index}]`}
        slots={slots}
        onClickSlot={onChange}
        selectedSlot={value}
      />
    ) : null;

  const slotsAfterEmployeeFilter = useMemo(() => {
    if (employeeIdFilter)
      return options.filter((slot) => slot.employees?.[0].includes(employeeIdFilter));
    return options;
  }, [options, employeeIdFilter]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!slotsAfterEmployeeFilter.length ? (
          <SlotPickerEmptyDate date={selectedDate.toISODate()} selectedStaff={selectedStaff} />
        ) : (
          <Box ref={emblaRef}>
            <Box sx={{ display: "flex", gap: "1rem", minHeight: "85px" }}>
              {spreadBinderPages.map(renderSlotPage)}
            </Box>
          </Box>
        )}
      </Grid>
    </Grid>
  );
}

export default SlotPickerBinder;

// TODO:CLEANUP: This and the above need some restructuring to reduce file lines
// One suggestion is to move all "Slot Binder" stuff into their own directory here
function createSlotBinder(slots: BookingSlot[]) {
  const periods: SlotPeriodBinder = {
    morning: [[]],
    afternoon: [[]],
    evening: [[]],
  };

  slots.forEach((slot) => {
    pushSlotToPeriod(periods.morning, slot);
  });

  return periods;
}
