import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translatePackage } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const packageAdapter = createEntityAdapter<Package>();

const initialState = packageAdapter.getInitialState({ status: SliceStatus.IDLE });

const packageSlice = createSlice({
  name: "packages",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const packagesWithTranslationKeys = payload.packages.map((_package) => ({
          ..._package,
          t: translatePackage(_package.id),
        }));
        packageAdapter.setAll(state, packagesWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default packageSlice.reducer;
