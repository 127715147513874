import { createSvgIcon } from "@mui/material";

export const LocationIcon = createSvgIcon(
  <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M4.05023 4.05026C5.36304 2.73749 7.14349 2 9 2C10.8565 2 12.637 2.73749 13.9497 4.05026C15.2625 5.36301 16 7.14349 16 9C16 11.605 14.2295 14.3616 12.2526 16.5856C11.2876 17.6713 10.3195 18.5792 9.59149 19.2162C9.36884 19.411 9.16925 19.5799 9 19.7202C8.83075 19.5799 8.63116 19.411 8.40851 19.2162C7.68054 18.5792 6.7124 17.6713 5.74744 16.5856C3.77045 14.3616 2 11.605 2 9C2 7.14349 2.73749 5.36301 4.05023 4.05026ZM8.39703 21.7978L8.39954 21.7997C8.75507 22.0664 9.24445 22.0667 9.59998 21.8L9 21C9.59998 21.8 9.60089 21.7993 9.60114 21.7992L9.60297 21.7978L9.60883 21.7933L9.62909 21.778L9.64154 21.7684C9.65753 21.7562 9.67792 21.7406 9.70239 21.7216C9.76538 21.6728 9.85596 21.6017 9.97015 21.51C10.1984 21.3265 10.5218 21.0597 10.9085 20.7213C11.6805 20.0458 12.7124 19.0787 13.7474 17.9144C15.7705 15.6384 18 12.395 18 9C18 6.61304 17.0518 4.32388 15.364 2.63605C13.6761 0.948212 11.3869 0 9 0C6.61304 0 4.32385 0.948212 2.63605 2.63605C0.948242 4.32388 0 6.61304 0 9C0 12.395 2.22955 15.6384 4.25256 17.9144C5.2876 19.0787 6.31946 20.0458 7.09149 20.7213C7.47821 21.0597 7.80157 21.3265 8.02985 21.51C8.14404 21.6017 8.23462 21.6728 8.29761 21.7216C8.32208 21.7406 8.34247 21.7562 8.35846 21.7684L8.37091 21.778L8.39117 21.7933L8.39703 21.7978ZM7 9C7 7.89542 7.89545 7 9 7C10.1046 7 11 7.89542 11 9C11 10.1046 10.1046 11 9 11C7.89545 11 7 10.1046 7 9ZM9 5C6.79083 5 5 6.79086 5 9C5 11.2091 6.79083 13 9 13C11.2091 13 13 11.2091 13 9C13 6.79086 11.2091 5 9 5Z"
  />,
  "Location"
);
