import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateOutlet } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const outletAdapter = createEntityAdapter<Outlet>();

const initialState = outletAdapter.getInitialState({ status: SliceStatus.IDLE });

const outletSlice = createSlice({
  name: "outlets",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const outletsWithTranslationKeys = payload.outlets.map((outlet) => ({
          ...outlet,
          t: translateOutlet(outlet.id),
        }));

        outletAdapter.setAll(state, outletsWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default outletSlice.reducer;
