import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateServiceVariant } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const serviceVariantAdapter = createEntityAdapter<ServiceVariant>();

const initialState = serviceVariantAdapter.getInitialState({ status: SliceStatus.IDLE });

const serviceVariantsSlice = createSlice({
  name: "serviceVariants",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const serviceVariantsWithTranslationKeys = payload.serviceVariants.map(
          (serviceVariant) => ({
            ...serviceVariant,
            t: translateServiceVariant(serviceVariant.service, serviceVariant.id),
          })
        );
        serviceVariantAdapter.setAll(state, serviceVariantsWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default serviceVariantsSlice.reducer;
