import { Calendar, Ticket } from "@bookpeep/ui";
import { Box, Button, Avatar as MuiAvatar, Stack, Typography, styled } from "@mui/material";
import PriceBox from "components/PriceBox";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectAuthUser } from "store/selectors";
import { selectWallet } from "store/slices/wallet/walletSelectors";

import ProfileButton from "./ProfileButton";

const UserProfileHeader = () => {
  const { t } = useTranslation("userProfilePage");
  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);
  const wallet = useSelector(selectWallet);

  if (!user) return null;

  const userFullName = `${user.firstName} ${user.lastName}`.trim();

  // Placeholder until wallet is implemented
  const walletBalance = wallet.availableCredit || 0.0;

  const goToEditProfilePage = () => {
    navigate("/profile/edit");
  };

  const goToBookingsPage = () => {
    navigate("/profile/bookings");
  };

  const goToSubscriptionsPage = () => {
    navigate("/profile/subscriptions");
  };

  return (
    <Stack alignItems="center" bgcolor="transparent" px={2} py={5}>
      <Avatar />

      <Stack alignItems="center" py={3}>
        <Typography variant="h1">{userFullName}</Typography>

        <Typography variant="secondary">{user.email}</Typography>
      </Stack>

      <Box display="flex" alignItems="center" borderRadius={50} bgcolor="#53BFB0" px={3} py={1}>
        <Typography variant="secondary" color="white" fontWeight={500}>
          {t("walletBalance")}
          {": "}
          <PriceBox
            value={Number(walletBalance) | 0}
            decimalStrategy="trim_zeros"
            variant="secondary"
            sx={{ p: 0.5 }}
          />
        </Typography>
      </Box>

      <Box display="flex" width="100%" gap={2} py={5}>
        <ProfileButton title={t("myBookings")} icon={<Calendar />} onClick={goToBookingsPage} />

        <ProfileButton
          title={t("mySubscriptions")}
          icon={<Ticket />}
          onClick={goToSubscriptionsPage}
        />
      </Box>

      <Button fullWidth onClick={goToEditProfilePage} size="medium">
        {t("editUserDetails")}
      </Button>
    </Stack>
  );
};

export default UserProfileHeader;

//  maybe we should create a common component for the avatar because it has a similar style to the outlet avatar
const Avatar = styled(MuiAvatar)(({ theme }) => ({
  border: "2px solid",
  borderColor: theme.palette.common.white,

  width: "104px",
  height: "104px",
}));
