import { Grid } from "@mui/material";
import LtrPreserver from "components/LtrPreserver";
import useAlertBox from "hooks/useAlertBox";
import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAuthUser } from "store/selectors";

import ActivateAccountButton from "./ActivateAccountButton";
import OtpField from "./OtpField";
import ResendVerificationCodeButton from "./ResendVerificationCodeButton";

export type PendingRequest = "activate" | "resend";

export default function VerifyPhonePage() {
  const { t } = useTranslation("verifyPhonePage");

  const [otp, setOtp] = useState("");

  const handleOtpChange = (otp: string) => setOtp(otp);

  const user = useSelector(selectAuthUser);

  const { AlertBox, setAlert } = useAlertBox();

  const [pendingRequest, setPendingRequest] = useState<PendingRequest | null>(null);

  const shouldDisableActivateAccountButton = pendingRequest === "activate" || otp?.length !== 6;

  return (
    <AuthenticationPageLayout
      title={t("verifyPhonePage:title")}
      subtitle={
        <Trans
          i18nKey="verifyPhonePage:subtitle"
          values={{ phoneNumber: user?.phoneNumber }}
          components={{ phoneNumber: <LtrPreserver /> }}
        />
      }
    >
      <Grid item md={12} xs={12}>
        <AlertBox />
      </Grid>

      <Grid item md={12} xs={12}>
        <OtpField value={otp} onChange={handleOtpChange} />
      </Grid>

      <Grid item md={12} xs={12}>
        <ActivateAccountButton
          otp={otp}
          setAlert={setAlert}
          isLoading={pendingRequest === "activate"}
          isDisabled={shouldDisableActivateAccountButton}
          setPendingRequest={setPendingRequest}
        />
      </Grid>

      <Grid item md={12} xs={12}>
        <ResendVerificationCodeButton
          setAlert={setAlert}
          isLoading={pendingRequest === "resend"}
          isDisabled={pendingRequest === "activate"}
          setPendingRequest={setPendingRequest}
        />
      </Grid>
    </AuthenticationPageLayout>
  );
}
