import api from "./globalApi";
import createThunkFromApi from "./utils/createThunkFromApi";

export const getNormalizedOrganization = createThunkFromApi(
  "global/getNormalizedOrganization",
  api.getNormalizedOrganization
);

export const getNormalizedAppointments = createThunkFromApi(
  "global/getNormalizedAppointments",
  api.getNormalizedAppointments
);

