import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translatePackageVariant } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const packageVariantAdapter = createEntityAdapter<PackageVariant>();

const initialState = packageVariantAdapter.getInitialState({ status: SliceStatus.IDLE });

const packageVariantsSlice = createSlice({
  name: "packageVariants",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const packageVariantsWithTranslationKeys = payload.packageVariants.map(
          (packageVariant) => ({
            ...packageVariant,
            t: translatePackageVariant(packageVariant.package, packageVariant.id),
          })
        );
        packageVariantAdapter.setAll(state, packageVariantsWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default packageVariantsSlice.reducer;
