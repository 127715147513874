export type Country = { label: string; code: string; prefix: string; flag: string };

const COUNTRIES = [
  { label: "andorra", code: "AD", prefix: "376", flag: "🇦🇩" },
  { label: "unitedArabEmirates", code: "AE", prefix: "971", flag: "🇦🇪" },
  { label: "afghanistan", code: "AF", prefix: "93", flag: "🇦🇫" },
  { label: "antiguaAndBarbuda", code: "AG", prefix: "1-268", flag: "🇦🇬" },
  { label: "anguilla", code: "AI", prefix: "1-264", flag: "🇦🇮" },
  { label: "albania", code: "AL", prefix: "355", flag: "🇦🇱" },
  { label: "armenia", code: "AM", prefix: "374", flag: "🇦🇲" },
  { label: "angola", code: "AO", prefix: "244", flag: "🇦🇴" },
  { label: "antarctica", code: "AQ", prefix: "672", flag: "🇦🇶" },
  { label: "argentina", code: "AR", prefix: "54", flag: "🇦🇷" },
  { label: "americanSamoa", code: "AS", prefix: "1-684", flag: "🇦🇸" },
  { label: "austria", code: "AT", prefix: "43", flag: "🇦🇹" },
  { label: "australia", code: "AU", prefix: "61", flag: "🇦🇺" },
  { label: "aruba", code: "AW", prefix: "297", flag: "🇦🇼" },
  { label: "allandIslands", code: "AX", prefix: "358", flag: "🇦🇽" },
  { label: "azerbaijan", code: "AZ", prefix: "994", flag: "🇦🇿" },
  { label: "bosniaAndHerzegovina", code: "BA", prefix: "387", flag: "🇧🇦" },
  { label: "barbados", code: "BB", prefix: "1-246", flag: "🇧🇧" },
  { label: "bangladesh", code: "BD", prefix: "880", flag: "🇧🇩" },
  { label: "belgium", code: "BE", prefix: "32", flag: "🇧🇪" },
  { label: "burkinaFaso", code: "BF", prefix: "226", flag: "🇧🇫" },
  { label: "bulgaria", code: "BG", prefix: "359", flag: "🇧🇬" },
  { label: "bahrain", code: "BH", prefix: "973", flag: "🇧🇭" },
  { label: "burundi", code: "BI", prefix: "257", flag: "🇧🇮" },
  { label: "benin", code: "BJ", prefix: "229", flag: "🇧🇯" },
  { label: "saintBarthelemy", code: "BL", prefix: "590", flag: "🇧🇱" },
  { label: "bermuda", code: "BM", prefix: "1-441", flag: "🇧🇲" },
  { label: "bruneiDarussalam", code: "BN", prefix: "673", flag: "🇧🇳" },
  { label: "bolivia", code: "BO", prefix: "591", flag: "🇧🇴" },
  { label: "brazil", code: "BR", prefix: "55", flag: "🇧🇷" },
  { label: "bahamas", code: "BS", prefix: "1-242", flag: "🇧🇸" },
  { label: "bhutan", code: "BT", prefix: "975", flag: "🇧🇹" },
  { label: "bouvetIsland", code: "BV", prefix: "47", flag: "🇧🇻" },
  { label: "botswana", code: "BW", prefix: "267", flag: "🇧🇼" },
  { label: "belarus", code: "BY", prefix: "375", flag: "🇧🇾" },
  { label: "belize", code: "BZ", prefix: "501", flag: "🇧🇿" },
  { label: "canada", code: "CA", prefix: "1", flag: "🇨🇦" },
  { label: "cocos(Keeling)Islands", code: "CC", prefix: "61", flag: "🇨🇨" },
  { label: "congo,DemocraticRepublicOfThe", code: "CD", prefix: "243", flag: "🇨🇩" },
  { label: "centralAfricanRepublic", code: "CF", prefix: "236", flag: "🇨🇫" },
  { label: "congo,RepublicOfThe", code: "CG", prefix: "242", flag: "🇨🇬" },
  { label: "switzerland", code: "CH", prefix: "41", flag: "🇨🇭" },
  { label: "coteD'Ivoire", code: "CI", prefix: "225", flag: "🇨🇮" },
  { label: "cookIslands", code: "CK", prefix: "682", flag: "🇨🇰" },
  { label: "chile", code: "CL", prefix: "56", flag: "🇨🇱" },
  { label: "cameroon", code: "CM", prefix: "237", flag: "🇨🇲" },
  { label: "china", code: "CN", prefix: "86", flag: "🇨🇳" },
  { label: "colombia", code: "CO", prefix: "57", flag: "🇨🇴" },
  { label: "costaRica", code: "CR", prefix: "506", flag: "🇨🇷" },
  { label: "cuba", code: "CU", prefix: "53", flag: "🇨🇺" },
  { label: "capeVerde", code: "CV", prefix: "238", flag: "🇨🇻" },
  { label: "curacao", code: "CW", prefix: "599", flag: "🇨🇼" },
  { label: "christmasIsland", code: "CX", prefix: "61", flag: "🇨🇽" },
  { label: "cyprus", code: "CY", prefix: "357", flag: "🇨🇾" },
  { label: "czechRepublic", code: "CZ", prefix: "420", flag: "🇨🇿" },
  { label: "germany", code: "DE", prefix: "49", flag: "🇩🇪" },
  { label: "djibouti", code: "DJ", prefix: "253", flag: "🇩🇯" },
  { label: "denmark", code: "DK", prefix: "45", flag: "🇩🇰" },
  { label: "dominica", code: "DM", prefix: "1-767", flag: "🇩🇲" },
  { label: "dominicanRepublic", code: "DO", prefix: "1-809", flag: "🇩🇴" },
  { label: "algeria", code: "DZ", prefix: "213", flag: "🇩🇿" },
  { label: "ecuador", code: "EC", prefix: "593", flag: "🇪🇨" },
  { label: "estonia", code: "EE", prefix: "372", flag: "🇪🇪" },
  { label: "egypt", code: "EG", prefix: "20", flag: "🇪🇬" },
  { label: "westernSahara", code: "EH", prefix: "212", flag: "🇪🇭" },
  { label: "eritrea", code: "ER", prefix: "291", flag: "🇪🇷" },
  { label: "spain", code: "ES", prefix: "34", flag: "🇪🇸" },
  { label: "ethiopia", code: "ET", prefix: "251", flag: "🇪🇹" },
  { label: "finland", code: "FI", prefix: "358", flag: "🇫🇮" },
  { label: "fiji", code: "FJ", prefix: "679", flag: "🇫🇯" },
  { label: "falklandIslands(Malvinas)", code: "FK", prefix: "500", flag: "🇫🇰" },
  { label: "micronesia,FederatedStatesOf", code: "FM", prefix: "691", flag: "🇫🇲" },
  { label: "faroeIslands", code: "FO", prefix: "298", flag: "🇫🇴" },
  { label: "france", code: "FR", prefix: "33", flag: "🇫🇷" },
  { label: "gabon", code: "GA", prefix: "241", flag: "🇬🇦" },
  { label: "unitedKingdom", code: "GB", prefix: "44", flag: "🇬🇧" },
  { label: "grenada", code: "GD", prefix: "1-473", flag: "🇬🇩" },
  { label: "georgia", code: "GE", prefix: "995", flag: "🇬🇪" },
  { label: "frenchGuiana", code: "GF", prefix: "594", flag: "🇬🇫" },
  { label: "guernsey", code: "GG", prefix: "44", flag: "🇬🇬" },
  { label: "ghana", code: "GH", prefix: "233", flag: "🇬🇭" },
  { label: "gibraltar", code: "GI", prefix: "350", flag: "🇬🇮" },
  { label: "greenland", code: "GL", prefix: "299", flag: "🇬🇱" },
  { label: "gambia", code: "GM", prefix: "220", flag: "🇬🇲" },
  { label: "guinea", code: "GN", prefix: "224", flag: "🇬🇳" },
  { label: "guadeloupe", code: "GP", prefix: "590", flag: "🇬🇵" },
  { label: "equatorialGuinea", code: "GQ", prefix: "240", flag: "🇬🇶" },
  { label: "greece", code: "GR", prefix: "30", flag: "🇬🇷" },
  { label: "southGeorgiaAndTheSouthSandwichIslands", code: "GS", prefix: "500", flag: "🇬🇸" },
  { label: "guatemala", code: "GT", prefix: "502", flag: "🇬🇹" },
  { label: "guam", code: "GU", prefix: "1-671", flag: "🇬🇺" },
  { label: "guinea-Bissau", code: "GW", prefix: "245", flag: "🇬🇼" },
  { label: "guyana", code: "GY", prefix: "592", flag: "🇬🇾" },
  { label: "hongKong", code: "HK", prefix: "852", flag: "🇭🇰" },
  { label: "heardIslandAndMcDonaldIslands", code: "HM", prefix: "672", flag: "🇭🇲" },
  { label: "honduras", code: "HN", prefix: "504", flag: "🇭🇳" },
  { label: "croatia", code: "HR", prefix: "385", flag: "🇭🇷" },
  { label: "haiti", code: "HT", prefix: "509", flag: "🇭🇹" },
  { label: "hungary", code: "HU", prefix: "36", flag: "🇭🇺" },
  { label: "indonesia", code: "ID", prefix: "62", flag: "🇮🇩" },
  { label: "ireland", code: "IE", prefix: "353", flag: "🇮🇪" },
  { label: "isleOfMan", code: "IM", prefix: "44", flag: "🇮🇲" },
  { label: "india", code: "IN", prefix: "91", flag: "🇮🇳" },
  { label: "britishIndianOceanTerritory", code: "IO", prefix: "246", flag: "🇮🇴" },
  { label: "iraq", code: "IQ", prefix: "964", flag: "🇮🇶" },
  { label: "iran,IslamicRepublicOf", code: "IR", prefix: "98", flag: "🇮🇷" },
  { label: "iceland", code: "IS", prefix: "354", flag: "🇮🇸" },
  { label: "italy", code: "IT", prefix: "39", flag: "🇮🇹" },
  { label: "jersey", code: "JE", prefix: "44", flag: "🇯🇪" },
  { label: "jamaica", code: "JM", prefix: "1-876", flag: "🇯🇲" },
  { label: "jordan", code: "JO", prefix: "962", flag: "🇯🇴" },
  { label: "japan", code: "JP", prefix: "81", flag: "🇯🇵" },
  { label: "kenya", code: "KE", prefix: "254", flag: "🇰🇪" },
  { label: "kyrgyzstan", code: "KG", prefix: "996", flag: "🇰🇬" },
  { label: "cambodia", code: "KH", prefix: "855", flag: "🇰🇭" },
  { label: "kiribati", code: "KI", prefix: "686", flag: "🇰🇮" },
  { label: "comoros", code: "KM", prefix: "269", flag: "🇰🇲" },
  { label: "saintKittsAndNevis", code: "KN", prefix: "1-869", flag: "🇰🇳" },
  { label: "korea,DemocraticPeople'SRepublicOf", code: "KP", prefix: "850", flag: "🇰🇵" },
  { label: "korea,RepublicOf", code: "KR", prefix: "82", flag: "🇰🇷" },
  { label: "kuwait", code: "KW", prefix: "965", flag: "🇰🇼" },
  { label: "caymanIslands", code: "KY", prefix: "1-345", flag: "🇰🇾" },
  { label: "kazakhstan", code: "KZ", prefix: "7", flag: "🇰🇿" },
  { label: "laoPeople'SDemocraticRepublic", code: "LA", prefix: "856", flag: "🇱🇦" },
  { label: "lebanon", code: "LB", prefix: "961", flag: "🇱🇧" },
  { label: "saintLucia", code: "LC", prefix: "1-758", flag: "🇱🇨" },
  { label: "liechtenstein", code: "LI", prefix: "423", flag: "🇱🇮" },
  { label: "sriLanka", code: "LK", prefix: "94", flag: "🇱🇰" },
  { label: "liberia", code: "LR", prefix: "231", flag: "🇱🇷" },
  { label: "lesotho", code: "LS", prefix: "266", flag: "🇱🇸" },
  { label: "lithuania", code: "LT", prefix: "370", flag: "🇱🇹" },
  { label: "luxembourg", code: "LU", prefix: "352", flag: "🇱🇺" },
  { label: "latvia", code: "LV", prefix: "371", flag: "🇱🇻" },
  { label: "libya", code: "LY", prefix: "218", flag: "🇱🇾" },
  { label: "morocco", code: "MA", prefix: "212", flag: "🇲🇦" },
  { label: "monaco", code: "MC", prefix: "377", flag: "🇲🇨" },
  { label: "moldova,RepublicOf", code: "MD", prefix: "373", flag: "🇲🇩" },
  { label: "montenegro", code: "ME", prefix: "382", flag: "🇲🇪" },
  { label: "saintMartin(FrenchPart)", code: "MF", prefix: "590", flag: "🇲🇫" },
  { label: "madagascar", code: "MG", prefix: "261", flag: "🇲🇬" },
  { label: "marshallIslands", code: "MH", prefix: "692", flag: "🇲🇭" },
  { label: "macedonia,TheFormerYugoslavRepublicOf", code: "MK", prefix: "389", flag: "🇲🇰" },
  { label: "mali", code: "ML", prefix: "223", flag: "🇲🇱" },
  { label: "myanmar", code: "MM", prefix: "95", flag: "🇲🇲" },
  { label: "mongolia", code: "MN", prefix: "976", flag: "🇲🇳" },
  { label: "macao", code: "MO", prefix: "853", flag: "🇲🇴" },
  { label: "northernMarianaIslands", code: "MP", prefix: "1-670", flag: "🇲🇵" },
  { label: "martinique", code: "MQ", prefix: "596", flag: "🇲🇶" },
  { label: "mauritania", code: "MR", prefix: "222", flag: "🇲🇷" },
  { label: "montserrat", code: "MS", prefix: "1-664", flag: "🇲🇸" },
  { label: "malta", code: "MT", prefix: "356", flag: "🇲🇹" },
  { label: "mauritius", code: "MU", prefix: "230", flag: "🇲🇺" },
  { label: "maldives", code: "MV", prefix: "960", flag: "🇲🇻" },
  { label: "malawi", code: "MW", prefix: "265", flag: "🇲🇼" },
  { label: "mexico", code: "MX", prefix: "52", flag: "🇲🇽" },
  { label: "malaysia", code: "MY", prefix: "60", flag: "🇲🇾" },
  { label: "mozambique", code: "MZ", prefix: "258", flag: "🇲🇿" },
  { label: "namibia", code: "NA", prefix: "264", flag: "🇳🇦" },
  { label: "newCaledonia", code: "NC", prefix: "687", flag: "🇳🇨" },
  { label: "niger", code: "NE", prefix: "227", flag: "🇳🇪" },
  { label: "norfolkIsland", code: "NF", prefix: "672", flag: "🇳🇫" },
  { label: "nigeria", code: "NG", prefix: "234", flag: "🇳🇬" },
  { label: "nicaragua", code: "NI", prefix: "505", flag: "🇳🇮" },
  { label: "netherlands", code: "NL", prefix: "31", flag: "🇳🇱" },
  { label: "norway", code: "NO", prefix: "47", flag: "🇳🇴" },
  { label: "nepal", code: "NP", prefix: "977", flag: "🇳🇵" },
  { label: "nauru", code: "NR", prefix: "674", flag: "🇳🇷" },
  { label: "niue", code: "NU", prefix: "683", flag: "🇳🇺" },
  { label: "newZealand", code: "NZ", prefix: "64", flag: "🇳🇿" },
  { label: "oman", code: "OM", prefix: "968", flag: "🇴🇲" },
  { label: "panama", code: "PA", prefix: "507", flag: "🇵🇦" },
  { label: "peru", code: "PE", prefix: "51", flag: "🇵🇪" },
  { label: "frenchPolynesia", code: "PF", prefix: "689", flag: "🇵🇫" },
  { label: "papuaNewGuinea", code: "PG", prefix: "675", flag: "🇵🇬" },
  { label: "philippines", code: "PH", prefix: "63", flag: "🇵🇭" },
  { label: "pakistan", code: "PK", prefix: "92", flag: "🇵🇰" },
  { label: "poland", code: "PL", prefix: "48", flag: "🇵🇱" },
  { label: "saintPierreAndMiquelon", code: "PM", prefix: "508", flag: "🇵🇲" },
  { label: "pitcairn", code: "PN", prefix: "870", flag: "🇵🇳" },
  { label: "puertoRico", code: "PR", prefix: "1", flag: "🇵🇷" },
  { label: "palestine,StateOf", code: "PS", prefix: "970", flag: "🇵🇸" },
  { label: "portugal", code: "PT", prefix: "351", flag: "🇵🇹" },
  { label: "palau", code: "PW", prefix: "680", flag: "🇵🇼" },
  { label: "paraguay", code: "PY", prefix: "595", flag: "🇵🇾" },
  { label: "qatar", code: "QA", prefix: "974", flag: "🇶🇦" },
  { label: "reunion", code: "RE", prefix: "262", flag: "🇷🇪" },
  { label: "romania", code: "RO", prefix: "40", flag: "🇷🇴" },
  { label: "serbia", code: "RS", prefix: "381", flag: "🇷🇸" },
  { label: "russianFederation", code: "RU", prefix: "7", flag: "🇷🇺" },
  { label: "rwanda", code: "RW", prefix: "250", flag: "🇷🇼" },
  { label: "saudiArabia", code: "SA", prefix: "966", flag: "🇸🇦" },
  { label: "solomonIslands", code: "SB", prefix: "677", flag: "🇸🇧" },
  { label: "seychelles", code: "SC", prefix: "248", flag: "🇸🇨" },
  { label: "sudan", code: "SD", prefix: "249", flag: "🇸🇩" },
  { label: "sweden", code: "SE", prefix: "46", flag: "🇸🇪" },
  { label: "singapore", code: "SG", prefix: "65", flag: "🇸🇬" },
  { label: "saintHelena", code: "SH", prefix: "290", flag: "🇸🇭" },
  { label: "slovenia", code: "SI", prefix: "386", flag: "🇸🇮" },
  { label: "svalbardAndJanMayen", code: "SJ", prefix: "47", flag: "🇸🇯" },
  { label: "slovakia", code: "SK", prefix: "421", flag: "🇸🇰" },
  { label: "sierraLeone", code: "SL", prefix: "232", flag: "🇸🇱" },
  { label: "sanMarino", code: "SM", prefix: "378", flag: "🇸🇲" },
  { label: "senegal", code: "SN", prefix: "221", flag: "🇸🇳" },
  { label: "somalia", code: "SO", prefix: "252", flag: "🇸🇴" },
  { label: "suriname", code: "SR", prefix: "597", flag: "🇸🇷" },
  { label: "southSudan", code: "SS", prefix: "211", flag: "🇸🇸" },
  { label: "saoTomeAndPrincipe", code: "ST", prefix: "239", flag: "🇸🇹" },
  { label: "elSalvador", code: "SV", prefix: "503", flag: "🇸🇻" },
  { label: "saintMaarten(DutchPart)", code: "SX", prefix: "1-721", flag: "🇸🇽" },
  { label: "syrianArabRepublic", code: "SY", prefix: "963", flag: "🇸🇾" },
  { label: "swaziland", code: "SZ", prefix: "268", flag: "🇸🇿" },
  { label: "turksAndCaicosIslands", code: "TC", prefix: "1-649", flag: "🇹🇨" },
  { label: "chad", code: "TD", prefix: "235", flag: "🇹🇩" },
  { label: "frenchSouthernTerritories", code: "TF", prefix: "262", flag: "🇹🇫" },
  { label: "togo", code: "TG", prefix: "228", flag: "🇹🇬" },
  { label: "thailand", code: "TH", prefix: "66", flag: "🇹🇭" },
  { label: "tajikistan", code: "TJ", prefix: "992", flag: "🇹🇯" },
  { label: "tokelau", code: "TK", prefix: "690", flag: "🇹🇰" },
  { label: "timor-Leste", code: "TL", prefix: "670", flag: "🇹🇱" },
  { label: "turkmenistan", code: "TM", prefix: "993", flag: "🇹🇲" },
  { label: "tunisia", code: "TN", prefix: "216", flag: "🇹🇳" },
  { label: "tonga", code: "TO", prefix: "676", flag: "🇹🇴" },
  { label: "turkey", code: "TR", prefix: "90", flag: "🇹🇷" },
  { label: "trinidadAndTobago", code: "TT", prefix: "1-868", flag: "🇹🇹" },
  { label: "tuvalu", code: "TV", prefix: "688", flag: "🇹🇻" },
  { label: "taiwan,ProvinceOfChina", code: "TW", prefix: "886", flag: "🇹🇼" },
  { label: "unitedRepublicOfTanzania", code: "TZ", prefix: "255", flag: "🇹🇿" },
  { label: "ukraine", code: "UA", prefix: "380", flag: "🇺🇦" },
  { label: "uganda", code: "UG", prefix: "256", flag: "🇺🇬" },
  { label: "unitedStates", code: "US", prefix: "1", flag: "🇺🇸" },
  { label: "uruguay", code: "UY", prefix: "598", flag: "🇺🇾" },
  { label: "uzbekistan", code: "UZ", prefix: "998", flag: "🇺🇿" },
  { label: "holySee(VaticanCityState)", code: "VA", prefix: "379", flag: "🇻🇦" },
  { label: "saintVincentAndTheGrenadines", code: "VC", prefix: "1-784", flag: "🇻🇨" },
  { label: "venezuela", code: "VE", prefix: "58", flag: "🇻🇪" },
  { label: "britishVirginIslands", code: "VG", prefix: "1-284", flag: "🇻🇬" },
  { label: "uSVirginIslands", code: "VI", prefix: "1-340", flag: "🇻🇮" },
  { label: "vietnam", code: "VN", prefix: "84", flag: "🇻🇳" },
  { label: "vanuatu", code: "VU", prefix: "678", flag: "🇻🇺" },
  { label: "wallisAndFutuna", code: "WF", prefix: "681", flag: "🇼🇫" },
  { label: "samoa", code: "WS", prefix: "685", flag: "🇼🇸" },
  { label: "kosovo", code: "XK", prefix: "383", flag: "🇽🇰" },
  { label: "yemen", code: "YE", prefix: "967", flag: "🇾🇪" },
  { label: "mayotte", code: "YT", prefix: "262", flag: "🇾🇹" },
  { label: "southAfrica", code: "ZA", prefix: "27", flag: "🇿🇦" },
  { label: "zambia", code: "ZM", prefix: "260", flag: "🇿🇲" },
  { label: "zimbabwe", code: "ZW", prefix: "263", flag: "🇿🇼" },
];

export default COUNTRIES;

const KUWAIT = COUNTRIES[120];

const COUNTRY_CODES = COUNTRIES.map(({ code }) => code);

const tarjuman = Object.fromEntries(COUNTRIES.map((country) => [country.code, country]));

function getCountryPhonePrefixFromCountryCode(countryCode: keyof typeof tarjuman) {
  return tarjuman[countryCode].prefix;
}

function getCountryFromCountryCode(countryCode: keyof typeof tarjuman) {
  return tarjuman[countryCode];
}

// Note that this is inaccurate because multiple countries can have the same prefix.
function getCountryCodeFromCountryPhonePrefix(phonePrefix: string) {
  const phonePrefixWithoutPlus = phonePrefix.replace("+", "");

  return COUNTRIES.find((country) => country.prefix === phonePrefixWithoutPlus)?.code;
}

export {
  COUNTRY_CODES,
  KUWAIT,
  getCountryPhonePrefixFromCountryCode,
  getCountryFromCountryCode,
  getCountryCodeFromCountryPhonePrefix,
};
