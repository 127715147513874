import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

import { PaymentMethod } from "./paymentMethods";

const PaymentCard = ({
  paymentMethod,
  selectedMethod,
  setPaymentMethod,
}: {
  paymentMethod: PaymentMethod;
  selectedMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
}) => {
  const { t } = useTranslation("checkoutPage");

  return (
    <Card
      onClick={() => setPaymentMethod(paymentMethod.name)}
      sx={{
        height: "62px",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: (theme) =>
          selectedMethod === paymentMethod.name ? theme.palette.primary.main : "#E5E5E5",
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center" p={1} height="100%">
        <Box display="flex" flexDirection="column">
          <Typography fontWeight={700} variant="primary">
            {t(paymentMethod.name)}
          </Typography>

          <Typography
            variant="secondary"
            fontWeight={500}
            color={selectedMethod === paymentMethod.name ? "primary" : "default"}
          >
            {selectedMethod === paymentMethod.name ? t("selected") : t("tapToSelect")}
          </Typography>
        </Box>

        <Box>
          <img src={paymentMethod.icon} height="20px" alt={t(paymentMethod.name)} />
        </Box>
      </Box>
    </Card>
  );
};

export default PaymentCard;
