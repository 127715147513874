import { FieldError, FieldValues, FormState } from "react-hook-form";
import { useTranslation } from "react-i18next";

// I made this type because Yup allows error messages to be functions but I am not sure how
// to handle those function messages in react-hook-form properly? Life is unfair
type ActualFieldError = Omit<FieldError, "message"> & {
  message?: string | { key: string; values: Record<string, string> };
};

type ExtraValidationHookOptions = {
  ns?: string;
};

export default function useFormValidation<FV extends FieldValues, FS extends FormState<FV>>(
  formState: FS,
  hookOptions?: ExtraValidationHookOptions
) {
  const { t } = useTranslation("validation");

  const ns = hookOptions?.ns || "common";

  const registerFormValidation = (fieldName: keyof FS["errors"]) => {
    // react-hook-form does crazy shit with types under the hood, these types are guaranteed for v7.32.0
    const fieldError = formState.errors[fieldName] as ActualFieldError;

    if (!fieldError) return {};

    const error = true;
    const { message, type } = fieldError;

    const translatedFieldName = t(String(fieldName), { ns });

    // Here I am returning the validation error type instead of the error message
    // We could possibly throw and force the dev to always display a proper message
    if (!message) return { error, helperText: type || t("unknownIssue") };
    else if (typeof message === "string") {
      return { error, helperText: t(message, { label: translatedFieldName }) };
    }

    const { label: yupLabel, ...yupValues } = message.values;

    const label = t(yupLabel, { ns }) || translatedFieldName;

    return { error, helperText: t(message.key, { label, ...yupValues }) };
  };

  return registerFormValidation;
}
