import PriceBox from "components/PriceBox";
// import ExpanderButton from "components/ExpanderButton";
import { getHighestPrice, getLowestPrice } from "helpers/service/getLowestPrice";
import { ServiceVariantPricingOption } from "types/ServiceVariantPricingOption";

const ServiceListItemPrice = ({ variants }: { variants: ServiceVariant[] }) => {
  const hasMoreThanOneVariant = variants.length > 1;

  const hasASingleVariant = !hasMoreThanOneVariant;

  const { pricingType } = variants[0];

  const shouldShowPriceFromForSingleVariant =
    hasASingleVariant && pricingType === ServiceVariantPricingOption.From;

  if (hasMoreThanOneVariant || shouldShowPriceFromForSingleVariant) {
    const lowestPrice = getLowestPrice(variants);
    const highestPrice = getHighestPrice(variants);

    return (
      <PriceBox
        value={Number(lowestPrice)}
        decimalStrategy="trim_zeros"
        shouldShowPriceFrom={true}
        variant="primary"
        sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
        textSx={{ fontWeight: "bold", fontSize: "0.875rem" }}
        upToPrice={highestPrice > lowestPrice ? Number(highestPrice) : null}
        hasMoreThanOneVariant={hasMoreThanOneVariant}
      />
    );
  }

  return (
    <PriceBox
      shouldShowPriceFrom={shouldShowPriceFromForSingleVariant}
      value={Number(variants[0].price)}
      decimalStrategy="trim_zeros"
      variant="primary"
      textSx={{ fontWeight: "bold", fontSize: "0.875rem" }}
    />
  );
};

export default ServiceListItemPrice;
