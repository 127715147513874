import { RootState } from "store";

import { SliceStatus } from "../utils";
import { outletAdapter } from "./outletsSlice";

const selectOutletsState = (state: RootState) => state.outlets;

const { selectById, selectAll, selectIds } =
  outletAdapter.getSelectors<RootState>(selectOutletsState);

export const selectOutletById = (id: number) => (state: RootState) => selectById(state, id);

export const selectAllOutlets = selectAll;

export const selectOutletIds = selectIds;

export const selectIsOutletsLoading = (state: RootState) =>
  state.outlets.status === SliceStatus.LOADING;

export const selectOutletBySlug = (slug: string) => (state: RootState) =>
  selectAll(state).find((outlet) => outlet?.slug === slug);

export const selectOutletIdBySlug = (slug: string) => (state: RootState) => {
  const outlet = selectAll(state).find((outlet) => outlet?.slug === slug);
  return outlet?.id || null;
};

export const selectOutletNamesByIds = (outletIds: number[]) => (state: RootState) => {
  const outlets = selectAll(state).filter((outlet) => outletIds.includes(outlet.id));

  const outletNames = outlets.map((outlet) => outlet.name);

  return outletNames;
};
