import replaceUrlLastPart from "helpers/route/replaceUrlLastPart";
import { ReactNode, createContext, useContext, useReducer } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectEmployeeById,
  selectPackageVariantById,
  selectServiceVariantById,
} from "store/selectors";

import { BookingSlot } from "./bookingPageTypes";

type BookingServiceEmployee = [serviceVariantId: number, employeeId: Nullable<number>];

type BookingState = {
  packageVariantId: Nullable<number>;

  selectedSlot: Nullable<BookingSlot>;

  serviceEmployees: BookingServiceEmployee[];

  subscriptionPurchaseId: Nullable<number>;
};

type BookingAction =
  | { type: "setSlot"; payload: Nullable<BookingSlot> }
  | { type: "setEmployee"; payload: { index: number; employeeId: Nullable<number> } }
  | {
      type: "setSubscriptionPurchase";
      payload: Nullable<number>;
    };

function bookingReducer(state: BookingState, action: BookingAction): BookingState {
  switch (action.type) {
    case "setSlot": {
      return { ...state, selectedSlot: action.payload };
    }

    case "setEmployee": {
      const { payload } = action;

      const updatedServiceVariants: BookingServiceEmployee[] = state.serviceEmployees.map(
        ([variantId, employeeId], index) => {
          if (index === payload.index) return [variantId, payload.employeeId];
          else return [variantId, employeeId];
        }
      );

      return { ...state, serviceEmployees: updatedServiceVariants };
    }

    case "setSubscriptionPurchase": {
      const { payload } = action;

      return { ...state, subscriptionPurchaseId: payload };
    }

    default: {
      throw new Error(`Unhandled action: ${action}`);
    }
  }
}

type BookingDispatch = (action: BookingAction) => void;

export type BookingContextValues = { state: BookingState; dispatch: BookingDispatch } | undefined;

const BookingContext = createContext<BookingContextValues>(undefined);

type BookingContextProviderProps = { children: ReactNode };

function BookingContextProvider({ children }: BookingContextProviderProps) {
  const { pathname } = useLocation();

  const [params] = useSearchParams();

  const subscriptionPurchaseId = Number(params.get("r"));

  const serviceVariantId = Number(params.get("s"));
  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId));

  const employeeId = Number(params.get("e"));
  const employee = useSelector(selectEmployeeById(employeeId));

  const packageVariantId = Number(params.get("p"));
  const packageVariant = useSelector(selectPackageVariantById(packageVariantId));

  const initialState = generateBookingState({
    serviceVariant,
    packageVariant,
    employee,
    subscriptionPurchaseId,
  });

  const [state, dispatch] = useReducer(bookingReducer, initialState);

  const fallbackRoute = replaceUrlLastPart(pathname, "/");
  if (!serviceVariant && !packageVariant) return <Navigate to={fallbackRoute} />;

  return <BookingContext.Provider value={{ state, dispatch }}>{children}</BookingContext.Provider>;
}

const BookingContextConsumer = BookingContext.Consumer;

function useBookingContext() {
  const context = useContext(BookingContext);

  if (context === undefined) {
    throw new Error("useBookingContext must be used within a BookingContextProvider");
  }

  return context;
}

export { BookingContextProvider, BookingContextConsumer, useBookingContext };

type GenerateBookingStateArgs = {
  serviceVariant?: ServiceVariant;
  packageVariant?: PackageVariant;
  employee?: Employee;
  subscriptionPurchaseId?: Nullable<number>;
};

function generateBookingState(args: GenerateBookingStateArgs): BookingState {
  const { serviceVariant, packageVariant, employee, subscriptionPurchaseId = null } = args;

  if (serviceVariant) {
    const employeeId = employee?.id || null;

    return {
      packageVariantId: null,
      selectedSlot: null,
      serviceEmployees: [[serviceVariant.id, employeeId]],
      subscriptionPurchaseId,
    };
  }

  if (packageVariant) {
    const serviceVariants: BookingServiceEmployee[] = packageVariant.serviceVariants.map(
      (serviceVariantId) => [serviceVariantId, null]
    );

    return {
      packageVariantId: packageVariant.id,
      selectedSlot: null,
      serviceEmployees: serviceVariants,
      subscriptionPurchaseId,
    };
  }

  return {
    packageVariantId: null,
    selectedSlot: null,
    serviceEmployees: [],
    subscriptionPurchaseId,
  };
}
