import { createSvgIcon } from "@mui/material";

export const TermsAndConditionsIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3ZM1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12Z"
      fill="#B6BCC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2582 8.0244C11.7927 7.94455 11.314 8.03203 10.9068 8.27135C10.4996 8.51066 10.1902 8.88637 10.0335 9.33193C9.85018 9.85292 9.27926 10.1267 8.75827 9.94342C8.23728 9.76015 7.96351 9.18923 8.14678 8.66824C8.46025 7.77713 9.07898 7.02572 9.89339 6.54708C10.7078 6.06845 11.6653 5.89349 12.5964 6.05319C13.5274 6.21289 14.3719 6.69694 14.9802 7.41961C15.5884 8.14213 15.9214 9.05656 15.9201 10.001C15.9197 11.5314 14.7851 12.542 13.9748 13.0821C13.5392 13.3726 13.1107 13.5861 12.795 13.7264C12.6358 13.7972 12.5016 13.8509 12.405 13.8877C12.3566 13.9061 12.3174 13.9204 12.2888 13.9306L12.2541 13.9427L12.243 13.9465L12.2391 13.9478L12.2376 13.9483C12.2373 13.9484 12.2363 13.9488 11.9201 13.0001L12.2363 13.9488C11.7124 14.1234 11.1461 13.8403 10.9714 13.3163C10.7969 12.7927 11.0796 12.2268 11.6028 12.0517L11.6016 12.0522C11.6017 12.0521 11.6018 12.0521 11.6028 12.0517L11.6188 12.0461C11.6342 12.0407 11.6594 12.0315 11.693 12.0187C11.7605 11.993 11.8607 11.953 11.9827 11.8988C12.2296 11.7891 12.551 11.6276 12.8654 11.418C13.555 10.9583 13.9201 10.4692 13.9201 10.0001L13.9201 9.99859C13.9208 9.52628 13.7543 9.06895 13.4502 8.70761C13.146 8.34628 12.7238 8.10425 12.2582 8.0244Z"
      fill="#B6BCC7"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 17C11 16.4477 11.4477 16 12 16H12.01C12.5623 16 13.01 16.4477 13.01 17C13.01 17.5523 12.5623 18 12.01 18H12C11.4477 18 11 17.5523 11 17Z"
      fill="#B6BCC7"
    />
  </>,
  "TermsAndConditions"
);
