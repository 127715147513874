import { Box, Button, styled } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import OutletModalItem from "./OutletModalItem";

type OutletSelectorModalProps = {
  outlets: Outlet[];
  onSelect: (outlet: Outlet) => void;
  open: boolean;
  onClose: () => void;
};

const OutletSelectorModal = ({ outlets, onSelect, open, onClose }: OutletSelectorModalProps) => {
  const { t } = useTranslation("outletPage");
  const [selectedOutletId, setSelectedOutletId] = useState<Nullable<number>>(null);

  const handleSelectedOutlet = () => {
    if (selectedOutletId) {
      const outlet = outlets.find((_outlet) => _outlet.id === selectedOutletId);
      if (outlet) {
        onSelect(outlet);
        onClose();
      }
    }
  };

  return (
    <DrawerModal
      title={t("selectALocation")}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "50vh", padding: "16px", paddingBottom: "60px" } }}
    >
      {outlets.map((outlet) => (
        <OutletModalItem
          key={outlet.id}
          outletId={outlet.id}
          selectedOutletId={selectedOutletId}
          onClick={(outletId: number) => setSelectedOutletId(outletId)}
        />
      ))}

      <ModalAction px={2}>
        <Button fullWidth onClick={handleSelectedOutlet} size="medium" disabled={!selectedOutletId}>
          {t("select")}
        </Button>
      </ModalAction>
    </DrawerModal>
  );
};
export default OutletSelectorModal;

const ModalAction = styled(Box)(() => ({
  position: "fixed",
  width: "calc(100%)",
  bottom: "16px",
  left: 0,
  backgroundColor: "white",
}));
