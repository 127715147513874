import { Remove } from "@bookpeep/ui";
import { Box, IconButton, Typography } from "@mui/material";
import PriceBox from "components/PriceBox";
import getIndexColor from "helpers/styles/getIndexColor";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectCartArticleSummary, selectOrganization } from "store/selectors";
import { removeArticleFromCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

export default function BookingCartSummary() {
  const { t } = useTranslation("bookingPage");

  const dispatch = useDispatch();

  const organization = useSelector(selectOrganization);
  const articles = useSelector(selectCartArticleSummary);

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {!!articles.length && <Typography variant="h4">{t("bookedServices")}</Typography>}

      {articles.map((article, index) => {
        const removeArticle = () => {
          if (organization?.id && organization?.name) {
            trackEvent(
              "Item removed from cart",
              { id: organization.id, name: organization.name },
              { from: "Booking" }
            );
          }
          dispatch(removeArticleFromCart(index));
        };

        const serviceCount = article.slots.length;

        const shouldShowServiceCount = serviceCount > 1;

        return (
          <Box
            key={article.startTime}
            display="flex"
            justifyContent="space-between"
            borderRadius={1}
            p={[2, 2.5]}
            bgcolor="bg.secondary"
          >
            <Box display="flex" flexDirection="column" gap={1}>
              <Box display="flex" flexDirection="column" gap={0.5}>
                <Typography variant="primary" fontWeight="600">
                  {t(article.title[0])}
                  {article.title[1] ? ` - ${t(article.title[1])}` : ""}
                </Typography>

                {shouldShowServiceCount && (
                  <Typography variant="secondary" color="text.label">
                    {t("includesCountServices", { count: serviceCount })}
                  </Typography>
                )}
              </Box>

              <Box display="flex" alignItems="center" gap={1}>
                <Box width={40} height={8} borderRadius={100} bgcolor={getIndexColor(index)} />
                <Typography variant="secondary">
                  {article.startTime} - {article.endTime}
                </Typography>
              </Box>
            </Box>

            <Box display="flex" gap={1}>
              <PriceBox
                shouldShowPriceFrom={article.isPriceFrom}
                value={article.totalPrice}
                decimalStrategy="trim_zeros"
                variant="primary"
                sx={{ display: "flex", flexDirection: "column" }}
                textSx={{ color: "text.secondary", fontWeight: 700 }}
              />

              <IconButton
                onClick={removeArticle}
                sx={{ width: "0.875rem", height: "0.875rem", padding: 1.1 }}
                color="error"
              >
                <Remove sx={{ fontSize: "0.875rem" }} />
              </IconButton>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
}
