import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useTranslation } from "react-i18next";

import ProfileResetPasswordForm from "./ProfileResetPasswordForm";

export default function ProfileResetPasswordPage() {
  const { t } = useTranslation("userProfilePage");

  return (
    <AuthenticationPageLayout
      title={t("changePassword")}
      subtitle={t("changePasswordSubtitle")}
      shouldShowPrivacyDisclaimer={false}
    >
      <ProfileResetPasswordForm />
    </AuthenticationPageLayout>
  );
}
