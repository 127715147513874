import { TranslationNamespaces } from "configs/i18n/TranslationTypes";

export function translateEmployee(id: number) {
  const keyRoot = TranslationNamespaces["employees"] + ":" + id;
  return {
    firstName: keyRoot + ".firstName",
    lastName: keyRoot + ".lastName",
    fullName: keyRoot + ".fullName",
    notes: keyRoot + ".notes",
  };
}
