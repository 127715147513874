import { Box, Button } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPackageVariantById, selectServiceVariantById } from "store/selectors";
import { SubscriptionPurchase } from "types/Subscription";

import SubscriptionOutletListModal from "./SubscriptionOutletListModal";

type SubscriptionCardActionProps = {
  subscriptionPurchase: SubscriptionPurchase;
  isExpired: boolean;
  isSubscriptionActive: boolean;
  orgHasSubscriptionWithMultipleServiceVariants: boolean;
};

const SubscriptionCardAction = ({
  subscriptionPurchase,
  isExpired,
  isSubscriptionActive,
  orgHasSubscriptionWithMultipleServiceVariants,
}: SubscriptionCardActionProps) => {
  const { t } = useTranslation("userSubscriptionsPage");

  const [open, setOpen] = useState(false);

  const onClose = () => setOpen(false);

  // Since we're removing subscription card action from multiple service variants, were accessing first item in serviceVariants
  const serviceVariant = useSelector(
    selectServiceVariantById(subscriptionPurchase.serviceVariants[0] || 0)
  );

  const packageVariant = useSelector(
    selectPackageVariantById(subscriptionPurchase.packageVariant || 0)
  );

  const canBeRedeemedOnline = serviceVariant || packageVariant;

  if (!isSubscriptionActive && isExpired) return null;

  const renderButton = () => {
    if (isExpired) {
      return (
        <Button onClick={() => {}} fullWidth size="small" variant="outlined">
          {t("repurchase")}
        </Button>
      );
    } else if (!canBeRedeemedOnline) {
      return (
        <Button disabled fullWidth size="small" variant="contained" onClick={() => setOpen(true)}>
          {t("cantBeRedeemedOnline")}
        </Button>
      );
    } else if (!orgHasSubscriptionWithMultipleServiceVariants) {
      return (
        <Button fullWidth size="small" variant="contained" onClick={() => setOpen(true)}>
          {t("use")}
        </Button>
      );
    } else {
      return null;
    }
  };

  return (
    <Box display="flex" flexDirection="row" gap={1} pt={2} pb={1} height="100%">
      {renderButton()}

      <SubscriptionOutletListModal
        open={open}
        onClose={onClose}
        subscriptionPurchaseId={subscriptionPurchase.id}
      />
    </Box>
  );
};

export default SubscriptionCardAction;
