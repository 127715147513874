import { useTranslation } from "react-i18next";

/**
 * This function would return the translated value of the given key, if it has been translated. otherwise it would return empty string.
 * @param valueTranslationKey // something like service?.t.description
 */

type GetTranslatedValue = (valueTranslationKey: string | undefined) => string;

const useTranslatedValue = () => {
  const { t } = useTranslation();

  const getTranslatedValue: GetTranslatedValue = (valueTranslationKey = "") => {
    // special thanks to @kalmohammed56 for this solution
    return valueTranslationKey.includes(t(valueTranslationKey || ""))
      ? ""
      : t(valueTranslationKey || "");
  };
  return { getTranslatedValue };
};

export default useTranslatedValue;
