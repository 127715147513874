/**
 * replace the last part of the url with the given new part
 * @param pathname
 * @param newPart to  replace the last part of the url
 * @returns
 */

export default function replaceUrlLastPart(pathname: string, newPart: string) {
  const modifiedUrl = pathname.split("/").slice(0, -1).join("/") + newPart;

  return modifiedUrl;
}
