import { Article } from "types/Article";
import { Subscription } from "types/Subscription";
import http from "utils/http";

//TODO think of a better fashion way to do this
export type NormalizedOrganizationResponse = {
  organization: Omit<Organization, "t">;

  outlets: Omit<Outlet, "t">[];

  serviceCategories: Omit<ServiceCategory, "t">[];
  services: Omit<Service, "t">[];
  serviceVariants: Omit<ServiceVariant, "t">[];

  packageCategories: Omit<PackageCategory, "t">[];
  packages: Omit<Package, "t">[];
  packageVariants: Omit<PackageVariant, "t">[];

  employees: Omit<Employee, "t">[];

  onlinePaymentRules: OnlinePaymentRule[];

  subscriptions: Subscription[];
};

export type NormalizedAppointmentsResponse = {
  appointments: Appointment[];
  articles: Article[];
  slots: ArticleSlot[];
  invoices: Invoice[];
  onlinePayments: OnlinePayment[];
};

const globalApi = {
  getNormalizedOrganization: async () =>
    http.get<NormalizedOrganizationResponse>(`ob/organizations/organization/`),

  getNormalizedAppointments: async () =>
    http.get<NormalizedAppointmentsResponse>(`ob/appointments/appointments/`),
};

export default globalApi;
