import { Stack } from "@mui/material";

import UserProfileHeader from "./UserProfileHeader";

const UserProfile = () => {
  return (
    <Stack bgcolor="bg.secondary">
      <UserProfileHeader />
    </Stack>
  );
};

export default UserProfile;
