import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const NoResults = () => {
  const { t } = useTranslation("common");

  return (
    <Grid item xs={12}>
      <Typography variant="primary" display="flex" justifyContent="center">
        {t("noResults")}
      </Typography>
    </Grid>
  );
};

export default NoResults;
