import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateCategory } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const serviceCategoriesAdapter = createEntityAdapter<ServiceCategory>();

const initialState = serviceCategoriesAdapter.getInitialState({ status: SliceStatus.IDLE });

const serviceCategoriesSlice = createSlice({
  name: "serviceCategories",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const serviceCategoriesWithTranslationKeys = payload.serviceCategories.map(
          (serviceCategory) => ({ ...serviceCategory, t: translateCategory(serviceCategory.id) })
        );
        serviceCategoriesAdapter.setAll(state, serviceCategoriesWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default serviceCategoriesSlice.reducer;
