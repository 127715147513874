import { Box, Stack, Typography } from "@mui/material";

type NothingHereProps = {
  message: string;
};

const NothingHere = ({ message }: NothingHereProps) => {
  return (
    <Box
      flexGrow="1"
      display="flex"
      justifyContent="center"
      alignItems="center"
      height="100%"
      py={5}
    >
      <Stack justifyContent="center" textAlign="center">
        <img alt="nothing here" src="/images/NothingHere.svg" height="200px" />

        <Typography variant="primary" pb={0.5}>
          {message}
        </Typography>
      </Stack>
    </Box>
  );
};

export default NothingHere;
