import { useState } from "react";

import { useBookingContext } from "../bookingContext";
import EmployeeItem from "./BookingEmployeeItem";
import EmployeeListModal from "./BookingEmployeeListModal";

function BookingEmployeeSelect() {
  const {
    state: { serviceEmployees },
  } = useBookingContext();

  const [serviceEmployeeIndex, setServiceEmployeeIndex] = useState<Nullable<number>>(null);
  const openEmployeeListModal = (index: number) => () => setServiceEmployeeIndex(index);
  const closeEmployeeListModal = () => setServiceEmployeeIndex(null);

  return (
    <>
      {serviceEmployees.map(([serviceVariantId, employeeId], index) => (
        <EmployeeItem
          key={index}
          serviceVariantId={serviceVariantId}
          employeeId={employeeId}
          onClick={openEmployeeListModal(index)}
        />
      ))}

      <EmployeeListModal
        open={serviceEmployeeIndex !== null}
        index={serviceEmployeeIndex}
        onClose={closeEmployeeListModal}
      />
    </>
  );
}

export default BookingEmployeeSelect;
