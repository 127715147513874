import cc from "images/cc.png";
import knet from "images/knet.png";

export type PaymentMethod = {
  name: string;
  icon: string;
};

const paymentMethods: PaymentMethod[] = [
  {
    name: "cc",
    icon: cc,
  },
  {
    name: "knet",
    icon: knet,
  },
];

export default paymentMethods;
