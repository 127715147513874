/**
 * a function to translate error detail response
 *
 * @param detail
 * @returns translation key for the detail
 */

// THIS FUNCTION IS TEMPORARY "till we have a proper error handling system"
export default function getErrorDetailTranslationKey(detail: string): string {
  switch (detail) {
    case "No active account found with the given credentials":
      return "errors:login.noActiveAccount";

    // this is a validation error we should remove when we have a proper error handling system
    case "Email already exists":
      return "errors:register.emailAlreadyInUse";

    default:
      return "errors:unknownError";
  }
}
