import * as i18next from "i18next";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import { Languages } from "./TranslationTypes";

const fallbackLng = (code: string): string[] => {
  if (!code || code !== ("en" || "ar")) return [Languages.english];
  else return [Languages.arabic];
};

i18next
  .use(Backend)
  .use(initReactI18next)
  .init({
    defaultNS: "common",
    fallbackLng,

    nsSeparator: ":",
    ns: ["common", "glossary"],

    interpolation: {
      escapeValue: false,
    },
  });

// This code adds TS support for i18next but caching problems with public dir made it difficult
// Look into this in the future

// import glossary from "../../public/locales/en/glossary.json";
// import common from "../../public/locales/en/common.json";

// declare module "react-i18next" {
//   interface Resources {
//     common: typeof common;
//     glossary: typeof glossary;
//   }
// }
