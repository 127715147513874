import { Box, Button, Typography } from "@mui/material";
import ExpanderButton from "components/ExpanderButton";
import PriceBox from "components/PriceBox";
import useParamOutlet from "hooks/useParamOutlet";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectPackageVariantById, selectSubscriptionById } from "store/selectors";

import SubscriptionItemDetails from "./SubscriptionItemDetails";

type SubscriptionListItemProps = {
  subscriptionId: number;
};

const SubscriptionListItem = ({ subscriptionId }: SubscriptionListItemProps) => {
  const { t } = useTranslation(["common", "subscriptions"]);

  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";

  const navigate = useNavigate();

  const [shouldShowMore, setShouldShowMore] = useState(false);

  const subscription = useSelector(selectSubscriptionById(subscriptionId));

  const subscriptionPackageVariant = useSelector(
    selectPackageVariantById(subscription?.packageVariant || 0)
  );

  const shouldHideSubscription = subscription?.packageVariant && !subscriptionPackageVariant;

  if (!subscription) return null;

  return (
    <Box sx={{ paddingY: "8px", display: shouldHideSubscription ? "none" : "block" }}>
      <Box sx={{ display: "flex", alignItems: "start" }}>
        <Box sx={{ flexGrow: 1 }}>
          <Typography variant="h4">{subscription.name}</Typography>

          <Typography variant="secondary">
            {subscription.itemName} x {subscription.count}
          </Typography>
        </Box>

        <PriceBox
          value={Number(subscription.price)}
          decimalStrategy="trim_zeros"
          variant="primary"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
            color: "primary.main",
          }}
          textSx={{ fontWeight: 700 }}
        />

        <Button
          size="small"
          variant="outlined"
          onClick={() => navigate(`/${outletSlug}/subscription-review/${subscription.id}`)}
        >
          {t("common:buy")}
        </Button>
      </Box>

      <ExpanderButton
        disableElevation
        disableRipple
        variant="text"
        expanded={shouldShowMore}
        onClick={() => setShouldShowMore(!shouldShowMore)}
      >
        <Typography sx={{ color: "#4E5D78" }}>
          {shouldShowMore ? t("showLess") : t("showMore")}
        </Typography>
      </ExpanderButton>

      <Box display={shouldShowMore ? "block" : "none"} my={1}>
        <SubscriptionItemDetails subscriptionId={subscription.id} />
      </Box>
    </Box>
  );
};

export default SubscriptionListItem;
