import { Typography } from "@mui/material";
import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import ForgotPasswordForm from "./ForgotPasswordForm";

export default function ForgotPasswordPage() {
  const { t } = useTranslation("forgetPassword");

  return (
    <AuthenticationPageLayout
      title={t("title")}
      subtitle={t("subtitle")}
      footer={
        <Typography variant="h4">
          {t("alreadyHaveAccount")}{" "}
          <Link replace to="/login">
            {t("login")}
          </Link>
        </Typography>
      }
    >
      <ForgotPasswordForm />
    </AuthenticationPageLayout>
  );
}
