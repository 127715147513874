import { Box, Tabs } from "@mui/material";
import TabButton from "components/TabButton";
import { DateTime } from "luxon";
import { useContext, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";

import DatePickerTab from "./DatePickerTab";
import getSlotPickerDateLabel from "./getSlotPickerDateLabel";
import OutletBookingContext from "./outletContext";

const ScrollableDatePickerButtons = () => {
  const { t } = useTranslation(["common"]);

  const organization = useSelector(selectOrganization);

  const today = DateTime.now().startOf("day");

  const hasSetDate = useRef(false);

  const { selectedDate, setSelectedDate } = useContext(OutletBookingContext);

  const cartDate = selectedDate ? selectedDate.startOf("day").toISODate() : null;

  const [tabDays, setTabDays] = useState(() =>
    Array.from({ length: 5 }, (_, index) => today.plus({ days: index }).toISODate())
  );

  const [selectedDateIndex, setSelectedDateIndex] = useState(0);

  const handleDateChangeFromPicker = (newDate: Nullable<DateTime>) => {
    if (newDate) {
      const newDateISO = newDate.toISODate();

      if (!tabDays.includes(newDateISO)) {
        const prevTabDays = [...tabDays];
        prevTabDays.push(newDateISO);
        prevTabDays.sort((a, b) => {
          return new Date(a) < new Date(b) ? -1 : new Date(a) > new Date(b) ? 1 : 0;
        });

        setTabDays(prevTabDays);
        setSelectedDateIndex(prevTabDays.length - 1);
      } else {
        setSelectedDateIndex(tabDays.indexOf(newDateISO));
      }
      setSelectedDate(newDate);

      if (organization?.id && organization?.name) {
        trackEvent(
          "Changed Date",
          { id: organization.id, name: organization.name },
          { from: "Date Picker" }
        );
      }
    }
  };

  const changeSelectedDate = (newDate: string, index: number) => {
    setSelectedDateIndex(index);
    setSelectedDate(DateTime.fromISO(newDate));

    if (organization?.id && organization?.name) {
      trackEvent(
        "Changed Date",
        { id: organization.id, name: organization.name },
        { from: "Tabs" }
      );
    }
  };

  // Effect to Select the date on the cartDate on page load
  useEffect(() => {
    if (!hasSetDate.current) {
      if (selectedDate && cartDate && cartDate !== today.toISODate()) {
        const isCartDateHasPassed = today.startOf("day") > selectedDate.startOf("day");

        if (isCartDateHasPassed) {
          setSelectedDateIndex(0); // Select "Today"
        } else {
          const indexOfCartDate = tabDays.indexOf(cartDate);
          if (indexOfCartDate === -1) {
            tabDays.push(cartDate);
            setSelectedDateIndex(tabDays.length - 1);
          } else {
            setSelectedDateIndex(indexOfCartDate);
          }
        }

        hasSetDate.current = true;
      }
    }
  }, [selectedDate, cartDate, tabDays, today]);

  return (
    <Box
      display="flex"
      width="100%"
      sx={{ position: "sticky", top: 50, zIndex: 999 }}
      bgcolor="bg.secondary"
    >
      <Tabs
        value={selectedDateIndex}
        variant="scrollable"
        scrollButtons={false}
        sx={{
          "& [role=tablist]": {
            display: "block",
            px: 1.5,
          },
          "& .css-15yew4y-MuiTabs-indicator": {
            display: "none",
          },
        }}
      >
        {tabDays.map((date, index) => (
          <TabButton
            key={date}
            selectedDate={index === selectedDateIndex}
            onClick={() => changeSelectedDate(date, index)}
          >
            {t(getSlotPickerDateLabel(date)[0])}
          </TabButton>
        ))}

        <DatePickerTab
          selectedCalendarDate={null}
          handleDateChange={handleDateChangeFromPicker}
          disabled={false}
        />
      </Tabs>
    </Box>
  );
};

export default ScrollableDatePickerButtons;
