import { Box } from "@mui/material";
import NothingHere from "components/NothingHere";
import SearchField from "components/SearchField";
import Fuse from "fuse.js";
import { DateTime } from "luxon";
import { ChangeEvent, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAllSubscriptionPurchases, selectAllSubscriptions } from "store/selectors";
import { SubscriptionStatus } from "types/Subscription";

import SubscriptionCard from "./SubscriptionCard";

type SubscriptionPurchasesListProps = {
  subscriptionStatus: SubscriptionStatus;
};

const SubscriptionPurchasesList = ({ subscriptionStatus }: SubscriptionPurchasesListProps) => {
  const { t } = useTranslation("userSubscriptionsPage");

  const [query, setQuery] = useState("");

  const subscriptions = useSelector(selectAllSubscriptions);

  const orgHasSubscriptionWithMultipleServiceVariants = subscriptions.some(
    (subscription) =>
      subscription.hasOwnProperty("serviceVariants") &&
      Array.isArray(subscription.serviceVariants) &&
      subscription.serviceVariants.length > 1
  );

  const subscriptionPurchases = useSelector(selectAllSubscriptionPurchases);

  const hasSubscriptionPurchases = subscriptionPurchases.length > 0;

  let filteredSubscriptionPurchases = subscriptionPurchases.map((subscriptionPurchase) => {
    const subscriptionExpirationDate = DateTime.fromISO(subscriptionPurchase.expirationDate);
    const isExpired =
      subscriptionPurchase.remainingRedemptionsCount <= 0 ||
      subscriptionExpirationDate < DateTime.now();

    return {
      ...subscriptionPurchase,
      isExpired,
    };
  });

  if (subscriptionStatus === SubscriptionStatus.activeSubscriptions) {
    filteredSubscriptionPurchases = filteredSubscriptionPurchases.filter(
      (subscriptionPurchase) => !subscriptionPurchase.isExpired
    );
  } else {
    filteredSubscriptionPurchases = filteredSubscriptionPurchases.filter(
      (subscriptionPurchase) => subscriptionPurchase.isExpired
    );
  }

  const changeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleClearSearch = () => setQuery("");

  if (query) {
    const fuse = new Fuse(filteredSubscriptionPurchases, {
      threshold: 0.4,
      keys: ["name"],
    });

    const result = fuse.search(query);

    filteredSubscriptionPurchases = result.map((obj) => obj.item);
  }

  const subscriptionPurchasesCards = filteredSubscriptionPurchases.map((subscriptionPurchase) => (
    <SubscriptionCard
      key={subscriptionPurchase.id}
      subscriptionPurchaseId={subscriptionPurchase.id}
      orgHasSubscriptionWithMultipleServiceVariants={orgHasSubscriptionWithMultipleServiceVariants}
    />
  ));

  return (
    <Box gap={1.75} m={2} minHeight="80vh">
      <Box paddingY={2}>
        <SearchField
          variant="outlined"
          value={query}
          onChange={changeSearchQuery}
          onClear={handleClearSearch}
        />
      </Box>

      {hasSubscriptionPurchases ? (
        subscriptionPurchasesCards
      ) : (
        <NothingHere message={t("noSubscriptionsAvailable")} />
      )}
    </Box>
  );
};

export default SubscriptionPurchasesList;
