/**
 * takes an image and returns the src for it
 * @param image
 * @returns image src string which represents a url of the image
 * @example getImageSrc("media/unknown_mug.jpg")  //"http://api.bookpeep.com/media/unknown_mug.jpg"
 */

type ImageArg = string | null | undefined | Blob | MediaSource;

export function getImageSrc(image: ImageArg): string {
  if (!image) return "";

  if (typeof image === "string" && image.startsWith("http")) return image; 

  return typeof image === "string"
    ? process.env.REACT_APP_BACKEND_URL + image
    : URL.createObjectURL(image);
}
