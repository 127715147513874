import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { WalletIcon } from "assets/icons/Wallet";
import PriceBox from "components/PriceBox";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";

type WalletPaymentCardType = {
  isThereEnoughWalletCredit: boolean;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  paymentMethod: string;
  walletBalance: number;
};

const WalletPaymentCard = ({
  isThereEnoughWalletCredit,
  setPaymentMethod,
  paymentMethod,
  walletBalance,
}: WalletPaymentCardType) => {
  const { t } = useTranslation("checkoutPage");

  return (
    <Box
      sx={(theme) => ({
        background: isThereEnoughWalletCredit ? "default" : theme.palette.bg.disabled,
      })}
    >
      <Button
        disabled={!isThereEnoughWalletCredit}
        variant="outlined"
        endIcon={<WalletIcon />}
        size="medium"
        fullWidth
        onClick={() => setPaymentMethod("wallet")}
        sx={{
          height: "62px",
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: (theme) =>
            paymentMethod === "wallet" ? theme.palette.primary.main : "#E5E5E5",
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          height="100%"
          sx={{ background: "bg.disabled" }}
        >
          <Box display="flex" flexDirection="column" width="100%" alignItems="flex-start">
            <Box display="flex" width="100%">
              <Typography fontWeight={700} variant="primary" color="black" noWrap>
                {t("wallet")}
              </Typography>

              <PriceBox
                value={walletBalance}
                decimalStrategy="trim_zeros"
                textSx={{ fontWeight: 700, color: (theme) => theme.palette.primary.main }}
                sx={{ px: 0.5 }}
              />

              <Typography fontWeight={700} variant="primary" color="primary" noWrap>
                {t("available")}
              </Typography>
            </Box>

            <Box display="flex" width="100%">
              <Typography
                variant="secondary"
                fontWeight={500}
                color={paymentMethod === "wallet" ? "primary" : "default"}
              >
                {!isThereEnoughWalletCredit
                  ? t("notEnoughCredit")
                  : paymentMethod === "wallet"
                  ? t("selected")
                  : t("tapToSelect")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Button>
    </Box>
  );
};

export default WalletPaymentCard;
