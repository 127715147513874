import { Box, styled } from "@mui/material";
import OtpInput from "react-otp-input";

type OtpFieldProps = {
  value: string;
  onChange: (value: string) => void;
};

const OtpField = ({ value, onChange }: OtpFieldProps) => {
  return (
    <OtpBox pt={5.5} display="flex" alignItems="center" justifyContent="center" dir="ltr">
      <OtpInput value={value} onChange={onChange} numInputs={6} shouldAutoFocus isInputNum />
    </OtpBox>
  );
};

export default OtpField;

const OtpBox = styled(Box)(({ theme }) => ({
  "& div": {
    justifyContent: "space-between",
    width: "100%",
    maxWidth: "400px",
    "& div": {
      flex: "0 0 100/6%",
      maxWidth: "100/6%",
      justifyContent: "center",
    },
  },

  "& input": {
    minWidth: "42px",
    minHeight: " 38px",
    border: "1px solid #4E5D7866",
    borderRadius: theme.spacing(0.5),
    transition: "all 0.1s",
    outline: "none !important",
  },
}));
