import { createSvgIcon } from "@mui/material";

export const EyeIcon = createSvgIcon(
  <>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.0001 2.99902C7.02956 2.99902 3.00012 7.02846 3.00012 11.999C3.00012 16.9696 7.02956 20.999 12.0001 20.999C16.9707 20.999 21.0001 16.9696 21.0001 11.999C21.0001 7.02846 16.9707 2.99902 12.0001 2.99902ZM1.00012 11.999C1.00012 5.92389 5.92499 0.999023 12.0001 0.999023C18.0753 0.999023 23.0001 5.92389 23.0001 11.999C23.0001 18.0742 18.0753 22.999 12.0001 22.999C5.92499 22.999 1.00012 18.0742 1.00012 11.999Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9999 11.001C12.5522 11.001 12.9999 11.4487 12.9999 12.001V16.001C12.9999 16.5533 12.5522 17.001 11.9999 17.001C11.4476 17.001 10.9999 16.5533 10.9999 16.001V12.001C10.9999 11.4487 11.4476 11.001 11.9999 11.001Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.9999 7.99902C10.9999 7.44674 11.4476 6.99902 11.9999 6.99902H12.0099C12.5622 6.99902 13.0099 7.44674 13.0099 7.99902C13.0099 8.55131 12.5622 8.99902 12.0099 8.99902H11.9999C11.4476 8.99902 10.9999 8.55131 10.9999 7.99902Z"
    />
  </>,
  "Eye"
);
