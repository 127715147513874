import { addMethod, ref, string } from "yup";

declare module "yup" {
  interface StringSchema {
    equalsTo(referenceValue: string, message?: string): StringSchema;
  }
}

addMethod(string, "equalsTo", function equalsTo(referenceValue: string, message?: string) {
  return this.oneOf([ref(referenceValue)], message);
});
