import { SxProps } from "@mui/material";
import {
  Arrived,
  Cancel,
  Completed,
  Confirmed,
  NoShow,
  Started,
} from "assets/icons/AppointmentStatus";
import { AppointmentStatus } from "types/AppointmentStatus";

export const getAppointmentStatusIcon = (value: string, iconSx?: SxProps) => {
  switch (value) {
    case AppointmentStatus.Confirmed:
      return <Confirmed sx={{ fill: "#53BFB0", ...iconSx }} fontSize="small" />;

    case AppointmentStatus.Arrived:
      return <Arrived fontSize="small" sx={{ ...iconSx }} />;

    case AppointmentStatus.NoShow:
      return <NoShow fontSize="small" sx={{ ...iconSx }} />;

    case AppointmentStatus.Started:
      return <Started fontSize="small" sx={{ ...iconSx }} />;

    case AppointmentStatus.Completed:
      return <Completed fontSize="small" sx={{ ...iconSx }} />;

    case AppointmentStatus.Cancelled:
      return <Cancel fontSize="small" sx={{ ...iconSx }} />;

    default:
      return <Confirmed sx={{ fill: "#53BFB0", ...iconSx }} fontSize="small" />;
  }
};
