import { TranslationNamespaces } from "configs/i18n/TranslationTypes";

export function translatePackage(id: number) {
  const keyRoot = TranslationNamespaces["packages"] + ":" + id;
  return {
    name: keyRoot + ".name",
    description: keyRoot + ".description",
  };
}

export function translatePackageVariant(packageId: number, id: number) {
  const keyRoot = `${TranslationNamespaces["packages"]}:${packageId}.variants.${id}`;
  return {
    name: keyRoot + ".name",
  };
}
