import { DecimalStrategy, KUWAITI_DINAR } from "poc/currency";

/**
 * @param value The price to format
 * @param strategy The formatting strategy to deal with decimals
 * @param decimals How many decimals to show
 * @returns A string of the formatted price
 */
export function formatPrice(
  value: number,
  strategy: DecimalStrategy = "currency_default",
  decimals: number = KUWAITI_DINAR[1]
) {
  switch (strategy) {
    case "currency_default":
      return value.toFixed(decimals);

    case "trim_zeros":
      return value % 1 === 0 ? String(value) : value.toFixed(decimals);

    default:
      throw new Error("Illegal decimal strategy provided to formatPrice() function");
  }
}
