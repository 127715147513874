import { Grid } from "@mui/material";
import { Interval } from "luxon";
import { memo, useMemo } from "react";
import { useSelector } from "store";
import { selectCartArticleIntervals } from "store/selectors";

import type { BookingSlot } from "../../bookingPageTypes";
import { getSlotDateTime, getSlotLabel } from "../slotPickerHelpers";
import SlotButton, { Blockage } from "./SlotButton";

type SlotPageProps = {
  duration: number;
  slots: BookingSlot[];

  selectedSlot: Nullable<BookingSlot>;
  onClickSlot: (slot: Nullable<BookingSlot>) => void;
};

function SlotPage({ slots, selectedSlot, onClickSlot, duration }: SlotPageProps) {
  const slotBlockages = useBlockages(slots, duration);

  return (
    <Grid container sx={{ position: "relative", flex: "0 0 90%" }} spacing={1}>
      {slots.map((slot, index) => (
        <Grid item xs={4} key={slot.time}>
          <SlotButton
            label={getSlotLabel(slot)}
            highlight={selectedSlot === slot}
            blockage={slotBlockages[index]}
            onClick={() => onClickSlot(slot)}
          />
        </Grid>
      ))}
    </Grid>
  );
}

export default memo(SlotPage);

function useBlockages(slots: BookingSlot[], duration: number) {
  const occupiedIntervals = useSelector(selectCartArticleIntervals);

  return useMemo<Blockage[]>(
    () =>
      slots.map((slot) => {
        const slotStartTime = getSlotDateTime(slot);
        const slotEndTime = slotStartTime.plus({ minutes: duration - 1 }); // Subtract one to avoid inclusive intersections (business logic)

        const slotInterval = Interval.fromDateTimes(slotStartTime, slotEndTime);

        const exactlyOccupiedSlot = occupiedIntervals.find(
          (interval) => interval.start.toISOTime() === slotStartTime.toISOTime()
        );

        const isUnavailable = occupiedIntervals.some((occupiedInterval) =>
          occupiedInterval.intersection(slotInterval)
        );

        if (isUnavailable) {
          // const colorIndex = occupiedIntervals.findIndex((occupiedInterval) =>
          //   occupiedInterval.contains(slotStartTime)
          // );

          return [isUnavailable, exactlyOccupiedSlot ? 5 : -1];
        }

        return [false, -1]; // Free and unoccupied
      }),

    [duration, occupiedIntervals, slots]
  );
}
