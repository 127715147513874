import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { getImageSrc } from "helpers/getImageSrc";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization, selectOutletById } from "store/selectors";
import { trackEvent } from "tracking";

type OutletAddressCardProps = {
  outletId?: number;
};

const OutletAddressCard = ({ outletId }: OutletAddressCardProps) => {
  const { t } = useTranslation("appointmentPage");

  const organization = useSelector(selectOrganization);

  const outlet = useSelector(selectOutletById(outletId || 0));

  if (!organization) return <CentredSpinnerBox />;

  const avatarSrc = getImageSrc(organization.avatar);
  const phoneNumber =
    outlet && outlet.contactNumbers.length ? outlet.contactNumbers[0].number : null;
  const mapLink = outlet && outlet.address.mapLink;

  const getOrganzationAndOutletName = () => {
    if (organization && outlet) {
      return (
        <Box>
          <Typography variant="h4">{`${t(organization?.t?.name || "")} - ${t(
            outlet.t.name
          )}`}</Typography>

          <Typography variant="secondary">{`${outlet.address.area} ${outlet.address.block} ${outlet.address.street} ${outlet.address.building}`}</Typography>
        </Box>
      );
    }

    return <Typography variant="h4">{`${t(organization?.t?.name || "")}`}</Typography>;
  };

  return (
    <Grid item xs={12}>
      <Card sx={{ minHeight: "3.375rem", padding: outlet ? 2 : 1 }}>
        <Box display="flex" flexDirection="row" alignItems="center" gap={2} p={1} height="100%">
          <Avatar sx={{ width: "30px", height: "30px" }} src={avatarSrc} />{" "}
          {getOrganzationAndOutletName()}
        </Box>

        {outlet && (
          <Box display="flex" flexDirection="row" gap={1} pt={2} height="100%">
            {phoneNumber && (
              <Button href={`tel:${phoneNumber}`} fullWidth size="small">
                {t("call")}
              </Button>
            )}

            {mapLink && (
              <Button
                href={mapLink}
                variant="outlined"
                fullWidth
                size="small"
                onClick={() => {
                  if (organization?.id && organization?.name)
                    trackEvent(
                      "Location Viewed",
                      { id: organization.id, name: organization.name },
                      {
                        from: "Partner Page",
                      }
                    );
                }}
              >
                {t("directions")}
              </Button>
            )}
          </Box>
        )}
      </Card>
    </Grid>
  );
};

export default OutletAddressCard;
