import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { ReactNode } from "react";

type AppointmentArticleContainerProps = {
  children: ReactNode;
};

const AppointmentArticleContainer = ({ children }: AppointmentArticleContainerProps) => {
  return <StyledBox>{children}</StyledBox>;
};

export default AppointmentArticleContainer;

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#D4D6E4",
  display: "flex",
  width: "100%",
  rowGap: theme.spacing(1),
  padding: theme.spacing(2),
}));
