import PriceBox from "components/PriceBox";

const PackageListItemPrice = ({ variants }: { variants: PackageVariant[] }) => {
  return (
    <PriceBox
      value={Number(variants[0].price)}
      decimalStrategy="trim_zeros"
      variant="primary"
      textSx={{ fontWeight: "bold", fontSize: "0.875rem", color: "#B447E6" }}
    />
  );
};

export default PackageListItemPrice;
