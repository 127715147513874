import { DateTime } from "luxon";

type DateLabelAndContext = [translationKey: string, translationContext: DateLabelContext];

enum DateLabelContext {
  date = "date",
  text = "text",
}

const today = DateTime.now().startOf("day");

/**
 * Takes in a date and turns it into either "Today", "Tomorrow", or "Tue, Feb 14"
 *
 * The function returns a tuple of the translation key and the context of whether it's a date or a text
 */
export default function getSlotPickerDateLabel(date: string): DateLabelAndContext {
  const luxonDate = DateTime.fromISO(date);

  if (luxonDate.hasSame(today, "day")) return ["common:today", DateLabelContext.text];
  if (luxonDate.hasSame(today.plus({ day: 1 }), "day"))
    return ["common:tomorrow", DateLabelContext.text];

  // e.g returned date: Tue, Feb 14
  return [luxonDate.toFormat("ccc, LLL dd"), DateLabelContext.date];
}
