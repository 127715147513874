import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const AuthenticationModal = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t } = useTranslation("reviewAppointmentPage");

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      <Box p={3}>
        <DialogContent>
          <Grid container justifyContent="center" spacing={3} bgcolor="bg.blank">
            <Grid item xs={12}>
              <Typography variant="h3" align="center">
                {t("promptAuthenticateUser")}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider variant="fullWidth" />

        <Box pt={2}>
          <DialogActions>
            <Grid container justifyContent="space-between" spacing={3} bgcolor="bg.paper">
              <Grid item xs={6}>
                <Button size="small" fullWidth variant="outlined" onClick={() => setOpen(false)}>
                  {t("common:cancel")}
                </Button>
              </Grid>

              <Grid item xs={6}>
                <Button size="small" fullWidth>
                  <Link style={{ color: "white" }} to="/login">
                    {t("common:loginOrSignup")}
                  </Link>
                </Button>
              </Grid>
            </Grid>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AuthenticationModal;
