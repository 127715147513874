import { Box, Card as MuiCard, Typography, styled } from "@mui/material";
import React from "react";

type ProfileButtonProps = {
  title: string;
  icon: React.ReactElement;

  onClick: () => void;
};

const ProfileButton = ({ title, icon, onClick }: ProfileButtonProps) => {
  return (
    <Card onClick={onClick}>
      <Box
        sx={{
          svg: {
            fill: (theme) => `${theme.palette.primary.main}`,
          },
        }}
      >
        {icon}
      </Box>

      <Typography variant="primary" fontWeight={500}>
        {title}
      </Typography>
    </Card>
  );
};

export default ProfileButton;

const Card = styled(MuiCard)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  flexGrow: 1,

  cursor: "pointer",

  padding: theme.spacing(2, 3),
  borderRadius: theme.spacing(1),

  boxShadow: "0px 10px 44px rgba(10, 31, 68, 0.1)",
}));
