import { Grid } from "@mui/material";
import SearchField from "components/SearchField";
import Fuse from "fuse.js";
import useParamOutlet from "hooks/useParamOutlet";
import { ChangeEvent, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import {
  selectAllPackageVariants,
  selectAllServiceVariants,
  selectOrganization,
  selectServicesByOutletId,
} from "store/selectors";
import { trackEvent } from "tracking";

import PackageSearchResult from "./PackageSearchResult";
import ServiceSearchResult from "./ServiceSearchResult";

const SearchTab = () => {
  const { t } = useTranslation(["outletPage", "glossary", "common"]);
  const hasTriggeredSearch = useRef(false);

  const { id: outletId } = useParamOutlet();

  const { employeeId } = useParams();

  const outletServices = useSelector(
    selectServicesByOutletId(outletId, employeeId ? Number(employeeId) : undefined)
  );
  const organization = useSelector(selectOrganization);

  const serviceVariants = useSelector(selectAllServiceVariants);

  const packageVariants = useSelector(selectAllPackageVariants);

  let filteredServices: Service[] = [];
  let filteredServiceVariants: ServiceVariant[] = [];
  let filteredPackageVariants: PackageVariant[] = [];

  const [searchQuery, setSearchQuery] = useState("");

  const changeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => {
    if (!hasTriggeredSearch.current) {
      if (organization?.id && organization?.name) {
        trackEvent("Client Searched", { id: organization.id, name: organization.name }, {});
        hasTriggeredSearch.current = true;
      }
    }

    setSearchQuery(event.target.value);
  };

  if (searchQuery) {
    const outletServicesWithTranslations = outletServices.map((service) => ({
      ...service,
      translatedNameAr: t(service.t.name, { lng: "ar" }),
    }));

    const fuse = new Fuse(outletServicesWithTranslations, {
      threshold: 0.4,
      keys: ["name", "translatedNameAr"],
    });
    const result = fuse.search(searchQuery);

    filteredServices = result.map((obj) => obj.item);

    const filteredServicesIds = filteredServices.map((service) => service.id);

    filteredServiceVariants = serviceVariants.filter((variant) =>
      filteredServicesIds.includes(variant.service)
    );

    const filteredServiceVariantsIds = filteredServiceVariants.map((variant) => variant.id);

    filteredPackageVariants = packageVariants.filter((packageVariant) => {
      let hasAtLeastOneService = false;
      packageVariant.serviceVariants.forEach(
        (serviceVariantId) =>
          (hasAtLeastOneService =
            hasAtLeastOneService || filteredServiceVariantsIds.includes(serviceVariantId))
      );
      return hasAtLeastOneService;
    });
  }

  const handleClearSearch = () => setSearchQuery("");
  return (
    <Grid container spacing={1} minHeight="150px">
      <Grid item xs={12}>
        <SearchField
          variant="outlined"
          value={searchQuery}
          onChange={changeSearchQuery}
          onClear={handleClearSearch}
        />
      </Grid>
      {!!filteredServices.length && <ServiceSearchResult filteredServices={filteredServices} />}
      {!!filteredPackageVariants.length && (
        <PackageSearchResult filteredPackageVariants={filteredPackageVariants} />
      )}
    </Grid>
  );
};

export default SearchTab;
