import { TranslationNamespaces } from "configs/i18n/TranslationTypes";

export function translateService(id: number) {
  const keyRoot = TranslationNamespaces["services"] + ":" + id;
  return {
    name: keyRoot + ".name",
    description: keyRoot + ".description",
  };
}

export function translateServiceVariant(serviceId: number, id: number) {
  const keyRoot = `${TranslationNamespaces["services"]}:${serviceId}.variants.${id}`;
  return {
    name: keyRoot + ".name",
  };
}
