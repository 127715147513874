import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getNormalizedOrganization } from "store/globalThunks";
import matchThunks from "store/utils/matchThunks";
import { Subscription } from "types/Subscription";

import { SliceStatus } from "../utils";

export const subscriptionsAdapter = createEntityAdapter<Subscription>({
  selectId: (subscription) => subscription.id,
});

const initialState = subscriptionsAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const subscriptionsSlice = createSlice({
  name: "subscriptions",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        subscriptionsAdapter.setAll(state, payload.subscriptions);
        state.status = SliceStatus.IDLE;
      })

      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "pending" }), (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "fulfilled" }), (state) => {
        state.status = SliceStatus.IDLE;
      })

      .addMatcher(matchThunks({ slice: "subscriptions", status: "rejected" }), (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default subscriptionsSlice.reducer;
