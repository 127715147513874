import { SubscriptionPurchase } from "types/Subscription";
import http from "utils/http";

export type SubscriptionPurchaseListResponse = {
  subscriptions: SubscriptionPurchase[];
};

type CreateSubscriptionArguments = {
  outletId: number;
  subscriptionId: number;
  paymentMethod: string;
};

type SubscriptionPurchaseResponse = {
  subscriptionPurchaseId: Nullable<number>;
  paymentContent: Nullable<{ status: string; paymentURL: string }>;
};

const subscriptionPurchasesApi = {
  getSubscriptionPurchases: async ({ subscriptionStatus }: { subscriptionStatus?: string }) =>
    http.get<SubscriptionPurchaseListResponse>(
      `ob/purchases/subscriptions/${subscriptionStatus ? `?status=${subscriptionStatus}` : ""}`
    ),

  getSubscriptionPurchase: async ({ id }: { id: string | number }) =>
    http.get<SubscriptionPurchase>(`ob/purchases/subscriptions/${id}/`),

  createSubscriptionPurchase: async (data: CreateSubscriptionArguments) =>
    http.post<SubscriptionPurchaseResponse>("ob/purchases/invoices/", data),
};

export default subscriptionPurchasesApi;
