// Stolen from https://github.com/facebook/react-devtools/issues/191#issuecomment-443607190

// This disables the dev tools if you are running the build version
if (process.env.NODE_ENV === "production") {
  const noop = (): void => undefined;
  const DEV_TOOLS = (window as any).__REACT_DEVTOOLS_GLOBAL_HOOK__;

  if (typeof DEV_TOOLS === "object") {
    for (const [key, value] of Object.entries(DEV_TOOLS)) {
      DEV_TOOLS[key] = typeof value === "function" ? noop : null;
    }
  }
}

// This is useless, just to shut TS up
export {};
