import { Button, ButtonProps, styled } from "@mui/material";
import dontForwardProps from "helpers/styles/dontForwardProps";

type CustomBookButtonProps = ButtonProps & {
  isPackage?: boolean;
  isSelected?: boolean;
};

const CustomBookButton = styled(
  Button,
  dontForwardProps("isPackage", "isSelected")
)<CustomBookButtonProps>(({ theme, isPackage, isSelected }) => ({
  border: isPackage ? "1px solid #D4A4EB" : "1px solid #4048D6",
  borderStyle: "solid",

  display: "flex",
  flexDirection: "column",
  width: "100%",
  rowGap: theme.spacing(1),
  padding: theme.spacing(2),
  backgroundColor: isSelected ? (isPackage ? "#F6E3FF" : "#D9DAF7") : "white",
  "&:hover": {
    border: isPackage ? "1px solid #D4A4EB" : "1px solid #4048D6",
    backgroundColor:
      isPackage && isSelected ? "#F6E3FF" : !isPackage && isSelected ? "#D9DAF7" : "white",
  },
}));

export default CustomBookButton;
