import { Button, styled } from "@mui/material";

type TabButtonProps = {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  children?: React.ReactNode;
  selectedDate: boolean;
};

const TabButton = ({ onClick, children, selectedDate }: TabButtonProps) => (
  <TabButtonStyled
    onClick={onClick}
    children={children}
    variant={selectedDate ? "contained" : "outlined"}
  />
);

const TabButtonStyled = styled(Button)(({ theme }) => ({
  margin: theme.spacing(0, 0.5),
}));

export default TabButton;
