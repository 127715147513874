import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectEmployeeById } from "store/selectors";

import getSlotPickerDateLabel from "./getSlotPickerDateLabel";

type SlotPickerEmptyDateProps = { date: string; selectedStaff?: Nullable<number> };

export default function SlotPickerEmptyDate({ date, selectedStaff }: SlotPickerEmptyDateProps) {
  const { t } = useTranslation(["bookingPage", "employees"]);

  const employee = useSelector(selectEmployeeById(selectedStaff || 0));

  const [dateLabelKey, dateLabelContext] = getSlotPickerDateLabel(date);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" minHeight={130.5}>
      <Typography sx={{ color: "text.label", fontWeight: 500 }}>
        {employee
          ? t("noAvailableSlotStaff", { employeeName: t(`${employee.t.fullName}`) })
          : t("noAvailableSlot", {
              date: dateLabelContext === "date" ? t(dateLabelKey) : t(dateLabelKey).toLowerCase(),
              context: dateLabelContext,
            })}
      </Typography>
    </Box>
  );
}
