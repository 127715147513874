import { Grid } from "@mui/material";
import SectionWrapper from "components/SectionWrapper";

import ServicesList from "./ServiceList";

const PurchasableListTabs = () => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} mb={2}>
        <SectionWrapper>
          <ServicesList />
        </SectionWrapper>
      </Grid>
    </Grid>
  );
};

export default PurchasableListTabs;
