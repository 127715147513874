import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PriceBox from "components/PriceBox";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { ProcessedSubscriptionPurchase } from "types/Subscription";

import ArticleItemRedeemableSubscriptions from "./ServiceBookingPage/ArticleItemRedeemableSubscriptions";

type BookingArticleItemCardProps = {
  title: string;
  duration: number;
  price: number;
  discountedPrice?: number;
  isPriceFrom?: boolean;
  subscriptions?: ProcessedSubscriptionPurchase[];
  appliedSubscription?: Nullable<number>;
  handleToggleSubscriptionActivation?: (subscriptionId: Nullable<number>) => void;
  selectedDate: DateTime | null;
};

function BookingArticleItemCard(props: BookingArticleItemCardProps) {
  const { t } = useTranslation("bookingPage");

  const {
    title,
    duration,
    price,
    isPriceFrom = false,
    subscriptions = [],
    appliedSubscription = null,
    handleToggleSubscriptionActivation,
    selectedDate,
  } = props;

  const canShowRedeemableSubscriptions =
    subscriptions.length > 0 && !!handleToggleSubscriptionActivation;

  return (
    <CardWrapper>
      <Grid container spacing={2}>
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <Box display="flex" flexDirection="column">
            <Typography variant="h4">{title}</Typography>
            <Typography>
              {t("duration")}: {getFormattedDuration(duration)}
            </Typography>
          </Box>

          <PriceBox
            isFree={!!appliedSubscription}
            shouldShowPriceFrom={isPriceFrom}
            value={Number(price)}
            decimalStrategy="trim_zeros"
            variant="h4"
            sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column" }}
            textSx={{ color: "text.secondary" }}
          />
        </Grid>

        {canShowRedeemableSubscriptions && (
          <ArticleItemRedeemableSubscriptions
            subscriptions={subscriptions}
            appliedSubscription={appliedSubscription}
            handleToggleSubscriptionActivation={handleToggleSubscriptionActivation}
            selectedDate={selectedDate || DateTime.now()}
            isDraft
          />
        )}
      </Grid>
    </CardWrapper>
  );
}

export default memo(BookingArticleItemCard);

const CardWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.blank,
  display: "flex",
  flexDirection: "column",

  padding: "12px 16px",
  boxShadow: "card",
  borderRadius: "0.25rem",
}));
