import { RootState } from "store";

import { selectPackageVariantById } from "../packageVariants/packageVariantsSelectors";
import { packageAdapter } from "./packagesSlice";

const selectPackagesState = (state: RootState) => state.packages;

const { selectById, selectAll } = packageAdapter.getSelectors(selectPackagesState);

export const selectPackageById = (id: string | number) => (state: RootState) =>
  selectById(state, id);

export const selectAllPackages = (state: RootState) =>
  selectAll(state).sort((a, b) => (a.position || 0) - (b.position || 0));

export const selectPackageByPackageVariantId = (id: string | number) => (state: RootState) => {
  const packageVariant = selectPackageVariantById(id)(state);
  return selectById(state, packageVariant?.package || 0);
};

export const selectPackagesByCategoryIdAndOutletId =
  (categoryId: number, outletId: number) => (state: RootState) =>
    selectAllPackages(state).filter(
      (pkg) => pkg && pkg.category === categoryId && pkg.outlets.includes(outletId)
    );

export const selectPackagesByOutletId = (outletId: number) => (state: RootState) =>
  selectAllPackages(state).filter((pkg) => pkg && pkg.outlets.includes(outletId));

export const selectPopularPackagesByOutletId = (outletId: number) => (state: RootState) =>
  selectAllPackages(state).filter((pkg) => pkg && pkg.popular && pkg.outlets.includes(outletId));
