import { Box, Grid } from "@mui/material";
import SectionWrapper from "components/SectionWrapper";
import getInitials from "helpers/getInitials";
import useParamOutlet from "hooks/useParamOutlet";
import GoToCartButton from "pages/BookingPage/GoToCartButton";
import OutletBookingContext from "pages/OutletPage/outletContext";
import ScrollableDatePickerButtons from "pages/OutletPage/ScrollableDatePickerButtons";
import ServicesList from "pages/OutletPage/ServiceList";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { selectCart, selectEmployeeById } from "store/selectors";

import EmployeeProfileHeader from "./EmployeeProfileHeader";

const EmployeeProfilePage = () => {
  const { t } = useTranslation(["translations", "bookingPage"]);
  const navigate = useNavigate();

  const { selectedStaff, setSelectedStaff } = useContext(OutletBookingContext);

  const outlet = useParamOutlet();

  const { employeeId } = useParams();

  const cart = useSelector(selectCart);
  const employee = useSelector(selectEmployeeById(employeeId ?? ""));

  const cartHasArticles = !!cart.articles.length;

  const isStaffAssignedToOutlet = employee?.outlets?.some(
    (employeeOutlet) => employeeOutlet === outlet?.id ?? 0
  );

  useEffect(() => {
    if (employeeId && selectedStaff !== Number(employeeId)) setSelectedStaff(Number(employeeId));
  }, [employeeId, selectedStaff, setSelectedStaff]);

  if (!employee || !outlet || !isStaffAssignedToOutlet) navigate("/");

  return (
    <Box position="static" bgcolor="bg.secondary">
      <EmployeeProfileHeader
        employeeAvatar={employee?.avatar || ""}
        employeeFullName={employee ? t(employee.t.fullName) : ""}
        employeeInitials={
          employee ? getInitials(t(employee.t.firstName), t(employee.t.lastName)) : ""
        }
        outletName={outlet?.name || ""}
      />

      <ScrollableDatePickerButtons />

      <Box
        display="flex"
        flexDirection="column"
        width="100%"
        minHeight="80vh"
        justifyContent="space-between"
      >
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs={12} mb={2}>
            <SectionWrapper>
              <ServicesList />
            </SectionWrapper>
          </Grid>
        </Grid>

        {cartHasArticles && (
          <Box
            display="flex"
            flexDirection="column"
            px={2}
            pt={2}
            bgcolor="white"
            width="100%"
            position="sticky"
            bottom={0}
            borderTop="1px solid #E6E9EC"
          >
            <GoToCartButton prefix={t("bookingPage:checkout")} />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default EmployeeProfilePage;
