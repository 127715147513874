import { Box } from "@mui/material";
import NothingHere from "components/NothingHere";
import { sortByDateAscending } from "helpers/sort";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectUpcomingAppointments } from "store/slices/appointments/appointmentsSelectors";

import AppointmentCard from "./AppointmentCard";

const UpcomingAppointments = () => {
  const { t } = useTranslation("userAppointmentsPage");

  const upcomingAppointments = useSelector(selectUpcomingAppointments);

  const hasUpcomingAppointments = upcomingAppointments.length > 0;

  const flattenedAppointments = upcomingAppointments.map((appointment) => ({
    ...appointment,
    startTime: appointment.appointmentTimeRange.startTime,
  }));

  const upcomingAppointmentsCards = sortByDateAscending(flattenedAppointments, "startTime").map(
    (appointment) => (
      <AppointmentCard
        key={appointment.id}
        appointmentId={appointment.id}
        startTime={appointment.startTime}
        isAnUpcomingAppointment
      />
    )
  );

  return (
    <Box gap={1.75} m={2} minHeight="80vh">
      {hasUpcomingAppointments ? (
        upcomingAppointmentsCards
      ) : (
        <NothingHere message={t("noUpcomingAppointments")} />
      )}
    </Box>
  );
};

export default UpcomingAppointments;
