import { Avatar, Box, Grid, Radio, Typography } from "@mui/material";
import { styled } from "@mui/system";
import PForPeep from "assets/icons/PForPeep";
import { UserIcon } from "assets/icons/User";
import { getImageSrc } from "helpers/getImageSrc";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectEmployeeById } from "store/selectors";

type StaffModalItemProps = {
  employeeId: Nullable<number>;
  checked: boolean;
  available: boolean;
  onClick: () => void;
};

export default function StaffModalItem({
  employeeId,
  checked,
  available,
  onClick,
}: StaffModalItemProps) {
  const { t } = useTranslation("bookingPage");

  const employee = useSelector(selectEmployeeById(employeeId));

  const name = employee ? t(employee.t.fullName) : t("anyStaff");

  return (
    <StaffRadioWrapper disabled={!available} onClick={available ? onClick : () => {}}>
      <Grid container>
        <Grid item xs={8} display="flex" alignItems="center">
          <StaffAvatarWrapper>
            {employee ? (
              <Avatar alt={name} src={getImageSrc(employee.avatar)} sx={{ width: 40, height: 40 }}>
                <PForPeep fontSize="large" />
              </Avatar>
            ) : (
              <Avatar alt={name} sx={{ width: 40, height: 40, backgroundColor: "white" }}>
                <UserIcon color="primary" />
              </Avatar>
            )}
          </StaffAvatarWrapper>
          <Box>
            <Typography variant="primary" fontWeight="700" color="text.label" component="h4">
              {name || t("anyStaff")}
            </Typography>

            {employee && (
              <Typography variant="secondary" fontWeight="500" color="text.primary" component="p">
                {available ? t("available") : t("busyDuringSlot")}
              </Typography>
            )}
          </Box>
        </Grid>

        <Grid item xs={4} textAlign="right">
          <Radio checked={checked} disabled={!available} />
        </Grid>
      </Grid>
    </StaffRadioWrapper>
  );
}

type StaffRadioWrapperProps = {
  disabled: boolean;
};

const StaffRadioWrapper = styled(Box)<StaffRadioWrapperProps>(({ theme, disabled }) => ({
  background: disabled ? theme.palette.bg.disabled : null,
  border: `1px solid ${theme.palette.common.gray}24`,
  borderRadius: 4,
  minHeight: "64px",
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  display: "flex",
  alignItems: "center",
  userSelect: "none",
  cursor: "pointer",
}));

const StaffAvatarWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  ".MuiSvgIcon-root": {
    width: 31,
    height: 31,
  },
}));
