import { createSlice } from "@reduxjs/toolkit";
import matchThunks from "store/utils/matchThunks";
import auth from "utils/auth";

import createThunkFromApi from "../../utils/createThunkFromApi";
import { SliceStatus } from "../utils";
import api, { LoginArguments, LoginCredentials, LoginResponse } from "./authApi";

type AuthSliceState = {
  profile: Profile | null;
  client: Client | null;

  status: SliceStatus;
  requestStatus: SliceStatus;
};

const initialState: AuthSliceState = {
  profile: null,
  client: null,

  status: SliceStatus.IDLE,
  requestStatus: SliceStatus.IDLE,
};

export const login = createThunkFromApi<LoginArguments, LoginCredentials, LoginResponse>(
  "auth/login",
  api.login,
  {
    extraArgumentKeys: ["shouldRememberUser"],

    handleResponse: ({ response, extraArgs: { shouldRememberUser }, thunkApi: { dispatch } }) => {
      auth.login(response.data, shouldRememberUser);

      dispatch(getProfile({}));

      return response.data;
    },
  }
);

export const register = createThunkFromApi("auth/register", api.register, {
  handleResponse: ({ response, apiArgs: { user }, thunkApi: { dispatch } }) => {
    const { username, password } = user;

    if (response.status === 201) dispatch(login({ username, password, shouldRememberUser: true }));

    return response.data;
  },
});

export const changePassword = createThunkFromApi("auth/changePassword", api.changePassword);

export const forgotPassword = createThunkFromApi("auth/forgotPassword", api.forgotPassword);

export const resetPassword = createThunkFromApi("auth/resetPassword", api.resetPassword);

export const validateResetToken = createThunkFromApi(
  "auth/validateResetToken",
  api.validateResetToken
);

export const getProfile = createThunkFromApi("auth/getProfile", api.getProfile);

export const updateProfile = createThunkFromApi("auth/updateProfile", api.updateProfile);

export const requestOtp = createThunkFromApi("auth/requestOtp", api.requestOtp);

export const verifyOtp = createThunkFromApi("auth/verifyOtp", api.verifyOtp);

export const authSlice = createSlice({
  name: "auth",
  initialState,

  reducers: {
    logout: () => {
      auth.logout();
      return initialState;
    },
  },

  extraReducers: (reducers) => {
    reducers
      .addCase(register.fulfilled, (state) => {
        state.status = SliceStatus.LOADING;
      })

      .addCase(getProfile.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        const { profile, client } = payload;

        state.profile = profile;
        state.client = client;
        state.status = SliceStatus.IDLE;
      })
      .addCase(getProfile.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })

      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.profile = payload;
      })

      .addCase(verifyOtp.fulfilled, (state) => {
        state.profile = state.profile
          ? { ...state.profile, user: { ...state.profile.user, phoneVerified: true } }
          : null;
      })

      .addMatcher(matchThunks({ slice: "auth", status: "pending" }), (state) => {
        state.requestStatus = SliceStatus.LOADING;
      })
      .addMatcher(matchThunks({ slice: "auth", status: "fulfilled" }), (state) => {
        state.requestStatus = SliceStatus.IDLE;
      })
      .addMatcher(matchThunks({ slice: "auth", status: "rejected" }), (state) => {
        state.requestStatus = SliceStatus.FAILED;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
