import { Alert, Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import useParamOutlet from "hooks/useParamOutlet";
import CheckoutSummary from "pages/CheckoutPage/CheckoutSummary";
import PaymentMethodsList from "pages/CheckoutPage/PaymentMethodsList";
import PriceBadge from "pages/CheckoutPage/PriceBadge";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectSubscriptionById } from "store/selectors";
import { createSubscriptionPurchase } from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import { PaymentType, PurchasableOptions } from "types/common";
import { notify } from "utils/SnackbarProvider";
import useQuery from "utils/useQuery";

const CheckoutSubscriptions = () => {
  const { t } = useTranslation(["checkoutPage", "errors"]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const query = useQuery();

  const { subscriptionId } = useParams();

  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";

  const [paymentMethod, setPaymentMethod] = useState("");

  const [isRequestLoading, setIsRequestLoading] = useState(false);

  const [hasPaymentFailed, setHasPaymentFailed] = useState(false);

  const [isRedirectingToPayment, setIsRedirectingToPayment] = useState(false);

  const subscription = useSelector(selectSubscriptionById(subscriptionId || 0));

  useEffect(() => {
    const paymentResult = query.get("Result") === "NOT CAPTURED";
    if (paymentResult) {
      setHasPaymentFailed(true);
    }
  }, [query]);

  if (!subscription || isRedirectingToPayment) return <CentredSpinnerBox />;

  const handlePurchaseSubscription = () => {
    setIsRequestLoading(true);

    if (outlet && subscription) {
      const data = {
        subscriptionId: subscription.id,
        outletId: outlet.id,
        paymentMethod: paymentMethod,
      };

      dispatch(createSubscriptionPurchase(data))
        .unwrap()
        .then((response: any) => {
          const { paymentContent, subscriptionPurchaseId } = response;

          if (subscriptionPurchaseId)
            navigate(`/${outletSlug}/purchase-confirmation/${subscriptionPurchaseId}`);

          if (paymentContent?.paymentURL) {
            setIsRedirectingToPayment(true);
            window.location.href = paymentContent.paymentURL;
          }
        })
        .catch((error: any) => {
          setIsRequestLoading(false);
          notify(
            `${t("errors:createPurchase.couldNotPurchaseSubscription")} ${
              error?.response?.data?.detail ?? ""
            }`,
            "error",
            {
              shouldPersist: true,
            }
          );
        });
    }
  };

  return (
    <>
      <Grid container spacing={3} justifyContent="center" p={2} bgcolor="#F6F7FC">
        <Grid item xs={12} pt={2}>
          <Typography variant="h1">{t("paymentSummary")}</Typography>
        </Grid>

        <Grid item xs={12} mb={2}>
          <PriceBadge
            paymentType={PaymentType.FullPayment}
            paymentPrice={Number(subscription?.price)}
          />
        </Grid>

        {hasPaymentFailed && (
          <Grid item xs={12}>
            <Alert severity="error" variant="filled">
              {t("problemWithPayment")}
            </Alert>
          </Grid>
        )}
      </Grid>

      <PaymentMethodsList
        paymentMethod={paymentMethod}
        setPaymentMethod={setPaymentMethod}
        payablePrice={Number(subscription?.price)}
      />

      <CheckoutSummary
        totalItems={1}
        totalPrice={Number(subscription?.price)}
        paymentDue={Number(subscription?.price)}
        isRequestLoading={isRequestLoading}
        isPaymentSelected={!!paymentMethod}
        handleConfirm={handlePurchaseSubscription}
        purchasableType={PurchasableOptions.Subscription}
      />
    </>
  );
};

export default CheckoutSubscriptions;
