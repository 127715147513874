import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import AuthenticationModal from "components/AuthenticationModal";
import PriceBox from "components/PriceBox";
import useParamOutlet from "hooks/useParamOutlet";
import SubscriptionOutletListModal from "pages/UserSubscriptionsPage/SubscriptionOutletListModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectAuthUser, selectSubscriptionPurchaseById } from "store/selectors";
import { PurchaseSummaryOption } from "types/common";

type SubscriptionPurchaseSummaryProps = {
  items: String;
  price: number;
  pageType?: PurchaseSummaryOption;
  subscriptionPurchaseId?: number;
};

export default function SubscriptionPurchaseSummary({
  items,
  price,
  pageType = PurchaseSummaryOption.Review,
  subscriptionPurchaseId,
}: SubscriptionPurchaseSummaryProps) {
  const { t } = useTranslation("bookingPage");

  const { subscriptionId } = useParams();

  const subscription = useSelector(selectSubscriptionPurchaseById(subscriptionPurchaseId || 0));

  const hasMultipleServiceVariants = (subscription?.serviceVariants.length || 0) > 1;

  const outlet = useParamOutlet();

  const outletSlug = outlet.slug || "";

  const [openAuthModal, setOpenAuthModal] = useState(false);

  const [openOutletList, setOpenOutletList] = useState(false);

  const onClose = () => setOpenOutletList(false);

  const navigate = useNavigate();

  const user = useSelector(selectAuthUser);

  const handleNextStep = () => {
    if (user) {
      navigate(`/${outletSlug}/purchase-checkout/${subscriptionId}`);
    } else {
      setOpenAuthModal(true);
    }
  };

  return (
    <>
      {user ? null : <AuthenticationModal open={openAuthModal} setOpen={setOpenAuthModal} />}

      <Grid item xs={12}>
        <Box display="flex" justifyContent="space-between" mb={2}>
          <Typography variant="h4" component="span">
            {t("totalPrice")}
            <Typography variant="primary" component="span" color="text.label" pl={0.7}>
              • {items}
            </Typography>
          </Typography>

          <PriceBox
            value={price}
            decimalStrategy="trim_zeros"
            variant="h4"
            sx={{ display: "flex", flexDirection: "column" }}
            textSx={{ color: "text.secondary" }}
          />
        </Box>
      </Grid>

      <Grid item xs={12}>
        {pageType === PurchaseSummaryOption.Review ? (
          <Button fullWidth onClick={handleNextStep} size="medium">
            {t("checkout")}
          </Button>
        ) : (
          <>
            {!hasMultipleServiceVariants && (
              <Button fullWidth onClick={() => setOpenOutletList(true)} size="medium">
                {t("bookAnAppointment")}
              </Button>
            )}

            <Button
              sx={{ mt: 2 }}
              fullWidth
              onClick={() => navigate("/profile/subscriptions")}
              size="medium"
              variant="outlined"
            >
              {t("goToMySubscriptions")}
            </Button>
          </>
        )}
      </Grid>

      {subscriptionPurchaseId && (
        <SubscriptionOutletListModal
          open={openOutletList}
          onClose={onClose}
          subscriptionPurchaseId={subscriptionPurchaseId}
        />
      )}
    </>
  );
}
