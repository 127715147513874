import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { translateCategory } from "helpers/translations";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const packageCategoriesAdapter = createEntityAdapter<PackageCategory>();

const initialState = packageCategoriesAdapter.getInitialState({ status: SliceStatus.IDLE });

const packageCategoriesSlice = createSlice({
  name: "packageCategories",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        const packageCategoriesWithTranslationKeys = payload.packageCategories.map(
          (packageCategory) => ({ ...packageCategory, t: translateCategory(packageCategory.id) })
        );
        packageCategoriesAdapter.setAll(state, packageCategoriesWithTranslationKeys);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default packageCategoriesSlice.reducer;
