import { yupResolver } from "@hookform/resolvers/yup";
import { Grid } from "@mui/material";
import { getCountryCodeFromCountryPhonePrefix } from "assets/constants/countries";
import getPhoneNumberWithoutCountryCode from "helpers/phoneNumber/getPhoneNumberWithoutCountryCode";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "store";
import { selectAuthProfile } from "store/selectors";
import { Gender } from "types/Gender";
import * as yup from "yup";

import EditProfileDetailsFormFields from "./EditProfileDetailsFormFields";
import UpdateProfileButton from "./UpdateProfileButton";

const profileDetailsFormSchema = yup.object({
  firstName: yup.string().max(255).required().trim(),
  lastName: yup.string().max(255).trim(),

  emailAddress: yup.string().email().required().trim(),

  countryCode: yup.string().countryCode().required().trim(),
  phoneNumber: yup.string().phoneNumber("countryCode").required().trim(),

  dateOfBirth: yup.string().label("birthdate").trim().nullable(),

  gender: yup.string().oneOf(Object.values(Gender), "string.required").trim(),
});

export type ProfileDetailsFormInputs = yup.InferType<typeof profileDetailsFormSchema>;

const EditProfileDetailsForm = () => {
  const profile = useSelector(selectAuthProfile);

  // we should have a profile here, because we are checking on the parent component, if no profile is found, we should redirect to login
  const { gender, dateOfBirth } = profile!;

  const { firstName, lastName, email, phoneCountryCode, phoneNumber } = profile?.user!;

  const phoneNumberWithoutCountryCode = getPhoneNumberWithoutCountryCode(
    phoneNumber,
    phoneCountryCode
  );

  const countryCodeAsPrefix = getCountryCodeFromCountryPhonePrefix(phoneCountryCode);

  const formMethods = useForm<ProfileDetailsFormInputs>({
    resolver: yupResolver(profileDetailsFormSchema),

    defaultValues: {
      firstName,
      lastName,
      emailAddress: email,
      countryCode: countryCodeAsPrefix,
      phoneNumber: phoneNumberWithoutCountryCode,
      dateOfBirth,
      gender,
    },
  });

  return (
    <Grid item xs={12} container spacing={3} component="form">
      <FormProvider {...formMethods}>
        <EditProfileDetailsFormFields />

        <UpdateProfileButton />
      </FormProvider>
    </Grid>
  );
};

export default EditProfileDetailsForm;
