export enum TranslationNamespaces {
  categories = "categories_backend",
  employees = "employees_backend",
  organizations = "organizations_backend",
  packages = "packages_backend",
  services = "services_backend",
}

export enum Languages {
  arabic = "ar",
  english = "en",
}

export type TranslationsResponse = {
  [key in Languages]: {
    categories: {
      [key: string]: {
        name: string;
      };
    };
    employees: {
      [key: string]: {
        firstName: string;
        lastName: string;
        notes: string;
      };
    };
    organizations: {
      organizations: {
        [key: string]: {
          name: string;
        };
      };
      outlets: {
        [key: string]: {
          name: string;
        };
      };
    };

    packages: {
      name: string;
      description: string;
      variants: {
        [key: string]: {
          name: string;
        };
      };
    };
    services: {
      name: string;
      description: string;
      variants: {
        [key: string]: {
          name: string;
        };
      };
    };
  };
};
