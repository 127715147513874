import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export const onlinePaymentRuleAdapter = createEntityAdapter<OnlinePaymentRule>({
  selectId: (onlinePaymentRule) => onlinePaymentRule.id,
});

const initialState = onlinePaymentRuleAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

const onlinePaymentRuleSlice = createSlice({
  name: "onlinePaymentRules",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        onlinePaymentRuleAdapter.setAll(state, payload.onlinePaymentRules);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});

export default onlinePaymentRuleSlice.reducer;
