import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import SubscriptionRedemptionTag from "pages/AppointmentPage/SubscriptionRedemptionTag";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectEmployeeById,
  selectServiceById,
  selectServiceVariantById,
  selectServiceVariantLongName,
} from "store/selectors";
import { ArticleWithDetails } from "types/Article";
import { ServiceVariantPricingOption } from "types/ServiceVariantPricingOption";

import PriceBox from "./PriceBox";
import SelectedEmployee from "./SelectedEmployee";

type ServiceArticleType = {
  article: ArticleWithDetails;

  shouldShowPrice?: boolean;
  shouldShowSelectedEmployeeBox?: boolean;
  isDetailsPage?: boolean;
};

const ServiceArticle = ({
  article,
  shouldShowPrice = true,
  shouldShowSelectedEmployeeBox = true,
  isDetailsPage = false,
}: ServiceArticleType) => {
  const { t } = useTranslation();

  const serviceVariant = useSelector(selectServiceVariantById(article.serviceVariant || ""));
  const service = useSelector(selectServiceById(serviceVariant?.service || ""));
  const serviceVariantNameKeys = useSelector(
    selectServiceVariantLongName(serviceVariant?.id || -1)
  );

  const employee = useSelector(selectEmployeeById(article.slots?.[0]?.staff || 0));

  if (!service || !serviceVariant) return <CentredSpinnerBox />;

  const { pricingType } = serviceVariant;

  const shouldShowPriceFrom = pricingType === ServiceVariantPricingOption.From;

  const slot = article.slots.length ? article.slots[0] : null;

  const hasStaff = !!employee && slot?.isStaffSelectedByClient;

  return (
    <Box position="relative">
      <Card sx={{ display: slot ? "block" : "none", minHeight: "5rem" }}>
        <Box display="flex" p={2} gap={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column" rowGap={1} height="100%">
            <Typography variant="h4">
              {t(serviceVariantNameKeys[0])}
              {serviceVariantNameKeys?.[1] && ` - ${t(serviceVariantNameKeys[1])}`}
            </Typography>

            {slot && (
              <>
                <Typography variant="primary">{`${fromTofyTimes(
                  DateTime.fromISO(slot.start),
                  DateTime.fromISO(slot.end)
                )} • ${getFormattedDuration(serviceVariant.duration)}`}</Typography>

                {!shouldShowSelectedEmployeeBox && hasStaff && (
                  <Typography variant="primary">{t(employee.t.fullName)}</Typography>
                )}
              </>
            )}
          </Box>

          {shouldShowPrice && (
            <PriceBox
              isFree={!!article.subscriptionRedemption}
              value={Number(serviceVariant.price)}
              decimalStrategy="trim_zeros"
              shouldShowPriceFrom={shouldShowPriceFrom}
              variant="h4"
              textSx={{ color: isDetailsPage ? "text.primary" : "text.secondary" }}
            />
          )}
        </Box>

        {shouldShowSelectedEmployeeBox && hasStaff && <SelectedEmployee employee={employee} />}

        {article.subscriptionRedemption && (
          <Box px={2} pb={2} width="100%">
            <SubscriptionRedemptionTag title={article.subscriptionRedemption.name} />
          </Box>
        )}
      </Card>
    </Box>
  );
};

export default ServiceArticle;
