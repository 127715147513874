export function getLowestPrice(variants: ServiceVariant[]) {
  const prices = variants.map((variant) => parseFloat(variant.price));

  return Math.min(...prices);
}

export function getHighestPrice(variants: ServiceVariant[]) {
  const prices = variants.map((variant) => parseFloat(variant.price));
  const uptoPrices = variants
    .filter((variant) => variant.uptoPrice)
    .map((variant) => parseFloat(variant.uptoPrice));

  return Math.max(...prices, ...uptoPrices);
}
