import { Box } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";

const SplashPage = () => {
  return (
    <Box height="100vh">
      <CentredSpinnerBox />
    </Box>
  );
};

export default SplashPage;
