import { Grid, Tab as MuiTab, Tabs, styled } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import useViewParam from "hooks/useViewParam";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectSubscriptionPurchasesIsLoading } from "store/selectors";
import { getSubscriptionPurchases } from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import { SubscriptionStatus } from "types/Subscription";

import SubscriptionPurchasesList from "./SubscriptionPurchasesList";

const UserSubscriptionsPage = () => {
  const { t } = useTranslation("userSubscriptionsPage");
  const dispatch = useDispatch();
  const areSubscriptionsLoading = useSelector(selectSubscriptionPurchasesIsLoading);

  const hasFetchedSubscriptions = useRef(false);
  const [activeTab, setActiveTab] = useViewParam(
    SubscriptionStatus,
    SubscriptionStatus.activeSubscriptions
  );

  useEffect(() => {
    if (!hasFetchedSubscriptions.current) {
      dispatch(getSubscriptionPurchases({ subscriptionStatus: activeTab }));
      hasFetchedSubscriptions.current = true;
    }
  }, [dispatch, activeTab]);

  const handleTabChange = (value: string) => {
    hasFetchedSubscriptions.current = false;
    setActiveTab(value);
    dispatch(getSubscriptionPurchases({ subscriptionStatus: value }));
    hasFetchedSubscriptions.current = true;
  };

  if (areSubscriptionsLoading) return <CentredSpinnerBox />;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tabs value={activeTab} onChange={(_, value) => handleTabChange(value)} variant="fullWidth">
          <Tab value={SubscriptionStatus.activeSubscriptions} label={t("activeSubscriptions")} />

          <Tab value={SubscriptionStatus.expiredSubscriptions} label={t("expiredSubscriptions")} />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        <SubscriptionPurchasesList subscriptionStatus={activeTab as SubscriptionStatus} />
      </Grid>
    </Grid>
  );
};

export default UserSubscriptionsPage;

const Tab = styled(MuiTab)({
  margin: 0,
});
