import AppSplashScreen from "components/AppSplashScreen";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import AppRoutes from "routes";
import { useSelector } from "store";
import {
  selectAuthIsLoggedIn,
  selectAuthUser,
  selectIsOrganizationLoading,
  selectOrganization,
} from "store/selectors";
import { setUserForTracking, trackEvent } from "tracking";

const TRACKING_ID = "G-NM0202GTDP"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function App() {
  const organization = useSelector(selectOrganization);
  const user = useSelector(selectAuthUser);
  const isLoggedIn = useSelector(selectAuthIsLoggedIn);
  const isOrganizationLoading = useSelector(selectIsOrganizationLoading);

  useEffect(() => {
    if (isLoggedIn) {
      ReactGA.set({ userId: user?.email });
    }
  }, [isLoggedIn, user?.email]);

  useEffect(() => {
    if (organization?.id && organization?.name) {
      trackEvent("Organization Visited", { id: organization.id, name: organization.name }, {});
    }
  }, [organization]);

  if (organization && organization?.id && organization?.name && user) {
    setUserForTracking(user, { id: organization.id, name: organization.name });
  }

  if (isOrganizationLoading) return <AppSplashScreen />;

  return <AppRoutes />;
}
