import { DirectionPointer } from "@bookpeep/ui";
import { Box, Button, Divider, Typography } from "@mui/material";
import { PhoneIcon } from "assets/icons/Phone";
import DrawerModal from "components/DrawerModal";
import { useTranslation } from "react-i18next";
import { OrganizationSliceState } from "store/slices/organization/organizationSlice";
import { trackEvent } from "tracking";

type OutletInformationModalProps = {
  organization: OrganizationSliceState;
  outlet: Outlet;
  open: boolean;
  onClose: () => void;
};

const OutletInformationModal = ({
  organization,
  outlet,
  open,
  onClose,
}: OutletInformationModalProps) => {
  const { t } = useTranslation("outletPage");

  const phoneNumber = !!outlet.contactNumbers.length ? outlet.contactNumbers[0].number : null;

  const address = `${outlet.address.area} ${outlet.address.block} ${outlet.address.street} ${outlet.address.building} `;

  return (
    <DrawerModal
      title=""
      showClose={false}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "30vh", padding: "16px" } }}
    >
      <Box>
        <Box>
          <Typography fontWeight={700} variant="primary" color="text.label">
            {t("telephone").toUpperCase()}
          </Typography>

          {phoneNumber && (
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
              <Typography variant="h5" color="text.secondary">
                {phoneNumber}
              </Typography>

              <Button
                href={`tel:${phoneNumber}`}
                variant="outlined"
                size="small"
                sx={{ minWidth: 38, minHeight: 38 }}
              >
                <PhoneIcon fontSize="small" />
              </Button>
            </Box>
          )}
        </Box>

        <Divider sx={{ my: 2 }} />

        <Box>
          <Typography fontWeight={700} variant="primary" color="text.label">
            {t("address").toUpperCase()}
          </Typography>

          {address && (
            <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
              <Typography variant="primary">{address}</Typography>

              {outlet.address.mapLink && (
                <Button
                  href={outlet.address.mapLink}
                  variant="outlined"
                  size="small"
                  sx={{ minWidth: 38, minHeight: 38 }}
                  onClick={() => {
                    if (organization?.id && organization?.name)
                      trackEvent(
                        "Location Viewed",
                        { id: organization.id, name: organization.name },
                        {
                          from: "Location Page",
                        }
                      );
                  }}
                >
                  <DirectionPointer fontSize="small" />
                </Button>
              )}
            </Box>
          )}
        </Box>
      </Box>
    </DrawerModal>
  );
};
export default OutletInformationModal;
