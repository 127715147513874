import { Divider, Typography } from "@mui/material";
import { Box } from "@mui/system";
import PriceBox from "components/PriceBox";
import StartNewBookingModal from "components/StartNewBookingModal";
import generateCartArticle from "helpers/article/generateCartArticle";
import { TIME_FORMAT } from "helpers/datetime/datetimeConstants";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { BookingSlot } from "pages/BookingPage/bookingPageTypes";
import { getSlotDateTime } from "pages/BookingPage/BookingSlotPicker/slotPickerHelpers";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import {
  selectCart,
  selectOrganization,
  selectPurchasableEarliestArticleFromCart,
  selectServiceById,
} from "store/selectors";
import { addArticleToCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";

import { TYPES } from "./constants";
import OutletBookingContext from "./outletContext";
import CustomBookButton from "./PurchasableCard/CustomBookButton";
import PurchasableSelectedSlotButton from "./PurchasableCard/PurchasableSelectedSlotButton";
import PurchasableSlots from "./PurchasableCard/PurchasableSlots";

const ServiceVariantItem = ({
  variant,
  displayDivider,
  shouldShowCart,
}: {
  variant: ServiceVariant;
  displayDivider: boolean;
  shouldShowCart?: Dispatch<SetStateAction<Nullable<number>>>;
}) => {
  const { t } = useTranslation(["outletPage", "common"]);
  const dispatch = useDispatch();

  const { selectedStaff, selectedDate, selectedPurchasable, setSelectedPurchasable } =
    useContext(OutletBookingContext);

  const [cartArticleDraft, setCartArticleDraft] = useState<Nullable<CartArticle>>(null);

  const cart = useSelector(selectCart);

  const organization = useSelector(selectOrganization);
  const service = useSelector(selectServiceById(variant.service));

  const earliestPurchasableArticleTimeInCart = useSelector(
    selectPurchasableEarliestArticleFromCart(variant.id, TYPES.SERVICES)
  );

  const handleBookClick = (id: number) => {
    if (selectedPurchasable === id) {
      setSelectedPurchasable(null);
      if (shouldShowCart) shouldShowCart(null);
    } else {
      setSelectedPurchasable(id);
      if (shouldShowCart) shouldShowCart(id);
    }
  };

  const shouldShowTimeButton =
    earliestPurchasableArticleTimeInCart && selectedDate.toISODate() === cart.date;

  const handleSlotSelection = (selectedSlot: Nullable<BookingSlot>) => {
    if (selectedSlot) {
      if (service && variant && selectedSlot) {
        const startTime = getSlotDateTime(selectedSlot);

        const cartArticle = generateCartArticle(
          startTime,
          selectedStaff || [],
          null,
          service?.depositPercentage,
          variant,
          undefined
        );

        if (cartArticle) {
          if (cart.articles.length && selectedSlot.date !== cart.date) {
            setCartArticleDraft(cartArticle);
            if (organization?.id && organization?.name) {
              trackEvent(
                "Lost Cart Initiated",
                { id: organization.id, name: organization.name },
                {}
              );
            }
          } else {
            dispatch(addArticleToCart(cartArticle));
            setSelectedPurchasable(null);
            if (organization?.id && organization?.name) {
              trackEvent(
                !!cart.articles.length ? "Item added to cart" : "Cart Initiated",
                { id: organization.id, name: organization.name },
                { isResourceSelected: selectedStaff ? !!selectedStaff : false }
              );
            }
          }
        }
      } else {
        return;
      }
    } else {
      return;
    }
  };

  const shouldShowPriceFrom = variant.pricingType === "FROM";

  const shouldShowSlots = variant.id === selectedPurchasable;

  return (
    <>
      <StartNewBookingModal
        open={!!cartArticleDraft}
        onClose={() => setCartArticleDraft(null)}
        cartArticle={cartArticleDraft}
      />
      <Box display="flex" flexDirection="column" rowGap={1} bgcolor="#F3F3FB" p={2}>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Box display="flex" flexDirection="column" rowGap={1}>
            <Typography variant="primary" fontWeight="bold">
              {t(variant.t.name)}
            </Typography>

            <Typography variant="secondary">
              {t("common:duration")}: {getFormattedDuration(variant.duration)}
            </Typography>
          </Box>

          <Box display="flex" flexDirection="row" alignItems="center">
            <PriceBox
              shouldShowPriceFrom={shouldShowPriceFrom}
              value={Number(variant.price)}
              decimalStrategy="trim_zeros"
              variant="primary"
              textSx={{ fontWeight: "bold", fontSize: "0.875rem" }}
              upToPrice={Number(variant.uptoPrice)}
            />

            {shouldShowTimeButton ? (
              <PurchasableSelectedSlotButton
                label={earliestPurchasableArticleTimeInCart.earliestTime.toFormat(TIME_FORMAT)}
                colorIndex={earliestPurchasableArticleTimeInCart.articleIndex}
                onClick={() => handleBookClick(variant.id)}
              />
            ) : (
              <CustomBookButton
                isSelected={shouldShowSlots}
                variant="outlined"
                onClick={() => {
                  if (organization?.id && organization?.name) {
                    trackEvent(
                      "Service Selected",
                      { id: organization.id, name: organization.name },
                      { isPopular: service?.popular || false }
                    );
                  }
                  handleBookClick(variant.id);
                }}
              >
                <Typography variant="primary" color="primary" fontWeight="bold">
                  {t("glossary:book")}
                </Typography>
              </CustomBookButton>
            )}
          </Box>
        </Box>

        {shouldShowSlots && (
          <Box py={1}>
            <PurchasableSlots
              duration={variant.duration}
              serviceVariantId={variant.id}
              handleSlotSelection={handleSlotSelection}
            />
          </Box>
        )}

        {displayDivider && (
          <Box display="flex" justifyContent="center" bgcolor="#F2F2F7">
            <Divider sx={{ color: "black", opacity: "90%", width: "95%" }} variant="fullWidth" />
          </Box>
        )}
      </Box>
    </>
  );
};

export default ServiceVariantItem;
