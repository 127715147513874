import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import PriceBox from "components/PriceBox";
import andifyArray from "helpers/andifyArray";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import {
  selectCartSummary,
  selectPackageVariantPriceById,
  selectServiceVariantPriceById,
} from "store/selectors";

import { useBookingContext } from "./bookingContext";

export default function BookingArticleSummary() {
  const { t } = useTranslation("bookingPage");

  const {
    state: { packageVariantId, serviceEmployees },
  } = useBookingContext();

  const serviceVariantPrice = useSelector(selectServiceVariantPriceById(serviceEmployees[0][0]));
  const packageVariantPrice = useSelector(selectPackageVariantPriceById(packageVariantId));

  const localServiceCount = packageVariantId ? 0 : 1;
  const localPackageCount = packageVariantId ? 1 : 0;

  const {
    totalPrice: cartTotal,
    serviceCount,
    packageCount,
    hasFromPrice,
  } = useSelector(selectCartSummary);

  const totalPrice = cartTotal + (packageVariantPrice || serviceVariantPrice);

  const counts = [
    t("serviceCount", { count: serviceCount + localServiceCount }),
    t("packageCount", { count: packageCount + localPackageCount }),
  ];

  return (
    <Box display="flex" width="100%" justifyContent="space-between">
      <Typography variant="h4" component="span">
        {t("totalPrice")}
        <Typography variant="primary" component="span" color="text.label" pl={0.7}>
          • {andifyArray(counts, t("common:and"))}
        </Typography>
      </Typography>

      <PriceBox
        shouldShowPriceFrom={hasFromPrice}
        value={totalPrice}
        decimalStrategy="trim_zeros"
        variant="h4"
        sx={{ display: "flex", flexDirection: "column" }}
        textSx={{ color: "text.secondary" }}
      />
    </Box>
  );
}
