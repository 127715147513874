import { COUNTRY_CODES } from "assets/constants/countries";
import { addMethod, string } from "yup";

declare module "yup" {
  interface StringSchema {
    countryCode(message?: string): StringSchema;
  }
}

addMethod(string, "countryCode", function countryCode(message?: string) {
  return this.oneOf(COUNTRY_CODES, message || "invalidCountryCode");
});
