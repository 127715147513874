import { Button, Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import ServiceVariantDetails from "components/ServiceVariantDetails";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import { DateTime } from "luxon";
import SubscriptionRedemptionTag from "pages/AppointmentPage/SubscriptionRedemptionTag";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPackageVariantById } from "store/selectors";
import { ArticleWithDetails } from "types/Article";

import PriceBox from "./PriceBox";

type PackageArticleType = {
  article: ArticleWithDetails;

  shouldShowPrice?: boolean;
  isExpandable?: boolean;
  isDetailsPage?: boolean;
};

const PackageArticle = ({
  article,
  shouldShowPrice = true,
  isExpandable = true,
  isDetailsPage = false,
}: PackageArticleType) => {
  const { t } = useTranslation(["common", "glossary"]);

  const [isExpanded, setIsExpanded] = useState(false);

  const packageVariant = useSelector(selectPackageVariantById(article.packageVariant ?? ""));

  if (!packageVariant) return <CentredSpinnerBox />;

  const startOfFirstService = article.slots[0]?.start;
  const endOfLastService = article.slots[article.slots.length - 1]?.end;

  return (
    <Box position="relative">
      <Card sx={{ display: article.slots.length ? "block" : "none", minHeight: "5rem" }}>
        <Box display="flex" flexDirection="row" p={2} gap={1} justifyContent="space-between">
          <Box display="flex" flexDirection="column" rowGap={1} height="100%">
            <Typography variant="h4">{t(packageVariant.t.name)}</Typography>

            <Typography variant="primary">{`${fromTofyTimes(
              DateTime.fromISO(startOfFirstService),
              DateTime.fromISO(endOfLastService)
            )} • ${article.slots.length} ${t("glossary:services")}`}</Typography>
          </Box>

          {shouldShowPrice && (
            <PriceBox
              isFree={!!article.subscriptionRedemption}
              value={Number(packageVariant.price)}
              decimalStrategy="trim_zeros"
              variant="h4"
              textSx={{ color: isDetailsPage ? "text.primary" : "text.secondary" }}
            />
          )}
        </Box>

        {article.subscriptionRedemption && (
          <Box px={2} pb={2} width="100%">
            <SubscriptionRedemptionTag title={article.subscriptionRedemption.name} />
          </Box>
        )}

        {isExpandable && (
          <>
            {isExpanded &&
              article.slots.map((slot, index) => (
                <Box
                  key={slot.serviceVariant}
                  display="flex"
                  flexDirection="column"
                  gap={1}
                  justifyContent="center"
                >
                  <ServiceVariantDetails
                    slot={slot}
                    index={index + 1}
                    isStaffSelectedByClient={slot.isStaffSelectedByClient}
                  />
                </Box>
              ))}

            <Box display="flex" flexDirection="row" p={2} gap={1} justifyContent="center">
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                onClick={() => setIsExpanded(!isExpanded)}
                size="small"
              >
                {isExpanded ? t("common:showLess") : t("common:showMore")}
              </Button>
            </Box>
          </>
        )}
      </Card>
    </Box>
  );
};

export default PackageArticle;
