import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useTranslation } from "react-i18next";

import AlreadyHaveAnAccount from "./AlreadyHaveAnAccount";
import RegisterForm from "./RegisterForm";

export default function RegisterPage() {
  const { t } = useTranslation("registerPage");

  return (
    <AuthenticationPageLayout
      title={t("title")}
      subtitle={t("subtitle")}
      footer={<AlreadyHaveAnAccount />}
    >
      <RegisterForm />
    </AuthenticationPageLayout>
  );
}
