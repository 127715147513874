import { Ticket } from "@bookpeep/ui";
import { Box, Typography } from "@mui/material";
import Switch from "components/Switch";
import { useTranslation } from "react-i18next";

type ArticleItemRedeemableSubscriptionProps = {
  title: string;

  remainingRedemptionsCount: number;
  expirationDate: string;

  isSubscriptionApplied: boolean;
  setIsSubscriptionApplied: (value: boolean) => void;

  disableRedeeming?: boolean;
};

const ArticleItemRedeemableSubscription = ({
  title,
  remainingRedemptionsCount,
  expirationDate,

  isSubscriptionApplied,
  setIsSubscriptionApplied,

  disableRedeeming = false,
}: ArticleItemRedeemableSubscriptionProps) => {
  const { t } = useTranslation("subscriptions");

  const handleSwitchToggle = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const newSwitchValue = event.target.checked;

    setIsSubscriptionApplied(newSwitchValue);
  };

  return (
    <Box
      sx={{ borderWidth: 1, borderStyle: "solid", borderColor: "#D4D6E4", borderRadius: "8px" }}
      bgcolor="bg.secondary"
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={2}
      borderRadius={1}
      gap={2}
      flexWrap="wrap"
    >
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box display="flex" alignItems="center">
          <Ticket
            sx={{
              fontSize: "0.9rem",
              color: "primary.dark",
            }}
          />

          <Typography pl={0.75} variant="primary" fontWeight={700} noWrap>
            {title}
          </Typography>
        </Box>

        <Box>
          <Typography variant="secondary" fontWeight={500} display="inline-flex">
            <Typography
              variant="inherit"
              color={remainingRedemptionsCount ? "inherit" : "error.main"}
            >
              {`${remainingRedemptionsCount} ${t("left")}`}
            </Typography>

            <Typography px={0.5} variant="inherit" color="text.placeholder">
              {t("until")}
            </Typography>

            {expirationDate}
          </Typography>
        </Box>
      </Box>

      <Switch
        checked={isSubscriptionApplied}
        onChange={handleSwitchToggle}
        disabled={disableRedeeming}
        sx={{ order: { xs: 0, md: 1 } }}
      />

      {disableRedeeming && (
        <Box display="flex">
          <Typography fontSize="0.687rem" fontWeight={500} color="error">
            {t("subscriptionExpirationDisclaimer", { expiration: expirationDate })}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ArticleItemRedeemableSubscription;
