import { DateTime } from "luxon";

/**
 * Get expiration date, based on start date and validityInDays
 * @param startDate
 * @param validityInDays
 * @returns expiration date (last valid date)
 */

export const getExpirationDate = (startDate: DateTime, validityInDays: number): DateTime => {
  const expirationDate = startDate.plus({ days: validityInDays });

  return expirationDate;
};
