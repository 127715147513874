import { Typography } from "@mui/material";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function AlreadyHaveAnAccount() {
  const { t } = useTranslation(["registerPage", "auth"]);

  return (
    <Typography variant="h4">
      {t("registerPage:alreadyRegistered")} <Link to="/login">{t("auth:logIn")}</Link>
    </Typography>
  );
}

export default memo(AlreadyHaveAnAccount);
