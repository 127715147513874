import type { RootState } from "store";

import { SliceStatus } from "../utils";

export const selectAuthUser = (state: RootState) => state.auth.profile?.user;

export const selectAuthProfile = (state: RootState) => state.auth.profile;

//This selector shows whether the profile has been fetched or not, this is mainly used for routing verification purposes
export const selectAuthIsLoading = (state: RootState) => state.auth.status === SliceStatus.LOADING;

//This selector shows the loading status of any request done on the auth store (More Generic)
export const selectAuthRequestIsLoading = (state: RootState) =>
  state.auth.requestStatus === SliceStatus.LOADING;

export const selectAuthIsLoggedIn = (state: RootState) => !!state.auth.profile;

export const selectIsUserPhoneVerified = (state: RootState) =>
  state.auth.profile?.user?.phoneVerified || false;
