export enum PurchasableOptions {
  Service = "SERVICE",
  Package = "PACKAGE",
  Product = "PRODUCT",
  Subscription = "SUBSCRIPTION",
}

export enum PaymentType {
  DownPayment = "DOWN PAYMENT",
  FullPayment = "FULL PAYMENT",
}
export enum PurchaseSummaryOption {
  Review = "Review",
  Confirmation = "Confirmation",
}
