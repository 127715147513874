import { Box } from "@mui/material";
import NothingHere from "components/NothingHere";
import { sortByDateAscending } from "helpers/sort";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectPastAppointments } from "store/slices/appointments/appointmentsSelectors";

import AppointmentCard from "./AppointmentCard";

const PastAppointments = () => {
  const { t } = useTranslation("userAppointmentsPage");

  const pastAppointments = useSelector(selectPastAppointments);

  const hasPastAppointments = pastAppointments.length > 0;

  const flattenedAppointments = pastAppointments.map((appointment) => ({
    ...appointment,
    startTime: appointment.appointmentTimeRange.startTime,
  }));

  const pastAppointmentsCards = sortByDateAscending(flattenedAppointments, "startTime").map(
    (appointment) => (
      <AppointmentCard
        key={appointment.id}
        appointmentId={appointment.id}
        startTime={appointment.startTime}
      />
    )
  );

  return (
    <Box gap={1.75} m={2} minHeight="80vh">
      {hasPastAppointments ? (
        pastAppointmentsCards
      ) : (
        <NothingHere message={t("noPastAppointments")} />
      )}
    </Box>
  );
};

export default PastAppointments;
