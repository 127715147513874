import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getNormalizedAppointments } from "store/globalThunks";
import { Article } from "types/Article";

import { createAppointment } from "../appointments/appointmentsSlice";
import { logout } from "../auth/authSlice";
import { SliceStatus } from "../utils";

export const articleAdapter = createEntityAdapter<Article>({
  selectId: (article) => article.id,
});

const initialState = articleAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const articlesSlice = createSlice({
  name: "articles",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(createAppointment.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createAppointment.fulfilled, (state, { payload: { articles } }) => {
        articleAdapter.upsertMany(state, articles);
        state.status = SliceStatus.IDLE;
      })
      .addCase(createAppointment.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addCase(getNormalizedAppointments.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedAppointments.fulfilled, (state, { payload: { articles } }) => {
        articleAdapter.upsertMany(state, articles);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedAppointments.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addMatcher(logout.match, () => initialState);
  },
});

export default articlesSlice.reducer;
