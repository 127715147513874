import { Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import { DateTime } from "luxon";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import {
  selectActiveSubscriptionByOutletIdAndItemId,
  selectCart,
  selectServiceVariantById,
  selectServiceVariantLongName,
  selectServiceVariantsLoading,
} from "store/selectors";
import { getSubscriptionPurchases } from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import { RedeemableSubscriptionItemOption, SubscriptionStatus } from "types/Subscription";

import ArticleItemCard from "../BookingArticleItemCard";
import ArticleSummary from "../BookingArticleSummary";
import BookingCartSummary from "../BookingCartSummary";
import { useBookingContext } from "../bookingContext";
import EmployeeSelect from "../BookingEmployeeSelect";
import SlotPicker from "../BookingSlotPicker";
import GoToCartButton from "../GoToCartButton";
import ServiceBookingAddToCartButton from "./ServiceBookingAddToCartButton";

export default function ServiceBookingPage() {
  const { t } = useTranslation(["bookingPage", "common"]);

  const dispatch = useDispatch();

  const { state } = useBookingContext();

  const cart = useSelector(selectCart);

  const [cartDate, setCartDate] = useState<DateTime | null>(
    cart.date ? DateTime.fromISO(cart.date) : DateTime.now()
  );

  const {
    state: { subscriptionPurchaseId },
    dispatch: contextDispatch,
  } = useBookingContext();

  const serviceVariantId = state.serviceEmployees[0][0];

  const subscriptions = useSelector(
    selectActiveSubscriptionByOutletIdAndItemId(
      cart.outlet || 0,
      serviceVariantId,
      RedeemableSubscriptionItemOption.ServiceVariants
    )
  );

  const hasFetchedSubscriptions = useRef(false);

  useEffect(() => {
    if (!hasFetchedSubscriptions.current) {
      dispatch(
        getSubscriptionPurchases({ subscriptionStatus: SubscriptionStatus.activeSubscriptions })
      );
      hasFetchedSubscriptions.current = true;
    }
  }, [dispatch]);

  const isLoading = useSelector(selectServiceVariantsLoading);
  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId));
  const name = useSelector(selectServiceVariantLongName(serviceVariantId));

  if (isLoading) return null;
  if (!serviceVariant || !name) return <Navigate to="/" />;

  const cartHasArticles = !!cart.articles.length;
  const { duration, price, pricingType } = serviceVariant;

  const handleToggleSubscriptionActivation = (subscriptionId: Nullable<number>) => {
    contextDispatch({
      type: "setSubscriptionPurchase",
      payload: subscriptionId === subscriptionPurchaseId ? null : subscriptionId,
    });
  };

  return (
    <>
      <PageWrapper container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h3">{t("pickDateAndTime")}</Typography>
        </Grid>

        <Grid item xs={12}>
          <ArticleItemCard
            title={name?.[1] ? `${t(name[0])} - ${t(name[1])}` : t(name[0])}
            isPriceFrom={pricingType === "FROM"}
            duration={duration}
            price={Number(price)}
            subscriptions={subscriptions}
            handleToggleSubscriptionActivation={handleToggleSubscriptionActivation}
            appliedSubscription={subscriptionPurchaseId}
            selectedDate={cartDate}
          />
        </Grid>

        <Grid item xs={12}>
          <SlotPicker setCartDate={setCartDate} />
        </Grid>

        <Grid item xs={12} mt={2}>
          <EmployeeSelect />
        </Grid>
      </PageWrapper>

      {/* Maybe reverse names of summaries? */}
      <Grid container spacing={1} p={3}>
        <Grid item xs={12}>
          <ArticleSummary />
        </Grid>

        <Grid item xs={cartHasArticles ? 9 : 12}>
          <ServiceBookingAddToCartButton />
        </Grid>

        {cartHasArticles && (
          <Grid item xs={3}>
            <GoToCartButton onlyCheckout />
          </Grid>
        )}

        <Grid item xs={12}>
          <BookingCartSummary />
        </Grid>
      </Grid>
    </>
  );
}

const PageWrapper = styled(Grid)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(2)}`,
  backgroundColor: theme.palette.bg.secondary,
  overflowX: "hidden",
  boxShadow: "divider",
}));
