import { ChevronDown } from "@bookpeep/ui";
import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";
import UnselectedStaff from "assets/icons/UnselectedStaff";
import { getImageSrc } from "helpers/getImageSrc";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEmployeeById, selectServiceVariantLongName } from "store/selectors";

type BookingEmployeeItemProps = {
  onClick: () => void;
  serviceVariantId: number;
  employeeId: Nullable<number>;
};

export default function BookingEmployeeItem({
  onClick,
  serviceVariantId,
  employeeId,
}: BookingEmployeeItemProps) {
  const { t } = useTranslation("bookingPage");

  const employee = useSelector(selectEmployeeById(employeeId));

  const employeeName = employee ? t(employee.t.fullName) : t("anyStaff");

  const serviceTitle = useSelector(selectServiceVariantLongName(serviceVariantId));

  return (
    <EmployeeItemWrapper onClick={onClick}>
      <Grid container>
        <Grid item xs={8} display="flex">
          <EmployeeAvatarWrapper>
            {employee && employee.avatar ? (
              <Avatar
                alt={employeeName}
                src={getImageSrc(employee.avatar)}
                sx={{ width: 31, height: 31 }}
              />
            ) : (
              <UnselectedStaff />
            )}
          </EmployeeAvatarWrapper>

          <Box display="flex" alignItems="center" gap={1}>
            <Typography variant="primary" fontWeight="700" color="text.label" component="h4">
              {employeeName}
            </Typography>

            <Typography variant="secondary" fontWeight="500" color="text.label" component="p">
              {t(serviceTitle[0])}
              {serviceTitle[1] ? ` - ${t(serviceTitle[1])}` : ""}
            </Typography>
          </Box>
        </Grid>

        <ChangeEmployeeWrapper item xs={4}>
          <ChangeEmployeeButton variant="text">
            {t("change")} <ChevronDown />
          </ChangeEmployeeButton>
        </ChangeEmployeeWrapper>
      </Grid>
    </EmployeeItemWrapper>
  );
}

const EmployeeItemWrapper = styled(Card)(({ theme }) => ({
  padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  marginBottom: theme.spacing(1),
  "&:last-child": {
    marginBottom: 0,
  },
}));

const ChangeEmployeeWrapper = styled(Grid)(() => ({
  textAlign: "right",
}));

const ChangeEmployeeButton = styled(Button)(() => ({
  fontSize: 12,
  fontWeight: 600,
  minHeight: "30px",
  padding: 2,
  ".MuiSvgIcon-root": {
    width: 16,
    marginLeft: 5,
  },
}));

const EmployeeAvatarWrapper = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.5),
  ".MuiSvgIcon-root": {
    width: 31,
    height: 31,
  },
}));
