import axios, { AxiosRequestTransformer, AxiosResponseTransformer } from "axios";
import { camelizeKeys, decamelize, decamelizeKeys } from "humps";

function decamelizeFormData(originalFormData: FormData) {
  const decamelizedFormData = new FormData();
  for (const [key, value] of originalFormData.entries()) {
    decamelizedFormData.append(decamelize(key), value);
  }
  return decamelizedFormData;
}

const defaultRequestTransformers = (): AxiosRequestTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) return [];
  else if (transformRequest instanceof Array) return transformRequest;
  else return [transformRequest];
};

const decamelizeRequest: AxiosRequestTransformer = (data) => {
  if (data instanceof FormData) return decamelizeFormData(data);
  return decamelizeKeys(data);
};

const defaultResponseTransformers = (): AxiosResponseTransformer[] => {
  const { transformResponse } = axios.defaults;
  if (!transformResponse) return [];
  else if (transformResponse instanceof Array) return transformResponse;
  else return [transformResponse];
};

const camelizeResponse: AxiosResponseTransformer = (data) => camelizeKeys(data);

// TODO: proper way of handling this
// The Subdomain from the environment always takes precedence over the url subdomain
export const subdomain = process.env.REACT_APP_SUBDOMAIN || window.location.hostname.split(".")[0];

const http = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  transformRequest: [decamelizeRequest, ...defaultRequestTransformers()],
  transformResponse: [...defaultResponseTransformers(), camelizeResponse],
  headers: { organization: subdomain ?? "" },
});

export default http;

export function setBearerToken(token: string | null) {
  if (token === null) delete http.defaults.headers.common["Authorization"];
  else http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export const createFormData = (data: any) => {
  const blobs = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => value instanceof Blob)
  );
  const nonBlobs = Object.fromEntries(
    Object.entries(data).filter(([_, value]) => !(value instanceof Blob))
  );
  const decamelizedData = decamelizeKeys(nonBlobs);

  const formData = new FormData();

  Object.entries(decamelizedData).forEach(([key, value]) => {
    if (value === null) return;

    if (typeof value === "string") {
      formData.append(key, value);
    } else {
      const stringifiedValue = JSON.stringify(value);
      formData.append(key, stringifiedValue);
    }
  });
  Object.entries(blobs).forEach(([key, value]) => {
    if (value === null) return;
    // @ts-ignore
    formData.append(key, value);
  });

  return formData;
};
