import { ChevronDown, Confirm } from "@bookpeep/ui";
import { Avatar, Badge, Box, Button, Typography, styled } from "@mui/material";
import { UserIcon } from "assets/icons/User";
import { getImageSrc } from "helpers/getImageSrc";
import getInitials from "helpers/getInitials";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectEmployeeById, selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";

import AppointmentArticleSlotStaffModal from "./AppointmentArticleSlotStaffModal";

type ArticleStaffSelectorProps = {
  isService?: boolean;
  slot: CartArticleSlot;
  blockedEmployeeIds: number[];
  handleArticleUpdate: (slot: CartArticleSlot) => void;
};

const ArticleStaffSelector = ({
  slot,
  blockedEmployeeIds,

  handleArticleUpdate,
}: ArticleStaffSelectorProps) => {
  const { t } = useTranslation();

  const [employeeModalOpen, setEmployeeModalOpen] = useState(false);

  const employeeId = slot.staff;

  const employee = useSelector(selectEmployeeById(employeeId));
  const organization = useSelector(selectOrganization);

  const employeeInitials = employee
    ? getInitials(t(employee.t.firstName), t(employee.t.lastName))
    : "";

  const handleCloseModal = () => {
    setEmployeeModalOpen(false);
  };

  return (
    <>
      <AppointmentArticleSlotStaffModal
        isService
        open={employeeModalOpen}
        onClose={handleCloseModal}
        blockedEmployeeIds={blockedEmployeeIds}
        slot={slot}
        handleArticleUpdate={handleArticleUpdate}
      />

      <Box
        sx={{ borderWidth: 1, borderStyle: "solid", borderColor: "#D4D6E4", borderRadius: "8px" }}
        width="100%"
        height="72px"
        display="flex"
        bgcolor="#F7F8FD"
        p={2}
        columnGap={1}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center" columnGap={1}>
          {employee ? (
            <Badge
              color="primary"
              componentsProps={{ badge: {} }}
              badgeContent={<Confirm sx={{ width: "9px", height: "9px" }} />}
              overlap="circular"
            >
              <Avatar src={getImageSrc(employee.avatar)} alt={t(employee.t.fullName)}>
                {employeeInitials}
              </Avatar>
            </Badge>
          ) : (
            <UserIcon color="primary" />
          )}

          <Typography variant="primary" fontWeight="bold">
            {employee ? t(employee.t.fullName) : t("bookingPage:anyStaff")}
          </Typography>
        </Box>

        <Box display="flex" alignItems="center" columnGap={1}>
          <ChangeEmployeeButton
            variant="text"
            onClick={() => {
              if (organization?.id && organization?.name) {
                trackEvent(
                  "Resource Selection explored",
                  { id: organization.id, name: organization.name },
                  {}
                );
              }

              setEmployeeModalOpen(true);
            }}
          >
            {t("bookingPage:change")} <ChevronDown />
          </ChangeEmployeeButton>
        </Box>
      </Box>
    </>
  );
};

export default ArticleStaffSelector;

const ChangeEmployeeButton = styled(Button)(() => ({
  fontSize: 12,
  fontWeight: 600,
  minHeight: "30px",
  padding: 2,
  ".MuiSvgIcon-root": {
    width: 16,
    marginLeft: 5,
  },
}));
