import { FormControlLabel, styled } from "@mui/material";

export const RadioButton = styled(FormControlLabel)(({ theme, checked }) => ({
  margin: 0,
  userSelect: "none",
  flexGrow: 1,
  flexBasis: 0,
  padding: theme.spacing(0.5, 0.75),
  border: `1px solid`,
  borderRadius: theme.spacing(1),
  backgroundColor: theme.palette.common.white,

  borderColor: checked ? theme.palette.primary.light : theme.palette.text.lightLabel,
  svg: { fill: checked ? theme.palette.primary.main : theme.palette.text.lightLabel },
}));
