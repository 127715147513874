import CentredSpinnerBox from "components/CentredSpinnerBox";

import {
  BookingContextConsumer,
  BookingContextProvider,
  BookingContextValues,
} from "./bookingContext";
import PackageBookingPage from "./PackageBookingPage";
import ServiceBookingPage from "./ServiceBookingPage";

// TODO: Instead of service and package booking pages, there should be a common layout
// component that has the common parts, and allows props to be passed based on type

export default function BookingPage() {
  const renderBookingContext = (context: BookingContextValues) => {
    if (!context) return <BookingContextSkeleton />;
    else if (context.state.packageVariantId) return <PackageBookingPage />;
    else return <ServiceBookingPage />;
  };

  return (
    <BookingContextProvider>
      <BookingContextConsumer>{renderBookingContext}</BookingContextConsumer>
    </BookingContextProvider>
  );
}

function BookingContextSkeleton() {
  return <CentredSpinnerBox />;
}
