import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getNormalizedAppointments } from "store/globalThunks";

import { createAppointment } from "../appointments/appointmentsSlice";
import { logout } from "../auth/authSlice";
import { SliceStatus } from "../utils";

export const articleSlotAdapter = createEntityAdapter<ArticleSlot>({
  selectId: (article) => article.id,
});

const initialState = articleSlotAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const articleSlotsSlice = createSlice({
  name: "articleSlots",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(createAppointment.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(createAppointment.fulfilled, (state, { payload: { slots } }) => {
        articleSlotAdapter.upsertMany(state, slots);
        state.status = SliceStatus.IDLE;
      })
      .addCase(createAppointment.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addCase(getNormalizedAppointments.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedAppointments.fulfilled, (state, { payload: { slots } }) => {
        articleSlotAdapter.upsertMany(state, slots);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedAppointments.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addMatcher(logout.match, () => initialState);
  },
});

export default articleSlotsSlice.reducer;
