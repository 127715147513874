import { Avatar, Box, Menu as MuiMenu, Typography, styled } from "@mui/material";
import PriceBox from "components/PriceBox";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectAuthUser } from "store/selectors";
import { logout } from "store/slices/auth/authSlice";
import { selectWallet } from "store/slices/wallet/walletSelectors";

import UserMenuItem from "./UserMenuItem";

const UserMenu = () => {
  const { t } = useTranslation(["auth", "userProfilePage", "walletPage"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchor);

  const user = useSelector(selectAuthUser);

  const wallet = useSelector(selectWallet);
  const balance = wallet.availableCredit || 0.0;

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  };

  const closeMenu = () => setMenuAnchor(null);

  const goToMyWallet = () => navigate("/wallet");

  const goToProfilePage = () => navigate("/profile");

  const handleLogout = () => {
    dispatch(logout());

    navigate("/login");
  };

  if (!user) return null;

  return (
    <>
      <Box display="flex" alignItems="center" gap={1} onClick={openMenu} sx={{ cursor: "pointer" }}>
        <Typography fontWeight={500} fontSize="0.875rem" color="primary">
          {user?.firstName}
        </Typography>

        <Avatar />
      </Box>

      <Menu anchorEl={menuAnchor} open={isMenuOpen} onClose={closeMenu}>
        <UserMenuItem onClose={closeMenu} onClick={goToProfilePage}>
          {t("userProfilePage:profile")}
        </UserMenuItem>

        <UserMenuItem onClose={closeMenu} divider onClick={goToMyWallet}>
          <Box display="flex" alignItems="flex-end" justifyContent="space-between" width="100%">
            <Typography variant="primary" fontWeight={500}>
              {t("walletPage:wallet")}
            </Typography>

            <Typography color="#53BFB0" fontSize="0.6875rem" fontWeight={500} pl={4}>
              {t("walletPage:balance")}:
              <PriceBox
                value={Number(balance) | 0}
                decimalStrategy="trim_zeros"
                sx={{ pl: 0.5, pr: 0 }}
                textSx={{ fontSize: "0.6875rem", fontWeight: 500 }}
              />
            </Typography>
          </Box>
        </UserMenuItem>

        <UserMenuItem onClose={closeMenu} onClick={handleLogout}>
          {t("logout")}
        </UserMenuItem>
      </Menu>
    </>
  );
};

export default UserMenu;

const Menu = styled(MuiMenu)(({ theme }) => ({
  "& .MuiPaper-root": {
    minWidth: "150px",
    marginRight: theme.spacing(2),
    // @ts-ignore
    boxShadow: theme.shadows.deepCard,
  },
}));
