import { Box, Grid, Skeleton } from "@mui/material";
import { memo } from "react";

import CategoryRowSkeleton from "./CategoryRow.skeleton";

const SkeletonCards = () => (
  <>
    {[...Array(3).keys()].map((key) => (
      <Grid item xs={12} key={key}>
        <CategoryRowSkeleton />
      </Grid>
    ))}
  </>
);

const OutletPageSkeleton = () => {
  return (
    <>
      <Box bgcolor="bg.secondary" display="flex" flexDirection="column">
        <Grid container spacing={2} p={2} justifyContent="center">
          <Grid item xs={12} my={1}>
            <Box display="flex" justifyContent="space-between" width="100%" columnGap={1}>
              <Box>
                <Skeleton variant="circular" width="60px" height="60px" />
              </Box>

              <Box flexGrow={1} pr={1}>
                <Skeleton variant="text" width="50%" />
                <Skeleton variant="text" width="25%" />
              </Box>

              <Box width="10%">
                <Skeleton variant="rectangular" />
                <Skeleton variant="rectangular" />
              </Box>
            </Box>
          </Grid>

          <Grid item xs={6}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={6}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={4}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={4}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={4}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>
        </Grid>
      </Box>

      <Box display="flex" flexDirection="column">
        <Grid container spacing={2} p={2} justifyContent="center">
          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={3}>
            <Skeleton variant="rectangular" height="48px" />
          </Grid>

          <Grid item xs={12}>
            <Skeleton variant="text" width="50%" />
          </Grid>
          <SkeletonCards />

          <Grid item xs={12}>
            <Skeleton variant="text" width="50%" />
          </Grid>
          <SkeletonCards />
        </Grid>
      </Box>
    </>
  );
};

export default memo(OutletPageSkeleton);
