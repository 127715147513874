import { Box } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { BookingSlot } from "pages/BookingPage/bookingPageTypes";
import SlotPickerBinder from "pages/BookingPage/BookingSlotPicker/SlotPickerBinder";
import SlotPickerEmptyDate from "pages/BookingPage/BookingSlotPicker/SlotPickerEmptyDate";
import { useContext } from "react";

import OutletBookingContext from "../outletContext";
import usePurchasableAvailability from "../usePurchasbaleAvailability";

type PurchasableSlotsType = {
  duration: number;
  serviceVariantId?: number;
  packageVariantId?: number;
  handleSlotSelection: (slot: Nullable<BookingSlot>) => void;
};

const PurchasableSlots = ({
  duration,
  serviceVariantId,
  packageVariantId,
  handleSlotSelection,
}: PurchasableSlotsType) => {
  const { selectedDate, selectedStaff } = useContext(OutletBookingContext);

  const { availableSlots, areSlotsLoading } = usePurchasableAvailability({
    serviceVariantId,
    packageVariantId,
    date: selectedDate.toISODate(),
  });

  const hasNoAvailableSlotsOnDate = !availableSlots.length;

  return (
    <Box>
      {areSlotsLoading ? (
        <Skeleton />
      ) : hasNoAvailableSlotsOnDate ? (
        <SlotPickerEmptyDate date={selectedDate.toISODate()} />
      ) : (
        <SlotPickerBinder
          duration={duration}
          options={availableSlots}
          value={null}
          onChange={handleSlotSelection}
          employeeIdFilter={packageVariantId ? null : selectedStaff}
        />
      )}
    </Box>
  );
};

export default PurchasableSlots;

// This is a placeholder, if you ever implement this component please move it to a separate file
const Skeleton = () => (
  <Box display="flex" alignItems="center" justifyContent="center" minHeight={130.5}>
    <CentredSpinnerBox />
  </Box>
);
