import { Box, Typography } from "@mui/material";

//Note: Kept the old props for the sake of using them later or at least until this design is permanent
type EmployeeProfileHeaderProps = {
  employeeAvatar: Employee["avatar"];
  employeeFullName: string;
  employeeInitials: string;
  outletName: string;
};

const EmployeeProfileHeader = ({ employeeFullName, outletName }: EmployeeProfileHeaderProps) => {
  return (
    <Box display="flex" flexDirection="column">
      <Box p={2}>
        <Typography variant="h3">{employeeFullName}</Typography>
        <Typography variant="secondary">{outletName}</Typography>
      </Box>
    </Box>
  );
};

export default EmployeeProfileHeader;
