import http from "utils/http";

type UsernameType = "OB" | "PP";

export type DjangoDetailResponse = {
  detail: string;
};

export type LoginCredentials = {
  username: string;
  password: string;
};

export type LoginArguments = LoginCredentials & {
  shouldRememberUser: boolean;
};

export type LoginResponse = {
  access: string;
  refresh: string;
};

export type LoginRejected = DjangoDetailResponse | string;

export type RegisterArguments = {
  user: {
    firstName: string;
    lastName?: string;
    username: string;
    phoneCountryCode: string;
    phoneNumber: string;
    password: string;
    confirmPassword: string;
    usernameType: UsernameType;
  };
};

export type RegisterResponse = {
  gender: string;
  id: number;
  dateOfBirth: string;
  preferredLanguage: string;
  createdAt: string;
  updatedAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    username: string;
    phoneCountryCode: string;
    phoneNumber: string;
    usernameType: UsernameType;
    emailVerified: boolean;
    phoneVerified: boolean;
  };
};

export type ChangePasswordArguments = {
  oldPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

type RefreshResponse = {
  access: string;
  refresh: string;
};

export type ForgotPasswordArguments = {
  username: string;
};

export type ForgotPasswordResponse = {
  status: string;
};

export type ResetPasswordArguments = {
  token: string;
  password: string;
};

export type ResetPasswordResponse = {
  status: string;
};

export type ValidateTokenArguments = {
  token: string;
};

export type ValidateTokenResponse = {
  status: string;
};

export type UpdateUserArguments = {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
  phoneCountryCode?: string;
};

export type UpdateUserResponse = {
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  phoneCountryCode: string;
  usernameType: UsernameType;
  emailVerified: boolean;
  phoneVerified: boolean;
  isOwnerOrAdmin: boolean;
  isEmployee: boolean;
};

export type RequestOtpResponse = {
  detail: string;
};

export type VerifyOtpArguments = {
  code: string;
};

export type GetProfileResponse = {
  profile: Profile;

  client: Client;

  wallet: Wallet;
};

type UpdateProfileUserArguments = Omit<
  ProfileUser,
  "id" | "emailVerified" | "phoneVerified" | "usernameType"
>;

export type UpdateProfileArguments = Omit<Partial<Profile>, "id" | "user" | "preferredLanguage"> & {
  user: UpdateProfileUserArguments;
};

export type UpdateProfileResponse = Profile;

const authApi = {
  login: async (credentials: LoginCredentials) =>
    http.post<LoginResponse>("api/users/signin/", credentials),

  register: async (formValues: RegisterArguments) =>
    http.post<RegisterResponse>("ob/peeps/peep/", formValues),

  requestOtp: async () => http.get<RequestOtpResponse>("api/users/verify/"),

  verifyOtp: async (code: VerifyOtpArguments) =>
    http.post<RequestOtpResponse>("api/users/verify/", code),

  changePassword: async (formValues: ChangePasswordArguments) =>
    http.put<DjangoDetailResponse>("api/users/change-password/", formValues),

  refreshToken: async (refresh: string) =>
    http.post<RefreshResponse>("api/users/token/refresh/", { refresh }),

  forgotPassword: async (data: ForgotPasswordArguments) =>
    http.post<ForgotPasswordResponse>("api/users/forget-password/", data),

  resetPassword: async (data: ResetPasswordArguments) =>
    http.post<ResetPasswordResponse>("api/users/forget-password/confirm/", data),

  validateResetToken: async (data: ValidateTokenArguments) =>
    http.post<ValidateTokenResponse>("api/users/forget-password/validate_token/", data),

  updateUser: async (data: UpdateUserArguments) =>
    http.put<UpdateUserResponse>("users/user/", data),

  getProfile: async () => http.get<GetProfileResponse>("/ob/peeps/peep/"),

  updateProfile: async (data: UpdateProfileArguments) =>
    http.put<UpdateProfileResponse>("/ob/peeps/peep/", data),
};

export default authApi;
