import i18next from "i18next";
import { Duration } from "luxon";

/**
 * Format the duration to 1h, 30m format.
 *
 * Hides unit if the value is 0.
 *
 * @param {number} durationInMinutes
 *
 * @returns {string} formatted duration
 */

const getFormattedDuration = (durationInMinutes: number): string => {
  const { language } = i18next;
  const isEnglish = language === "en";

  const minuteLabel = isEnglish ? "m" : " د";
  const hourLabel = isEnglish ? "h" : " س";
  const separator = isEnglish ? ", " : " و";

  let format = `h'${hourLabel}'${separator}m'${minuteLabel}'`;

  const isMoreThanZero = durationInMinutes > 0;

  if (isMoreThanZero) {
    const isLessThanAnHour = durationInMinutes < 60;
    const hasNoRemainingMinutes = durationInMinutes % 60 === 0;

    if (isLessThanAnHour) format = `m'${minuteLabel}'`;
    else if (hasNoRemainingMinutes && isMoreThanZero) format = `h'${hourLabel}'`;
  }

  const formattedDuration = Duration.fromObject({
    minutes: durationInMinutes,
  }).toFormat(format);

  return formattedDuration;
};

export default getFormattedDuration;
