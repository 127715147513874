import { useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

/**
 *  A hook to control component state view using search parameters from react router.
 *
 * @param {object} views - Object of possible views
 * @param {number} defaultView - The default view to fallback to
 * @returns [view, setView]
 */
export default function useViewParam<Views extends { [key: string]: string }>(
  views: Views,
  defaultView: string
) {
  const [params, setParams] = useSearchParams();

  const view = useMemo(() => {
    const viewParam = params.get("view");
    if (viewParam && Object.values(views).includes(viewParam)) return viewParam;
    return defaultView;
  }, [params, views, defaultView]);

  const setView = useCallback(
    (newView: string) => {
      if (newView === defaultView) setParams({ view: defaultView });
      else setParams({ view: newView });
    },
    [defaultView, setParams]
  );

  return [view, setView] as const;
}
