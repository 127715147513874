import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import PhoneField from "components/PhoneField";
import { RadioButton } from "components/RadioButton";
import useFormValidation from "hooks/useFormValidation";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectIsUserPhoneVerified } from "store/selectors";
import { requestOtp } from "store/slices/auth/authSlice";
import { Gender } from "types/Gender";

import type { ProfileDetailsFormInputs } from "./EditProfileDetailsForm";

const EditProfileDetailsFormFields = () => {
  const { t } = useTranslation(["auth", "editUserProfilePage"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isPhoneVerified = useSelector(selectIsUserPhoneVerified);

  const { register, formState, watch, setValue } = useFormContext<ProfileDetailsFormInputs>();
  const registerValidation = useFormValidation(formState, { ns: "auth" });

  const countryCode = watch("countryCode");
  const setCountryCode = (code: string) => setValue("countryCode", code);

  const gender = watch("gender");
  const genderValidation = registerValidation("gender");

  const handleVerifyPhoneClicked = () => {
    dispatch(requestOtp({}));
    navigate("/verify-phone-number");
  };

  return (
    <>
      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("firstName")}
          placeholder={t("firstNamePlaceholder")}
          {...register("firstName")}
          {...registerValidation("firstName")}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("lastName")}
          placeholder={t("lastNamePlaceholder")}
          {...register("lastName")}
          {...registerValidation("lastName")}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("emailAddress")}
          placeholder={t("emailAddressPlaceholder")}
          {...register("emailAddress")}
          {...registerValidation("emailAddress")}
        />
      </Grid>

      <Grid item xs={12}>
        <PhoneField
          fullWidth
          label={t("auth:phoneNumber")}
          placeholder={t("auth:phoneNumberPlaceholder")}
          {...register("phoneNumber")}
          {...registerValidation("phoneNumber")}
          CountryCodeAutocompleteProps={{ value: countryCode, onChange: setCountryCode }}
        />

        {!isPhoneVerified && (
          <Box py={1.5}>
            <Typography color="text.label">
              {t("editUserProfilePage:youNeedToVerifyYourPhone")}
            </Typography>

            <Typography
              sx={{ cursor: "pointer" }}
              color="primary"
              fontWeight={700}
              onClick={handleVerifyPhoneClicked}
            >
              {t("editUserProfilePage:verifyPhoneNumber")}
            </Typography>
          </Box>
        )}
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          label={t("auth:birthdate")}
          {...register("dateOfBirth")}
          {...registerValidation("dateOfBirth")}
          type="date"
        />
      </Grid>

      <Grid item xs={12}>
        <FormControl fullWidth>
          <Typography variant="h5" mb={1.5}>
            {t("auth:selectGender")}
          </Typography>

          <RadioGroup row sx={{ gap: "10px" }}>
            <RadioButton
              checked={gender === Gender.Female}
              value={Gender.Female}
              control={<Radio {...register("gender")} />}
              label={t("auth:female")}
            />

            <RadioButton
              checked={gender === Gender.Male}
              value={Gender.Male}
              control={<Radio {...register("gender")} />}
              label={t("auth:male")}
            />
          </RadioGroup>

          {genderValidation.error && (
            <FormHelperText error>{genderValidation.helperText}</FormHelperText>
          )}
        </FormControl>
      </Grid>
    </>
  );
};

export default EditProfileDetailsFormFields;
