import CentredSpinnerBox from "components/CentredSpinnerBox";
import { useCallback, useEffect, useRef, useState } from "react";
import { Outlet as RouterOutlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectAuthIsLoading, selectAuthIsLoggedIn, selectAuthUser } from "store/selectors";

type RedirectCondition = "isLoggedIn" | "isNotLoggedIn";

type AuthenticationRedirectorProps = {
  redirectIf: RedirectCondition;
};

export default function AuthenticationRedirector({ redirectIf }: AuthenticationRedirectorProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const isLoggedIn = useSelector(selectAuthIsLoggedIn);
  const isAuthLoading = useSelector(selectAuthIsLoading);

  const [isRedirecting, setIsRedirecting] = useState(false);

  const whereWeCameFrom = useRef(0);
  const whereWeAre = useRef("");

  const user = useSelector(selectAuthUser);

  const isUserVerified = user?.phoneVerified;

  const isOnVerifyPhonePage = location.pathname === "/verify-phone-number";

  const shouldRedirectFromVerifyPhonePage = isUserVerified && isOnVerifyPhonePage;

  // we want to go back in history based on the routes that have been visited
  useEffect(() => {
    if (whereWeAre.current !== location.pathname) {
      // If you visit the verify page coming from any page other than the register back to previous route
      if (isOnVerifyPhonePage && whereWeAre.current !== "/register") {
        whereWeCameFrom.current = -1;
      } else {
        whereWeAre.current = location.pathname;
        whereWeCameFrom.current--;
      }
    }
  }, [isOnVerifyPhonePage, location.pathname]);

  const canGoBack = window.history.state && window.history.state.idx > 0;

  const redirectBasedOnHistory = useCallback(() => {
    setIsRedirecting(true);

    canGoBack ? navigate(whereWeCameFrom.current) : navigate("/");

    setIsRedirecting(false);
  }, [canGoBack, navigate]);

  useEffect(() => {
    if (!isAuthLoading && !isRedirecting) {
      switch (redirectIf) {
        case "isLoggedIn":
          if (isLoggedIn) {
            redirectBasedOnHistory();
          }
          break;

        case "isNotLoggedIn":
          if (!isLoggedIn) redirectBasedOnHistory();

          break;

        default:
          throw new Error(`Unknown redirect condition: ${redirectIf}`);
      }
    }
  }, [
    isAuthLoading,
    isLoggedIn,
    navigate,
    redirectBasedOnHistory,
    redirectIf,
    location.pathname,
    isRedirecting,
  ]);

  useEffect(() => {
    // if the user is verified -> and went to verify phone page or just successfully verified his phone -> then he will be redirected to previous route
    if (!isAuthLoading && isLoggedIn && shouldRedirectFromVerifyPhonePage) {
      redirectBasedOnHistory();
    }
  }, [
    isAuthLoading,
    isLoggedIn,
    redirectBasedOnHistory,
    shouldRedirectFromVerifyPhonePage,
    location.pathname,
  ]);

  if (isAuthLoading && isRedirecting) return <CentredSpinnerBox />;

  return <RouterOutlet />;
}
