import { LoadingButton } from "@mui/lab";
import type { AlertOptions } from "hooks/useAlertBox";
import { useTranslation } from "react-i18next";
import { useDispatch } from "store";
import { requestOtp } from "store/slices/auth/authSlice";

import type { PendingRequest } from "./VerifyPhonePage";

type ResendVerificationCodeButtonProps = {
  isLoading: boolean;
  isDisabled: boolean;

  setAlert: (alert: AlertOptions) => void;

  setPendingRequest: (pendingRequest: PendingRequest | null) => void;
};

const ResendVerificationCodeButton = ({
  isLoading,
  isDisabled,
  setAlert,
  setPendingRequest,
}: ResendVerificationCodeButtonProps) => {
  const { t } = useTranslation(["verifyPhonePage", "notifications"]);
  const dispatch = useDispatch();

  const requestNewOtp = () => {
    setPendingRequest("resend");

    dispatch(requestOtp({}))
      .unwrap()
      .then(() => {
        setPendingRequest(null);

        setAlert({
          message: t("notifications:entitySentSuccessfully", {
            entity: t("verifyPhonePage:verificationCode"),
          }),
          severity: "success",
        });
      })
      .catch((error) => {
        setPendingRequest(null);

        setAlert({
          message: `${t("notifications:entityFailedToBeSent", {
            entity: t("verifyPhonePage:verificationCode"),
          })} ${error?.response?.data?.detail ?? ""}`,
          severity: "error",
        });
      });
  };

  return (
    <LoadingButton
      fullWidth
      variant="text"
      loading={isLoading}
      disabled={isDisabled}
      onClick={requestNewOtp}
      size="medium"
    >
      {t("verifyPhonePage:resendVerificationCode")}
    </LoadingButton>
  );
};

export default ResendVerificationCodeButton;
