import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { isFulfilled, isRejected } from "@reduxjs/toolkit";
import { KUWAIT, getCountryPhonePrefixFromCountryCode } from "assets/constants/countries";
import PasswordField from "components/PasswordField";
import PhoneField from "components/PhoneField";
import TermsAndConditions from "components/TermsAndConditions";
import convertEmailToUsername from "helpers/convertEmailToUsername";
import getErrorDetailTranslationKey from "helpers/errors/getErrorDetailTranslationKey";
import useFormValidation from "hooks/useFormValidation";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { RegisterArguments } from "store/slices/auth/authApi";
import { register as registerUser } from "store/slices/auth/authSlice";
import { trackEvent } from "tracking";
import { isAxiosErrorOfResponseType } from "types/helpers";
import * as yup from "yup";

const registerFormSchema = yup.object({
  firstName: yup.string().max(255).required().trim(),
  lastName: yup.string().max(255).trim(),

  emailAddress: yup.string().email().required().trim(),
  password: yup.string().min(6).max(255).required(),
  confirmPassword: yup.string().equalsTo("password", "passwordsMustMatch").required(),

  agreeToTerms: yup.bool().isTrue("mustAgreeToTerms").required(),

  countryCode: yup.string().countryCode().required().trim(),
  phoneNumber: yup.string().phoneNumber("countryCode").required().trim(),
});

export type RegisterFormInputs = yup.InferType<typeof registerFormSchema>;

export default function RegisterForm() {
  const { t } = useTranslation(["auth", "registerPage", "misc", "errors"]);

  const organization = useSelector(selectOrganization);

  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formMethods = useForm<RegisterFormInputs>({
    resolver: yupResolver(registerFormSchema),

    defaultValues: { countryCode: KUWAIT.code, agreeToTerms: false },
  });

  const { register, formState, watch, setValue, handleSubmit, setError } = formMethods;

  const countryCode = watch("countryCode");
  const setCountryCode = (code: string) => setValue("countryCode", code);
  const registerValidation = useFormValidation(formState, { ns: "auth" });

  const submitFormAndGoToOtp = handleSubmit((data) => {
    const { emailAddress, countryCode, phoneNumber, ...restOfUSer } = data;

    const phoneCountryCode = `+${getCountryPhonePrefixFromCountryCode(countryCode)}`;

    const user: RegisterArguments["user"] = {
      username: convertEmailToUsername(emailAddress),
      usernameType: "OB",
      phoneCountryCode,
      phoneNumber: `${phoneCountryCode}${phoneNumber}`,
      ...restOfUSer,
    };

    setIsRequestPending(true);

    dispatch(registerUser({ user })).then((action) => {
      // TODO: this should be much shorter once errors are handled "automatically"
      if (isRejected(action)) {
        setIsRequestPending(false);

        if (isAxiosErrorOfResponseType(action.payload) && action.payload.response) {
          const responseData = action.payload.response.data;
          // Proposal
          // const djangoError = {
          //   fieldKey: "gender",
          //   errorTranslationKey: "invalidEntity",
          //   translationProperties: { entity: "gender" },
          // };
          // const { fieldKey, errorTranslationKey, translationProperties } = djangoError;
          // setError(fieldKey, t(errorTranslationKey, translationProperties));

          // TODO: fix this typescript shit - ANFAL
          // Loop over response data object excluding detail and setError for each
          if (!responseData.detail)
            Object.entries(responseData).forEach(([field, errors]) => {
              const fieldKey = field as keyof RegisterFormInputs;
              const isErrorAnArray = typeof errors !== "string";

              if (isErrorAnArray) {
                // error is an array of strings
                const errorMessages = errors.join("\n");

                setError(fieldKey, {
                  type: "custom",
                  message: errorMessages,
                });
              }
            });

          // check for specific detail errors and handle them manually
          const errorMessageTranslationKey = getErrorDetailTranslationKey(responseData.detail);

          if (responseData.detail === "Email already exists") {
            setError("emailAddress", {
              type: "custom",
              message: t(errorMessageTranslationKey),
            });
          }
        }
      }

      if (isFulfilled(action)) {
        navigate("/verify-phone-number");
        if (organization?.id && organization?.name)
          trackEvent(
            "Sign up Completed",
            { id: organization.id, name: organization.name },
            {
              Email: emailAddress,
            }
          );
      }
    });
  });

  const showTermsAndConditions = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setOpenTermsAndConditions(true);
  };

  const hasAgreed = watch("agreeToTerms");
  const agreeToTermsValidation = registerValidation("agreeToTerms");

  return (
    <FormProvider {...formMethods}>
      <Grid item xs={12} container spacing={3} component="form" onSubmit={submitFormAndGoToOtp}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("firstName")}
            placeholder={t("firstNamePlaceholder")}
            {...register("firstName")}
            {...registerValidation("firstName")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("lastName")}
            placeholder={t("lastNamePlaceholder")}
            {...register("lastName")}
            {...registerValidation("lastName")}
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label={t("emailAddress")}
            placeholder={t("emailAddressPlaceholder")}
            {...register("emailAddress")}
            {...registerValidation("emailAddress")}
          />
        </Grid>

        <Grid item xs={12}>
          <PhoneField
            fullWidth
            label={t("auth:phoneNumber")}
            placeholder={t("auth:phoneNumberPlaceholder")}
            {...register("phoneNumber")}
            {...registerValidation("phoneNumber")}
            CountryCodeAutocompleteProps={{ value: countryCode, onChange: setCountryCode }}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordField
            fullWidth
            label={t("password")}
            placeholder={t("passwordPlaceholder")}
            {...register("password")}
            {...registerValidation("password")}
          />
        </Grid>

        <Grid item xs={12}>
          <PasswordField
            fullWidth
            label={t("confirmPassword")}
            placeholder={t("confirmPasswordPlaceholder")}
            {...register("confirmPassword")}
            {...registerValidation("confirmPassword")}
          />
        </Grid>

        <Grid item xs={12}>
          <FormControl fullWidth>
            <FormControlLabel
              control={<Checkbox checked={hasAgreed} {...register("agreeToTerms")} />}
              label={
                <Typography variant="h5" sx={{ a: { fontWeight: 700 } }}>
                  {t("agree")}{" "}
                  <Typography
                    variant="h4"
                    component="span"
                    color="primary"
                    onClick={showTermsAndConditions}
                  >
                    {t("misc:termsAndConditions")}
                  </Typography>
                </Typography>
              }
            />

            {agreeToTermsValidation.error && (
              <FormHelperText error>{agreeToTermsValidation.helperText}</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12}>
          <LoadingButton fullWidth type="submit" loading={isRequestPending} size="medium">
            {t("registerPage:createAccount")}
          </LoadingButton>
        </Grid>

        <TermsAndConditions
          open={openTermsAndConditions}
          onClose={() => setOpenTermsAndConditions(false)}
        />
      </Grid>
    </FormProvider>
  );
}
