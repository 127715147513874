import { Box, Divider, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import fromTofyTimes from "helpers/datetime/fromTofyTimes";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectEmployeeById, selectServiceById, selectServiceVariantById } from "store/selectors";

import SelectedEmployee from "./SelectedEmployee";

type Slot = {
  serviceVariant: number;
  start: string;
  end: string;
  staff: number | undefined | null;
};

const ServiceVariantDetails = ({
  slot,
  index,
  isStaffSelectedByClient = true,
}: {
  slot: Slot;
  index: number;
  isStaffSelectedByClient?: boolean;
}) => {
  const { t } = useTranslation();
  const serviceVariant = useSelector(selectServiceVariantById(slot.serviceVariant));
  const service = useSelector(selectServiceById(serviceVariant?.service || ""));
  const employee = useSelector(selectEmployeeById(slot.staff || ""));

  if (!service || !serviceVariant) return <CentredSpinnerBox />;

  const shouldShowServiceVariantName = service.name !== serviceVariant.name;

  const shouldShowSelectedEmployee = !!employee && isStaffSelectedByClient;

  return (
    <>
      <Divider variant="fullWidth" />

      <Box
        width="100%"
        display="flex"
        flexDirection="column"
        rowGap={2}
        gap={2}
        px={2}
        pt={2}
        pb={2}
      >
        <Typography variant="h4" fontWeight={500}>
          {index} {t(service.t.name)}
        </Typography>

        <Typography variant="primary">
          {shouldShowServiceVariantName && t(serviceVariant.t.name)} •{" "}
          {getFormattedDuration(serviceVariant.duration)}
        </Typography>

        <Typography variant="primary">
          {`${fromTofyTimes(DateTime.fromISO(slot.start), DateTime.fromISO(slot.end))}`}
        </Typography>

        {shouldShowSelectedEmployee && <SelectedEmployee employee={employee} />}
      </Box>
    </>
  );
};

export default ServiceVariantDetails;
