import { createSvgIcon } from "@mui/material";

export const Arrived = createSvgIcon(
  <>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#EAAA39"
    />
    <path
      d="M16.6671 10.0003C16.6671 9.38751 16.5463 8.78067 16.3119 8.21449C16.0773 7.6483 15.7336 7.13385 15.3003 6.70051C14.8669 6.26718 14.3524 5.92342 13.7863 5.68891C13.2201 5.45439 12.6132 5.33368 12.0004 5.33368C11.3876 5.33368 10.7807 5.45439 10.2146 5.68891C9.64836 5.92342 9.13392 6.26718 8.70057 6.70051C8.26724 7.13385 7.92349 7.6483 7.68897 8.21449C7.45444 8.78067 7.33374 9.38751 7.33374 10.0003C7.33374 10.925 7.60707 11.785 8.07041 12.5103H8.06508C9.6384 14.9737 12.0004 18.667 12.0004 18.667L15.9357 12.5103H15.931C16.4113 11.7613 16.6668 10.8902 16.6671 10.0003ZM12.0004 12.0003C11.47 12.0003 10.9613 11.7896 10.5862 11.4146C10.2111 11.0395 10.0004 10.5308 10.0004 10.0003C10.0004 9.46992 10.2111 8.96121 10.5862 8.58613C10.9613 8.21106 11.47 8.00034 12.0004 8.00034C12.5308 8.00034 13.0395 8.21106 13.4146 8.58613C13.7897 8.96121 14.0004 9.46992 14.0004 10.0003C14.0004 10.5308 13.7897 11.0395 13.4146 11.4146C13.0395 11.7896 12.5308 12.0003 12.0004 12.0003Z"
      fill="white"
    />
  </>,
  "Arrived"
);

export const Started = createSvgIcon(
  <>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#53BFB0"
    />
    <path d="M9.24975 7.50018V16.5002L16.7498 12.0002L9.24975 7.50018Z" fill="white" />
  </>,
  "Started"
);

export const NoShow = createSvgIcon(
  <>
    <path
      d="M0 12C0 5.76242 5.37258 0.705872 12 0.705872C18.6274 0.705872 24 5.76242 24 12C24 18.2376 18.6274 23.2941 12 23.2941C5.37258 23.2941 0 18.2376 0 12Z"
      fill="#4E5D78"
    />
    <path
      d="M12.1256 15.3354C11.8826 15.3358 11.6418 15.291 11.4174 15.2033C11.1929 15.1156 10.9891 14.9869 10.8178 14.8245L5.49024 9.81228L6.67647 8.69582L12.002 13.71C12.0351 13.7396 12.0789 13.7561 12.1246 13.7561C12.1702 13.7561 12.2141 13.7396 12.2472 13.71L17.5748 8.69582L18.761 9.81228L13.4334 14.8245C13.2621 14.9869 13.0584 15.1156 12.8339 15.2033C12.6094 15.291 12.3687 15.3358 12.1256 15.3354Z"
      fill="white"
    />
    <path
      d="M6.67691 12.9696H5.00025V9.02453C5.00025 8.81527 5.08857 8.61458 5.24578 8.46661C5.403 8.31864 5.61624 8.23552 5.83857 8.23552H10.0302V9.81355H6.67691V12.9696Z"
      fill="white"
    />
  </>,
  "NoShow"
);

export const New = createSvgIcon(
  <>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#AD34E5"
    />
    <path
      d="M12.0002 15.8468L16.1202 18.3335L15.0268 13.6467L18.6669 10.4934L13.8735 10.0868L12.0002 5.66674L10.1268 10.0868L5.33349 10.4934L8.97349 13.6467L7.88016 18.3335L12.0002 15.8468Z"
      fill="white"
    />
  </>,
  "New"
);

export const Confirmed = createSvgIcon(
  <>
    <path d="M10 14.17L16.59 7.58002L18 9.00002L10 17L6 13L7.41 11.59L10 14.17Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
    />
  </>,
  "Confirmed"
);

export const Completed = createSvgIcon(
  <>
    <path
      d="M0.47998 12C0.47998 5.37258 5.63766 0 12 0C18.3623 0 23.52 5.37258 23.52 12C23.52 18.6274 18.3623 24 12 24C5.63766 24 0.47998 18.6274 0.47998 12Z"
      fill="#919AAB"
    />
    <path
      d="M17.52 8.64L9.12958 5.09H9.11038C9.01438 5.04 8.91838 5 8.80318 5C8.44798 5 8.15998 5.3 8.15998 5.67V18.67C8.15998 19.04 8.44798 19.34 8.80318 19.34C9.15838 19.34 9.44638 19.04 9.44638 18.67V12.73L17.5296 9.31C17.6544 9.26 17.7408 9.12 17.7408 8.98C17.7312 8.83 17.6544 8.7 17.52 8.64Z"
      fill="white"
    />
  </>,
  "Completed"
);

export const Cancel = createSvgIcon(
  <>
    <path
      d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
      fill="#F43319"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 12C4.5 7.86 7.86 4.5 12 4.5C16.14 4.5 19.5 7.86 19.5 12C19.5 16.14 16.14 19.5 12 19.5C7.86 19.5 4.5 16.14 4.5 12ZM6 12C6 15.3075 8.6925 18 12 18C15.3075 18 18 15.3075 18 12C18 8.6925 15.3075 6 12 6C8.6925 6 6 8.6925 6 12Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9998 13.0602L14.6514 15.7118L15.7121 14.6511L13.0605 11.9995L15.7121 9.34791L14.6514 8.28725L11.9998 10.9388L9.34812 8.28713L8.28746 9.34779L10.9392 11.9995L8.28744 14.6512L9.3481 15.7119L11.9998 13.0602Z"
      fill="white"
    />
  </>,
  "Cancel"
);
