export const calculateServiceDeposit = (service: Service, variant: ServiceVariant) => {
  if (service.depositPercentage !== null) {
    return Number(variant.price) * (service.depositPercentage / 100);
  }

  return 0;
};

export const calculatePackageDeposit = (pkg: Package, variant: PackageVariant) => {
  if (pkg.depositPercentage !== null) {
    return Number(variant.price) * (pkg.depositPercentage / 100);
  }

  return 0;
};

export const calculateDeposit = (depositPercentage: Nullable<number>, finalPrice: number) => {
  if (depositPercentage !== null) {
    return Number(finalPrice) * (depositPercentage / 100);
  }

  return 0;
};
