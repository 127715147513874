import { Grid, Typography } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectWallet } from "store/slices/wallet/walletSelectors";

import PaymentCard from "./PaymentCard";
import paymentMethods from "./paymentMethods";
import WalletPaymentCard from "./WalletPaymentCard";

type PaymentMethodsListProps = {
  paymentMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
  payablePrice: number;
};

const PaymentMethodsList = ({
  paymentMethod,
  setPaymentMethod,
  payablePrice,
}: PaymentMethodsListProps) => {
  const { t } = useTranslation("checkoutPage");

  const wallet = useSelector(selectWallet);
  const walletBalance = +(wallet.availableCredit || 0.0);

  const isThereEnoughWalletCredit = +walletBalance >= +payablePrice;

  return (
    <Grid container bgcolor="white" spacing={2} p={2}>
      <Grid item xs={12}>
        <Typography variant="h2">{t("howToPay")}</Typography>
      </Grid>

      {paymentMethods.map((pm) => (
        <Grid item xs={12} key={pm.name}>
          <PaymentCard
            paymentMethod={pm}
            selectedMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
        </Grid>
      ))}

      <Grid item xs={12}>
        <WalletPaymentCard
          isThereEnoughWalletCredit={isThereEnoughWalletCredit}
          setPaymentMethod={setPaymentMethod}
          paymentMethod={paymentMethod}
          walletBalance={walletBalance}
        />
      </Grid>
    </Grid>
  );
};

export default PaymentMethodsList;
