import { TIME_FORMAT } from "helpers/datetime/datetimeConstants";
import { DateTime } from "luxon";
import http from "utils/http";

import type { BookingSlot } from "../bookingPageTypes";

const MORNING_START_HOUR = 0;
const AFTERNOON_START_HOUR = 12;
const EVENING_START_HOUR = 18;

export function getSlotDateTime({ date, time }: BookingSlot) {
  return DateTime.fromISO(`${date}T${time}+03:00`);
}

export function getSlotLabel(slot: BookingSlot) {
  return getSlotDateTime(slot).toFormat(TIME_FORMAT);
}

export function isMorningSlot(slot: BookingSlot) {
  const datetime = getSlotDateTime(slot);
  const slotHour = datetime.hour;

  return slotHour >= MORNING_START_HOUR && slotHour < AFTERNOON_START_HOUR;
}

export function isAfternoonSlot(slot: BookingSlot) {
  const datetime = getSlotDateTime(slot);
  const slotHour = datetime.hour;

  return slotHour >= AFTERNOON_START_HOUR && slotHour < EVENING_START_HOUR;
}

export function pushSlotToPeriod(period: BookingSlot[][], slot: BookingSlot) {
  const lastPage = period[period.length - 1];

  if (lastPage.length === 6) period.push([slot]);
  else lastPage.push(slot);
}

// Thought: The apis could be combined on frontend into one, which takes "variantId" and
// then takes "variantType" which could be service or package

type ApiServiceSlot = {
  date: string;
  time: string;
  employees: number[];
};

type ApiPackageSlot = { [serviceVariantId: string]: ApiServiceSlot };

// Thought: api could return startDatetime and endDatetime instead of whatever it does now (date and start)
// Slots technically have an endDatetime they just don't display it on frontend, this way we remove "date"
// which would allow custom timezones and frikin overnight slots!!
export const slotsApi = {
  getServiceSlots: async (params: { serviceVariantId: number; date: string; outletId: number }) =>
    http.get<ApiServiceSlot[]>("ob/organizations/service_availability/", {
      params: {
        date: params.date,
        outlet: params.outletId,
        service_variant: params.serviceVariantId,
      },
    }),

  getPackageSlots: async (params: { packageVariantId: number; date: string; outletId: number }) =>
    http.get<ApiPackageSlot[]>("ob/organizations/package_availability/", {
      params: {
        date: params.date,
        outlet: params.outletId,
        package_variant: params.packageVariantId,
      },
    }),
};
