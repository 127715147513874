import { RootState } from "store";

import { serviceAdapter } from "./servicesSlice";

const selectServicesState = (state: RootState) => state.services;

const { selectById, selectAll } = serviceAdapter.getSelectors(selectServicesState);

export const selectServiceById = (id: string | number) => (state: RootState) =>
  selectById(state, id);

export const selectAllServices = (state: RootState) =>
  selectAll(state).sort((a, b) => (a.position || 0) - (b.position || 0));

export const selectServicesByCategoryIdAndOutletIdAndEmployeeId =
  (categoryId: number, outletId: number, employeeId?: number) => (state: RootState) => {
    let services = selectAllServices(state).filter(
      (service) => service && service.category === categoryId && service.outlets.includes(outletId)
    );

    if (employeeId) {
      const employee = state.employees.entities[employeeId];
      if (employee) services = services.filter((service) => employee.services.includes(service.id));
    }

    return services;
  };

export const selectServicesByOutletId =
  (outletId: number, employeeId?: number) => (state: RootState) => {
    let services = selectAllServices(state).filter(
      (service) => service && service.outlets.includes(outletId)
    );

    if (employeeId) {
      const employee = state.employees.entities[employeeId];
      if (employee) services = services.filter((service) => employee.services.includes(service.id));
    }
    return services;
  };

export const selectPopularServicesByOutletIdAndEmployeeId =
  (outletId: number, employeeId?: number) => (state: RootState) => {
    let services = selectAllServices(state).filter(
      (service) => service && service.popular && service.outlets.includes(outletId)
    );

    if (employeeId) {
      const employee = state.employees.entities[employeeId];
      if (employee) services = services.filter((service) => employee.services.includes(service.id));
    }

    return services;
  };
