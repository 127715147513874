import { LoadingButton } from "@mui/lab";
import type { AlertOptions } from "hooks/useAlertBox";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { selectAuthUser, selectOrganization } from "store/selectors";
import { verifyOtp } from "store/slices/auth/authSlice";
import { trackEvent } from "tracking";
import { notify } from "utils/SnackbarProvider";

import type { PendingRequest } from "./VerifyPhonePage";

type ActivateAccountButtonProps = {
  otp: string;

  setAlert: (alert: AlertOptions) => void;

  isLoading: boolean;
  isDisabled: boolean;

  setPendingRequest: (pendingRequest: PendingRequest | null) => void;
};

const ActivateAccountButton = ({
  otp,
  setAlert,
  isLoading,
  isDisabled,
  setPendingRequest,
}: ActivateAccountButtonProps) => {
  const { t } = useTranslation(["verifyPhonePage", "notifications", "auth"]);

  const organization = useSelector(selectOrganization);
  const user = useSelector(selectAuthUser);

  const dispatch = useDispatch();

  const handleActivatePhoneNumber = () => {
    setPendingRequest("activate");

    dispatch(verifyOtp({ code: otp }))
      .unwrap()
      .then(() => {
        const message = t("notifications:entitySuccessfullyActivated", {
          entity: t("auth:phoneNumber"),
        });

        notify(message, "success", { shouldPersist: false });

        if (organization?.id && organization?.name && user)
          trackEvent(
            "Verification Completed",
            { id: organization.id, name: organization.name },
            {
              Email: user?.email,
            }
          );

        // the AuthenticationRedirector will redirect to the correct page
      })
      .catch((error) => {
        setPendingRequest(null);

        setAlert({
          message: `${t("notifications:entityFailedToBeActivated", {
            entity: t("verifyPhonePage:account"),
          })} ${error?.response?.data?.detail ?? ""}`,
          severity: "error",
        });
      });
  };

  return (
    <LoadingButton
      fullWidth
      loading={isLoading}
      disabled={isDisabled}
      onClick={handleActivatePhoneNumber}
      size="medium"
    >
      {t("verifyPhonePage:activateAccount")}
    </LoadingButton>
  );
};

export default ActivateAccountButton;
