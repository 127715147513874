import { Grid } from "@mui/material";

import NoResults from "./NoResults";
import PurchasableCard from "./PurchasableCard";

const ServiceSearchResult = ({ filteredServices }: { filteredServices: Service[] }) => {
  const isListEmpty = !filteredServices.length;

  return isListEmpty ? (
    <NoResults />
  ) : (
    <>
      {filteredServices.map((service) => (
        <Grid key={service?.id ?? 0} item xs={12}>
          <PurchasableCard serviceId={service?.id ?? 0} />
        </Grid>
      ))}
    </>
  );
};
export default ServiceSearchResult;
