import { Box, ButtonBase, Avatar as MuiAvatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import bannerPlaceholder from "assets/images/PlaceholderBackground.svg";
import useEmblaCarousel from "embla-carousel-react";
import { getImageSrc } from "helpers/getImageSrc";
import dontForwardProps from "helpers/styles/dontForwardProps";
import useLanguageDirection from "hooks/useLanguageDirection";
import { memo, useCallback, useEffect, useState } from "react";

type OutletBannerProps = {
  avatar?: Organization["avatar"];
  banners?: Outlet["images"];
  height?: string;
};

const OutletBanner = ({ avatar, banners, height = "250px" }: OutletBannerProps) => {
  const languageDirection = useLanguageDirection();

  const [emblaRef, embla] = useEmblaCarousel({
    loop: true,
    draggable: !!banners,
    direction: languageDirection,
  });

  const scrollTo = useCallback(
    (bannerIndex: number) => () => embla && embla.scrollTo(bannerIndex),
    [embla]
  );

  const [selectedBanner, setSelectedBanner] = useState(0);

  const onSelect = useCallback(() => {
    if (!embla) return;

    setSelectedBanner(embla.selectedScrollSnap());
  }, [embla, setSelectedBanner]);

  useEffect(() => {
    if (!embla) return;

    onSelect();
    embla.on("select", onSelect);
  }, [embla, onSelect]);

  return (
    <SlideWrapper ref={emblaRef}>
      <Box display="flex" height={height} bgcolor="white">
        {banners ? (
          banners.map((banner) => <Slide key={banner.id} image={getImageSrc(banner.image)} />)
        ) : (
          <Slide key="placeholder" image={bannerPlaceholder} />
        )}
      </Box>

      <Box position="relative">
        {avatar && <Avatar src={getImageSrc(avatar)} />}

        <DotsWrapper>
          {banners?.map((banner, index) => (
            <DotButton
              sx={{
                opacity: index === selectedBanner ? "100%" : "50%",
              }}
              key={banner.id}
              onClick={scrollTo(index)}
            />
          ))}
        </DotsWrapper>
      </Box>
    </SlideWrapper>
  );
};

export default memo(OutletBanner);

const Slide = styled(
  Box,
  dontForwardProps("image")
)(({ image }: { image: string }) => ({
  position: "relative",
  flex: "0 0 100%",

  backgroundImage: `url(${image})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  boxShadow: "inset 0 -150px 200px -150px rgba(0,0,0,0.7)",
}));

const Avatar = styled(MuiAvatar)(({ theme }) => ({
  position: "absolute",
  border: "2px solid",
  borderColor: theme.palette.common.white,
  left: "17px",
  bottom: "8px",
  width: "80px",
  height: "80px",
}));

const SlideWrapper = styled(Box)(({ theme }) => ({
  overflow: "hidden",
  width: "100%",
  // borderRadius: "0px 0px 16px 16px",
}));

const DotsWrapper = styled(Box)({
  position: "absolute",
  display: "flex",
  bottom: "18px",
  width: "100%",
  justifyContent: "center",
  gap: "12px",
});

const DotButton = styled(ButtonBase)(({ theme }) => ({
  padding: "4px",
  borderRadius: "50%",
  minWidth: "0px",
  backgroundColor: theme.palette.common.white,
}));
