import { Grid } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import useAlertBox from "hooks/useAlertBox";
import AuthenticationPageLayout from "layouts/AuthenticationPageLayout";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectAuthIsLoading, selectIsUserPhoneVerified } from "store/selectors";

import ChangePasswordSection from "./ChangePasswordSection";
import EditProfileDetailsForm from "./EditProfileDetailsForm";

const EditUserDetailsPage = () => {
  const { t } = useTranslation("editUserProfilePage");

  const { AlertBox, setAlert } = useAlertBox();

  const isPhoneVerified = useSelector(selectIsUserPhoneVerified);

  const isAuthLoading = useSelector(selectAuthIsLoading);

  useEffect(() => {
    if (!isPhoneVerified) {
      const message = t("youNeedToVerifyYouPhoneToBeAbleToBook");

      setAlert({ message, severity: "warning" });
    }
  }, [isPhoneVerified, setAlert, t]);

  if (isAuthLoading) return <CentredSpinnerBox />;

  return (
    <AuthenticationPageLayout
      title={t("editUserProfilePage:title")}
      subtitle={t("editUserProfilePage:subtitle")}
      footer={<ChangePasswordSection />}
      shouldShowPrivacyDisclaimer={false}
    >
      <Grid item xs={12}>
        <AlertBox />
      </Grid>

      <EditProfileDetailsForm />
    </AuthenticationPageLayout>
  );
};

export default EditUserDetailsPage;
