import { DateTime } from "luxon";
import type { RootState } from "store";
import { AppointmentStatus } from "types/AppointmentStatus";

import { SliceStatus } from "../utils";
import { appointmentAdapter } from "./appointmentsSlice";

export const selectAppointmentsState = (state: RootState) => state.appointments;

const { selectById, selectAll } = appointmentAdapter.getSelectors(selectAppointmentsState);

export const selectAllAppointments = selectAll;

export const selectAppointmentIsLoading = (state: RootState) =>
  state.appointments.status === SliceStatus.LOADING;

export const selectAppointmentById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

// we need to filter out the appointments that their end time has passed the current time
export const selectUpcomingAppointments = (state: RootState) =>
  selectAll(state).filter((appointment) => {
    const currentTime = DateTime.local();
    const appointmentEndTime = DateTime.fromISO(appointment.appointmentTimeRange.endTime, {
      zone: "utc",
    });

    return (
      appointmentEndTime > currentTime &&
      appointment.status !== AppointmentStatus.Cancelled &&
      appointment.status !== AppointmentStatus.Completed &&
      appointment.status !== AppointmentStatus.NoShow
    );
  });

export const selectPastAppointments = (state: RootState) =>
  selectAll(state).filter((appointment) => {
    const currentTime = DateTime.local();
    const appointmentEndTime = DateTime.fromISO(appointment.appointmentTimeRange.endTime, {
      zone: "utc",
    });

    return (
      currentTime > appointmentEndTime ||
      appointment.status === AppointmentStatus.Cancelled ||
      appointment.status === AppointmentStatus.Completed ||
      appointment.status === AppointmentStatus.NoShow
    );
  });
