import { Box, Button, styled } from "@mui/material";
import DrawerModal from "components/DrawerModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSelector } from "store";
import {
  selectPackageByPackageVariantId,
  selectServicesByServiceVariantIds,
  selectSubscriptionPurchaseById,
} from "store/selectors";

import OutletRadio from "./OutletRadio";

type SubscriptionOutletListModalProps = {
  subscriptionPurchaseId: number;
  open: boolean;
  onClose: () => void;
};

const SubscriptionOutletListModal = ({
  subscriptionPurchaseId,
  open,
  onClose,
}: SubscriptionOutletListModalProps) => {
  const { t } = useTranslation("userSubscriptionsPage");

  const [checkedOutletSlug, setCheckedOutletSlug] = useState<Nullable<string>>(null);

  const navigate = useNavigate();

  const subscriptionPurchase = useSelector(selectSubscriptionPurchaseById(subscriptionPurchaseId));

  const pkg = useSelector(
    selectPackageByPackageVariantId(subscriptionPurchase?.packageVariant || 0)
  );

  const services = useSelector(
    selectServicesByServiceVariantIds(subscriptionPurchase?.serviceVariants || [])
  );

  const selectCommonOutletsAmongServices = () => {
    if (services.length === 0) {
      return [];
    }

    // Get outlets from the first service
    const firstServiceOutlets = services[0]?.outlets || [];

    // Use Array.reduce to find the common outlets among all services
    const commonOutlets = services.slice(1).reduce((intersection, service) => {
      const serviceOutlets = service?.outlets || [];
      return intersection.filter((outlet) => serviceOutlets.includes(outlet));
    }, firstServiceOutlets);

    return commonOutlets;
  };

  const itemOutlets = pkg?.outlets || selectCommonOutletsAmongServices() || [];

  const outlets = subscriptionPurchase?.outlets || [];

  const setOutletAndHideModal = () => {
    onClose();

    navigate(
      subscriptionPurchase?.serviceVariants
        ? `/${checkedOutletSlug}/book?s=${subscriptionPurchase?.serviceVariants[0]}&r=${subscriptionPurchaseId}`
        : `/${checkedOutletSlug}/book?p=${subscriptionPurchase?.packageVariant}&r=${subscriptionPurchaseId}`
    );
  };

  return (
    <DrawerModal
      title={t("selectALocation")}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "80vh", padding: "16px" } }}
    >
      {outlets.map((outlet) => (
        <OutletRadio
          key={outlet}
          outletId={outlet}
          checkedOutletSlug={checkedOutletSlug}
          onClick={(outletSlug: string) => setCheckedOutletSlug(outletSlug)}
          disabled={!itemOutlets.includes(outlet)}
          disabledText={t("itemNotAvailableInThisLocation", {
            item: services ? t("service") : t("package"),
          })}
        />
      ))}

      <ModalAction>
        <Button
          fullWidth
          onClick={setOutletAndHideModal}
          size="medium"
          disabled={!checkedOutletSlug}
        >
          {t("checkAvailability")}
        </Button>
      </ModalAction>
    </DrawerModal>
  );
};
export default SubscriptionOutletListModal;

const ModalAction = styled(Box)(() => ({
  position: "absolute",
  width: "calc(100% - 32px)",
  bottom: "16px",
}));
