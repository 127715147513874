import { createSvgIcon } from "@mui/material";

export const PackageIcon = createSvgIcon(
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="32" height="32" rx="8" fill="url(#paint0_linear_2156_10611)" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66797 11.3332C8.66797 10.965 8.96645 10.6665 9.33464 10.6665H14.0013C14.1781 10.6665 14.3477 10.7367 14.4727 10.8618L18.666 15.0551C19.5531 15.9421 19.5531 17.3909 18.666 18.2779L16.2794 20.6646C15.3924 21.5516 13.9436 21.5516 13.0566 20.6646L8.86323 16.4712C8.73821 16.3462 8.66797 16.1766 8.66797 15.9998V11.3332ZM10.0013 11.9998V15.7237L13.9994 19.7218C14.3657 20.0881 14.9702 20.0881 15.3366 19.7218L17.7232 17.3351C18.0895 16.9688 18.0895 16.3642 17.7232 15.9979L13.7252 11.9998H10.0013Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9987 13.3335C12.3669 13.3335 12.6654 13.632 12.6654 14.0002V14.0068C12.6654 14.375 12.3669 14.6735 11.9987 14.6735C11.6305 14.6735 11.332 14.375 11.332 14.0068V14.0002C11.332 13.632 11.6305 13.3335 11.9987 13.3335Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.5273 10.8618C17.7876 10.6014 18.2098 10.6014 18.4701 10.8618L22.6693 15.0609C22.6692 15.0608 22.6694 15.0611 22.6693 15.0609C22.8804 15.2714 23.0483 15.5219 23.1627 15.7973C23.2771 16.0728 23.336 16.3682 23.336 16.6665C23.336 16.9648 23.2771 17.2602 23.1627 17.5357C23.0484 17.811 22.8809 18.0611 22.6698 18.2716C22.6696 18.2717 22.6695 18.2719 22.6693 18.2721L19.8034 21.1379C19.5431 21.3983 19.121 21.3983 18.8606 21.1379C18.6003 20.8776 18.6003 20.4554 18.8606 20.1951L21.7281 17.3276C21.8151 17.2409 21.8842 17.1379 21.9313 17.0244C21.9784 16.911 22.0026 16.7893 22.0026 16.6665C22.0026 16.5437 21.9784 16.422 21.9313 16.3086C21.8842 16.1951 21.8151 16.0921 21.7281 16.0054L17.5273 11.8046C17.2669 11.5442 17.2669 11.1221 17.5273 10.8618Z"
      fill="white"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2156_10611"
        x1="0"
        y1="0"
        x2="79.2"
        y2="83.2"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6842B3" />
        <stop offset="1" stop-color="white" />
      </linearGradient>
    </defs>
  </svg>,
  "Package"
);
