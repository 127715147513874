import { Box, Button, Typography } from "@mui/material";
import { CancellationPolicyIcon } from "assets/icons/CancellationPolicy";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";

import CancellationPolicyModal from "./CancellationPolicyModal";

const CancellationPolicySection = () => {
  const { t } = useTranslation("common");

  const organization = useSelector(selectOrganization);

  const [isCancellationPolicyModalOpen, setIsCancellationPolicyModalOpen] =
    useState<boolean>(false);

  return (
    <>
      <CancellationPolicyModal
        open={isCancellationPolicyModalOpen}
        onClose={() => setIsCancellationPolicyModalOpen(false)}
        cancellationTimeFrame={organization.cancellationTimeFrame}
        isCancellationAllowed={organization.allowCancellation}
        orgName={organization.name}
      />

      <Box display="flex" width="100%" bgcolor="white" p={2} mt={1} borderRadius="20px">
        <Button
          size="small"
          fullWidth
          variant="outlined"
          color="secondary"
          onClick={() => setIsCancellationPolicyModalOpen(true)}
          sx={{ padding: 2, borderRadius: "25px" }}
        >
          <Box display="flex" mt={1.7}>
            <CancellationPolicyIcon
              sx={{
                fontSize: 34,
              }}
            />
          </Box>

          <Typography variant="h4" fontWeight={600} pl={1}>
            {t("cancellationPolicy")}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default CancellationPolicySection;
