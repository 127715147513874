import { Avatar, Box, Button, Link, Stack, Typography, styled } from "@mui/material";
import { getImageSrc } from "helpers/getImageSrc";
import { getUseAppLink } from "helpers/getMarketplaceAppLink";
import { useDeviceDetection } from "hooks/useDeviceDetection";
import OutletBanner from "pages/OutletPage/OutletBanner";
import { useMemo } from "react";
import { useSelector } from "store";
import { selectAllOutlets, selectOrganization } from "store/selectors";

const ForceDownloadApp = () => {
  const outlets = useSelector(selectAllOutlets);
  const { isAndroidDevice } = useDeviceDetection();

  const { avatar, name, appLink: organizationAppLink } = useSelector(selectOrganization);

  const appLink = useMemo(
    () => organizationAppLink || getUseAppLink(isAndroidDevice),
    [isAndroidDevice, organizationAppLink]
  );

  if (!outlets.length) return null;
  const outlet = outlets[0];

  return (
    <Box display="flex" flexDirection="column" height="100vh">
      <Box>
        <OutletBanner
          height="40vh"
          banners={[{ id: 0, image: outlet?.primaryImage, outlet: outlet?.id }, ...outlet?.images]}
        />
      </Box>
      <Box
        bgcolor="white"
        display="flex"
        flexDirection="column"
        alignItems="center"
        height="65vh"
        marginTop="-5vh"
        zIndex={999}
        sx={{ borderTopRightRadius: "20px", borderTopLeftRadius: "20px" }}
      >
        <Box
          marginTop="-50px"
          display="flex"
          alignItems="center"
          justifyContent="center"
          p={2}
          width="100%"
        >
          {avatar && (
            <OrgAvatar
              sx={{ width: "80px", height: "80px", zIndex: 999 }}
              src={getImageSrc(avatar)}
            />
          )}
        </Box>

        <img width="120px" alt="download-app" src="/images/bookpeepApp.png" />

        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={2}
          width="100%"
        >
          <Typography align="center" fontSize="28px" fontWeight={700}>
            Enjoy a special discount on everything <br></br> only on the Bookpeep App
          </Typography>

          <Typography align="center" fontSize="16px" fontWeight={500} color="#53627C" mt={1}>
            Book your appointment with {name}
          </Typography>
        </Box>

        <Stack direction="row" gap={0.5} alignItems="center" justifyContent="center">
          <Link href={appLink} underline="none">
            <img
              alt="download-on-app-store"
              src="/images/DownloadAppOnAppStore.svg"
              height="42px"
            />
          </Link>

          <Link href={appLink} underline="none">
            <img
              alt="download-on-google-play"
              src="/images/DownloadAppOnGooglePlay.svg"
              height="42px"
            />
          </Link>
        </Stack>

        <Box display="flex" flexDirection="column" alignItems="center" py={5} px={3} width="310px">
          <Button
            sx={{
              fontWeight: "bold",
              borderRadius: "20px",
            }}
            fullWidth
            LinkComponent={Link}
            href={appLink}
            variant="contained"
          >
            Download the app now
          </Button>
        </Box>

        {/* <Box
          display="flex"
          width="100%"
          bgcolor="#EAEBFA"
          borderRadius={100}
          height="50vh"
          position="fixed"
          bottom={0}
          zIndex={-100}
          marginBottom="-17vh"
          sx={{ opacity: 0.5 }}
        ></Box> */}
      </Box>
    </Box>
  );
};

export default ForceDownloadApp;

const OrgAvatar = styled(Avatar)(({ theme }) => ({
  border: "2px solid",
  borderColor: theme.palette.common.white,
}));
