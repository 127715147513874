import { Box, Divider, Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import _ from "lodash";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectWallet, selectWalletRequestIsLoading } from "store/slices/wallet/walletSelectors";

import WalletTransactionCard from "./WalletTransactionCard";

const WalletPage = () => {
  const { t } = useTranslation(["walletPage", "common"]);
  const isWalletRequestLoading = useSelector(selectWalletRequestIsLoading);
  const wallet = useSelector(selectWallet);

  if (isWalletRequestLoading) return <CentredSpinnerBox />;

  const groupedTransactions = _.groupBy(wallet.transactions, ({ createdAt }) =>
    DateTime.fromISO(createdAt).toFormat("LLLL yyyy")
  );

  return (
    <Box height="100%" bgcolor="bg.secondary">
      <Grid container spacing={3} justifyContent="center">
        <Grid item xs={12}>
          <Box
            bgcolor="text.secondary"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="10.5rem"
            alignItems="center"
            sx={{
              borderBottomRightRadius: "20px",
              borderBottomLeftRadius: "20px",
            }}
          >
            <Typography color="white" variant="primary">
              {t("availableCredit")}
            </Typography>

            <PriceBox
              value={Number(wallet.availableCredit) || 0.0}
              variant="h1"
              sx={{ color: "white" }}
              textSx={{ fontSize: "2.25rem" }}
            />
          </Box>
        </Grid>

        <Grid item xs={11}>
          <Typography variant="h4">{t("recentTransactions")}</Typography>
        </Grid>

        {Object.keys(groupedTransactions).map((month: string) => (
          <Grid key={month} item xs={11} container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="primary">{month}</Typography>
            </Grid>

            {groupedTransactions[month].map((transaction) => (
              <Grid key={transaction.id} item xs={12}>
                <WalletTransactionCard transaction={transaction} />
              </Grid>
            ))}

            <Grid item xs={12}>
              <Divider variant="fullWidth" />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default WalletPage;
