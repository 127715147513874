import { KeysMatching } from "types/helpers";

type SortAlphabetically = <E extends Record<string, any>>(
  keyToSortBy: KeysMatching<E, string>
) => (a: E, b: E) => number;

/**
 * This is a helper function meant to be used inside a sort function to sort an array of objects alphabetically by a given key.
 *
 * @param keyToSortBy The key to sort by.
 * @returns A function that can be used to sort an array of objects alphabetically by the given key.
 */

const sortAlphabetically: SortAlphabetically = (keyToSortBy) => (a, b) =>
  a[keyToSortBy].localeCompare(b[keyToSortBy]);

export default sortAlphabetically;
