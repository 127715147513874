import { Box, Button, Stack, Typography } from "@mui/material";
import { PackageIcon } from "assets/icons/Package";
import { ServiceIcon } from "assets/icons/Service";
import { TermsAndConditionsIcon } from "assets/icons/TermsAndConditions";
import DrawerModal from "components/DrawerModal";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { getUniqueCartArticlesWithDetails } from "store/selectors";

type TermsAndConditionsModalProps = {
  open: boolean;
  onClose: () => void;
  handleNextStep: () => void;
};

const TermsAndConditionsModal = ({
  open,
  onClose,
  handleNextStep,
}: TermsAndConditionsModalProps) => {
  const { t } = useTranslation("common");

  const uniqueArticlesWithDetails = useSelector(getUniqueCartArticlesWithDetails);

  const title = (
    <Box display="flex" mt={1} gap={1}>
      <TermsAndConditionsIcon />

      <Typography variant="h3">{t("termsAndConditions")}</Typography>
    </Box>
  );

  return (
    <DrawerModal
      title={title}
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { minHeight: "60vh", maxHeight: "60vh", px: "16px", pt: 2 } }}
    >
      <Stack gap={3} flexGrow={1} overflow="scroll" px={2} py={3}>
        {uniqueArticlesWithDetails.map(
          (article) =>
            article.termsAndConditions && (
              <Box key={article.id}>
                <Box p={2} bgcolor="#FAFAFA" borderRadius={2}>
                  <Box display="flex" alignItems="center" gap={1.5}>
                    {"isService" in article ? (
                      <ServiceIcon viewBox="0 0 32 32" sx={{ height: 32, width: 32 }} />
                    ) : (
                      <PackageIcon viewBox="0 0 32 32" sx={{ height: 32, width: 32 }} />
                    )}

                    <Typography variant="h4">{article.name}</Typography>
                  </Box>

                  <FormatTermsAndConditions content={article.termsAndConditions} />
                </Box>
              </Box>
            )
        )}
      </Stack>

      <Stack width="100%" gap={2} p={2}>
        <Button fullWidth onClick={handleNextStep} size="medium">
          {t("yesIAgree")}
        </Button>

        <Button fullWidth size="large" variant="text" onClick={onClose}>
          <Typography fontSize="0.875rem" fontWeight={700} color="#F43319">
            {t("noIDisagree")}
          </Typography>
        </Button>
      </Stack>
    </DrawerModal>
  );
};

export default TermsAndConditionsModal;

type FormatTermsAndConditionsProps = {
  content: string;
};

function FormatTermsAndConditions({ content }: FormatTermsAndConditionsProps) {
  const formattedTerms = content.replace(/(\r\n|\r|\n)/g, "<br />");

  return (
    <Typography
      mt={3}
      variant="h5"
      fontSize={14}
      dangerouslySetInnerHTML={{ __html: formattedTerms }}
    />
  );
}
