import { Box, Typography } from "@mui/material";
import { PaymentIcon } from "assets/icons/Cart";
import PriceBox from "components/PriceBox";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { PaymentType } from "types/common";

type PriceBadgeProps = {
  paymentType: string;
  paymentPrice: number;
};

function PriceBadge({ paymentType = PaymentType.DownPayment, paymentPrice }: PriceBadgeProps) {
  const { t } = useTranslation("checkoutPage");

  const organization = useSelector(selectOrganization);

  if (paymentType === PaymentType.FullPayment) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "primary.main", padding: 2, borderRadius: 2 }}
        gap={1}
      >
        <PaymentIcon sx={{ fontSize: "40px" }} />

        <Typography variant="h3" fontWeight={500} color="white">
          {t("fullPayment")}
        </Typography>

        <PriceBox
          value={paymentPrice}
          textSx={{ fontWeight: 700, color: "white", fontSize: "medium" }}
        />
      </Box>
    );
  }

  return (
    <Box sx={BadgeWrapper}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
        height="100%"
        p={2}
      >
        <Typography sx={{ color: "text.label", fontWeight: "500" }}>
          {t("mustPayDownPayment")} <br />
        </Typography>

        <Typography sx={{ color: "text.secondary", fontWeight: 700 }}>
          {t(organization?.t?.name || "")}
        </Typography>
      </Box>

      <Box sx={BadgeWrapper.rightPart}>
        <Box sx={BadgeWrapper.splitter} />

        <Box sx={BadgeWrapper.rightPart.inside}>
          <Box
            bgcolor="#F7F8Fd"
            height="100%"
            width="100%"
            borderRadius="2px"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
          >
            <PriceBox value={paymentPrice} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

const BadgeWrapper = {
  backgroundColor: "white",
  borderRadius: "4px",
  display: "flex",
  boxShadow: "card",
  alignItems: "stretch",
  splitter: {
    position: "absolute",
    height: "calc( 100% - 30px )",
    width: "2px",
    backgroundImage: `linear-gradient(rgba(78, 93, 120, 0.24) 70%, rgba(255,255,255,0) 0%)`,
    backgroundPosition: " right",
    backgroundSize: "2px 8px",
    backgroundRepeat: "repeat-y",
    left: "-8px",
    top: "50%",
    transform: "translateY(-50%)",
  },
  rightPart: {
    flex: "0 0 30%",
    maxWidth: "30%",
    padding: "6px",

    position: "relative",
    "&::before": {
      content: '""',
      position: "absolute",
      backgroundColor: "rgba(78, 93, 120, 0.09)",
      bottom: "0",
      left: "-15px",
      display: "inline-block",
      width: "16px",
      height: "8px",
      borderTopRightRadius: "500px",
      borderTopLeftRadius: "500px",
    },
    "&::after": {
      content: '""',
      position: "absolute",
      backgroundColor: "rgba(78, 93, 120, 0.09)",
      top: "0",
      left: "-15px",
      display: "inline-block",
      width: "16px",
      height: "8px",
      borderBottomRightRadius: "500px",
      borderBottomLeftRadius: "500px",
    },
    inside: {
      backgroundColor: "background.secondary",
      height: "100%",
      width: "100%",
      borderRadius: "2px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      color: "text.secondary",
      fontWeight: 600,
      fontSize: "16px",
    },
  },
};

export default PriceBadge;
