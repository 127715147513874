import { Box, Button, Card, Divider, Grid, Typography } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import useParamOutlet from "hooks/useParamOutlet";
import AppointmentArticleContainer from "pages/ReviewAppointmentPage/AppointmentArticleContainer";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import { selectSubscriptionPurchaseByIdWithSubscriptionDetails } from "store/selectors";
import { getSubscriptionPurchases } from "store/slices/subscriptionPurchases/subscriptionPurchasesSlice";
import { PurchaseSummaryOption } from "types/common";

import OutletAddressCard from "../OutletAddressCard";
import SubscriptionPurchaseSummary from "./ReviewSubscriptionPage/SubscriptionPurchaseSummary";
import SubscriptionItemDetails from "./SubscriptionItemDetails";

const SubscriptionConfirmation = () => {
  const { t } = useTranslation(["reviewSubscriptionPage", "glossary"]);

  const dispatch = useDispatch();

  const outlet = useParamOutlet();

  const { subscriptionPurchaseId } = useParams();

  useEffect(() => {
    if (subscriptionPurchaseId) dispatch(getSubscriptionPurchases({ id: subscriptionPurchaseId }));
  }, [subscriptionPurchaseId, dispatch]);

  const subscriptionPurchase = useSelector(
    selectSubscriptionPurchaseByIdWithSubscriptionDetails(subscriptionPurchaseId || "")
  );

  const [shouldShowMore, setShouldShowMore] = useState(false);

  if (!subscriptionPurchase) return <CentredSpinnerBox />;

  return (
    <>
      <Grid container justifyContent="center" bgcolor="#F6F7FC" p={2} spacing={3}>
        <Grid item md={12} xs={12}>
          <Typography variant="h3">{t("purchaseConfirmed")}</Typography>
        </Grid>

        <OutletAddressCard outletId={outlet?.id} />

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item xs={12}>
          <AppointmentArticleContainer>
            <Card sx={{ minHeight: "5rem", padding: 2 }}>
              <Box display="flex" gap={1} justifyContent="space-between">
                <Box display="flex" flexDirection="column" rowGap={1} height="100%">
                  <Typography variant="h4">{subscriptionPurchase.name}</Typography>

                  <Typography variant="secondary">
                    {subscriptionPurchase.itemName} x {subscriptionPurchase.subscription?.count}
                  </Typography>
                </Box>

                <PriceBox
                  value={Number(subscriptionPurchase.subscription?.price || 0)}
                  decimalStrategy="trim_zeros"
                  variant="h4"
                  sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
                  textSx={{ color: "text.secondary" }}
                />
              </Box>

              <Box display={shouldShowMore ? "block" : "none"} my={2}>
                <SubscriptionItemDetails
                  subscriptionId={subscriptionPurchase.subscription?.id || 0}
                />
              </Box>

              <Button
                onClick={() => setShouldShowMore(!shouldShowMore)}
                fullWidth
                variant="outlined"
                sx={{
                  marginTop: 1,
                  color: "#4E5D78",
                  padding: "1.3rem",
                  borderColor: "rgba(78,93,120, 0.2)",
                }}
              >
                <Typography>
                  {shouldShowMore ? t("common:showLess") : t("common:showMore")}
                </Typography>
              </Button>
            </Card>
          </AppointmentArticleContainer>
        </Grid>
      </Grid>

      <Grid container mt={2} bgcolor="white" p={2}>
        <SubscriptionPurchaseSummary
          items={t("glossary:subscriptionCount", { count: 1 })}
          price={Number(subscriptionPurchase.subscription?.price || 0)}
          pageType={PurchaseSummaryOption.Confirmation}
          subscriptionPurchaseId={subscriptionPurchase.id}
        />
      </Grid>
    </>
  );
};

export default SubscriptionConfirmation;
