import { RootState } from "store";

import { employeeAdapter } from "./employeesSlice";

const selectEmployeesState = (state: RootState) => state.employees;

const { selectById, selectAll } = employeeAdapter.getSelectors(selectEmployeesState);

export const selectEmployeeById = (id: Nullable<number | string>) => (state: RootState) =>
  selectById(state, id || "");

export const selectAllEmployees = selectAll;

export const selectEmployeesByOutletId = (outletId: number) => (state: RootState) =>
  selectAll(state).filter((employee) => employee.outlets.includes(outletId));

export const selectEmployeesByServiceIdAndOutletId =
  (serviceId: number, outletId: number) => (state: RootState) =>
    selectAll(state).filter(
      (employee) =>
        employee && employee.services.includes(serviceId) && employee.outlets.includes(outletId)
    );
