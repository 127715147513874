export default function UnselectedStaff() {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#EBF3FE" />
      <g clipPath="url(#clip0_2_317)">
        <path
          d="M15.7108 21.0256C14.8442 21.4459 14.1135 22.1017 13.6024 22.918C13.0912 23.7342 12.8203 24.6779 12.8205 25.641V25.641C12.8205 25.641 14.8718 27.6923 19.4872 27.6923C24.1026 27.6923 26.1538 25.641 26.1538 25.641C26.154 24.6778 25.8829 23.7341 25.3715 22.9178C24.8602 22.1015 24.1293 21.4458 23.2626 21.0256"
          stroke="#4048D6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.0769 15.8975C23.0769 17.88 21.4697 20.5129 19.4872 20.5129C17.5046 20.5129 15.8974 17.88 15.8974 15.8975C15.8974 14.9454 16.2756 14.0324 16.9488 13.3592C17.622 12.6859 18.5351 12.3077 19.4872 12.3077C20.4392 12.3077 21.3523 12.6859 22.0255 13.3592C22.6987 14.0324 23.0769 14.9454 23.0769 15.8975V15.8975Z"
          stroke="#4048D6"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_317">
          <rect
            width="16.4103"
            height="16.4103"
            fill="white"
            transform="translate(11.282 11.7949)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
