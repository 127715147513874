import { RootState } from "store";

import { selectServiceVariantById } from "../serviceVariants/serviceVariantsSelectors";
import { SliceStatus } from "../utils";
import { packageVariantAdapter } from "./packageVariantsSlice";

export type PackageVariantWithServiceVariants = Omit<PackageVariant, "serviceVariants"> & {
  serviceVariants: ServiceVariant[];
};

const selectPackageVariantsState = (state: RootState) => state.packageVariants;

const { selectById, selectAll } = packageVariantAdapter.getSelectors(selectPackageVariantsState);

export const selectPackageVariantById = (id: Nullable<number | string>) => (state: RootState) =>
  selectById(state, id || "");

export const selectPackageVariantPriceById =
  (id: Nullable<number | string>) => (state: RootState) =>
    Number(selectById(state, id || "")?.price || 0);

export const selectPackageVariantsByPackageId =
  (id: number) =>
  (state: RootState): PackageVariant[] =>
    selectAll(state).filter((variant) => variant && variant.package === id);

export const selectAllPackageVariants = selectAll;

export const selectPackageVariantsLoading = (state: RootState) =>
  state.packageVariants.status === SliceStatus.LOADING;

export const selectPackageVariantWithServicesById =
  (id: Nullable<number | string>) =>
  (state: RootState): PackageVariantWithServiceVariants | undefined => {
    const packageVariant = selectById(state, id || "");

    if (!packageVariant) return packageVariant;

    const serviceVariants = packageVariant.serviceVariants.map((serviceVariantId) =>
      selectServiceVariantById(serviceVariantId)(state)
    );

    if (serviceVariants.some((serviceVariant) => !serviceVariant)) return undefined;

    return { ...packageVariant, serviceVariants: serviceVariants as ServiceVariant[] };
  };

export const selectServiceVariantNamesByPackageVariantId =
  (id: Nullable<number | string>) => (state: RootState) => {
    const packageVariant = selectById(state, id || "");

    if (!packageVariant) return [];

    const serviceVariants = packageVariant.serviceVariants
      .map((serviceVariantId) => selectServiceVariantById(serviceVariantId)(state))
      .filter((serviceVariant) => !!serviceVariant);

    const serviceVariantNames = serviceVariants.map((serviceVariant) => serviceVariant?.name || "");

    return serviceVariantNames;
  };
