import { Grid, Typography, styled } from "@mui/material";
import { Box } from "@mui/system";
import useParamOutlet from "hooks/useParamOutlet";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectIsOutletsLoading, selectOrganization } from "store/selectors";

import { OutletBookingContextProvider } from "./outletContext";
import OutletPageSkeleton from "./OutletPage.skeleton";
import OutletPageHeader from "./OutletPageHeader";
import ScrollableDatePickerButtons from "./ScrollableDatePickerButtons";
import ServiceAndStaffListTabs from "./ServiceAndStaffListTabs";

export default function OutletPage() {
  const { t } = useTranslation("bookingPage");

  const outlet = useParamOutlet();
  const organization = useSelector(selectOrganization);

  const isLoading = useSelector(selectIsOutletsLoading);

  if (isLoading || !outlet) return <OutletPageSkeleton />;

  const isOutletAcceptingOnlineBookings = outlet.isAcceptingOnlineBookings;

  return (
    <OutletBookingContextProvider>
      <PageWrapper>
        <Grid container justifyContent="center">
          <Grid item xs={12} mb={1}>
            <OutletPageHeader />
          </Grid>
        </Grid>

        <ScrollableDatePickerButtons />
        {organization.hasActiveSubscription ? (
          <>
            {!isOutletAcceptingOnlineBookings && (
              <Grid item p={2}>
                <Box display="flex" gap={2} sx={{ background: "#FFEED1" }} p={2}>
                  <img alt="try later" src="/images/WeAreBusy.svg" />

                  <Box>
                    <Typography fontWeight={700}> {t("weAreCurrentlyBusy")} </Typography>
                    <Typography color="#53627C" fontWeight={500} mt={1}>
                      {" "}
                      {t("pleaseCheckBackLaterText")}{" "}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            )}

            <Grid
              container
              justifyContent="center"
              sx={{
                pointerEvents: isOutletAcceptingOnlineBookings ? "" : "none",
                opacity: isOutletAcceptingOnlineBookings ? "none" : 0.5,
              }}
            >
              <Grid item xs={12}>
                <ServiceAndStaffListTabs />
              </Grid>
            </Grid>
          </>
        ) : (
          <Box
            display="flex"
            textAlign="center"
            flexDirection="column"
            width="100%"
            flex="1"
            alignItems="center"
            justifyContent="center"
            py={8}
            px={2}
            gap={1}
          >
            <img alt="Under Maintenance" src="/images/UnderMaintenance.svg" />
            <Typography>{t("underMaintenance")}</Typography>
          </Box>
        )}
      </PageWrapper>
    </OutletBookingContextProvider>
  );
}

const PageWrapper = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.bg.secondary,
  position: "static",
}));
