import { Home } from "@bookpeep/ui";
import { Box, Button, Grid, IconButton } from "@mui/material";
import DownloadAppBanner from "components/DownloadAppBanner";
import UserMenu from "components/UserMenu";
import SplashPage from "pages/SplashPage/SplashPage";
import ArrowLeft from "poc/rtl-icons/ArrowLeft";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import { Outlet as RouterOutlet, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "store";
import { selectIsOrganizationLoading } from "store/selectors";
import { selectAuthIsLoggedIn } from "store/slices/auth/authSelectors";

export default function TopBarLayout() {
  const { t } = useTranslation("auth");
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const isLoggedIn = useSelector(selectAuthIsLoggedIn);

  const isOrganizationLoading = useSelector(selectIsOrganizationLoading);

  const goBack = () => navigate(-1);

  const goToLoginPage = () => navigate("/login");

  const goToHomePage = () => navigate("/");

  const isOnHomePage = pathname === "/";

  const shouldShowBackButton = !isOnHomePage;

  if (isOrganizationLoading) return <SplashPage />;

  return (
    <>
      <Box position="sticky" top="0" zIndex="appBar">
        <DownloadAppBanner />

        <Box
          px={2}
          py={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor="bg.secondary"
          boxShadow="divider"
          maxHeight="56px"
          minHeight="56px"
        >
          <Grid container spacing={2} justifyContent="space-between" alignItems="center">
            <Grid item xs={4}>
              {shouldShowBackButton && (
                <Button variant="text" size="small" startIcon={<ArrowLeft />} onClick={goBack}>
                  {t("back")}
                </Button>
              )}
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="center">
              <IconButton color="primary" onClick={goToHomePage}>
                <Home />
              </IconButton>
            </Grid>

            <Grid item xs={4} display="flex" justifyContent="flex-end">
              {isLoggedIn ? (
                <UserMenu />
              ) : (
                <Button variant="text" size="small" onClick={goToLoginPage}>
                  {t("login")}
                </Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Suspense fallback={<SplashPage />}>
        <RouterOutlet />
      </Suspense>
    </>
  );
}
