import { Button } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import {
  PackageVariantWithServiceVariants,
  selectCart,
  selectOrganization,
  selectPackageVariantWithServicesById,
} from "store/selectors";
import { addArticleToCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";
import { calculateDeposit } from "utils/deposit";

import { useBookingContext } from "../bookingContext";
import { getSlotDateTime } from "../BookingSlotPicker/slotPickerHelpers";

export default function PackageBookingAddToCartButton() {
  const { t } = useTranslation("bookingPage");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    state: { selectedSlot, serviceEmployees, packageVariantId, subscriptionPurchaseId },
  } = useBookingContext();

  const packageVariant = useSelector(selectPackageVariantWithServicesById(packageVariantId));
  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);

  const outlet = useParamOutlet();

  function addArticle() {
    if (!selectedSlot || !packageVariant)
      throw new Error("Cannot add to cart, invalid slot or service variant");

    const startTime = getSlotDateTime(selectedSlot);
    const cartArticle = generateCartArticle(
      packageVariant,
      startTime,
      serviceEmployees,
      subscriptionPurchaseId,
      packageVariant.depositPercentage
    );

    dispatch(addArticleToCart(cartArticle));
    if (organization?.id && organization?.name) {
      trackEvent(
        !!cart.articles.length ? "Item added to cart" : "Cart Initiated",
        { id: organization.id, name: organization.name },
        { isResourceSelected: !!serviceEmployees.length }
      );
    }

    navigate(`/${outlet.slug}/appointment-review`);
  }

  return (
    <Button fullWidth disabled={!selectedSlot} onClick={addArticle} size="medium">
      {t("common:apply")}
    </Button>
  );
}

/**
 * 🎁 Note: Dear future developers, please remember to update the return of both parallel and sequential packages when adding new fields to the cart article. 🎁 - Anfal Your Life Saver
 *
 * @param packageVariant
 * @param startTime
 * @param serviceEmployees
 * @param subscriptionPurchaseId
 * @returns
 */

function generateCartArticle(
  packageVariant: PackageVariantWithServiceVariants,
  startTime: DateTime,
  serviceEmployees: [number, Nullable<number>][],
  subscriptionPurchaseId: Nullable<number>,
  depositPercentage: Nullable<number>
) {
  if (packageVariant.parallel) {
    const slots = packageVariant.serviceVariants.map((serviceVariant, index) => ({
      serviceVariant: serviceVariant.id,
      staff: serviceEmployees[index][1],
      start: startTime.toISO(),
      end: startTime.plus({ minute: serviceVariant.duration }).toISO(),
    }));

    return {
      serviceVariant: null,
      packageVariant: packageVariant.id,
      totalPrice: Number(packageVariant.price),
      subscriptionPurchaseId,
      slots,
      amountToBePaid: calculateDeposit(depositPercentage, Number(packageVariant.price)),
    };
  }

  let nextStartTime = startTime;

  const slots = packageVariant.serviceVariants.map((serviceVariant, index) => {
    const start = nextStartTime.toISO();
    nextStartTime = nextStartTime.plus({ minute: serviceVariant.duration });

    return {
      serviceVariant: serviceVariant.id,
      staff: serviceEmployees[index][1],
      start,
      end: nextStartTime.toISO(),
    };
  });

  return {
    serviceVariant: null,
    packageVariant: packageVariant.id,
    totalPrice: Number(packageVariant.price),
    subscriptionPurchaseId,
    slots,
    amountToBePaid: calculateDeposit(depositPercentage, Number(packageVariant.price)),
  };
}
