import { isAsyncThunkAction } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import type { RootState } from "store";

type MatchThunksOptions = {
  slice?: keyof RootState;
  status?: "pending" | "rejected" | "fulfilled";
};

export default function matchThunks({ slice, status }: MatchThunksOptions) {
  return (action: AnyAction) => {
    if (!isAsyncThunkAction(action)) return false;

    // e.g.
    // "organization/getOrganization/pending"
    // ["organization", "getOrganization", "pending"]
    const actionProperties = action.type.split("/");

    const actionPrefix = actionProperties[0];
    const actionStatus = actionProperties[2];

    const isMatchingSlice = slice ? actionPrefix === slice : true;

    const isMatchingStatus = status ? actionStatus === status : true;

    return isMatchingSlice && isMatchingStatus;
  };
}
