import { setLocale } from "yup";

setLocale({
  mixed: {
    required: (values) => ({ key: "mixed.required", values }),
  },
  string: {
    email: (values) => ({ key: "string.email", values }),
    min: (values) => ({ key: "string.min", values }),
  },
});
