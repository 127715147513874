import { createSlice } from "@reduxjs/toolkit";
import { translateOrganization } from "helpers/translations";
import { DateTime } from "luxon";

import { getNormalizedOrganization } from "../../globalThunks";
import { SliceStatus } from "../utils";

export type OrganizationSliceState = Partial<Organization> & {
  status: SliceStatus;
  date: string;
};

const initialState: OrganizationSliceState = {
  status: SliceStatus.IDLE,
  date: DateTime.now().startOf("day").toISO(),
};

const organizationSlice = createSlice({
  name: "organization",
  initialState,

  reducers: {
    setDate: (state, action) => {
      state.date = action.payload.date || DateTime.now().startOf("day").toISO();
    },
  },
  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedOrganization.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedOrganization.fulfilled, (state, { payload }) => {
        return {
          ...{ ...payload.organization, t: translateOrganization(payload.organization.id) },
          status: SliceStatus.IDLE,
          date: state.date,
        };
      })
      .addCase(getNormalizedOrganization.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      });
  },
});
export const { setDate } = organizationSlice.actions;
export default organizationSlice.reducer;
