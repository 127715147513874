import { createAsyncThunk } from "@reduxjs/toolkit";
import type { BaseThunkAPI } from "@reduxjs/toolkit/dist/createAsyncThunk";
import axios, { AxiosError, AxiosPromise, AxiosResponse } from "axios";
import type { RootDispatch, RootState } from "store";

// Right now this function can't handle api calls with no arguments, you must pass {}
// TODO: fix this مهزلة

type ThunkApi<RejectedValue> = BaseThunkAPI<RootState, unknown, RootDispatch, RejectedValue>;

type HandleResponse<ApiArguments, ActionArguments, ApiResponse, RejectValue> =
  (responseHandlerArguments: {
    apiArgs: ApiArguments;
    extraArgs: Omit<ActionArguments, keyof ApiArguments>;
    response: AxiosResponse<ApiResponse>;
    thunkApi: ThunkApi<RejectValue>;
  }) => any;

type HandleError<ApiArguments, ActionArguments, RejectValue> = (errorHandlerArguments: {
  apiArgs: ApiArguments;
  extraArgs: Omit<ActionArguments, keyof ApiArguments>;
  error: Error | AxiosError;
  thunkApi: ThunkApi<RejectValue>;
}) => any;

type DjangoErrorResponse<T> = DjangoDetailResponse & Partial<Record<keyof T, string[]>>;

/**
 * Create an async thunk from an API with very little effort.
 *
 * Extra arguments passed in thunk extensions are not sent to API args, but must
 * be included in thunk action call
 *
 * @param name - action type prefix
 * @param apiCall
 * @param thunkExtensions - extra arguments, handleResponse and handleError
 * @returns Thunk action creator
 */

export default function createThunkFromApi<
  ActionArguments extends Record<string, any>,
  ApiArguments,
  ApiResponse,
  RejectValue = Error | AxiosError<DjangoErrorResponse<ApiArguments & ApiResponse>>
>(
  name: string,

  apiCall: (apiArguments: ApiArguments) => AxiosPromise<ApiResponse>,

  thunkExtensions?: {
    extraArgumentKeys?: (keyof Omit<ActionArguments, keyof ApiArguments>)[];

    handleResponse?: HandleResponse<ApiArguments, ActionArguments, ApiResponse, RejectValue>;

    handleError?: HandleError<ApiArguments, ActionArguments, RejectValue>;
  }
) {
  return createAsyncThunk<
    ApiResponse,
    ApiArguments & ActionArguments,
    { state: RootState; dispatch: RootDispatch; extra: unknown; rejectValue: RejectValue }
  >(name, async (args, thunkApi) => {
    const { extraArgumentKeys, handleResponse, handleError } = thunkExtensions || {};
    const [apiArgs, extraArgs] = splitObjectByKeys(args, extraArgumentKeys || []);

    try {
      const response = await apiCall(args);

      if (handleResponse) return handleResponse({ apiArgs, extraArgs, response, thunkApi });
      else return response.data;
    } catch (error) {
      // TODO: We should have a real error handling mechanism
      if (axios.isAxiosError(error) || error instanceof Error) {
        if (handleError) return handleError({ apiArgs, extraArgs, error, thunkApi });
      }

      return thunkApi.rejectWithValue(error as RejectValue);
    }
  });
}

function omitObjectKeys<TargetObject extends Record<string, unknown>>(
  targetObject: TargetObject,
  keys: (keyof TargetObject)[]
): Partial<TargetObject> {
  return (keys as any).reduce((a: Partial<TargetObject>, e: keyof TargetObject) => {
    const { [e]: omitted, ...rest } = a;
    return rest;
  }, targetObject);
}

function splitObjectByKeys<TargetObject extends Record<string, unknown>>(
  targetObject: TargetObject,
  keysToPluckOut: (keyof TargetObject)[]
) {
  if (!keysToPluckOut.length) return [targetObject, {}];

  // Object without plucked out keys
  const objectWithoutPluckedOutItems = omitObjectKeys(targetObject, keysToPluckOut);

  // Object with plucked out keys
  const objectWithPluckedOutItems = Object.assign(
    {},
    ...keysToPluckOut.map((key) => ({ [key]: targetObject[key] }))
  );

  return [objectWithoutPluckedOutItems, objectWithPluckedOutItems];
}
