import { TranslationNamespaces } from "configs/i18n/TranslationTypes";

export function translateOrganization(id: number) {
  const keyRoot = `${TranslationNamespaces["organizations"]}:organizations.${id}`;
  return {
    name: keyRoot + ".name",
  };
}

export function translateOutlet(id: number) {
  const keyRoot = `${TranslationNamespaces["organizations"]}:outlets.${id}`;
  return {
    name: keyRoot + ".name",
  };
}
