import { Stack, styled } from "@mui/material";

const AppSplashScreen = () => {
  return (
    <Stack
      sx={{
        bgcolor: "primary.main",
        height: "100svh",
      }}
      alignItems="center"
      justifyContent="center"
    >
      <PulsingLogo alt="logo" src="/images/BookpeepLogo.svg" />
    </Stack>
  );
};

export default AppSplashScreen;

const PulsingLogo = styled("img")`
  animation: pulse 2s infinite;

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.4);
    }
    100% {
      transform: scale(1);
    }
  }
`;
