import { useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectAllOutlets, selectOutletBySlug } from "store/selectors";

/**
 * Hook to get the current outlet from the active route.
 *
 * @param allowUndefined - Whether to allow the return type to be undefined (defaults to false)
 */
function useParamOutlet(): Outlet;
function useParamOutlet(allowUndefined: boolean): Outlet | undefined;
function useParamOutlet(allowUndefined: boolean = false) {
  const { outletSlug } = useParams();
  let outlet = useSelector(selectOutletBySlug(outletSlug || ""));

  const outlets = useSelector(selectAllOutlets);
  if (!outlet) outlet = !!outlets.length ? outlets[0] : undefined;

  if (allowUndefined) return outlet;

  if (!outlet) throw new Error("useParamOutlet() hook must be used within an <OutletRedirector />");

  return outlet;
}

export default useParamOutlet;
