import { RootState } from "store";

import { onlinePaymentRuleAdapter } from "./onlinePaymentRulesSlice";

const selectOnlinePaymentRulesState = (state: RootState) => state.onlinePaymentRules;

const { selectById, selectAll } = onlinePaymentRuleAdapter.getSelectors(
  selectOnlinePaymentRulesState
);

export const selectOnlinePaymentRuleById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectAllOnlinePaymentRules = selectAll;

export const selectDownPaymentByAppointmentAmount =
  (amount: number | string) => (state: RootState) => {
    let onlinePaymentRule = selectAll(state).find(
      (onlinePaymentRule) =>
        +amount >= +onlinePaymentRule.fromAmount &&
        +amount <= +onlinePaymentRule.toAmount &&
        !onlinePaymentRule.isGlobal
    );

    if (!onlinePaymentRule) {
      onlinePaymentRule = selectAll(state).find(
        (onlinePaymentRule) =>
          +amount >= +onlinePaymentRule.fromAmount &&
          +amount <= +onlinePaymentRule.toAmount &&
          onlinePaymentRule.isGlobal
      );
    }

    if (onlinePaymentRule) {
      let downPayment = 0.0;
      switch (onlinePaymentRule.feeType) {
        case "PERCENTAGE":
          downPayment = +amount * (+onlinePaymentRule.fee / 100);
          break;
        case "FIXED":
          downPayment = +onlinePaymentRule.fee;
          break;
        default:
          downPayment = 0;
          break;
      }
      return downPayment;
    }

    return null;
  };
