import { Done } from "@bookpeep/ui";
import { Box, Button, ButtonProps, styled } from "@mui/material";
import dontForwardProps from "helpers/styles/dontForwardProps";

// colorIndex is the index of the alreadyBookedArticle blocking the articleBeingBooked
export type Blockage = [isBlocked: boolean, colorIndex: number];

type PurchasableSelectedSlotButtonProps = {
  label: string;
  colorIndex: number;

  onClick: () => void;
};

const PurchasableSelectedSlotButton = (props: PurchasableSelectedSlotButtonProps) => {
  const { label, onClick } = props;

  return (
    <CustomButton
      customColor="#53BFB0"
      onClick={onClick}
      variant="contained"
      sx={{ color: "white", fontSize: "0.75rem", minHeight: 0, py: 1 }}
    >
      <Box display="flex" alignItems="center" columnGap={0.5}>
        {label} <Done sx={{ fontSize: "0.875rem", fill: "white" }} />
      </Box>
    </CustomButton>
  );
};
type CustomButtonProps = ButtonProps & {
  customColor: string;
};

const CustomButton = styled(
  Button,
  dontForwardProps("customColor")
)<CustomButtonProps>(({ theme, customColor }) => ({
  border: `1px solid ${customColor}`,
  borderStyle: "solid",
  color: "white",
  fontWeight: 700,
  width: theme.spacing(11.5),
  backgroundColor: customColor,

  display: "flex",
  flexDirection: "row",

  rowGap: theme.spacing(1),

  "&:hover": {
    border: `1px solid ${customColor}`,
    backgroundColor: customColor,
  },
  "&:active": {
    backgroundColor: customColor,
  },
}));

export default PurchasableSelectedSlotButton;
