import { Box, Button, Container, Grid, Typography, styled } from "@mui/material";
import FullWidthModal from "components/FullWidthModal";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";

import TermsAndConditionsSection from "./TermsAndConditionsSection";

type TermsAndConditionsProps = {
  open: boolean;
  onClose: () => void;
};

const terms = [
  {
    title: "introduction.title",
    description: "introduction.description",
  },
  {
    title: "introduction.definition.title",
    description: "introduction.definition.description",
  },
  {
    title: "introduction.dataController.title",
    description: "introduction.dataController.description",
  },
];

function TermsAndConditions({ open, onClose }: TermsAndConditionsProps) {
  const { t } = useTranslation(["terms", "common"]);

  const { setValue } = useFormContext();

  const handleAgree = () => {
    setValue("agreeToTerms", true);
    onClose();
  };

  return (
    <FullWidthModal
      open={open}
      onClose={onClose}
      footer={
        <FooterActionsWrapper>
          <Grid container spacing={2.5}>
            <Grid item xs={6}>
              <Button size="medium" onClick={onClose} fullWidth variant="outlined">
                {t("common:cancel")}
              </Button>
            </Grid>

            <Grid item xs={6}>
              <Button onClick={handleAgree} size="medium" fullWidth>
                {t("agree")}
              </Button>
            </Grid>
          </Grid>
        </FooterActionsWrapper>
      }
    >
      <Container>
        <Grid container pt={3} spacing={3} pb={6}>
          <Grid item xs={12}>
            <Typography color="primary" mt={1.25} variant="h2" textAlign="center">
              {t("termsAndConditions")}
            </Typography>
          </Grid>

          {terms.map((term, index) => (
            <TermsAndConditionsSection
              key={index}
              title={term.title}
              description={term.description}
            />
          ))}
        </Grid>
      </Container>
    </FullWidthModal>
  );
}

const FooterActionsWrapper = styled(Box)(({ theme }) => ({
  width: "100%",
  backgroundColor: theme.palette.common.white,
  padding: theme.spacing(3),
  borderTop: `1px solid ${theme.palette.text.label}25`,
}));

export default TermsAndConditions;
