import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { getNormalizedAppointments } from "store/globalThunks";

import { logout } from "../auth/authSlice";
import { SliceStatus } from "../utils";

export const onlinePaymentAdapter = createEntityAdapter<OnlinePayment>({
  selectId: (onlinePayment) => onlinePayment.id,
});

const initialState = onlinePaymentAdapter.getInitialState<{
  status: SliceStatus;
}>({
  status: SliceStatus.IDLE,
});

export const onlinePaymentsSlice = createSlice({
  name: "onlinePayments",
  initialState,

  reducers: {},

  extraReducers: (reducers) => {
    reducers
      .addCase(getNormalizedAppointments.pending, (state) => {
        state.status = SliceStatus.LOADING;
      })
      .addCase(getNormalizedAppointments.fulfilled, (state, { payload: { onlinePayments } }) => {
        onlinePaymentAdapter.upsertMany(state, onlinePayments);
        state.status = SliceStatus.IDLE;
      })
      .addCase(getNormalizedAppointments.rejected, (state) => {
        state.status = SliceStatus.FAILED;
      })
      .addMatcher(logout.match, () => initialState);
  },
});

export default onlinePaymentsSlice.reducer;
