import { Box, Button, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectOrganization } from "store/selectors";
import { trackEvent } from "tracking";

type AppointmentOrganizationHeaderProps = {
  time: Nullable<string>;
  shouldShowActions?: boolean;
  outlet: Outlet;
};

const AppointmentOrganizationHeader = ({
  time,
  outlet,
  shouldShowActions = false,
}: AppointmentOrganizationHeaderProps) => {
  const { t } = useTranslation("appointmentPage");
  const organization = useSelector(selectOrganization);

  const name = `${organization.name} -  ${outlet.name}`;

  const phoneNumber = outlet.contactNumbers.length ? outlet.contactNumbers[0].number : null;
  const mapLink = outlet.address.mapLink;

  return (
    <Box display="flex" flexDirection="column" width="100%" rowGap={1}>
      <StyledBox p={2}>
        <Typography variant="primary" fontWeight="bold" color="#848FA1">
          {name}
        </Typography>

        {time && (
          <Typography variant="h2" color="primary">
            {time}
          </Typography>
        )}
      </StyledBox>

      {shouldShowActions && (
        <Box display="flex" flexDirection="row" gap={1} py={1} height="100%">
          {phoneNumber && (
            <Button size="small" href={`tel:${phoneNumber}`} fullWidth>
              {t("call")}
            </Button>
          )}

          {mapLink && (
            <Button
              size="small"
              href={mapLink}
              variant="outlined"
              fullWidth
              onClick={() => {
                if (organization?.id && organization?.name)
                  trackEvent(
                    "Location Viewed",
                    { id: organization.id, name: organization.name },
                    {
                      from: "Partner Page",
                    }
                  );
              }}
            >
              {t("directions")}
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AppointmentOrganizationHeader;

const StyledBox = styled(Box)(({ theme }) => ({
  borderRadius: 16,
  borderStyle: "solid",
  borderWidth: 1,
  borderColor: "#D4D6E4",
  display: "flex",
  flexDirection: "column",
  width: "100%",
  rowGap: theme.spacing(1),
}));
