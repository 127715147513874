import { ChevronDown } from "@bookpeep/ui";
import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import dontForwardProps from "helpers/styles/dontForwardProps";
import { memo } from "react";

const endIcon = "& .MuiButton-endIcon";

type ExpanderButtonProps = ButtonProps & {
  expanded: boolean;
  iconColor?: string;
  bgcolor?: string;
};

const ExpanderButton = styled(
  (props: ExpanderButtonProps) => <Button endIcon={<ChevronDown />} {...props} />,
  dontForwardProps("expanded", "iconColor")
)<ExpanderButtonProps>(({ expanded, bgcolor, iconColor, theme }) => ({
  justifyContent: "space-between",
  backgroundColor: bgcolor ? bgcolor : "inherit",
  borderRadius: expanded ? "0 0 0 0" : "0 0px 8px 8px",
  "&:hover": {
    backgroundColor: bgcolor ? bgcolor : "inherit",
    boxShadow: "none",
  },

  [endIcon]: {
    transition: theme.transitions.create(["transform"], {
      duration: theme.transitions.duration.shorter,
    }),

    transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
    color: iconColor,
  },
}));

export default memo(ExpanderButton);
