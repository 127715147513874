import { Box, Card, Typography } from "@mui/material";
import PriceBox from "components/PriceBox";
import { DateTime } from "luxon";

type WalletTransactionCardType = {
  transaction: WalletTransaction;
};

const WalletTransactionCard = ({ transaction }: WalletTransactionCardType) => {
  return (
    <Card>
      <Box
        p={2}
        display="flex"
        width="100%"
        height="70px"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h4">
          {DateTime.fromISO(transaction.createdAt).toFormat("ccc, LLL dd, yyyy")}
        </Typography>

        <PriceBox
          value={Number(transaction.amount) | 0}
          variant="h4"
          sx={{ color: +transaction.amount > 0 ? "primary.main" : "error.main" }}
        />
      </Box>
    </Card>
  );
};

export default WalletTransactionCard;
