import type { RootState } from "store";

import { SliceStatus } from "../utils";
import { subscriptionsAdapter } from "./subscriptionsSlice";

export const selectSubscriptionsState = (state: RootState) => state.subscriptions;

const { selectAll, selectById } = subscriptionsAdapter.getSelectors(selectSubscriptionsState);

export const selectAllSubscriptions = selectAll;

export const selectSubscriptionIsLoading = (state: RootState) =>
  state.subscriptions.status === SliceStatus.LOADING;

export const selectSubscriptionById = (id: number | string) => (state: RootState) =>
  selectById(state, id);

export const selectSubscriptionByOutletId = (outletId: number) => (state: RootState) =>
  selectAllSubscriptions(state).filter((subscription) =>
    subscription.sellingOutlets.includes(outletId)
  );
