import { Button, Divider, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import PriceBox from "components/PriceBox";
import OutletAddressCard from "pages/OutletPage/OutletAddressCard";
import AppointmentArticleContainer from "pages/ReviewAppointmentPage/AppointmentArticleContainer";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useSelector } from "store";
import { selectOrganization, selectSubscriptionById } from "store/selectors";

import SubscriptionItemDetails from "../SubscriptionItemDetails";
import SubscriptionPurchaseSummary from "./SubscriptionPurchaseSummary";

const ReviewSubscriptionPage = () => {
  const { t } = useTranslation(["reviewSubscriptionPage", "common", "glossary"]);

  const { subscriptionId } = useParams();

  const organization = useSelector(selectOrganization);

  const subscription = useSelector(selectSubscriptionById(subscriptionId || 0));

  const [shouldShowMore, setShouldShowMore] = useState(false);

  if (!organization || !subscription) return <CentredSpinnerBox />;

  return (
    <>
      <Grid container justifyContent="center" bgcolor="#F6F7FC" p={2} spacing={3}>
        <Grid item md={12} xs={12}>
          <Typography variant="h3">{t("reviewSubscriptionPage:reviewYourPurchase")}</Typography>
        </Grid>

        <OutletAddressCard />

        <Grid item xs={12}>
          <Divider variant="fullWidth" />
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            width="100%"
            flexDirection="column"
            bgcolor="bg.blank"
            borderRadius={4}
          >
            <AppointmentArticleContainer>
              <Box display="flex" width="100%" flexDirection="column">
                <Box display="flex" gap={1} justifyContent="space-between">
                  <Box display="flex" flexDirection="column" rowGap={1} height="100%">
                    <Typography variant="h4">{subscription.name}</Typography>

                    <Typography variant="secondary">
                      {subscription.itemName} x {subscription.count}
                    </Typography>
                  </Box>

                  <PriceBox
                    value={Number(subscription.price)}
                    decimalStrategy="trim_zeros"
                    variant="h4"
                    sx={{ display: "flex", flexDirection: "column", alignItems: "end" }}
                    textSx={{ color: "text.secondary" }}
                  />
                </Box>

                <Box display={shouldShowMore ? "block" : "none"} my={2}>
                  <SubscriptionItemDetails subscriptionId={subscription.id} />
                </Box>

                <Button
                  onClick={() => setShouldShowMore(!shouldShowMore)}
                  fullWidth
                  variant="outlined"
                  sx={{
                    marginTop: 1,
                    color: "#4E5D78",
                    padding: "1.3rem",
                    borderColor: "rgba(78,93,120, 0.2)",
                  }}
                >
                  <Typography>
                    {shouldShowMore ? t("common:showLess") : t("common:showMore")}
                  </Typography>
                </Button>
              </Box>
            </AppointmentArticleContainer>
          </Box>
        </Grid>
      </Grid>

      <Grid container mt={2} bgcolor="white" p={2}>
        <SubscriptionPurchaseSummary
          items={t("glossary:subscriptionCount", { count: 1 })}
          price={Number(subscription.price)}
        />
      </Grid>
    </>
  );
};

export default ReviewSubscriptionPage;
