import { Done } from "@bookpeep/ui";
import { Box, Button, styled } from "@mui/material";
import dontForwardProps from "helpers/styles/dontForwardProps";
import getIndexColor from "helpers/styles/getIndexColor";

// colorIndex is the index of the alreadyBookedArticle blocking the articleBeingBooked
export type Blockage = [isBlocked: boolean, colorIndex: number];

type SlotButtonProps = {
  label: string;
  highlight: boolean;
  blockage: Blockage;
  onClick: () => void;
};

export default function SlotButton(props: SlotButtonProps) {
  const { label, highlight, blockage, onClick } = props;

  const [isBlocked, colorIndex] = blockage;

  const isOccupied = colorIndex > -1;

  return isBlocked ? (
    <CustomSlotButton
      colorIndex={colorIndex !== -1 ? getIndexColor(colorIndex) : null}
      disabled={isBlocked}
      onClick={onClick}
      variant={highlight ? "contained" : "outlined"}
      fullWidth
    >
      <Box display="flex" alignItems="center" minWidth={55} columnGap={0.5} justifyContent="center">
        {isOccupied && <Done sx={{ fontSize: "0.875rem", fill: getIndexColor(colorIndex) }} />}
        {label}
      </Box>
    </CustomSlotButton>
  ) : (
    <Button
      disabled={isBlocked}
      onClick={onClick}
      variant={highlight ? "contained" : "outlined"}
      fullWidth
      sx={{ fontSize: "0.75rem", minHeight: 0, py: 1 }}
    >
      <Box display="flex" alignItems="center" minWidth={55} columnGap={0.5} justifyContent="center">
        {isOccupied && <Done sx={{ fontSize: "0.875rem", fill: getIndexColor(colorIndex) }} />}
        {label}
      </Box>
    </Button>
  );
}

type CustomSlotButtonProps = {
  colorIndex: Nullable<string>;
};

const CustomSlotButton = styled(
  Button,
  dontForwardProps("colorIndex")
)<CustomSlotButtonProps>(({ theme, colorIndex }) =>
  colorIndex
    ? {
        border: `1px solid ${colorIndex}`,

        color: colorIndex,
        fontWeight: 700,
        paddingY: theme.spacing(1),
        fontSize: "0.75rem",

        backgroundColor: "inherit",

        display: "flex",
        flexDirection: "row",

        rowGap: theme.spacing(1),

        "&:disabled": {
          border: `1px solid ${colorIndex}`,

          color: colorIndex,
          fontWeight: 700,
          paddingY: theme.spacing(1),
          fontSize: "0.75rem",

          backgroundColor: "inherit",

          display: "flex",
          flexDirection: "row",

          rowGap: theme.spacing(1),
        },

        "&:hover": {
          border: `1px solid ${colorIndex}`,
          backgroundColor: "inherit",
        },
        "&:active": {
          backgroundColor: "inherit",
        },
      }
    : {
        fontWeight: 700,
        paddingY: theme.spacing(1),
        fontSize: "0.75rem",
        display: "flex",
        flexDirection: "row",

        rowGap: theme.spacing(1),
      }
);
