import { Grid } from "@mui/material";
import { getSubscriptionExpirationDate } from "helpers/getSubscriptionExpirationDate";
import { DateTime } from "luxon";
import { useEffect, useRef } from "react";
import { useSelector } from "store";
import { selectAppliedSubscriptions } from "store/selectors";
import { ProcessedSubscriptionPurchase } from "types/Subscription";

import ArticleItemRedeemableSubscription from "./ArticleItemRedeemableSubscription";

type ArticleItemRedeemableSubscriptionsProps = {
  isDraft?: boolean;
  subscriptions: ProcessedSubscriptionPurchase[];
  selectedDate: DateTime;
  appliedSubscription: Nullable<number>;
  handleToggleSubscriptionActivation: (subscriptionId: Nullable<number>) => void;
};

const ArticleItemRedeemableSubscriptions = ({
  subscriptions,
  selectedDate,
  appliedSubscription,
  handleToggleSubscriptionActivation,
  isDraft = false,
}: ArticleItemRedeemableSubscriptionsProps) => {
  const allAppliedSubscriptions = useSelector(selectAppliedSubscriptions) as Nullable<number>[];
  const hasHandledAutomaticSubscriptionRedemption = useRef(false);

  const availableSubscriptions = subscriptions
    .map((subscription) => ({
      ...subscription,
      totalRemaining:
        subscription.remainingRedemptionsCount -
        allAppliedSubscriptions.filter(
          (appliedSubscriptionId) =>
            appliedSubscription && appliedSubscriptionId === subscription.id
        ).length,
    }))
    .filter((subscription) => {
      if (
        DateTime.fromISO(subscription.expirationDate) >= selectedDate &&
        subscription.totalRemaining > 0
      )
        return true;
      return false;
    });

  useEffect(() => {
    if (isDraft && !availableSubscriptions.length && !!appliedSubscription) {
      handleToggleSubscriptionActivation(null);
    } else {
      if (isDraft && !hasHandledAutomaticSubscriptionRedemption.current) {
        const appliedSubscriptionObject = availableSubscriptions.find(
          (subscription) => subscription.id === appliedSubscription
        );

        if (!availableSubscriptions.length && appliedSubscription !== null) {
          handleToggleSubscriptionActivation(null);
        } else if (
          appliedSubscriptionObject &&
          appliedSubscription !== appliedSubscriptionObject.id &&
          appliedSubscriptionObject.totalRemaining > 0
        ) {
          handleToggleSubscriptionActivation(appliedSubscriptionObject.id);
        } else if (availableSubscriptions.length && !appliedSubscription) {
          const subscriptionToApply = availableSubscriptions[0];

          const subscriptionToApplyTotalRemainingCount =
            subscriptionToApply.remainingRedemptionsCount -
            allAppliedSubscriptions.filter(
              (appliedSubscription) => appliedSubscription === subscriptionToApply.id
            ).length;

          if (subscriptionToApplyTotalRemainingCount > 0)
            handleToggleSubscriptionActivation(subscriptionToApply.id);
        }

        hasHandledAutomaticSubscriptionRedemption.current = true;
      }
    }
  }, [
    selectedDate,
    appliedSubscription,

    handleToggleSubscriptionActivation,
    allAppliedSubscriptions,
    isDraft,
    availableSubscriptions,
  ]);

  return (
    <>
      {subscriptions.map((subscription) => {
        const {
          id: subscriptionId,
          name,
          createdAt,
          validityInDays,
          remainingRedemptionsCount,
          expirationDate,
        } = subscription;

        const isSubscriptionApplied = subscriptionId === appliedSubscription;

        const totalSubscriptionRedemptionInCart = allAppliedSubscriptions.filter(
          (subscription) => subscription === subscriptionId
        ).length;

        let totalRemainingRedemptionsCount =
          remainingRedemptionsCount - totalSubscriptionRedemptionInCart;

        if (isSubscriptionApplied && isDraft) totalRemainingRedemptionsCount -= 1;

        const subscriptionExpirationDate = getSubscriptionExpirationDate(createdAt, validityInDays);
        const formattedSubscriptionExpirationDate =
          subscriptionExpirationDate.toFormat("dd MMMM yyyy");

        const shouldDisableRedeeming =
          (totalRemainingRedemptionsCount === 0 && !isSubscriptionApplied) ||
          selectedDate > DateTime.fromISO(expirationDate);

        return (
          <Grid key={subscriptionId} item xs={12}>
            <ArticleItemRedeemableSubscription
              title={name}
              expirationDate={formattedSubscriptionExpirationDate}
              remainingRedemptionsCount={totalRemainingRedemptionsCount}
              isSubscriptionApplied={isSubscriptionApplied}
              setIsSubscriptionApplied={() => handleToggleSubscriptionActivation(subscriptionId)}
              disableRedeeming={shouldDisableRedeeming}
            />
          </Grid>
        );
      })}
    </>
  );
};

export default ArticleItemRedeemableSubscriptions;
