import { Grid, Tab as MuiTab, Tabs, styled } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import useViewParam from "hooks/useViewParam";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "store";
import { getNormalizedAppointments } from "store/globalThunks";
import { selectAppointmentIsLoading } from "store/slices/appointments/appointmentsSelectors";

import PastAppointments from "./PastAppointments";
import UpcomingAppointments from "./UpcomingAppointments";

enum TABS {
  upcomingBookings = "upcomingBookings",
  pastBookings = "pastBookings",
}

const UserAppointmentsPage = () => {
  const { t } = useTranslation("userAppointmentsPage");
  const dispatch = useDispatch();
  const areAppointmentsLoading = useSelector(selectAppointmentIsLoading);

  const hasFetchedAppointments = useRef(false);
  const [activeTab, setActiveTab] = useViewParam(TABS, TABS.upcomingBookings);

  useEffect(() => {
    if (!hasFetchedAppointments.current) {
      dispatch(getNormalizedAppointments({}));
      hasFetchedAppointments.current = true;
    }
  }, [dispatch]);

  if (areAppointmentsLoading) return <CentredSpinnerBox />;

  return (
    <Grid container bgcolor="bg.secondary">
      <Grid item xs={12}>
        <Tabs value={activeTab} onChange={(_, value) => setActiveTab(value)} variant="fullWidth">
          <Tab value={TABS.upcomingBookings} label={t("upcomingBookings")} />
          <Tab value={TABS.pastBookings} label={t("pastBookings")} />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {activeTab === TABS.upcomingBookings && <UpcomingAppointments />}
        {activeTab === TABS.pastBookings && <PastAppointments />}
      </Grid>
    </Grid>
  );
};

export default UserAppointmentsPage;

const Tab = styled(MuiTab)({
  margin: 0,
});
