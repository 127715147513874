import { Button } from "@mui/material";
import useParamOutlet from "hooks/useParamOutlet";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "store";
import {
  selectCart,
  selectOrganization,
  selectServiceById,
  selectServiceVariantById,
} from "store/selectors";
import { addArticleToCart } from "store/slices/cart/cartSlice";
import { trackEvent } from "tracking";
import { calculateDeposit } from "utils/deposit";

import { useBookingContext } from "../bookingContext";
import { getSlotDateTime } from "../BookingSlotPicker/slotPickerHelpers";

export default function ServiceBookingAddToCartButton() {
  const { t } = useTranslation("bookingPage");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    state: { selectedSlot, serviceEmployees, subscriptionPurchaseId },
  } = useBookingContext();

  const [serviceVariantId, employeeId] = serviceEmployees[0];

  const cart = useSelector(selectCart);
  const organization = useSelector(selectOrganization);
  const serviceVariant = useSelector(selectServiceVariantById(serviceVariantId));
  const service = useSelector(selectServiceById(serviceVariant?.service || 0));
  const outlet = useParamOutlet();

  function addArticle() {
    if (!selectedSlot || !serviceVariant || !service)
      throw new Error("Cannot add to cart, invalid slot or service variant");

    const startTime = getSlotDateTime(selectedSlot);
    const cartArticle = generateCartArticle(
      serviceVariant,
      startTime,
      employeeId,
      subscriptionPurchaseId,
      service.depositPercentage
    );

    dispatch(addArticleToCart(cartArticle));

    if (organization?.id && organization?.name) {
      trackEvent(
        !!cart.articles.length ? "Item added to cart" : "Cart Initiated",
        { id: organization.id, name: organization.name },
        { isResourceSelected: !!employeeId }
      );
    }

    navigate(`/${outlet.slug}/appointment-review`);
  }

  return (
    <Button fullWidth disabled={!selectedSlot} onClick={addArticle} size="medium">
      {t("common:apply")}
    </Button>
  );
}

function generateCartArticle(
  { duration, id, price }: ServiceVariant,
  startTime: DateTime,
  staff: Nullable<number>,
  subscriptionPurchaseId: Nullable<number>,
  depositPercentage: Nullable<number>
) {
  const start = startTime.toISO();
  const end = startTime.plus({ minutes: duration }).toISO();

  return {
    serviceVariant: id,
    packageVariant: null,

    totalPrice: Number(price),

    slots: [{ serviceVariant: id, staff, start, end }],
    subscriptionPurchaseId,
    amountToBePaid: calculateDeposit(depositPercentage, Number(price)),
  };
}
