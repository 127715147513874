import { Box, Grid } from "@mui/material";
import SearchField from "components/SearchField";
import SectionWrapper from "components/SectionWrapper";
import Fuse from "fuse.js";
import sortAlphabetically from "helpers/array/sortAlphabetically";
import useParamOutlet from "hooks/useParamOutlet";
import { ChangeEvent, memo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectEmployeesByOutletId } from "store/selectors";

import EmployeeCard from "./EmployeeCard";
import NoResults from "./NoResults";

const EmployeeList = () => {
  const { t } = useTranslation();

  const [query, setQuery] = useState("");

  const { id: outletId } = useParamOutlet();

  const outletEmployees = useSelector(selectEmployeesByOutletId(outletId));

  const sortedEmployees = outletEmployees.sort(sortAlphabetically("firstName"));

  const changeSearchQuery = (event: ChangeEvent<HTMLInputElement>) => setQuery(event.target.value);

  const handleClearSearch = () => setQuery("");

  let filteredEmployees = sortedEmployees;

  if (query) {
    const employeesWithTranslations = outletEmployees.map((employee) => ({
      ...employee,
      translatedFullNameAr: t(employee.t.fullName, { lng: "ar" }),
    }));

    const fuse = new Fuse(employeesWithTranslations, {
      threshold: 0.4,
      keys: ["fullName", "translatedFullNameAr"],
    });

    const result = fuse.search(query);

    filteredEmployees = result.map((obj) => obj.item);
  }

  const isListEmpty = !filteredEmployees.length;

  return (
    <Box bgcolor="#F7F8FD" pb={4}>
      <SectionWrapper p={2}>
        <SearchField
          variant="outlined"
          value={query}
          onChange={changeSearchQuery}
          onClear={handleClearSearch}
        />
      </SectionWrapper>

      <Grid container justifyContent="center" my={2} bgcolor="white" spacing={2} p={2}>
        {isListEmpty ? (
          <NoResults />
        ) : (
          filteredEmployees.map((employee) => (
            <Grid key={employee.id} item xs={12}>
              <EmployeeCard employee={employee} />
            </Grid>
          ))
        )}
      </Grid>
    </Box>
  );
};

export default memo(EmployeeList);
