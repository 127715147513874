import { Card, Grid, Skeleton } from "@mui/material";
import { memo } from "react";

const CategoryCardSkeleton = () => (
  <Card sx={{ height: "120px", display: "flex", flexDirection: "row" }}>
    <Grid container spacing={3}>
      <Grid item xs={7}>
        <Skeleton variant="text" width="150px" />
        <Skeleton variant="text" width="75px" />
        <Skeleton variant="text" width="100px" />
      </Grid>
      <Grid item xs={2}>
        <Skeleton variant="text" />
      </Grid>
      <Grid item xs={3}>
        <Skeleton variant="rectangular" height="31px" />
      </Grid>
    </Grid>
  </Card>
);
export default memo(CategoryCardSkeleton);
