import { RootState } from "store";

import { packageCategoriesAdapter } from "./packageCategoriesSlice";

const selectPackageCategoriesState = (state: RootState) => state.packageCategories;

const { selectById, selectAll } = packageCategoriesAdapter.getSelectors(
  selectPackageCategoriesState
);

export const selectPackageCategoryById = (id: string) => (state: RootState) =>
  selectById(state, id);

export const selectAllPackageCategories = (state: RootState) =>
  selectAll(state).sort((a, b) => (a.position || 0) - (b.position || 0));
