import { Box, Button, Typography, styled } from "@mui/material";
import { CancellationPolicyIcon } from "assets/icons/CancellationPolicy";
import DrawerModal from "components/DrawerModal";
import getFormattedDuration from "helpers/datetime/getFormattedDuration";
import { useTranslation } from "react-i18next";

type CancellationPolicyModalProps = {
  open: boolean;
  onClose: () => void;

  cancellationTimeFrame?: number;
  isCancellationAllowed?: boolean;
  orgName?: string;
};

const CancellationPolicyModal = ({
  open,
  onClose,
  cancellationTimeFrame,
  isCancellationAllowed,
  orgName,
}: CancellationPolicyModalProps) => {
  const { t } = useTranslation("common");

  const cancellationPolicyModal = (
    <Box display="flex" mt={2} gap={1}>
      <CancellationPolicyIcon
        sx={{
          fontSize: 34,
        }}
      />

      <Typography fontWeight={700}>{t("cancellationPolicy")}</Typography>
    </Box>
  );

  return (
    <DrawerModal
      title={cancellationPolicyModal}
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: { minHeight: isCancellationAllowed ? "40vh" : "30vh", padding: 2 },
      }}
    >
      <Typography variant="h5" color="#848FA1" mt={1}>
        {!isCancellationAllowed && orgName
          ? t("cancellationIsNotAllowed", { orgName })
          : t("cancellationPolicyParagraph", {
              timeNotice: getFormattedDuration(cancellationTimeFrame || 0),
            })}
      </Typography>

      <ModalAction px={2}>
        <Button fullWidth onClick={onClose} size="medium" sx={{ borderRadius: "25px" }}>
          {t("okay")}
        </Button>
      </ModalAction>
    </DrawerModal>
  );
};

export default CancellationPolicyModal;

const ModalAction = styled(Box)(() => ({
  position: "fixed",
  width: "calc(100%)",
  bottom: "16px",
  left: 0,
  backgroundColor: "white",
}));
