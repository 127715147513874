import { Box, Grid, Tab, Tabs } from "@mui/material";
import CentredSpinnerBox from "components/CentredSpinnerBox";
import { DateTime } from "luxon";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "store";
import { selectCartDate } from "store/selectors";

import { useBookingContext } from "../bookingContext";
import { BookingSlot } from "../bookingPageTypes";
import DatePickerTab from "./DatePickerTab";
import getSlotPickerDateLabel from "./getSlotPickerDateLabel";
import SlotPickerBinder from "./SlotPickerBinder";
import SlotPickerEmptyDate from "./SlotPickerEmptyDate";
import useAvailableSlots from "./useAvailableSlots";

const today = DateTime.now().startOf("day");

type BookingSlotPickerProps = {
  duration?: number;
  setCartDate: Dispatch<SetStateAction<DateTime | null>>;
};

export default function BookingSlotPicker({
  setCartDate,

  // this a fallback value to avoid children components that require duration prop to crash
  duration = 5,
}: BookingSlotPickerProps) {
  const { t } = useTranslation(["bookingPage", "common"]);

  const cartDate = useSelector(selectCartDate);

  const { state, dispatch } = useBookingContext();
  const { selectedSlot, serviceEmployees, packageVariantId } = state;

  const setSlot = (newSlot: Nullable<BookingSlot>) =>
    dispatch({ type: "setSlot", payload: newSlot });

  const handleDateChange = (newDate: DateTime) => {
    const newDateISO = newDate.toISODate();
    if (!tabDays.includes(newDateISO)) {
      const prevTabDays = tabDays;
      prevTabDays.push(newDateISO);
      tabDays.sort((a, b) => {
        return new Date(a) < new Date(b) ? -1 : new Date(a) > new Date(b) ? 1 : 0;
      });

      setTabDays(tabDays);
    }
    setCartDate(newDate);
    dispatch({ type: "setSubscriptionPurchase", payload: null });
    setSelectedDate(newDateISO);
  };

  const [tabDays, setTabDays] = useState(() =>
    Array.from({ length: 5 }, (_, index) => today.plus({ days: index }).toISODate())
  );

  // Date tabs management
  const [selectedDate, setSelectedDate] = useState(cartDate?.toISODate() || tabDays[0]);

  const changeDate = (_: any, newDate: string) => {
    if (!cartDate) {
      setCartDate(DateTime.fromISO(newDate));
      dispatch({ type: "setSubscriptionPurchase", payload: null });
      setSelectedDate(newDate);
      dispatch({ type: "setSlot", payload: null });
    }
  };

  useEffect(() => {
    if (cartDate) setSelectedDate(cartDate.toISODate());
  }, [cartDate]);

  const { areSlotsLoading, availableSlots } = useAvailableSlots(selectedDate);

  const hasNoAvailableSlotsOnDate = availableSlots.length === 0;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Tabs value={selectedDate} onChange={changeDate} variant="scrollable" scrollButtons={false}>
          {tabDays.map((date) => (
            <Tab
              label={t(getSlotPickerDateLabel(date)[0])}
              key={date}
              value={date}
              disabled={!!cartDate && cartDate.toISODate() !== date}
            />
          ))}

          {cartDate && !tabDays.includes(cartDate.toISODate()) && (
            <Tab
              label={t(getSlotPickerDateLabel(cartDate.toISODate())[0])}
              value={cartDate.toISODate()}
            />
          )}

          <DatePickerTab
            selectedCalendarDate={null}
            handleDateChange={handleDateChange}
            disabled={!!cartDate}
          />
        </Tabs>
      </Grid>

      <Grid item xs={12}>
        {areSlotsLoading ? (
          <Skeleton />
        ) : hasNoAvailableSlotsOnDate ? (
          <SlotPickerEmptyDate date={selectedDate} />
        ) : (
          <SlotPickerBinder
            duration={duration}
            options={availableSlots}
            value={selectedSlot}
            onChange={setSlot}
            employeeIdFilter={packageVariantId ? null : serviceEmployees[0][1]}
          />
        )}
      </Grid>
    </Grid>
  );
}

// This is a placeholder, if you ever implement this component please move it to a separate file
const Skeleton = () => (
  <Box display="flex" alignItems="center" justifyContent="center" minHeight={130.5}>
    <CentredSpinnerBox />
  </Box>
);
