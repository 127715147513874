import { Grid } from "@mui/material";
import SectionWrapper from "components/SectionWrapper";
import { Subscription } from "types/Subscription";

import SubscriptionListItem from "./SubscriptionListItem";

type SubscriptionListProps = {
  filteredSubscriptions: Subscription[];
};

const SubscriptionList = ({ filteredSubscriptions }: SubscriptionListProps) => {
  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item xs={12} mb={2}>
        <SectionWrapper>
          <Grid container justifyContent="center" spacing={1} p={2}>
            {filteredSubscriptions.map((subscription) => (
              <Grid
                key={subscription?.id ?? 0}
                item
                xs={12}
                sx={{ "&:not(:last-child) > div": { boxShadow: "thinDivider" } }}
              >
                <SubscriptionListItem subscriptionId={subscription?.id} />
              </Grid>
            ))}
          </Grid>
        </SectionWrapper>
      </Grid>
    </Grid>
  );
};
export default SubscriptionList;
