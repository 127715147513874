import EventEmitter from "events";

import { Close } from "@bookpeep/ui";
import { Alert, IconButton, Snackbar } from "@mui/material";
import getUniqueId from "helpers/getUniqueId";
import { useCallback, useEffect, useState } from "react";

type EventSeverity = "success" | "warning" | "error";

type SnackbarEventOptions = {
  durationInSeconds?: number;
  shouldPersist?: boolean;
};

type SnackbarEvent = {
  message: string;
  severity: EventSeverity;
  options?: SnackbarEventOptions;
};

const snackbarEventEmitter = new EventEmitter();

export function notify(message: string, severity: EventSeverity, options?: SnackbarEventOptions) {
  const defaultOptions = { durationInSeconds: 5, shouldPersist: true, ...options };

  snackbarEventEmitter.emit("notify", { message, severity, options: defaultOptions });
}

export default function SnackbarProvider() {
  const [snackbars, setSnackbars] = useState<[id: number, snackbar: SnackbarEvent][]>([]);

  const insertSnackbar = useCallback((snackbar: SnackbarEvent) => {
    const { options } = snackbar;
    const uniqueId = getUniqueId();
    const id = Number(uniqueId);

    setSnackbars((previousValue) => [...previousValue, [id, snackbar]]);

    if (!options?.shouldPersist && options?.durationInSeconds) {
      setTimeout(() => {
        removeSnackbar(id);
      }, options?.durationInSeconds * 1000);
    }
  }, []);

  const removeSnackbar = (idToRemove: number) => {
    setSnackbars((previousValue) => previousValue.filter(([id]) => +id !== +idToRemove));
  };

  useEffect(() => {
    snackbarEventEmitter.on("notify", insertSnackbar);

    return () => {
      snackbarEventEmitter.off("notify", insertSnackbar);
    };
  }, [insertSnackbar]);

  return (
    <>
      {snackbars.map(([key, value], index) => (
        <Snackbar
          key={key}
          open
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{ top: 8 + index * 55 }}
        >
          <Alert
            variant="filled"
            severity={value.severity}
            action={
              <IconButton onClick={() => removeSnackbar(key)}>
                <Close sx={{ fill: "white", fontSize: 22 }} />
              </IconButton>
            }
            sx={{
              display: "flex",
              alignItems: "center",
              "& .MuiAlert-action": {
                paddingTop: 0,
              },
            }}
          >
            {value.message}
          </Alert>
        </Snackbar>
      ))}
    </>
  );
}
