import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const ChangePasswordSection = () => {
  const { t } = useTranslation("userProfilePage");

  return (
    <Typography>
      {t("tryChangePassword")} <Link to="/profile/reset-password">{t("change")}</Link>
    </Typography>
  );
};

export default ChangePasswordSection;
